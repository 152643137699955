import React, { useState } from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

import { SEASON_CONFIG_URL } from "../../../../api/api_routing_urls";
import axios from "../../../../api/axios";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../../theme/lightTheme";

export default function SeasonList({
  seasonList,
  getSeasonList,
  setEditSeasonDetails,
  setShowAddForm,
}) {
  const onClickEdit = (season) => {
    setEditSeasonDetails(season);
    setShowAddForm(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [seasonDeleteId, setSeasonDeleteId] = useState(null);

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", seasonDeleteId);
    try {
      let response = "";
      if (seasonDeleteId) {
        response = await axios.post(`${SEASON_CONFIG_URL}/delete`, {
          season_id: seasonDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        showToast("Season details has been deleted successfully.", "success");
        getSeasonList();
      } else {
        showToast("Season details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setSeasonDeleteId(null);
    }
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            Season Name
          </th>
          <th
            scope="col"
            className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
        {seasonList?.length > 0 &&
          seasonList?.map((season, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {season.season_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(season)}
                    aria-label="Edit Season Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      setSeasonDeleteId(season?.season_id || null);
                      setShowDelete(true);
                    }}
                    aria-label="Delete Season Details"
                    className={linkTextDanger}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This entry will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </TableWrapper>
  );
}
