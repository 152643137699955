import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";

import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const UpdateOrderAwbForm = ({
  editOrderAwbDetails,
  setEditOrderAwbDetails,
  setShowUpdateForm,
  getShippedOrderDetailsAdminList,
}) => {
  const defaultValues = {
    awb_tracking_number: editOrderAwbDetails?.awb_tracking_number,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let sendDataObj = {
        order_id: editOrderAwbDetails?.order_id,
        awb_tracking_number: data?.awb_tracking_number,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL}/awbnumberupdate`,
        sendDataObj
      );

      if (response.status === 200) {
        showToast("Order AWB Number updated successfully!", "success");
        getShippedOrderDetailsAdminList();
        setShowUpdateForm(false);
      } else {
        showToast("Order AWB Number updation error!", "error");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <section>
      <div className="flex flex-col bg-white border border-gray-400 p-4 rounded-md">
        <p className="text-xs font-bold">
          You are updating the AWB Number for the Order No:{" "}
        </p>
        <div className="flex gap-3">
          <p className="text-lg font-semibold">
            {editOrderAwbDetails.order_no}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="awb_tracking_number"
          register={register}
          name="AWB Tracking Number"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter the AWB Number"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border-2 focus:border-black`}
          onChangeInput={null}
          defaultValue={defaultValues.awb_tracking_number}
          setValue={setValue}
        />
        <div className="flex justify-between pt-4">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditOrderAwbDetails([]);
              setValue("awb_tracking_number", "");
              setShowUpdateForm(false);
            }}
            aria-label="Cancel AWB Number Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UpdateOrderAwbForm;
