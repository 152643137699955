import React, { useState } from "react";

import { RxCross2 } from "react-icons/rx";

const BillingAddressCardMobile = ({
  billing_id,
  first_name,
  last_name,
  address_line1,
  address_line2,
  landmark,
  city_name,
  state_name,
  pincode,
  country_name,
  phone_number,
  onBillingClickHandler,
  selectedBillingId,
  onClickEdit,
  setBillingDetailsDeleteId,
  setShowDelete,
  mapObj,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <section className="grid grid-cols-3 border-b border-gray-400 -mx-2 pb-2">
      <div
        // onClick={() => onBillingClickHandler(billing_id)}
        className={`col-span-2 px-3 cursor-pointer font-tenorsans`}
      >
        <div className="text-sm uppercase mb-1">
          {first_name} {last_name}
        </div>
        <div className="text-xs">{address_line1}</div>
        <div className="text-xs">{address_line2}</div>
        {landmark !== null ? <div className="text-xs">{landmark}</div> : null}
        <div className="capitalize text-xs">
          {city_name} {pincode}
        </div>
        <div className="uppercase text-xs">{state_name}</div>
        <div className="text-xs">{country_name}</div>
        <div className="text-xs">
          <span className="mr-1">+91</span> {phone_number}
        </div>
      </div>

      <div className="col-span-1">
        {!showOptions ? (
          <div
            onClick={() => setShowOptions(true)}
            className="font-bold text-xl h-[70%] flex flex-col justify-center items-end mr-4 tracking-widest"
          >
            ...
          </div>
        ) : (
          <div className="py-5 flex flex-col gap-1 h-[70%] justify-center">
            <div
              onClick={() => setShowOptions(false)}
              className="font-bold text-xs mr-4 tracking-widest flex justify-end"
            >
              <RxCross2 size={12} />
            </div>

            <div className="flex gap-x-2 justify-end mr-4">
              <div
                onClick={() => onClickEdit(mapObj)}
                className="text-xs cursor-pointer text-black uppercase"
              >
                Edit
              </div>

              <div
                onClick={() => {
                  setBillingDetailsDeleteId(billing_id);
                  setShowDelete(true);
                }}
                className="text-xs cursor-pointer text-black uppercase"
              >
                Remove
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BillingAddressCardMobile;
