import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartQuantity: 0,
};

export const cartQuantitySlice = createSlice({
  name: "cartQuantity",
  initialState,
  reducers: {
    setCartQuantity: (state, action) => {
      state.cartQuantity = action.payload;
    },

    resetCartQuantity: (state) => {
      state.cartQuantity = 0;
    },
  },
});

export const { setCartQuantity, resetCartQuantity } = cartQuantitySlice.actions;

export default cartQuantitySlice.reducer;
