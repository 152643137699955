import React, { useState, useEffect } from "react";

import showToast from "../../../utilities/notification/NotificationModal";
import deleteFile from "../../../utilities/uploadFiles/deleteFileController";

import { PRODUCT_IMAGE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import DeleteModal from "../../../reusable-components/modals/DeleteModal";

import { IoCloseSharp } from "react-icons/io5";
import AddProductImagesForm from "./addProductImagesForm.component";

const ProductImagesList = ({
  setShowProductImagesList,
  selectedProduct,
  setSelectedProduct,
  getProductWithVariantDetailsList,
}) => {
  const [productWithImageDetailsList, setProductWithImageDetailsList] =
    useState([]);

  const [imageDetails, setImageDetails] = useState();
  const [showAddForm, setShowAddForm] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [imageDeleteId, setImageDeleteId] = useState(null);

  // const defaultPrefix = "https://gratitude.sgp1.cdn.digitaloceanspaces.com/";
  const defaultPrefix = "https://files.gratitudefg.com/products/";

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", imageDeleteId);

    try {
      let response = "";
      if (imageDeleteId) {
        response = await axios.post(
          `${PRODUCT_IMAGE_DETAILS_CONFIG_URL}/delete`,
          {
            file_id: imageDeleteId?.img_id,
          }
        );
        setShowDelete(false);
      }

      if (response.status === 200) {
        await deleteFile(imageDeleteId?.img_url);
        showToast("Image details has been deleted successfully.", "success");
        getProductWithImageDetailsList(selectedProduct.product_id);
      } else {
        showToast("Image details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setImageDeleteId(null);
    }
  };

  const getProductWithImageDetailsList = async (product_id) => {
    try {
      const response = await axios.get(PRODUCT_IMAGE_DETAILS_CONFIG_URL, {
        params: {
          product_id,
        },
      });
      // console.log("Product with image details List", { response });
      response.status === 200 &&
        setProductWithImageDetailsList(
          response.data.productWithImageDetailsList
        );
      response.status === 202 &&
        showToast("No product image found in the system.", "error");
    } catch (error) {
      console.error("getProductWithImageDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  // console.log("Selected Product", selectedProduct);
  // console.log("Product with image details List", productWithImageDetailsList);

  const onBackClickHandler = () => {
    setShowProductImagesList((prev) => !prev);
    setSelectedProduct();
    getProductWithVariantDetailsList();
  };

  const addImageClickHandler = (product) => {
    setImageDetails({
      color_name: product.color_name,
      color_variety_id: product.color_variety_id,
    });
    setShowAddForm(true);
  };

  //useEffect to fetch variants combinations and images details for the particular selected product id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithImageDetailsList(selectedProduct.product_id);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProduct.product_id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setImageDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {!showAddForm ? (
        <>
          <div className="flex justify-between my-5 py-2 border-b border-gray-400">
            <div className="font-bold uppercase px-2 py-1">
              {selectedProduct.product_name}
            </div>
            <div
              className="flex justify-end px-4 py-1 text-xs text-black hover:underline cursor-pointer"
              onClick={() => onBackClickHandler()}
            >
              Back to list
            </div>
          </div>

          <div>
            <div className="flex flex-col gap-4">
              {selectedProduct?.color_details?.map((mapObj, index) => (
                <div key={index}>
                  <div className="text-sm font-semibold py-1 my-3">
                    {mapObj.color_name} Variant
                    <span
                      className="bg-black w-full cursor-pointer px-2 py-0.5 ml-6 text-white"
                      onClick={() => addImageClickHandler(mapObj)}
                    >
                      +
                    </span>
                  </div>

                  <div className="grid grid-cols-6 gap-3 pt-4">
                    {productWithImageDetailsList
                      ?.filter(
                        (prodimgObj) =>
                          +prodimgObj.color_id === +mapObj.color_id
                      )
                      .map((img) => (
                        <div className="relative">
                          <img src={img?.file_url} alt="Product Image" />
                          <div
                            className="absolute top-0 right-0 p-1 bg-black text-white cursor-pointer"
                            onClick={() => {
                              setImageDeleteId({
                                img_id: img?.file_id || null,
                                img_url: img?.file_url
                                  ? img?.file_url?.replace(
                                      `${defaultPrefix}`,
                                      ""
                                    )
                                  : null,
                              });
                              setShowDelete(true);
                            }}
                          >
                            <IoCloseSharp color="white" />
                          </div>
                        </div>
                      ))}
                  </div>
                  {productWithImageDetailsList?.filter(
                    (prodimgObj) => +prodimgObj.color_id === +mapObj.color_id
                  ).length === 0 ? (
                    <>
                      <p className="mb-6 text-slate-700 text-sm">
                        No images found for the {mapObj.color_name} variant.
                      </p>
                    </>
                  ) : // (
                  //   <div className="text-sm uppercase text-slate-700">
                  //     No images found for the {mapObj.color_name} variant.
                  //   </div>
                  // )
                  null}
                </div>
              ))}
            </div>
          </div>
          <>
            <DeleteModal
              open={showDelete}
              setOpen={setShowDelete}
              message={"This image will be deleted. Are you sure?"}
              onDelete={onClickDelete}
            />
          </>
        </>
      ) : (
        <>
          <AddProductImagesForm
            imageDetails={imageDetails}
            setImageDetails={setImageDetails}
            setShowAddForm={setShowAddForm}
            getProductWithImageDetailsList={getProductWithImageDetailsList}
            selectedProduct={selectedProduct}
          />
        </>
      )}
    </>
  );
};

export default ProductImagesList;
