import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { PRODUCT_PRICE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";

import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const UpdateVariantPriceForm = ({
  editPriceDetails,
  setEditPriceDetails,
  setShowAddForm,
  getProductWithPriceDetailsList,
  selectedProductId,
}) => {
  const defaultValues = {
    product_price: editPriceDetails?.product_price,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let response = "";
      data.price_id = editPriceDetails?.price_id;
      response = await axios.post(PRODUCT_PRICE_DETAILS_CONFIG_URL, data);

      // console.log({ response });

      if (response.status === 200) {
        setEditPriceDetails([]);
        showToast("Price details has been updated successfully.", "success");
        getProductWithPriceDetailsList(selectedProductId);
      } else {
        showToast("Price details updation error.", "error");
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <section>
      <div className="flex flex-col bg-yellow-100 p-4 rounded-md">
        <p className="text-xs font-bold">You are updating price for: </p>
        <div className="flex gap-3">
          <p className="text-xs">{editPriceDetails.product_name} - </p>
          <p className="text-xs">{editPriceDetails.fabric_type_name}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="product_price"
          register={register}
          name="Product Price"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter the price"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="number"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.product_price}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditPriceDetails([]);
              setValue("product_price", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Price Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UpdateVariantPriceForm;
