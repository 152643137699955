import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { BsCalendarCheck } from "react-icons/bs";
import { RxReader } from "react-icons/rx";
import { BsCalendar3 } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineFlightTakeoff } from "react-icons/md";
import { FaSignal } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";

import axios from "../../../api/axios";

import { ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";

import UpdateOrderAwbForm from "./updateOrderAwbForm.component";

import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import GenericModal from "../../../reusable-components/modals/GenericModal";

const TrackOrdersConfig = () => {
  const [shippedOrderDetailsAdminList, setShippedOrderDetailsAdminList] =
    useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editOrderAwbDetails, setEditOrderAwbDetails] = useState({});

  let curDate = new Date().toLocaleDateString("en-GB");

  const getShippedOrderDetailsAdminList = async () => {
    try {
      const response = await axios.get(ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL);
      // console.log("Shipped Orders List for Admin", { response });
      response.status === 200 &&
        setShippedOrderDetailsAdminList(
          response.data.shippedOrderDetailsAdminList
        );
      // console.log("shippedOrderDetailsAdminList", {
      //   shippedOrderDetailsAdminList,
      // });
      response.status === 202 &&
        showToast("No shipped orders found in the system.", "error");
    } catch (error) {
      console.error("getShippedOrderDetailsAdminList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onUpdateClickHandler = (orderObj) => {
    setEditOrderAwbDetails(orderObj);
    setShowUpdateForm(true);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getShippedOrderDetailsAdminList();
      setEditOrderAwbDetails({});
      setShowUpdateForm(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard>
        <ToastContainer />
        <div className="flex justify-between  pb-5">
          <div className="font-bold text-lg">
            Shipped Orders AWB Tracking Numbers
          </div>
          <div className="flex gap-2 items-center justify-center">
            <BsCalendarCheck color="black" size={16} />
            <div className="font-semibold">{curDate}</div>
          </div>
        </div>

        <div className="pt-5 grid grid-cols-3 gap-x-28">
          <div className="px-8 py-4 flex flex-col rounded-sm bg-red-50">
            <div className="text-sm text-gray-600 font-extrabold">
              Tracking ID Not Provided
            </div>
          </div>

          <div className="px-8 py-4 flex flex-col rounded-sm bg-yellow-50">
            <div className="text-sm text-gray-600 font-extrabold">
              Tracking ID Provided
            </div>
          </div>
        </div>

        <>
          <div className="pt-10">
            <table className="w-full">
              <thead>
                <tr className="bg-black">
                  <th className="py-5 px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <RxReader color="white" />
                      </div>
                      <div className="font-light text-white">Order No</div>
                    </div>
                  </th>

                  <th className="px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <BsCalendar3 color="white" />
                      </div>
                      <div className="font-light text-white">Order Date</div>
                    </div>
                  </th>

                  <th className="px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <AiOutlineUser color="white" />
                      </div>
                      <div className="font-light text-white">Customer Name</div>
                    </div>
                  </th>

                  <th className="px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <MdOutlineFlightTakeoff color="white" />
                      </div>
                      <div className="font-light text-white">AWB Number</div>
                    </div>
                  </th>

                  <th className="px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <BiEdit color="white" />
                      </div>
                      <div className="font-light text-white">Action</div>
                    </div>
                  </th>

                  <th className="px-8">
                    <div className="flex gap-1 items-center">
                      <div>
                        <FaSignal color="white" />
                      </div>
                      <div className="font-light text-white">Order Status</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {shippedOrderDetailsAdminList?.length > 0 ? (
                  <>
                    {shippedOrderDetailsAdminList?.map((orderObj, index) => (
                      <tr
                        key={index}
                        // onClick={() => hasSeenUpdateHandler(orderObj?.order_id)}
                        className={`border-b ${
                          orderObj?.awb_tracking_number !== "0"
                            ? "bg-yellow-50"
                            : "bg-red-50"
                        }`}
                      >
                        <td className="py-5 px-8 text-sm font-bold">
                          {orderObj?.order_no}
                        </td>

                        <td className="px-8 text-sm">
                          {getFormattedDateDDMMMYYYYDate(orderObj?.order_date)}
                        </td>

                        <td className="px-8 text-sm">
                          {orderObj?.ordered_by.fname}{" "}
                          {orderObj?.ordered_by.lname}
                        </td>
                        <td className="px-8 text-sm">
                          {orderObj?.awb_tracking_number}
                        </td>

                        <td
                          onClick={() => onUpdateClickHandler(orderObj)}
                          className="px-8 cursor-pointer text-sm text-black hover:underline"
                        >
                          Update AWB
                        </td>

                        <td className="px-8 text-sm">
                          {orderObj?.order_status}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="py-2 bg-slate-100 text-sm font-semibold text-center"
                    >
                      No orders have been shipped!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {showUpdateForm && (
            <GenericModal
              open={showUpdateForm}
              setOpen={setShowUpdateForm}
              title={"Update Order AWB Number"}
              isAdd={false}
              clearData={setEditOrderAwbDetails}
            >
              <UpdateOrderAwbForm
                editOrderAwbDetails={editOrderAwbDetails}
                setEditOrderAwbDetails={setEditOrderAwbDetails}
                setShowUpdateForm={setShowUpdateForm}
                getShippedOrderDetailsAdminList={
                  getShippedOrderDetailsAdminList
                }
              />
            </GenericModal>
          )}
        </>
      </Dashboard>
    </>
  );
};

export default TrackOrdersConfig;
