import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { USER_REGISTRATION_CONFIG_URL } from "../../../api/api_routing_urls";

import axios from "../../../api/axios";
import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";

import UserRegistrationAdminList from "./userRegistrationAdminList.component";

const UserRegistrationAdminConfig = () => {
  const [userRegistrationRequestList, setUserRegistrationRequestList] =
    useState([]);

  const getUserRegistrationRequestList = async () => {
    try {
      const response = await axios.get(USER_REGISTRATION_CONFIG_URL);
      // console.log("User Registration Request List", { response });
      response.status === 200 &&
        setUserRegistrationRequestList(response?.data?.userRegistrationDetails);
      response.status === 202 &&
        showToast("No user registration request found in the system.", "error");
    } catch (error) {
      console.error("getUserRegistrationRequestList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUserRegistrationRequestList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      <div className="font-bold text-lg pb-5">Registration Requests</div>
      <div className="grid grid-cols-1">
        <UserRegistrationAdminList
          userRegistrationRequestList={userRegistrationRequestList}
          getUserRegistrationRequestList={getUserRegistrationRequestList}
        />
      </div>
    </Dashboard>
  );
};

export default UserRegistrationAdminConfig;
