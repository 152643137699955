import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  setShowSearchBar,
  setIsSearchDrawerOpen,
} from "../../../features/searchBar/searchBarSlice";

import axios from "../../../api/axios";
import { SEARCHED_PRODUCT_LIST_CONFIG_URL } from "../../../api/api_routing_urls";

import ProductCard from "../../../reusable-components/product-card/productCard.component";

const SearchedProducts = () => {
  const dispatch = useDispatch();

  const { searchTerm } = useParams();

  const [searchedProductList, setSearchedProductList] = useState([]);

  const onSearchDetailsSubmit = async () => {
    // console.log("searchTerm", searchTerm);

    try {
      const response = await axios.get(`${SEARCHED_PRODUCT_LIST_CONFIG_URL}`, {
        params: {
          search_term: searchTerm,
        },
      });

      //   console.log({ response });

      if (response.status === 200) {
        setSearchedProductList(response?.data?.searchedProductCompleteDetails);
      } else {
        console.log("Search could not be processed.");
      }

      // reset();
    } catch (error) {
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  //useEffect to fetch complete list of products based on search term
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      onSearchDetailsSubmit(searchTerm);
    }
    return () => {
      isMounted = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(setShowSearchBar(false));
      dispatch(setIsSearchDrawerOpen(false));
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="bg-white font-tenorsans min-h-screen">
      <div className="pt-24 md:pt-28 lg:pt-40 pb-10 text-xs uppercase text-center text-gray-400">
        {searchedProductList?.length ? (
          <span className="pr-1 font-semibold text-gray-800">
            {searchedProductList?.length}
          </span>
        ) : (
          <span className="pr-1 font-semibold text-gray-800">0</span>
        )}
        Results for "{searchTerm}"
      </div>

      {searchedProductList?.length > 0 ? (
        <div className="px-3 pb-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <>
            {searchedProductList?.map((productObj) => (
              <div key={productObj?.product_id}>
                <Link to={`/view-product-details/${productObj?.product_id}`}>
                  <ProductCard
                    productObj={productObj}
                    productid={productObj?.product_id}
                    productthumbnail={productObj?.product_images[0]?.file_url}
                  />
                </Link>
              </div>
            ))}
          </>
        </div>
      ) : (
        <div className="text-sm uppercase text-gray-600 min-h-screen pl-3">
          Your search for "{searchTerm}" did not match any results
        </div>
      )}
    </section>
  );
};

export default SearchedProducts;
