import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fileTypeChecker } from "../validateDocuments/validateDocument";
import { v4 as uuidv4 } from "uuid";
import resizeImage from "./resizeImage";

const uploadFileController = async (file) => {
  try {
    let fileType = await fileTypeChecker(file);
    if (fileType === "unknown") {
      return "Error, unknown file type";
    }

    const s3Client = new S3Client({
      endpoint: `${process.env.REACT_APP_DO_ENDPOINT}`,
      forcePathStyle: false,
      region: `${process.env.REACT_APP_DO_REGION}`,
      credentials: {
        accessKeyId: `${process.env.REACT_APP_DO_ACCESS_KEY_ID}`,
        secretAccessKey: `${process.env.REACT_APP_DO_SPACES_SECRET}`,
      },
    });

    if (fileType === "IMG" && file?.size > 500256) {
      const fileBlob = await resizeImage(file);
      file = new File([fileBlob], file?.name, { type: file?.type });
    }

    const uniqueId = uuidv4();
    const params = {
      Bucket: process.env.REACT_APP_DO_BUCKET,
      Key: `${process.env.REACT_APP_DO_FOLDER}/${uniqueId}_${file?.name}`,
      Body: file,
      ACL: "public-read",
      Metadata: {
        "Content-Disposition": "inline",
      },
      ContentType: file?.type,
      ContentDisposition: "inline",
    };

    const data = await s3Client.send(new PutObjectCommand(params));
    if (data.$metadata.httpStatusCode === 200) {
      const responseUrl = `https://files.gratitudefg.com/${params.Key}`;
      return responseUrl;
    } else {
      return "Error, uploading file.";
    }
  } catch (error) {
    console.error("Error", error);
    return "Error, " + error;
  }
};

export default uploadFileController;
