import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import rsa from "../../../utilities/rsaFunction/rsaEncryption";

import { setAuth } from "../../../features/authProvider/authProviderSlice";

import {
  USER_LOGIN_USING_OTP_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import ProfileDetails from "./profile_details.component";
import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import {
  reContact,
  numberOnly,
} from "../../../utilities/constants/regularexpressions";

const { rsaEncrypt } = rsa;

const OtpLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state.authProvider);

  const [loginFailStatus, setLoginFailStatus] = useState(0);

  const [rsaPublicKey, setRsaPublicKey] = useState();
  const [sessionUuid, setSessionUuid] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isNumberChecked, setIsNumberChecked] = useState(false);
  const [isLoggingInText, setIsLoggingInText] = useState(false);
  const [submittedPhoneNumber, setSubmittedPhoneNumber] = useState("");

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RSA_KEY_URL);
      if (response?.data?.status === 201) {
        setRsaPublicKey(response?.data?.publicKey);
        setSessionUuid(response?.data?.session_uuid);
        // console.log(response?.data);
      } else {
        setRsaPublicKey("");
        setSessionUuid("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPublicKeyRsa();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const defaultValues = {
    phone_number: "",
    session_otp: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const checkPhoneNumber = async (data) => {
    setIsLoggingInText(true);
    try {
      let sendDataObj = {
        phone_number: data?.phone_number,
      };
      let response = "";
      response = await axios.post(
        `${USER_LOGIN_USING_OTP_CONFIG_URL}/manageOtp`,
        sendDataObj
      );

      // console.log({ response });

      if (response.status === 200) {
        setIsNumberChecked(true);
        setSubmittedPhoneNumber(data?.phone_number);
      } else {
        console.log("Phone Number could not be verified. Please try again.");
        setLoginFailStatus(1);
        if (response.data.userdetails.includes("unique_phone_number")) {
          console.log("This phone number already exists.");
        } else {
          console.log(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
            "error"
          );
        }
        return;
      }

      reset();
    } catch (error) {
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoggingInText(false);
    }
  };

  const onSubmitLogin = async (data) => {
    let user_password = "passw0rd";

    if (rsaPublicKey && sessionUuid) {
      data.encryptedOtp = rsaEncrypt(data?.session_otp, rsaPublicKey);
      data.encryptedPassword = rsaEncrypt(user_password, rsaPublicKey);
      setIsLoggingInText(true);

      try {
        let sendDataObj = {
          phone_number: submittedPhoneNumber,
          session_otp: data?.encryptedOtp,
          user_password: data?.encryptedPassword,
          session_uuid: sessionUuid,
        };

        let response = "";

        response = await axios.post(
          USER_LOGIN_USING_OTP_CONFIG_URL,
          sendDataObj,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        // console.log({ response });

        if (response.status === 200) {
          // console.log("User logged in successfully.", "success");
          setLoginFailStatus(0);

          dispatch(
            setAuth({
              username: response.data.userdetails.username,
              accessToken: response.data.userdetails.accessToken,
              user_id: response.data.userdetails.user_id,
              first_name: response.data.userdetails.first_name,
              last_name: response.data.userdetails.last_name,
              gender: response.data.userdetails.gender,
              phone_number: response.data.userdetails.phone_number,
              roleid: response.data.userdetails.roleid,
              rolename: response.data.userdetails.rolename,
            })
          );
          navigate("/");
        } else {
          console.log("Either username or otp is wrong.", "error");
          setLoginFailStatus(1);
          if (response.data.userdetails.includes("unique_phone_number")) {
            console.log("This phone number already exists.", "error");
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
          return;
        }

        reset();
      } catch (error) {
        if (!error?.response) {
          console.log("No Server Response", "error");
        } else if (error.response.status === 422) {
          console.log(
            "Some of the required inputs were not provided.",
            "error"
          );
        } else {
          console.log(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
            "error"
          );
        }
      } finally {
        setIsLoggingInText(false);
      }
    } else {
      console.error(
        "Encryption error in login due to absence of rsaPublicKey!"
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginFailStatus(0);
      setSubmittedPhoneNumber("");
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : !auth?.user_id ? (
        <section className="font-tenorsans bg-white min-h-screen pt-24 lg:pt-36">
          <div>
            <>
              <div className="bg-white w-[80%] md:w-[60%] lg:w-[40%] mx-auto py-20">
                <div className="flex justify-center uppercase text-gray-600 text-sm lg:text-lg pb-3">
                  Log in
                </div>

                {loginFailStatus === 1 && (
                  <div className="flex justify-center text-red-500 text-xs lg:text-sm pb-3">
                    Seems like you have entered incorrect phone number or OTP.
                    Please try again.
                  </div>
                )}

                <section>
                  {!isNumberChecked ? (
                    <div className="mb-2">
                      <form onSubmit={handleSubmit(checkPhoneNumber)}>
                        <div className="flex flex-col gap-y-2">
                          <Input2
                            defaultName="phone_number"
                            register={register}
                            name="Phone Number"
                            required={false}
                            pattern={reContact}
                            errors={errors}
                            // placeholder="Enter your phone number"
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="number"
                            classes={`px-2 py-1 lg:px-3 lg:py-2 text-sm w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-black`}
                            onChangeInput={null}
                            defaultValue={defaultValues.phone_number}
                            setValue={setValue}
                          />

                          <div className="mt-6 flex flex-col flex-grow">
                            <button
                              onClick={() => handleSubmit}
                              className="bg-white text-gray-600 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                            >
                              {!isLoggingInText ? "Submit" : "Submitting..."}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div className="mb-2">
                      <div className="flex justify-center text-gray-600 text-xs lg:text-sm pb-3">
                        We have sent an OTP to your phone number. Please enter
                        it below.
                      </div>

                      <form onSubmit={handleSubmit(onSubmitLogin)}>
                        <div className="flex flex-col gap-y-2">
                          <Input2
                            defaultName="session_otp"
                            register={register}
                            name="OTP"
                            required={false}
                            pattern={numberOnly}
                            errors={errors}
                            // placeholder="Enter the OTP you received"
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="number"
                            classes={`px-2 py-1 lg:px-3 lg:py-2 text-sm w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none [-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none focus:outline-none focus:ring-0 focus:border-2 focus:border-black`}
                            onChangeInput={null}
                            defaultValue={defaultValues.session_otp}
                            setValue={setValue}
                          />

                          <div className="mt-6 flex flex-col flex-grow">
                            <button
                              onClick={() => handleSubmit}
                              className="bg-white text-gray-600 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                            >
                              {!isLoggingInText ? "Submit" : "Submitting..."}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </section>
              </div>
            </>
          </div>
        </section>
      ) : (
        <div className="py-10">
          <ProfileDetails />
        </div>
      )}
    </>
  );
};

export default OtpLogin;
