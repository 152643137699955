/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import "./swipeableImageSlider.styles.css";

const SwipeableImageSlider = ({ imagesArr }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    const diff = touchStart - touchEnd;
    if (diff > 150 && activeIndex < imagesArr.length - 1) {
      setActiveIndex(activeIndex + 1);
      setImageIndex(imageIndex + 1);
    }

    if (diff < -150 && activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      setImageIndex(imageIndex - 1);
    }
  };

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
    setImageIndex(index);
  };

  return (
    <div className="relative carousel_container">
      <div
        className="carousel_items"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          transform: `translateX(-${activeIndex * 100}%)`, // Adjust as needed
          transition: "transform 0.3s ease",
        }}
      >
        {imagesArr.map((imageObj, index) => (
          <div key={index} className="carousel_item">
            <img src={imageObj} alt={`Image ${index}`} />
          </div>
        ))}
      </div>

      {/* Nav Indicators */}
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          left: "50%",
          translate: "-35%",
          display: "flex",
          gap: ".25rem",
        }}
      >
        {imagesArr?.map((_, index) => (
          <div className="" key={index}>
            <button
              className={`img-slider-nav-btn ${
                index === imageIndex ? "active" : ""
              }`}
              onClick={() => handleIndicatorClick(index)}
            >
              {index === imageIndex ? (
                <div>
                  <svg
                    width="60"
                    height="20"
                    viewBox="0 0 76 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="30" height="1" fill="black" />
                  </svg>
                </div>
              ) : (
                <div>
                  <svg
                    width="60"
                    height="20"
                    viewBox="0 0 76 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="30" height="1" fill="#C5C5C5" />
                  </svg>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SwipeableImageSlider;
