import React, { useState } from "react";
import Button from "../../../reusable-components/buttons/Button";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../theme/lightTheme";

import addproductimage from "../../../assets/add_product.png";

import {
  PRODUCT_GENERIC_DETAILS_CONFIG_URL,
  PRODUCT_VARIANT_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function ProductVariantList({
  productWithVariantDetailsList,
  getProductWithVariantDetailsList,
  setEditProductVariantDetails,
  setAddProductVariantDetails,
  setShowAddForm,
  setIsAdd,
}) {
  const onClickEdit = (product) => {
    // console.log(
    //   "product received inside Edit [Product Variant Module]: ",
    //   product
    // );
    setEditProductVariantDetails(product);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (product) => {
    setAddProductVariantDetails(product);
    setShowAddForm(true);
    setIsAdd(true);
  };

  // console.log("productWithVariantDetailsList", productWithVariantDetailsList);

  return (
    <>
      <div className="font-bold text-lg pb-5">Product Variants</div>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Sl. No.
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Product Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Fabric Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Size
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Color
            </th>
            <th
              scope="col"
              className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
          {productWithVariantDetailsList?.length > 0 ? (
            <>
              {productWithVariantDetailsList?.map((product, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm">{product.product_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center gap-x-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product?.fit_details,
                      }}
                    />

                    <div>-</div>

                    <div>{product?.gender_name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.fabric_details === null ? (
                      <p className="text-xs text-gray-500 uppercase font-semibold">
                        Not added yet.
                      </p>
                    ) : (
                      <div className="flex gap-1">
                        {product.fabric_details?.map((fabricobj) => {
                          return (
                            <span className="text-xs px-2 py-1 border border-black rounded-sm">
                              {fabricobj?.fabric_type_name}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.size_details === null ? (
                      <p className="text-xs text-gray-500 uppercase font-semibold">
                        Not added yet.
                      </p>
                    ) : (
                      <div className="flex gap-1">
                        {product.size_details?.map((sizeobj) => {
                          return (
                            <span className="text-xs px-2 py-1 border border-black rounded-sm">
                              {sizeobj.size_name}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.color_details === null ? (
                      <p className="text-xs text-gray-500 uppercase font-semibold">
                        Not added yet.
                      </p>
                    ) : (
                      <div className="flex gap-1">
                        {product.color_details?.map((colorobj) => {
                          return (
                            <span className="text-xs px-2 py-1 border border-black rounded-sm">
                              {colorobj.color_name}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.fabric_details === null ? (
                      <>
                        <div className="flex justify-end">
                          <Button
                            onClick={() => onClickAdd(product)}
                            aria-label="Add Product Variant Details"
                            className={linkTextPrimary}
                          >
                            Add Variant
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-end">
                          <Button
                            onClick={() => onClickEdit(product)}
                            aria-label="Edit Product Variant Details"
                            className={linkTextPrimary}
                          >
                            Manage Variant
                          </Button>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr className="text-xs font-semibold">
              <td colSpan={4} className="text-center py-2">
                No products configured in the system!
              </td>
            </tr>
          )}
        </tbody>
      </TableWrapper>
    </>
  );
}
