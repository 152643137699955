// const LOGIN_URL = "/login";
const LOGOUT_URL = "/logout";

//GRATITUDE ADMIN URLS start

const PRODUCT_TYPE_CONFIG_URL = "/gratitude-admin/productTypeConfig";
const GENDER_CONFIG_URL = "/gratitude-admin/genderConfig";
const SEASON_CONFIG_URL = "/gratitude-admin/seasonConfig";
const SIZE_CONFIG_URL = "/gratitude-admin/sizeConfig";
const COLOR_CONFIG_URL = "/gratitude-admin/colorConfig";
const OCCASION_TYPE_CONFIG_URL = "/gratitude-admin/occasionTypeConfig";
const FABRIC_TYPE_CONFIG_URL = "/gratitude-admin/fabricTypeConfig";
const WEAR_TYPE_CONFIG_URL = "/gratitude-admin/wearTypeConfig";
const CLOTH_TYPE_CONFIG_URL = "/gratitude-admin/clothTypeConfig";
const TAG_CONFIG_URL = "/gratitude-admin/tagConfig";
const PRODUCT_GENERIC_DETAILS_CONFIG_URL =
  "/gratitude-admin/productGenericDetailsConfig";
const PRODUCT_VARIANT_DETAILS_CONFIG_URL =
  "/gratitude-admin/productVariantDetailsConfig";
const PRODUCT_STOCK_DETAILS_CONFIG_URL =
  "/gratitude-admin/productStockDetailsConfig";
const PRODUCT_PRICE_DETAILS_CONFIG_URL =
  "/gratitude-admin/productPriceDetailsConfig";
const PRODUCT_IMAGE_DETAILS_CONFIG_URL =
  "/gratitude-admin/productImageDetailsConfig";
const ORDER_DETAILS_ADMIN_CONFIG_URL = "/gratitude-admin/orderAdminConfig";
const ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL =
  "/gratitude-admin/orderTrackingAdminConfig";
const ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL =
  "/gratitude-admin/orderReturnAdminConfig";

const LEGAL_NOTICE_CONFIG_URL = "/gratitude-admin/legalNoticeConfig";
const ABOUT_US_GRATITUDE_CONFIG_URL = "/gratitude-admin/aboutUsConfig";
const RETURN_REFUND_POLICY_CONFIG_URL =
  "/gratitude-admin/returnRefundPolicyConfig";
const SHIPPING_POLICY_CONFIG_URL = "/gratitude-admin/shippingPolicyConfig";

const USER_REGISTRATION_CONFIG_URL = "/gratitude-admin/userRegistrationConfig";
const USER_LOGIN_CONFIG_URL = "/gratitude-admin/userLoginConfig";
const USER_LOGIN_USING_OTP_CONFIG_URL = "/gratitude-admin/userOtpLoginConfig";
const CHECK_USER_REGISTERED_URL = "/gratitude-admin/checkUserRegistered";
const USER_PROFILE_CONFIG_URL = "/gratitude-admin/userProfileConfig";
const PASSWORD_CHANGE_CONFIG_URL = "/gratitude-admin/passwordChangeConfig";
const PRODUCT_LIST_CONFIG_URL = "/gratitude-admin/productListPublicConfig";
const CART_DETAILS_CONFIG_URL = "/gratitude-admin/cartConfig";
const BILLING_DETAILS_CONFIG_URL = "/gratitude-admin/billingConfig";
const ORDER_DETAILS_CONFIG_URL = "/gratitude-admin/orderClientConfig";
const ORDER_RETURN_DETAILS_CONFIG_URL =
  "/gratitude-admin/orderReturnClientConfig";
const ORDER_RETURN_TRACKING_DETAILS_CONFIG_URL =
  "/gratitude-admin/orderReturnTrackingClientConfig";
const NEWSLETTER_SUBSCRIPTION_DETAILS_CONFIG_URL =
  "/gratitude-admin/newsletterSubscriptionConfig";
const WISHLIST_DETAILS_CONFIG_URL = "/gratitude-admin/wishlistConfig";

const SEARCHED_PRODUCT_LIST_CONFIG_URL =
  "/gratitude-admin/searchedProductListPublicConfig";

const FILTERED_PRODUCT_LIST_CONFIG_URL =
  "/gratitude-admin/filteredProductListPublicConfig";

//GRATITUDE ADMIN URLS end

const AUDIT_TRAIL_CONFIG_URL = "/sysAdmin/auditTrailConfig";

const CHANGE_PASSWORD = "/sysAdmin/changePassword";

const SEND_MAIL_URL = "/sendmail";

//#region Public URLs

const AWS_ACCESS_URL = "/getAWSAccess";
const RSA_KEY_URL = "/getRsaPublicKey";
const CAPTCHA_URL = "/getCaptcha";
const ROLELIST_URL = "/rolelist";

//#endregion

module.exports = {
  // LOGIN_URL,
  LOGOUT_URL,

  AWS_ACCESS_URL,

  RSA_KEY_URL,
  CAPTCHA_URL,
  AUDIT_TRAIL_CONFIG_URL,
  ROLELIST_URL,

  CHANGE_PASSWORD,

  PRODUCT_TYPE_CONFIG_URL,
  GENDER_CONFIG_URL,
  SEASON_CONFIG_URL,
  SIZE_CONFIG_URL,
  COLOR_CONFIG_URL,
  OCCASION_TYPE_CONFIG_URL,
  FABRIC_TYPE_CONFIG_URL,
  WEAR_TYPE_CONFIG_URL,
  CLOTH_TYPE_CONFIG_URL,
  TAG_CONFIG_URL,
  PRODUCT_GENERIC_DETAILS_CONFIG_URL,
  PRODUCT_VARIANT_DETAILS_CONFIG_URL,
  PRODUCT_STOCK_DETAILS_CONFIG_URL,
  PRODUCT_PRICE_DETAILS_CONFIG_URL,
  PRODUCT_IMAGE_DETAILS_CONFIG_URL,
  ORDER_DETAILS_ADMIN_CONFIG_URL,
  ORDER_TRACKING_DETAILS_ADMIN_CONFIG_URL,
  ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL,
  LEGAL_NOTICE_CONFIG_URL,
  ABOUT_US_GRATITUDE_CONFIG_URL,
  RETURN_REFUND_POLICY_CONFIG_URL,
  SHIPPING_POLICY_CONFIG_URL,
  USER_REGISTRATION_CONFIG_URL,
  USER_LOGIN_CONFIG_URL,
  USER_LOGIN_USING_OTP_CONFIG_URL,
  USER_PROFILE_CONFIG_URL,
  PRODUCT_LIST_CONFIG_URL,
  CART_DETAILS_CONFIG_URL,
  BILLING_DETAILS_CONFIG_URL,
  ORDER_DETAILS_CONFIG_URL,
  ORDER_RETURN_DETAILS_CONFIG_URL,
  ORDER_RETURN_TRACKING_DETAILS_CONFIG_URL,
  NEWSLETTER_SUBSCRIPTION_DETAILS_CONFIG_URL,
  WISHLIST_DETAILS_CONFIG_URL,
  SEARCHED_PRODUCT_LIST_CONFIG_URL,
  FILTERED_PRODUCT_LIST_CONFIG_URL,
  CHECK_USER_REGISTERED_URL,
  PASSWORD_CHANGE_CONFIG_URL,
};
