import React, { useState, useEffect } from "react";
import "./demoDrawer.css"; // Make sure to include the CSS file

export const DemoDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
    setIsExiting(false);
  };

  const closeDrawer = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsDrawerOpen(false);
      setIsExiting(false);
    }, 300); // Duration of the animation
  };

  // Ensure the exiting class is applied before removing the drawer
  useEffect(() => {
    if (!isDrawerOpen && !isExiting) {
      setIsExiting(false); // Reset exiting state if not exiting
    }
  }, [isDrawerOpen, isExiting]);

  return (
    <section className="bg-white min-h-screen">
      <div className="pt-20 flex justify-center">
        <div
          onClick={isDrawerOpen ? closeDrawer : openDrawer}
          className="mt-40 cursor-pointer px-8 py-2 border rounded font-semibold"
        >
          {isDrawerOpen ? "Close" : "Open"}
        </div>
      </div>
      <div
        className={`drawer ${
          isDrawerOpen ? "drawer-open" : isExiting ? "drawer-exit" : ""
        }`}
      >
        {/* Drawer content */}
        <div className="drawer-content">
          <button onClick={closeDrawer} className="close-button">
            Close
          </button>
          <p>Drawer Content Here</p>
        </div>
      </div>
    </section>
  );
};

export default DemoDrawer;
