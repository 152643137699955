// This Accordion is for mobile navlinks with sub-menu used in publicNavbar.component.jsx

import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import { RxCaretDown } from "react-icons/rx";
import { RxCaretUp } from "react-icons/rx";

export default function MobileNavAccordion({
  title,
  content,
  isOpen,
  onAccordionClick,
}) {
  return (
    <section>
      <div>
        <div className="my-2">
          <div
            className="flex items-center justify-start gap-2 text-black cursor-pointer"
            onClick={onAccordionClick}
          >
            <motion.p className="text-sm text-black uppercase">
              {title}
            </motion.p>

            <motion.div className="transform rotate-0 transition-transform duration-300 ease-in-out">
              {isOpen ? (
                <>
                  <span>
                    <RxCaretUp />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <RxCaretDown />
                  </span>
                </>
              )}
            </motion.div>
          </div>

          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: 1,
                  height: "auto",
                  transition: { duration: 0.3 },
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  transition: { duration: 0.2 },
                }}
                className="text-xs text-gray-500"
              >
                {content}
              </motion.div>
            )}
          </AnimatePresence>
          <div className=""></div>
        </div>
      </div>
    </section>
  );
}
