import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartDrawerOpen: false,
};

export const cartPageSlice = createSlice({
  name: "cartPage",
  initialState,
  reducers: {
    setIsCartDrawerOpen: (state, action) => {
      state.isCartDrawerOpen = action.payload;
    },
  },
});

export const { setIsCartDrawerOpen } = cartPageSlice.actions;

export default cartPageSlice.reducer;
