import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import PublicFooter from "../../common/publicNavbar/PublicFooter";
// import PublicNavbar from "../../common/publicNavbar/PublicNavbar";
import PublicNavbar from "../../common/publicNavbar/LatestNavbar/publicNavbar.component";

import { useSelector, useDispatch } from "react-redux";
import { setCartQuantity } from "../../features/cartQuantity/cartQuantitySlice";

import useRefreshToken from "../hooks/useRefreshToken";

import axios from "../../api/axios";
import { CART_DETAILS_CONFIG_URL } from "../../api/api_routing_urls";

import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import AnimatedLoader from "../../reusable-components/loader/animated_loader.component";

const PersistLogins = ({ is_admin }) => {
  const { auth, persist } = useSelector((state) => state.authProvider);

  const user_id = useSelector((state) => state.authProvider.auth?.user_id);
  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  //   console.log({ auth });

  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const dispatch = useDispatch();

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  //useEffect to fetch the total items in cart of a logged-in user
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user_id) {
      getCartItemsQuantityDetails(user_id);
    }

    return () => {
      isMounted = false;
    };
  }, [user_id]);

  // console.log("cartQuantity", cartQuantity);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : is_admin ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          <PublicNavbar />
          <Outlet />
          {/* <PublicFooter /> */}
        </>
      )}
    </>
  );
};

export default PersistLogins;
