import React, { useState } from "react";

import { Ripple, initTE } from "tw-elements";

const ImagesProductCard = ({
  productObj,
  setShowProductImagesList,
  setSelectedProduct,
}) => {
  initTE({ Ripple });

  const onManageClickHandler = (productObj) => {
    setShowProductImagesList((prev) => !prev);
    setSelectedProduct(productObj);
  };

  return (
    <>
      <div class="block rounded-lg bg-white border border-gray-400 p-3">
        <div className="flex justify-between">
          <div>
            <p class="text-lg font-medium leading-tight text-black">
              {productObj.product_name}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-x-2 font-semibold text-sm -mt-2 mb-2">
          (
          <div
            dangerouslySetInnerHTML={{
              __html: productObj?.fit_details,
            }}
          />
          <div>-</div>
          <div>{productObj?.gender_name}</div>)
        </div>

        <div className="flex">
          <div className="text-xs text-black">Available colors:</div>
          <div className="flex">
            {productObj.color_details?.map((colorobj) => {
              return (
                <div className="text-xs text-black ml-1">
                  {colorobj?.color_name}
                </div>
              );
            })}
          </div>
        </div>

        <button
          type="button"
          class="inline-block mt-4 text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-2 py-1 uppercase"
          onClick={() => onManageClickHandler(productObj)}
        >
          Manage Gallery
        </button>
      </div>
    </>
  );
};

export default ImagesProductCard;
