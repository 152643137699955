/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "../../../api/axios";
import { ORDER_RETURN_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";

const ReturnItemForm = ({
  selectedOrderDetailsId,
  setSelectedOrderDetailsId,
  setShowReturnPage,
}) => {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const [isLoading, setIsLoading] = useState(true);
  const [itemDetailsClient, setItemDetailsClient] = useState();
  const [returnReasonList, setReturnReasonList] = useState();
  const [selectedReturnReason, setSelectedReturnReason] = useState({});
  const [remarksInput, setRemarksInput] = useState("");
  const [showRequestSuccessPage, setShowRequestSuccessPage] = useState(false);

  const getReturnReasonListDetails = async () => {
    try {
      const response = await axios.get(
        `${ORDER_RETURN_DETAILS_CONFIG_URL}/returnReasonConfig`
      );
      // console.log({ response });
      response.status === 200 &&
        setReturnReasonList(response?.data?.returnReasonList);
      response.status === 202 &&
        console.log(
          "Return Reason List details not found in the system.",
          "error"
        );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const getItemDetailsClientList = async (selectedOrderDetailsId) => {
    try {
      const response = await axios.get(ORDER_RETURN_DETAILS_CONFIG_URL, {
        params: {
          order_details_id: selectedOrderDetailsId,
        },
      });
      // console.log({ response });
      response.status === 200 &&
        setItemDetailsClient(response?.data?.itemDetailsClient[0]);

      response.status === 202 &&
        console.log("Item details not found in the system.", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitReason = async () => {
    try {
      let sendDataObj = {};
      sendDataObj.order_details_id = itemDetailsClient?.order_details_id;
      sendDataObj.return_reason_id = selectedReturnReason?.return_reason_id;
      sendDataObj.user_id = userId;

      if (selectedReturnReason?.return_reason_id === 6) {
        sendDataObj.return_remarks = remarksInput;
      } else {
        sendDataObj.return_remarks = selectedReturnReason?.return_remarks;
      }

      // console.log("sendDataObj", sendDataObj);

      let response = "";
      response = await axios.post(ORDER_RETURN_DETAILS_CONFIG_URL, sendDataObj);

      // console.log("response", response);

      if (response.status === 200) {
        navigate("/return-request-successful");
      } else {
        console.log("Return request could not be placed.");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onBackClickHandler = () => {
    setSelectedOrderDetailsId(0);
    setShowReturnPage(false);
  };

  const onOptionClickHandler = (optionObj) => {
    setSelectedReturnReason({
      return_reason_id: +optionObj?.return_reason_id,
      return_remarks: optionObj?.return_reason,
    });
  };

  //useEffect to fetch item details for the particular order_details_id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getReturnReasonListDetails();
      getItemDetailsClientList(selectedOrderDetailsId);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedOrderDetailsId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setRemarksInput("");
    }
    return () => {
      isMounted = false;
    };
  }, [selectedReturnReason?.return_reason_id]);

  return (
    <section className="bg-white min-h-screen p-5 font-tenorsans">
      <div className="w-[95%] mx-auto my-10 pt-24 lg:pt-36">
        <div className="flex justify-between pb-1">
          <div className="text-lg lg:text-xl text-black uppercase">
            Return Item
          </div>
          <div
            onClick={() => onBackClickHandler()}
            className="px-1 py-0.5 lg:px-2 lg:py-1 border border-black text-xs text-black cursor-pointer uppercase"
          >
            Go Back
          </div>
        </div>
        <Link to="/return-refund-policy">
          <div className="text-xs text-red-700 hover:underline pt-5 lg:pt-0 pb-8">
            Click here to read our{" "}
            <span className="font-bold">Return-Refund Policy</span> before you
            proceed further.
          </div>
        </Link>

        <div className="text-sm lg:text-xl text-gray-600 uppercase">
          Order ID: {itemDetailsClient?.order_id}
        </div>

        <div className="text-xs lg:text-sm">
          <span className="font-semibold uppercase">Date of purchase: </span>
          {getFormattedDateDDMMMYYYYDate(itemDetailsClient?.order_date)}
        </div>

        <div className="text-sm lg:text-base pt-8 pb-3 uppercase text-gray-600">
          Selected Item for Return:
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 uppercase">
          <div className="col-span-1">
            <div className="flex gap-x-3">
              <div>
                <img
                  src={itemDetailsClient?.product_image}
                  className="w-20 md:w-28 lg:w-40"
                />
              </div>
              <div>
                <div className="text-gray-800 text-xs lg:text-sm font-semibold pb-2 lg:pb-0">
                  {itemDetailsClient?.product_details.product_name}
                </div>
                <div className="text-gray-800 text-xs lg:text-sm">
                  <span className="font-semibold">Size:</span>{" "}
                  {itemDetailsClient?.product_details.size_name}
                </div>
                <div className="text-gray-800 text-xs lg:text-sm">
                  <span className="font-semibold">Ordered Quantity:</span>{" "}
                  {itemDetailsClient?.ordered_quantity}
                </div>
                <div className="text-gray-800 text-xs lg:text-sm">
                  <span className="font-semibold">Amount Paid:</span> ₹{" "}
                  {itemDetailsClient?.product_details.amount_paid}/-
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-1 lg:col-span-2 md:border-l border-gray-200 md:pl-5">
            <div className="text-sm lg:text-base pt-8 md:pt-0 pb-3 uppercase text-gray-600">
              Why are you returning this item?
            </div>

            <div>
              {returnReasonList?.map((mapObj) => (
                <div className="py-1">
                  <input
                    type="radio"
                    id={mapObj?.return_reason_id}
                    name="return_reason"
                    value={mapObj?.return_reason_id}
                    className="cursor-pointer focus:outline-none focus:ring-0 checked:bg-black"
                    onClick={() => onOptionClickHandler(mapObj)}
                  />
                  <label
                    for={mapObj?.return_reason_id}
                    className="pl-2 py-1 text-xs lg:text-sm text-gray-600"
                  >
                    {mapObj?.return_reason}
                  </label>
                  <br />
                </div>
              ))}
            </div>
            <>
              {selectedReturnReason?.return_reason_id === 6 && (
                <div className="pt-1">
                  <p className="text-gray-600 text-xs font-semibold">
                    Explain your reason
                  </p>
                  <input
                    type="text"
                    name="return_remarks"
                    onChange={(e) => setRemarksInput(e.target.value)}
                    className="text-sm text-gray-700 w-full focus:outline-none focus:ring-0 focus:border-2 focus:border-black"
                  ></input>
                </div>
              )}
            </>

            <div className="flex justify-end cursor-pointer">
              <div
                onClick={() => onSubmitReason()}
                className="mt-10 bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
              >
                Submit Return Request
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReturnItemForm;
