/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setCartQuantity } from "../../../features/cartQuantity/cartQuantitySlice";
import { setIsCartDrawerOpen } from "../../../features/cartPage/cartPageSlice";

import { formatINRCurrency } from "../../../utilities/currency/currency";

import { RxCross2 } from "react-icons/rx";
import { LuMoveRight } from "react-icons/lu";

import emptycartimage from "../../../assets/emptycart.jpg";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

import { CART_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import Checkout from "./checkout.component";

const Cart = () => {
  // const userId = useSelector((state) => state.login.userId);

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authProvider.auth);
  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  // console.log("auth variable value:", auth);

  const [isLoading, setIsLoading] = useState(true);
  const [cartList, setCartList] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [showCheckoutPage, setShowCheckoutPage] = useState(false);

  const getCartDetailsList = async (userId) => {
    try {
      const response = await axios.get(CART_DETAILS_CONFIG_URL, {
        params: {
          user_id: userId,
        },
      });
      // console.log("User Cart details List", { response });
      response.status === 200 && setCartList(response.data.cartDetailsList);
      response.status === 202 &&
        console.log("No cart details found in the system.", "error");
    } catch (error) {
      console.error("getCartDetailsList", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotal = () => {
    let ctotal = 0;
    cartList?.map((mapObj) => {
      ctotal =
        ctotal +
        mapObj.quantity *
          (mapObj?.product_rate -
            (mapObj?.discount_percentage / 100) * mapObj?.product_rate);
    });

    setCartTotal(ctotal);
  };

  const onDeleteConfirmClickHandler = async (cartid) => {
    try {
      let sendDataObj = {
        user_id: userId,
        delete_all: false,
        cart_id: cartid,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${CART_DETAILS_CONFIG_URL}/delete`,
        sendDataObj
      );

      if (response.status === 200) {
        // console.log("Product deleted from cart successfully!");
        getCartDetailsList(userId);
        getCartItemsQuantityDetails(userId);
        dispatch(setCartQuantity(cartQuantity));
      } else {
        console.log("Delete cart item error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  const onCheckoutClickHandler = () => {
    setShowCheckoutPage(true);
  };

  const isCartDrawerOpen = useSelector(
    (state) => state.cartPage.isCartDrawerOpen
  );

  const toggleCartDrawer = () => {
    dispatch(setIsCartDrawerOpen(!isCartDrawerOpen));
  };

  const handleClose = () => {
    setTimeout(() => {
      toggleCartDrawer();
    }, 0); // Adjust the delay as needed, 300ms in this case
  };

  //useEffect to fetch cart details for the particular user id
  useEffect(() => {
    let isMounted = true;
    if (isMounted && userId) {
      getCartDetailsList(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [userId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowCheckoutPage(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  //useEffect to fetch the total items in cart of a logged-in user
  useEffect(() => {
    let isMounted = true;

    if (isMounted && userId) {
      getCartItemsQuantityDetails(userId);
    }

    return () => {
      isMounted = false;
    };
  }, [userId]);

  //useEffect to fetch calculate cart total
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      calculateTotal();
    }
    return () => {
      isMounted = false;
    };
  }, [onDeleteConfirmClickHandler]);

  return (
    <>
      {auth?.user_id ? (
        <div className="font-tenorsans">
          {!showCheckoutPage ? (
            <section className="w-full md:w-[60vw] lg:w-[35vw]">
              {isLoading ? (
                <div className="bg-white min-h-screen flex items-center justify-center">
                  <AnimatedLoader />
                </div>
              ) : (
                <>
                  <div className="my-10 flex justify-between items-center">
                    <div className="text-sm lg:text-base">Shopping Bag</div>
                    <div>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="text-gray-400 hover:text-gray-900 rounded-lg text-xl lg:text-2xl w-8 h-8"
                      >
                        <LuMoveRight />
                        <span className="sr-only">Close menu</span>
                      </button>
                    </div>
                  </div>

                  <div className="mb-6 flex justify-between text-gray-500 text-xs lg:text-sm">
                    <div>{cartQuantity} item(s)</div>
                    <div>RS. {cartTotal}</div>
                  </div>

                  <div className="mb-10 flex justify-between">
                    <div className="text-gray-800 text-sm lg:text-lg">
                      Subtotal
                    </div>
                    <div className="text-gray-800 text-lg lg:text-2xl">
                      RS. {cartTotal}
                    </div>
                  </div>

                  {cartList?.length > 0 ? (
                    <>
                      <section>
                        <div className="bg-white">
                          {cartList?.map((cartObj, index) => (
                            <div
                              key={index}
                              className="mb-8 flex flex-row justify-between py-3"
                            >
                              <div className="flex flex-row gap-x-2">
                                <Link
                                  to={`/view-product-details/${cartObj?.product_details?.product_id}`}
                                >
                                  <img
                                    src={
                                      cartObj?.product_details?.product_image
                                    }
                                    className="h-32 md:h-48 lg:h-64"
                                  />
                                </Link>

                                <div className="flex flex-col">
                                  <div className="text-xs pt-2 md:pt-0 lg:text-sm">
                                    {cartObj?.product_details?.product_name}
                                  </div>

                                  <div className="text-xs lg:text-sm pt-2 md:pt-3">
                                    <span className="">Size:</span>{" "}
                                    {cartObj?.size_name}
                                  </div>

                                  <div className="text-xs lg:text-sm pt-2 md:pt-3">
                                    <span className="">Discount:</span>{" "}
                                    {cartObj?.discount_percentage}%
                                  </div>

                                  <div className="text-xs lg:text-sm pt-2 flex items-center gap-x-2">
                                    <span className="">Qty:</span>{" "}
                                    <div>{cartObj.quantity}</div>
                                  </div>
                                </div>
                              </div>

                              <div className="flex pt-3 justify-between md:pt-0 items-center flex-col">
                                <div className="text-xs lg:text-sm">
                                  {cartObj.discount_percentage === 0 ? (
                                    <span>
                                      ₹{" "}
                                      {cartObj.quantity * cartObj.product_rate}
                                    </span>
                                  ) : (
                                    <span>
                                      ₹{" "}
                                      {cartObj.quantity *
                                        (cartObj?.product_rate -
                                          (cartObj?.discount_percentage / 100) *
                                            cartObj?.product_rate)}
                                    </span>
                                  )}
                                </div>

                                <div>
                                  <div
                                    onClick={() => {
                                      onDeleteConfirmClickHandler(
                                        cartObj?.cart_id
                                      );
                                    }}
                                    aria-label="Delete Product"
                                    className="cursor-pointer text-[10px] md:text-xs uppercase"
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div
                            onClick={() => onCheckoutClickHandler()}
                            className="mt-16 text-xs text-center cursor-pointer border-[0.1px] border-black bg-black text-white hover:border-slate-300 hover:bg-slate-200 hover:text-gray-500 transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                          >
                            Checkout
                          </div>
                        </div>
                      </section>
                    </>
                  ) : (
                    <div className="mt-10 h-[60vh] bg-white">
                      <div className="flex flex-col justify-between h-full">
                        <div className="uppercase text-xs flex justify-center">
                          You don't have any items in your cart.
                        </div>
                        <Link to="/">
                          <div className="my-10 mx-2 bg-white text-black text-xs text-center border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                            Continue Shopping
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              )}
            </section>
          ) : (
            <>
              {/* Checkout Section */}
              <div className="bg-white w-[100vw] pl-20">
                <Checkout
                  cartList={cartList}
                  setShowCheckoutPage={setShowCheckoutPage}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="bg-white min-h-screen font-tenorsans">
          <div className="flex justify-end">
            <div>
              <button
                type="button"
                onClick={handleClose}
                className="text-gray-400 hover:text-gray-900 rounded-lg text-xl lg:text-2xl w-8 h-8"
              >
                <LuMoveRight />
                <span className="sr-only">Close menu</span>
              </button>
            </div>
          </div>

          <div className="mt-5 flex justify-center">
            <div className="flex flex-col justify-center items-center">
              <img src={emptycartimage} className="w-64 md:w-72 lg:w-96" />
              <div className="text-gray-800 text-base lg:text-lg uppercase py-4 text-center">
                Please login to view your cart.
              </div>
              <div className="py-4">
                <Link to="/user-signing">
                  {/* <div className="py-2 px-3 border bg-[#BEC6B5] rounded-sm text-white text-sm font-bold cursor-pointer">
                    Sign In/ Sign Up
                  </div> */}
                  <button className="bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                    Sign In/ Sign Up
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
