/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import axios from "../../../api/axios";
import { ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";

import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import showToast from "../../../utilities/notification/NotificationModal";
import { FaUserAlt } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

const ViewReturnDetailsAdmin = ({
  selectedOrderReturn,
  setSelectedOrderReturn,
  setViewReturnDetailsPage,
  getOrderReturnDetailsAdminList,
}) => {
  //   console.log("selectedOrderReturn", selectedOrderReturn);

  const returnStatusList = [
    { return_status_id: 1, return_status: "Initiated" },
    { return_status_id: 2, return_status: "Processing" },
    { return_status_id: 3, return_status: "Completed" },
    { return_status_id: 4, return_status: "Failed" },
  ];

  const refundStatusList = [
    { refund_status_id: 1, refund_status: "Pending" },
    { refund_status_id: 2, refund_status: "Processing" },
    { refund_status_id: 3, refund_status: "Success" },
    { refund_status_id: 4, refund_status: "Failed" },
  ];

  const [selectedReturnStatus, setSelectedReturnStatus] = useState(
    selectedOrderReturn?.returning_items[0].return_status
  );
  const [selectedRefundStatus, setSelectedRefundStatus] = useState(
    selectedOrderReturn?.returning_items[0].refund_status
  );

  const onReturnStatusOptionClickHandler = (optionObj) => {
    setSelectedReturnStatus(optionObj?.return_status);
  };

  const onRefundStatusOptionClickHandler = (optionObj) => {
    setSelectedRefundStatus(optionObj?.refund_status);
  };

  const onSubmitReturnStatus = async () => {
    try {
      let sendDataObj = {};
      sendDataObj.return_id =
        selectedOrderReturn?.returning_items[0]?.return_id;
      sendDataObj.order_details_id = selectedOrderReturn?.order_details_id;
      sendDataObj.return_status = selectedReturnStatus;

      //   console.log("sendDataObj", sendDataObj);

      let response = "";
      response = await axios.post(
        `${ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL}/returnstatusupdate`,
        sendDataObj
      );

      // console.log("response", response);

      if (response.status === 200) {
        showToast(
          "Order return status has been updated successfully.",
          "success"
        );
      } else {
        showToast("Order return status updation failed.", "error");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onSubmitRefundStatus = async () => {
    try {
      let sendDataObj = {};
      sendDataObj.return_id =
        selectedOrderReturn?.returning_items[0]?.return_id;
      sendDataObj.order_details_id = selectedOrderReturn?.order_details_id;
      sendDataObj.refund_status = selectedRefundStatus;

      // console.log("sendDataObj", sendDataObj);

      let response = "";
      response = await axios.post(
        `${ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL}/refundstatusupdate`,
        sendDataObj
      );

      // console.log("response", response);

      if (response.status === 200) {
        showToast(
          "Order refund status has been updated successfully.",
          "success"
        );
      } else {
        showToast("Order refund status updation failed.", "error");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onBackClickHandler = () => {
    getOrderReturnDetailsAdminList();
    setSelectedOrderReturn(null);
    setViewReturnDetailsPage(false);
  };

  return (
    <>
      <div>
        <div className="mt-2 flex justify-between">
          <div>
            Viewing Order Return Details from{" "}
            <span className="font-bold">
              Order# {selectedOrderReturn?.order_no}
            </span>
          </div>
          <div
            onClick={() => onBackClickHandler()}
            className="flex justify-end px-4 py-1 text-xs text-black hover:underline cursor-pointer"
          >
            Go Back
          </div>
        </div>
        <div className="mt-10 bg-white rounded-md px-5 py-3 grid grid-cols-3 gap-x-2">
          {/* L.H.S */}

          <div className="col-span-2">
            <div className="pb-1 mr-5 font-semibold text-gray-800 border-b">
              Item Details
            </div>

            <div className="py-3 flex justify-center">
              <img
                src={selectedOrderReturn?.returning_items[0]?.product_image}
                className="w-60"
              />
            </div>

            <div>
              <div className="py-1">
                <span className="font-semibold">Product Name:</span>{" "}
                {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .product_name
                }
              </div>

              <div className="py-1">
                <span className="font-semibold">Ordered Size:</span>{" "}
                {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .size_name
                }
              </div>

              <div className="py-1">
                <span className="font-semibold">Product Rate:</span> ₹{" "}
                {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .product_rate
                }
                /-
              </div>

              <div className="py-1">
                <span className="font-semibold">
                  Discount during time of purchase:
                </span>{" "}
                {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .discount_percentage
                }
                %
              </div>

              <div className="py-1">
                <span className="font-semibold">Ordered Quantity:</span>{" "}
                {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .quantity
                }
              </div>

              <div className="py-1">
                <span className="font-semibold">
                  Amount Paid (excluding delivery fees):
                </span>{" "}
                {/* {
                  selectedOrderReturn?.returning_items[0]?.product_details
                    .amount_paid
                } */}
                <>
                  {selectedOrderReturn?.returning_items[0]?.product_details
                    ?.discount_percentage === 0 ? (
                    <span>
                      ₹{" "}
                      {selectedOrderReturn?.returning_items[0]?.product_details
                        ?.quantity *
                        selectedOrderReturn?.returning_items[0]?.product_details
                          ?.product_rate}
                    </span>
                  ) : (
                    <span>
                      ₹{" "}
                      {selectedOrderReturn?.returning_items[0]?.product_details
                        ?.quantity *
                        (selectedOrderReturn?.returning_items[0]
                          ?.product_details?.product_rate -
                          (selectedOrderReturn?.returning_items[0]
                            ?.product_details?.discount_percentage /
                            100) *
                            selectedOrderReturn?.returning_items[0]
                              ?.product_details?.product_rate)}
                    </span>
                  )}
                </>
                /-
              </div>

              <div className="my-4 pl-1 py-2">
                <span className="font-semibold">Return Reason:</span>{" "}
                <span className="uppercase text-red-700 font-bold">
                  {selectedOrderReturn?.returning_items[0]?.return_remarks}
                </span>
              </div>
            </div>

            <div>
              <div className="pt-5 font-semibold text-gray-600">
                Return Status:
              </div>

              <div className="flex justify-between">
                <div className="flex gap-x-3">
                  {returnStatusList?.map((mapObj, index) => (
                    <div key={index} className="py-1">
                      <input
                        type="radio"
                        id={mapObj?.return_status_id}
                        name="return_status"
                        value={mapObj?.return_status}
                        className="cursor-pointer focus:outline-none focus:ring-0 checked:bg-black"
                        checked={mapObj?.return_status === selectedReturnStatus}
                        // defaultChecked={
                        //   mapObj?.return_status ===
                        //   selectedOrderReturn?.returning_items[0].return_status
                        // }
                        onClick={() => onReturnStatusOptionClickHandler(mapObj)}
                      />
                      <label
                        for={mapObj?.return_status_id}
                        className="pl-2 py-1 text-sm text-gray-600"
                      >
                        {mapObj?.return_status}
                      </label>
                      <br />
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => onSubmitReturnStatus()}
                  className="text-xs px-3 py-1 mr-5 border border-black hover:bg-black hover:text-white rounded-md cursor-pointer"
                >
                  Update Return Status
                </div>
              </div>
            </div>

            <div>
              <div className="pt-5 font-semibold text-gray-600">
                Refund Status:
              </div>
              <div className="flex justify-between">
                <div className="flex gap-x-3">
                  {refundStatusList?.map((mapObj) => (
                    <div className="py-1">
                      <input
                        type="radio"
                        id={mapObj?.refund_status_id}
                        name="refund_status"
                        value={mapObj?.refund_status_id}
                        className="cursor-pointer focus:outline-none focus:ring-0 checked:bg-black"
                        checked={mapObj?.refund_status === selectedRefundStatus}
                        // defaultChecked={
                        //   mapObj?.refund_status ===
                        //   selectedOrderReturn?.returning_items[0].refund_status
                        // }
                        onClick={() => onRefundStatusOptionClickHandler(mapObj)}
                      />
                      <label
                        for={mapObj?.refund_status_id}
                        className="pl-2 py-1 text-sm text-gray-600"
                      >
                        {mapObj?.refund_status}
                      </label>
                      <br />
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => onSubmitRefundStatus()}
                  className="text-xs px-3 py-1 mr-5 border border-black hover:bg-black hover:text-white rounded-md cursor-pointer"
                >
                  Update Refund Status
                </div>
              </div>
            </div>
          </div>

          {/* R.H.S */}

          <div className="col-span-1">
            <div className="flex flex-col">
              <div className="border border-slate-200 rounded-md px-3 py-4">
                <div className="text-lg text-gray-600 font-semibold">
                  Payment Info
                </div>
                <div className="flex gap-1 pt-3">
                  <div className="font-semibold">Payment Mode:</div>
                  <div>
                    {selectedOrderReturn?.returning_items[0]?.payment_mode}
                  </div>
                </div>
                {/* <div className="flex gap-1 pt-3">
                  <div className="font-semibold">Transaction ID: </div>
                  <div>TBD</div>
                </div> */}
              </div>
            </div>

            <div className="mt-2 flex gap-4 border border-slate-200 rounded-md px-3 py-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <FaUserAlt size={20} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Customer</div>
                <div>
                  <p>
                    {selectedOrderReturn?.ordered_by?.fname}{" "}
                    {selectedOrderReturn?.ordered_by?.lname}
                  </p>
                  <p>{selectedOrderReturn?.ordered_by?.usremail}</p>
                  <p>{selectedOrderReturn?.ordered_by?.usrphoneno}</p>
                </div>
              </div>
            </div>

            <div className="mt-2 flex gap-4 border border-slate-200 rounded-md px-3 py-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <FaTruck size={20} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Shipping Partner</div>
                <div>
                  <p>Delhivery Couriers</p>
                  <a
                    href="https://one.delhivery.com/login?redirectFrom=/home"
                    target="_blank"
                    className="text-blue-600 cursor-pointer hover:underline"
                  >
                    Go to Delhivery One
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-2 flex gap-4 border border-slate-200 rounded-md px-3 py-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <MdLocationPin size={22} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Delivered at</div>
                <div>
                  <p>
                    {selectedOrderReturn?.billing_address?.first_name}{" "}
                    {selectedOrderReturn?.billing_address?.last_name}
                  </p>
                  <p className="text-left">
                    {selectedOrderReturn?.billing_address?.address_line1}
                  </p>
                  <p>{selectedOrderReturn?.billing_address?.address_line2}</p>
                  <p>{selectedOrderReturn?.billing_address?.landmark}</p>
                  <p>{selectedOrderReturn?.billing_address?.city_name}</p>
                  <p>
                    {selectedOrderReturn?.billing_address?.state_name} -{" "}
                    {selectedOrderReturn?.billing_address?.pincode}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewReturnDetailsAdmin;
