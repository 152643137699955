import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import AnimatedLoader from "../../reusable-components/loader/animated_loader.component";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const RequireAuth = ({ allowedRoles }) => {
  // const { auth } = useAuth();
  const auth = useSelector((state) => state.authProvider.auth);
  const refresh = useRefreshToken();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    Object.keys(auth).length === 0 ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, [Object.keys(auth).length]);

  // console.log({ auth });

  // Multiple Roles & Login auth.roles.find(role => allowedRoles.includes(role))
  return isLoading ? (
    <div className="bg-white h-screen flex items-center justify-center">
      <AnimatedLoader />
    </div>
  ) : allowedRoles.includes(parseInt(auth?.roleid)) ? (
    <Outlet />
  ) : auth?.username ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : allowedRoles.includes(parseInt(3)) ? (
    <Navigate
      to="/login-registration-page"
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
