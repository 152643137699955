/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "../../../api/axios";
import { ORDER_RETURN_TRACKING_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import noordersyetimage from "../../../assets/noordersyet.jpg";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import ReturnedItemCard from "../../../reusable-components/returned-item-card/returnedItemCard.component";
import UserLoginForm from "./user_login_form.component";

const MyReturns = () => {
  const auth = useSelector((state) => state.authProvider.auth);
  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const [isLoading, setIsLoading] = useState(false);
  const [orderReturnDetailsClientList, setOrderReturnDetailsClientList] =
    useState();

  const getOrderReturnDetailsClientList = async (userId) => {
    try {
      const response = await axios.get(
        ORDER_RETURN_TRACKING_DETAILS_CONFIG_URL,
        {
          params: {
            user_id: userId,
          },
        }
      );
      // console.log({ response });
      response.status === 200 &&
        setOrderReturnDetailsClientList(
          response?.data?.orderReturnDetailsClientList
        );
      response.status === 202 &&
        console.log(
          "Order Return Details List details not found in the system.",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  //useEffect to fetch order details for the particular user id
  useEffect(() => {
    let isMounted = true;
    if (isMounted && userId) {
      getOrderReturnDetailsClientList(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : auth?.user_id ? (
        <>
          <div className="bg-white min-h-screen pb-6 lg:pb-10 pt-24 lg:pt-36 font-tenorsans">
            <div className="flex justify-center text-lg lg:text-2xl text-black pb-10">
              Return and Refunds
            </div>
            {orderReturnDetailsClientList?.length > 0 ? (
              <>
                {orderReturnDetailsClientList?.map((orderObj) => (
                  <div className="mt-5 mb-20 mx-3">
                    <div className="w-[95%] mx-auto">
                      <div className="flex justify-between text-xs md:text-sm lg:text-lg">
                        <div>Order# {orderObj?.order_no}</div>
                        <div>
                          Order Placed on{" "}
                          {getFormattedDateDDMMMYYYYDate(orderObj?.order_date)}
                        </div>
                      </div>
                      <div className="text-gray-500 font-light text-xs lg:text-sm pt-3">
                        Returned Items:
                      </div>
                    </div>

                    {orderObj?.returning_item_details?.map((itemObj) => (
                      <div className="my-3">
                        <ReturnedItemCard
                          order_id={orderObj?.order_id}
                          order_no={orderObj?.order_no}
                          order_details_id={itemObj?.order_details_id}
                          return_initiated_date={itemObj?.return_initiated_date}
                          return_remarks={itemObj?.return_remarks}
                          return_status={itemObj?.return_status}
                          refund_status={itemObj?.refund_status}
                          order_date={orderObj?.order_date}
                          billing_address={orderObj?.billing_address}
                          company_name="Gratitude"
                          product_image={itemObj?.product_image}
                          product_id={itemObj?.product_details?.product_id}
                          product_name={itemObj?.product_details?.product_name}
                          product_size={itemObj?.product_details?.size_name}
                          quantity={itemObj?.product_details?.quantity}
                          amount_paid={itemObj?.product_details?.amount_paid}
                        />
                      </div>
                    ))}
                  </div>
                ))}

                <Link to="/">
                  <div className="w-[90%] lg:w-[95%] mx-auto flex justify-end cursor-pointer">
                    <div className="lg:mt-5 mb-20 bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                      Continue Shopping
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <div className="pb-10 bg-white min-h-screen">
                <div className="flex justify-center">
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={noordersyetimage}
                      className="w-64 md:w-72 lg:w-96"
                    />
                    <div className="text-gray-800 text-lg font-semibold py-4">
                      No Return History!
                    </div>

                    <div className="my-4">
                      <Link to="/">
                        <button className="bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                          Continue Shopping
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          <UserLoginForm />
        </div>
      )}
    </>
  );
};

export default MyReturns;
