import React, { useState } from "react";
import Button from "../../../reusable-components/buttons/Button";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../theme/lightTheme";

import addproductimage from "../../../assets/add_product.png";

import { PRODUCT_GENERIC_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function ProductList({
  productWithGenericDetailsList,
  getProductWithGenericDetailsList,
  setEditProductGenericDetails,
  setShowAddForm,
  setIsAdd,
}) {
  // console.log({ productWithGenericDetailsList });

  const onClickEdit = (product) => {
    // console.log("product received inside Edit: ", product);
    setEditProductGenericDetails(product);
    setShowAddForm(true);
    setIsAdd(false);
  };

  const onClickAdd = (product) => {
    setShowAddForm(true);
    setIsAdd(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [productDeleteId, setProductDeleteId] = useState(null);

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", productDeleteId);
    try {
      let response = "";
      if (productDeleteId) {
        response = await axios.post(
          `${PRODUCT_GENERIC_DETAILS_CONFIG_URL}/delete`,
          {
            product_id: productDeleteId,
          }
        );
        setShowDelete(false);
      }

      if (response.status === 200) {
        showToast(
          "Product generic details has been deleted successfully.",
          "success"
        );
        getProductWithGenericDetailsList();
      } else {
        showToast("Product generic details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setProductDeleteId(null);
    }
  };

  return (
    <>
      <div className="font-bold text-lg ">Products</div>
      <div className="flex flex-col w-28 mt-5 mb-10">
        <div className="">
          <img src={addproductimage} />
        </div>
        <div
          className="bg-white cursor-pointer text-center text-black text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-2 py-1 uppercase"
          onClick={() => onClickAdd()}
        >
          New Product
        </div>
      </div>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Sl. No.
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Product Type Name
            </th> */}
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Gender
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Season
            </th> */}
            {/* <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Wear Type
            </th> */}
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Cloth Type
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Occasion Type
            </th> */}
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Product Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
            >
              Product Description
            </th>
            <th
              scope="col"
              className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
          {productWithGenericDetailsList?.length > 0 ? (
            <>
              {productWithGenericDetailsList?.map((product, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {index + 1}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.product_type_name}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.gender_name}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.season_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.wear_type_name}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.cloth_type_name}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.occasion_type_name}
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.product_name}
                  </td>
                  <td className="px-6 py-4 text-sm">
                    {product.product_description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex justify-end">
                      <Button
                        onClick={() => onClickEdit(product)}
                        aria-label="Edit Product Generic Details"
                        className={linkTextPrimary}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          setProductDeleteId(product?.product_id || null);
                          setShowDelete(true);
                        }}
                        aria-label="Delete Product Generic Details"
                        className={linkTextDanger}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr className="text-xs font-semibold">
              <td colSpan={9} className="text-center py-2">
                No products configured in the system!
              </td>
            </tr>
          )}
        </tbody>

        <>
          <DeleteModal
            open={showDelete}
            setOpen={setShowDelete}
            message={
              "This entry will be deleted. All the corresponding entries for variants will also be deleted. Are you sure?"
            }
            onDelete={onClickDelete}
          />
        </>
      </TableWrapper>
    </>
  );
}
