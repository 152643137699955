import React, { useEffect, useState } from "react";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import { useParams } from "react-router-dom";

import VerificationSuccessModal from "../../../reusable-components/account-verification-status-modal/verification_success_modal.component";
import VerificationFailureModal from "../../../reusable-components/account-verification-status-modal/verification_failure_modal.component";

const EmailVerificationPage = () => {
  const { status } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoading(false);
      if (status === "success") {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [status]);

  return (
    <>
      <section>
        {isLoading ? (
          <div className="bg-white h-screen flex items-center justify-center">
            <AnimatedLoader />
          </div>
        ) : (
          <div className="min-h-screen bg-white pt-24 lg:pt-36">
            {isSuccess ? (
              <div className="pb-16">
                <VerificationSuccessModal />
              </div>
            ) : (
              <div className="pb-16">
                <VerificationFailureModal />
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default EmailVerificationPage;
