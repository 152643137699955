import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Transition from "../../../utilities/sidebar-utils/Transition";

import useLogout from "../../../authentication/hooks/useLogout";

import { AiOutlineUser } from "react-icons/ai";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const logout = useLogout();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const navigate = useNavigate();

  let pathName = window.location.pathname.split("/")[1];

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex justify-center items-center  gap-x-1 truncate">
          <div>
            <AiOutlineUser size={18} />
          </div>
          <div className="text-sm font-medium group-hover:text-slate-800">
            Admin
          </div>
          <div>
            <svg
              className="w-3 h-3 shrink-0 fill-current text-slate-400"
              viewBox="0 0 12 12"
            >
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
          </div>
        </div>
      </button>
      <div></div>

      <Transition
        className="origin-top-right z-60 absolute top-full right-0 min-w-44 bg-slate-100 border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
            <div className="font-medium text-slate-800">Yangyel</div>
            <div className="text-xs text-slate-500 italic">Admin</div>
          </div>
          <ul>
            {/* <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                to="/"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Settings
              </Link>
            </li> */}
            <li>
              <Link
                className="font-medium text-sm text-primary hover:text-gray-600 flex items-center py-1 px-3 cursor-pointer"
                to={`/${pathName}/change-password`}
              >
                Change Password
              </Link>
            </li>
            <li>
              <div
                className="font-medium text-sm text-primary hover:text-gray-600 flex items-center py-1 px-3 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setDropdownOpen(!dropdownOpen);
                  signOut();
                }}
              >
                Sign Out
              </div>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;
