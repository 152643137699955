import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsSearchDrawerOpen } from "./searchBar/searchBarSlice";

export const useCloseSearchBarOnRouteChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Close the search bar drawer on route change
    dispatch(setIsSearchDrawerOpen(false));
  }, [location, dispatch]);
};
