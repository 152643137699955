import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "../features/login/loginSlice";
import authProviderReducer from "../features/authProvider/authProviderSlice";
import cartQuantityReducer from "../features/cartQuantity/cartQuantitySlice";
import searchBarReducer from "../features/searchBar/searchBarSlice";
import randomProductsReducer from "../features/randomProducts/randomProductsSlice";
import cartPageReducer from "../features/cartPage/cartPageSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    authProvider: authProviderReducer,
    cartQuantity: cartQuantityReducer,
    searchBar: searchBarReducer,
    randomProducts: randomProductsReducer,
    cartPage: cartPageReducer,
  },
});
