import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { NavLink, useNavigate } from "react-router-dom";

import rsa from "../../../utilities/rsaFunction/rsaEncryption";

import { setAuth } from "../../../features/authProvider/authProviderSlice";

import {
  USER_REGISTRATION_CONFIG_URL,
  USER_LOGIN_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import ProfileDetails from "./profile_details.component";
import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import PasswordInput from "../../../reusable-components/inputs/InputTextBox/PasswordInput";
import FloatingInput from "../../../reusable-components/inputs/InputTextBox/FloatingInput";
import FloatingPasswordInput from "../../../reusable-components/inputs/InputTextBox/FloatingPasswordInput";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import { reEmail } from "../../../utilities/constants/regularexpressions";
import RegistrationSuccessModal from "../../../reusable-components/registration-status-modal/registration_success_modal.component";
import RegistrationFailureModal from "../../../reusable-components/registration-status-modal/registration_failure_modal.component";

const { rsaEncrypt } = rsa;

const LoginRegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state.authProvider);

  const [isRegistration, setIsRegistration] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(0);
  const [showRegistrationStatusModal, setShowRegistrationStatusModal] =
    useState(false);

  const [loginFailStatus, setLoginFailStatus] = useState(0);

  const [rsaPublicKey, setRsaPublicKey] = useState();
  const [sessionUuid, setSessionUuid] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggingInText, setIsLoggingInText] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RSA_KEY_URL);
      if (response?.data?.status === 201) {
        setRsaPublicKey(response?.data?.publicKey);
        setSessionUuid(response?.data?.session_uuid);
        // console.log(response?.data);
      } else {
        setRsaPublicKey("");
        setSessionUuid("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPublicKeyRsa();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const defaultValues = {
    username: "",
    user_email_address: "",
    user_password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const onSubmitRegister = async (data) => {
    setIsErrorMessage(false);
    setIsLoggingInText(true);
    if (data.user_password === data.confirm_user_password) {
      if (rsaPublicKey && sessionUuid) {
        data.encryptedPassword = rsaEncrypt(data?.user_password, rsaPublicKey);
        // setIsLoggingInText(true);

        try {
          let sendDataObj = {
            user_email_address: data?.user_email_address,
            user_password: data?.encryptedPassword,
            session_uuid: sessionUuid,
          };
          let response = "";
          // data.createdby = auth.userid;
          response = await axios.post(
            USER_REGISTRATION_CONFIG_URL,
            sendDataObj
          );

          // console.log({ response });

          if (
            response.status === 200 &&
            response?.data?.saveUserRegistrationDetails
          ) {
            // console.log("User Registration initiated successfully.");
            setRegistrationStatus(1);
            setShowRegistrationStatusModal(true);
          } else if (
            response.status === 200 &&
            response?.data?.checkIfEmailExists === "true"
          ) {
            // console.log("User Registration failed. Duplicate email address.");
            setRegistrationStatus(0);
            setShowRegistrationStatusModal(true);
          } else {
            setRegistrationStatus(0);
            setShowRegistrationStatusModal(true);
            if (
              response.data.saveUserRegistrationDetails.includes(
                "unique_user_email"
              )
            ) {
              console.log(
                "This email already exists. Cannot insert duplicate email for registration.",
                "error"
              );
            } else {
              console.log(
                "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
                "error"
              );
            }

            return;
          }

          reset();
        } catch (error) {
          setRegistrationStatus(0);
          setShowRegistrationStatusModal(true);
          if (!error?.response) {
            console.log("No Server Response", "error");
          } else if (error.response.status === 422) {
            console.log(
              "Some of the required inputs were not provided.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        } finally {
          setIsLoggingInText(false);
        }
      } else {
        console.error(
          "Encryption error in registration due to absence of rsaPublicKey!"
        );
      }
    } else {
      setIsLoggingInText(false);
      setIsErrorMessage(true);
    }
  };

  const onSubmitLogin = async (data) => {
    if (rsaPublicKey && sessionUuid) {
      data.encryptedPassword = rsaEncrypt(data?.user_password, rsaPublicKey);
      setIsLoggingInText(true);

      try {
        let sendDataObj = {
          username: data?.username,
          user_password: data?.encryptedPassword,
          session_uuid: sessionUuid,
        };
        let response = "";
        // data.createdby = auth.userid;
        response = await axios.post(USER_LOGIN_CONFIG_URL, sendDataObj, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        // console.log({ response });

        if (response.status === 200) {
          // console.log("User logged in successfully.", "success");
          setLoginFailStatus(0);

          dispatch(
            setAuth({
              username: response.data.userdetails.username,
              accessToken: response.data.userdetails.accessToken,
              user_id: response.data.userdetails.user_id,
              first_name: response.data.userdetails.first_name,
              last_name: response.data.userdetails.last_name,
              gender: response.data.userdetails.gender,
              phone_number: response.data.userdetails.phone_number,
              roleid: response.data.userdetails.roleid,
              rolename: response.data.userdetails.rolename,
            })
          );
          navigate("/");
        } else {
          console.log("Either username or password is wrong.", "error");
          setLoginFailStatus(1);
          if (response.data.userdetails.includes("unique_user_email")) {
            console.log("This email already exists.", "error");
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
          return;
        }

        reset();
      } catch (error) {
        if (!error?.response) {
          console.log("No Server Response", "error");
        } else if (error.response.status === 422) {
          console.log(
            "Some of the required inputs were not provided.",
            "error"
          );
        } else {
          console.log(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
            "error"
          );
        }
      } finally {
        setIsLoggingInText(false);
      }
    } else {
      console.error(
        "Encryption error in login due to absence of rsaPublicKey!"
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsRegistration(false);
      setRegistrationStatus(0);
      setShowRegistrationStatusModal(false);
      setLoginFailStatus(0);
      setIsErrorMessage(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : !auth?.user_id ? (
        <section className="font-tenorsans bg-white min-h-screen pt-14 lg:pt-24">
          {!showRegistrationStatusModal ? (
            <div>
              {!isRegistration ? (
                <>
                  <div className="bg-white w-[90%] md:w-[60%] lg:w-[40%] mx-auto py-20">
                    <div className="flex justify-start uppercase text-gray-800 text-sm lg:text-lg pb-3">
                      Log in to your account
                    </div>

                    {loginFailStatus === 1 && (
                      <div className="flex justify-center text-red-500 text-sm pb-3">
                        Seems like you have entered incorrect email or password.
                        Please try again.
                      </div>
                    )}

                    <div className="mb-2">
                      <form onSubmit={handleSubmit(onSubmitLogin)}>
                        <div className="flex flex-col">
                          <FloatingInput
                            placeholderText="Enter your e-mail id"
                            defaultName="username"
                            register={register}
                            name="E-MAIL ID"
                            required={false}
                            pattern={null}
                            errors={errors}
                            placeholder=""
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="text"
                            classes={`w-full`}
                            onChangeInput={null}
                            defaultValue={defaultValues.username}
                            setValue={setValue}
                          />

                          <FloatingPasswordInput
                            id="myPasswordInput"
                            placeholderText="Enter your password"
                            defaultName="user_password"
                            register={register}
                            name="PASSWORD"
                            required={false}
                            pattern={null}
                            errors={errors}
                            placeholder=""
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="password"
                            onChangeInput={null}
                            defaultValue={defaultValues.user_password}
                            setValue={setValue}
                          />
                        </div>
                        <div className="mt-4 flex flex-col flex-grow">
                          <button
                            onClick={() => handleSubmit}
                            className="bg-black text-white text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                          >
                            {!isLoggingInText ? "Log In" : "Logging In..."}
                          </button>
                        </div>
                      </form>

                      <NavLink end to="/forget-password">
                        <p className="mt-6 text-black text-xs cursor-pointer">
                          Have you forgotten your password?
                        </p>
                      </NavLink>
                    </div>

                    <div className="mt-12">
                      <div className="mt-4 flex flex-col flex-grow">
                        <div className="mb-5 text-[11px] uppercase">
                          Need an account?
                        </div>

                        <button
                          onClick={() => setIsRegistration(true)}
                          className="bg-white text-gray-700 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-white w-[90%] md:w-[60%] lg:w-[40%] mx-auto py-20">
                    <div className="flex justify-start uppercase text-gray-800 text-sm lg:text-lg pb-3">
                      New registration
                    </div>

                    {isErrorMessage && (
                      <div className="flex justify-center text-red-500 text-xs lg:text-sm pb-3">
                        Passwords entered in the fields does not match with each
                        other. Please re-enter the passwords.
                      </div>
                    )}

                    <div className="mb-2">
                      <form onSubmit={handleSubmit(onSubmitRegister)}>
                        <div className="flex flex-col">
                          <FloatingInput
                            placeholderText="Enter your e-mail id"
                            defaultName="user_email_address"
                            register={register}
                            name="E-MAIL ID"
                            required={false}
                            pattern={null}
                            errors={errors}
                            placeholder=""
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="text"
                            classes={`w-full`}
                            onChangeInput={null}
                            // defaultValue={defaultValues.user_email_address}
                            setValue={setValue}
                          />

                          <FloatingPasswordInput
                            id="myPasswordInput"
                            placeholderText="Enter your password"
                            defaultName="user_password"
                            register={register}
                            name="PASSWORD"
                            required={false}
                            pattern={null}
                            errors={errors}
                            placeholder=""
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="password"
                            onChangeInput={null}
                            // defaultValue={defaultValues.user_password}
                            setValue={setValue}
                          />

                          <FloatingPasswordInput
                            id="myConfirmPasswordInput"
                            placeholderText="Re-enter the password"
                            defaultName="confirm_user_password"
                            register={register}
                            name="CONFIRM PASSWORD"
                            required={false}
                            pattern={null}
                            errors={errors}
                            placeholder=""
                            setError={setError}
                            clearError={clearErrors}
                            autoComplete="off"
                            type="password"
                            onChangeInput={null}
                            // defaultValue={defaultValues.confirm_user_password}
                            setValue={setValue}
                          />
                        </div>
                        <div className="mt-4 flex flex-col flex-grow">
                          <button
                            onClick={() => handleSubmit}
                            className="bg-black text-white text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                          >
                            {!isLoggingInText
                              ? "Create Account"
                              : "Creating Account..."}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="mt-12">
                      <div className="mt-4 flex flex-col flex-grow">
                        <button
                          onClick={() => setIsRegistration(false)}
                          className="bg-white text-gray-700 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                        >
                          Already Registered? Login
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>
              {registrationStatus === 1 ? (
                <div className="py-16">
                  <RegistrationSuccessModal isOtpProcess={false} />
                </div>
              ) : (
                <div className="py-16">
                  <RegistrationFailureModal isOtpProcess={false} />
                </div>
              )}
            </div>
          )}
        </section>
      ) : (
        <div className="bg-white min-h-screen pt-24 lg:pt-36 pb-10">
          <ProfileDetails />
        </div>
      )}
    </>
  );
};

export default LoginRegisterForm;
