import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { LuMoveLeft } from "react-icons/lu";

const DrawerModal = ({ isOpen, toggleDrawer, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling when the drawer is open
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling when the drawer is closed
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling when the component unmounts
    };
  }, [isOpen]);

  const handleClose = () => {
    setTimeout(() => {
      toggleDrawer();
    }, 0); // Adjust the delay as needed, 300ms in this case
  };

  return (
    <section>
      <motion.div
        id="drawer-right-example"
        className={`fixed top-0 right-0 z-60 min-h-screen p-4 overflow-y-auto bg-white w-full md:w-[40vw] lg:w-[20vw]`}
        tabIndex="-1"
        aria-labelledby="drawer-right-label"
        initial={{ x: "100%" }}
        animate={{ x: isOpen ? 0 : "100%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div>
          <button
            type="button"
            onClick={handleClose}
            // className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
            className="text-gray-400 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-16 start-2.5 inline-flex items-center justify-center"
          >
            <LuMoveLeft />
            <span className="sr-only">Close menu</span>
          </button>
        </div>

        {/* Drawer content */}
        <div className="mt-24 md:mt-32 lg:mt-40">{children}</div>
      </motion.div>
    </section>
  );
};

export default DrawerModal;
