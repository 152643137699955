import React, { Fragment, useEffect } from "react";
import Error from "../../outputs/Error";

import { CiCircleInfo } from "react-icons/ci";

export default function FloatingInput({
  placeholderText,
  defaultName,
  register,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  onChangeInput,
  showInput,
  ...rest
}) {
  const { onChange, ...props } = register(defaultName, {
    required: required,
    pattern: pattern,
  });

  useEffect(() => {
    if (rest?.defaultValue !== "") {
      rest.setValue(defaultName, rest?.defaultValue, { shouldTouch: true });
    }
  }, [rest?.defaultValue]);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div className="my-1">
        <div className="relative z-0">
          <input
            onBlur={async (e) => {
              rest?.onBlur(e);
            }}
            onChange={async (e) => {
              if (e.target.value === "") {
                if (required) {
                  setError(defaultName, {
                    type: "required",
                    message: `${name} is required`,
                  });
                } else {
                  clearError(defaultName);
                  clearError(`${defaultName}_onChange`);
                  onChange(e);
                }
                if (onChangeInput !== null) {
                  onChangeInput(e?.target?.value);
                }
              } else if (pattern !== null) {
                if (!pattern.test(e.target.value)) {
                  clearError(defaultName);
                  clearError(`${defaultName}_onChange`);
                  setError(defaultName, {
                    type: "pattern",
                    message: `${name} is not valid`,
                  });
                } else {
                  if (onChangeInput !== null) {
                    clearError(defaultName);
                    clearError(`${defaultName}_onChange`);
                    const res = await onChangeInput(e?.target?.value);
                    if (res) {
                      setError(`${defaultName}_onChange`, {
                        type: "manual",
                        message: `${name} is not available`,
                      });
                    } else {
                      clearError(`${defaultName}_onChange`);
                      onChange(e);
                    }
                  } else {
                    onChange(e);
                  }
                }
              } else if (onChangeInput !== null) {
                const res = await onChangeInput(e.target.value);
                if (res) {
                  setError(`${defaultName}_onChange`, {
                    type: "manual",
                    message: `${name} is not available`,
                  });
                } else {
                  clearError(`${defaultName}_onChange`);
                  onChange(e);
                }
              } else {
                clearError(defaultName);
                clearError(`${defaultName}_onChange`);

                onChange(e);
              }
              if (rest?.type === "number") {
                if (parseInt(e.target.value) < parseInt(rest?.min)) {
                  setError(`${defaultName}_onChange`, {
                    type: "manual",
                    message: `${name} is less than expected`,
                  });
                } else {
                  clearError(`${defaultName}_onChange`);
                  onChange(e);
                }
              }
              if (rest?.type === "date") {
                if (new Date(e.target.value) > new Date(rest?.max)) {
                  setError(`${defaultName}_onChange`, {
                    type: "manual",
                    message: `${name} is less than expected`,
                  });
                } else {
                  clearError(`${defaultName}_onChange`);
                  onChange(e);
                }
              }
            }}
            {...props}
            {...rest}
            autoSave="off"
            onWheel={numberInputOnWheelPreventChange}
            className={`${classes} block py-2.5 px-0 w-full font-tenorsans text-sm text-black bg-transparent border-0 border-b
             border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer ${
               errors[defaultName]
                 ? "border-red-700"
                 : errors[`${defaultName}_onChange`]
                 ? "border-red-700"
                 : "border-gray-400"
             }`}
          />

          <div className="text-white flex peer-focus:text-black items-center pt-2 ">
            <CiCircleInfo size={20} />
            <span className="text-[10px] pl-1">{placeholderText}</span>
          </div>

          <label
            className="absolute text-xs lg:text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-gray-500 
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 
          rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            {name} {required && <span className="text-red-700">*</span>}
          </label>
        </div>

        {errors[defaultName] && errors[defaultName].type === "required" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is required`}
          />
        )}
        {errors[defaultName] && errors[defaultName].type === "pattern" && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${name} is not valid`}
          />
        )}
        {errors[`${defaultName}_onChange`] && (
          <Error
            classes="flex flex-row gap-1 justify-start items-center max-w-sm w-full mt-1"
            message={`${errors[`${defaultName}_onChange`].message}`}
          />
        )}
      </div>
    </>
  );
}
