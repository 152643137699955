import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import rsa from "../../../utilities/rsaFunction/rsaEncryption";

import { setAuth } from "../../../features/authProvider/authProviderSlice";

import {
  USER_LOGIN_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import gratitudelogo from "../../../assets/gratitude_black_logo-nobg.png";
import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import { reEmail } from "../../../utilities/constants/regularexpressions";

const { rsaEncrypt } = rsa;

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state.authProvider);

  const [loginFailStatus, setLoginFailStatus] = useState(0);

  const [rsaPublicKey, setRsaPublicKey] = useState();
  const [sessionUuid, setSessionUuid] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggingInText, setIsLoggingInText] = useState(false);

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RSA_KEY_URL);
      if (response?.data?.status === 201) {
        setRsaPublicKey(response?.data?.publicKey);
        setSessionUuid(response?.data?.session_uuid);
        // console.log(response?.data);
      } else {
        setRsaPublicKey("");
        setSessionUuid("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPublicKeyRsa();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const defaultValues = {
    username: "",
    user_email_address: "",
    user_password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const onSubmitLogin = async (data) => {
    setIsLoggingInText(true);
    if (rsaPublicKey && sessionUuid) {
      data.encryptedPassword = rsaEncrypt(data?.user_password, rsaPublicKey);

      try {
        let sendDataObj = {
          username: data?.username,
          user_password: data?.encryptedPassword,
          session_uuid: sessionUuid,
        };
        let response = "";
        // data.createdby = auth.userid;
        response = await axios.post(USER_LOGIN_CONFIG_URL, sendDataObj, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        // console.log({ response });

        if (response.status === 200) {
          // console.log("User logged in successfully.", "success");
          setLoginFailStatus(0);

          dispatch(
            setAuth({
              username: response.data.userdetails.username,
              accessToken: response.data.userdetails.accessToken,
              user_id: response.data.userdetails.user_id,
              first_name: response.data.userdetails.first_name,
              last_name: response.data.userdetails.last_name,
              gender: response.data.userdetails.gender,
              phone_number: response.data.userdetails.phone_number,
              roleid: response.data.userdetails.roleid,
              rolename: response.data.userdetails.rolename,
            })
          );
          navigate("/auth/dashboard");
        } else {
          console.log("Either username or password is wrong.", "error");
          setLoginFailStatus(1);
          if (response.data.userdetails.includes("unique_user_email")) {
            console.log("This email already exists.", "error");
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
          return;
        }

        reset();
      } catch (error) {
        if (!error?.response) {
          console.log("No Server Response", "error");
        } else if (error.response.status === 422) {
          console.log(
            "Some of the required inputs were not provided.",
            "error"
          );
        } else {
          console.log(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
            "error"
          );
        }
      } finally {
        setIsLoggingInText(false);
      }
    } else {
      console.error(
        "Encryption error in login due to absence of rsaPublicKey!"
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoginFailStatus(0);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : !auth?.user_id ? (
        <section className="bg-white h-[100vh] flex items-center justify-center">
          <div className="w-[40%] border border-gray-500 mx-auto px-10 py-14">
            <div className="flex justify-center">
              <img src={gratitudelogo} className="w-1/3 pb-4" />
            </div>

            <div className="flex justify-center uppercase text-gray-500 text-lg pb-3">
              Admin Login
            </div>

            {loginFailStatus === 1 && (
              <div className="flex justify-center text-red-500 text-sm pb-3">
                Seems like you have entered incorrect email or password. Please
                try again.
              </div>
            )}

            <div className="mb-2">
              <form onSubmit={handleSubmit(onSubmitLogin)}>
                <div className="flex flex-col gap-y-2">
                  <Input2
                    defaultName="username"
                    register={register}
                    name="Email ID"
                    required={false}
                    pattern={reEmail}
                    errors={errors}
                    placeholder="Enter your email id"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="text"
                    classes={`px-3 py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
                    onChangeInput={null}
                    defaultValue={defaultValues.username}
                    setValue={setValue}
                  />
                  <Input2
                    defaultName="user_password"
                    register={register}
                    name="Password"
                    required={false}
                    pattern={null}
                    errors={errors}
                    placeholder="Enter your password"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="password"
                    classes={`px-3 py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
                    onChangeInput={null}
                    defaultValue={defaultValues.user_password}
                    setValue={setValue}
                  />
                </div>
                <div className="mt-4 flex flex-col flex-grow">
                  <button
                    onClick={() => handleSubmit}
                    className="bg-white text-gray-500 text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-5 py-2 uppercase"
                  >
                    {!isLoggingInText ? "Login" : "Logging In..."}
                  </button>
                </div>
              </form>
              <p className="mt-4 text-gray-500 text-xs uppercase cursor-pointer">
                Forgot your password?
              </p>
            </div>
          </div>
        </section>
      ) : (
        <div className="py-10">
          <div></div>
        </div>
      )}
    </>
  );
};

export default AdminLogin;
