import React, { useRef } from "react";
import { useState } from "react";
import { IoPrintOutline } from "react-icons/io5";
import ReactToPrint from "react-to-print";
import gratitudeLogo from "../../assets/gratitude_black_logo-nobg.png";
import { button } from "../../theme/lightTheme";

export const PrintReport = (props) => {
  const componentRef = useRef();

  return (
    <>
      <div className="my-6">
        <ReactToPrint
          trigger={() => (
            <button className="bg-black text-white px-4 py-2 rounded-sm">
              <div className="flex gap-1 items-center">
                <IoPrintOutline size={20} />{" "}
                <span>Print {props?.buttonName}</span>
              </div>
            </button>
          )}
          content={() => componentRef.current}
          pageStyle="A4"
          documentTitle={props?.reportTitle}
        />
      </div>
      <div className="hidden">
        {/* <div className=""> */}
        <section ref={componentRef}>
          <div className="border bg-slate-50 border-slate-200 ">
            <div className="w-[95%] mx-auto flex justify-between items-center px-3 py-2 gap-3">
              <div className="flex gap-3 items-center">
                <img className="h-16 w-16" src={gratitudeLogo} />
                <div>
                  <p className="text-center text-[14px] font-semibold">
                    Gratitude - Forever Grateful
                  </p>
                  <p className="text-[12px]  -mt-1">Gangtok, Sikkim 737101</p>
                </div>
              </div>
              <div className="border-l w-[200px] px-3">
                <p className="text-[14px] text-slate-900 font-medium">
                  {props?.reportTitle}
                </p>
              </div>
            </div>
          </div>
          {props?.children ? (
            <div className="mt-3 ">{props?.children}</div>
          ) : null}
        </section>
      </div>
    </>
  );
};
