/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "../../../api/axios";
import {
  ORDER_DETAILS_CONFIG_URL,
  PRODUCT_LIST_CONFIG_URL,
} from "../../../api/api_routing_urls";
import noordersyetimage from "../../../assets/noordersyet.jpg";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import OrderedItemCard from "../../../reusable-components/ordered-item-card/orderedItemCard.component";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import ViewProductDetails from "./view_product_details.component";
import UserLoginForm from "./user_login_form.component";
import ReturnItemForm from "./return_item_form.component";

const MyOrders = () => {
  // const user_id = 0;
  // const userId = useSelector((state) => state.login.userId);

  const auth = useSelector((state) => state.authProvider.auth);
  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const [isLoading, setIsLoading] = useState(true);
  const [orderDetailsClientList, setOrderDetailsClientList] = useState();

  const [productList, setProductList] = useState([]);
  const [showProductDetailsPage, setShowProductDetailsPage] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [selectedOrderDetailsId, setSelectedOrderDetailsId] = useState(0);
  const [showReturnPage, setShowReturnPage] = useState(false);

  const getOrderDetailsClientList = async (userId) => {
    try {
      const response = await axios.get(ORDER_DETAILS_CONFIG_URL, {
        params: {
          user_id: userId,
        },
      });
      // console.log({ response });
      response.status === 200 &&
        setOrderDetailsClientList(response?.data?.orderDetailsClientList);
      response.status === 202 &&
        console.log(
          "Order Details List details not found in the system.",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getProductListDetails = async () => {
    try {
      const response = await axios.get(PRODUCT_LIST_CONFIG_URL);
      // console.log({ response });
      response.status === 200 &&
        setProductList(
          response?.data?.productCompleteDetails
            ?.get_products_complete_details_v2
        );
      response.status === 202 &&
        console.log("Product List details not found in the system.", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const onProductLinkClickHandler = () => {
    productList?.filter((filterObj) => {
      +filterObj.product_id === +selectedProductId &&
        setSelectedProduct(filterObj);
    });
    setShowProductDetailsPage(true);
  };

  //useEffect to fetch order details for the particular user id
  useEffect(() => {
    let isMounted = true;
    if (isMounted && userId) {
      getOrderDetailsClientList(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [userId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedProductId(0);
      setSelectedOrderDetailsId(0);
      setShowReturnPage(false);
      getProductListDetails();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : auth?.user_id ? (
        <>
          {!showReturnPage ? (
            <div>
              {showProductDetailsPage ? (
                <>
                  <ViewProductDetails
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    setShowProductDetailsPage={setShowProductDetailsPage}
                  />
                </>
              ) : (
                <>
                  <div className="bg-white min-h-screen pb-6 lg:pb-10 pt-24 lg:pt-36 font-tenorsans">
                    <div className="flex justify-center text-lg lg:text-2xl text-black pb-10">
                      Your Orders
                    </div>
                    {orderDetailsClientList?.length > 0 ? (
                      <>
                        {orderDetailsClientList?.map((orderObj, index) => (
                          <div key={index} className="mt-5 mb-20 mx-3">
                            <div className="w-[95%] mx-auto">
                              <div className="flex justify-between text-xs md:text-sm lg:text-lg">
                                <div>Order# {orderObj?.order_no}</div>

                                <div>
                                  Order Placed on{" "}
                                  {getFormattedDateDDMMMYYYYDate(
                                    orderObj?.order_date
                                  )}
                                </div>
                              </div>

                              <div className="mt-2 text-xs md:text-sm lg:text-lg">
                                Amount Paid (Incl. delivery): ₹{" "}
                                {
                                  orderObj?.ordered_items[0]?.product_details
                                    ?.amount_paid
                                }
                              </div>

                              <div className="text-gray-500 font-light text-xs lg:text-sm pt-3">
                                Order Items:
                              </div>
                            </div>

                            {orderObj?.ordered_items?.map((itemObj) => (
                              <div className="my-3">
                                <OrderedItemCard
                                  order_id={orderObj?.order_id}
                                  order_no={orderObj?.order_no}
                                  order_details_id={itemObj?.order_details_id}
                                  order_status={orderObj?.order_status}
                                  is_return_initiated={
                                    itemObj?.is_return_initiated
                                  }
                                  awb_tracking_number={
                                    orderObj?.awb_tracking_number || null
                                  }
                                  order_date={orderObj?.order_date}
                                  billing_address={orderObj?.billing_address}
                                  company_name="Gratitude"
                                  product_image={itemObj?.product_image}
                                  product_id={
                                    itemObj?.product_details?.product_id
                                  }
                                  product_name={
                                    itemObj?.product_details?.product_name
                                  }
                                  product_size={
                                    itemObj?.product_details?.size_name
                                  }
                                  product_rate={
                                    itemObj?.product_details?.product_rate
                                  }
                                  discount_percentage={
                                    itemObj?.product_details
                                      ?.discount_percentage
                                  }
                                  quantity={itemObj?.product_details?.quantity}
                                  amount_paid={
                                    itemObj?.product_details?.amount_paid
                                  }
                                  setSelectedProductId={setSelectedProductId}
                                  onProductLinkClickHandler={
                                    onProductLinkClickHandler
                                  }
                                  setSelectedOrderDetailsId={
                                    setSelectedOrderDetailsId
                                  }
                                  setShowReturnPage={setShowReturnPage}
                                />
                              </div>
                            ))}
                          </div>
                        ))}

                        <Link to="/">
                          <div className="w-[90%] lg:w-[95%] mx-auto flex justify-end cursor-pointer">
                            <div className="lg:mt-5 mb-20 bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                              Continue Shopping
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <div className="pb-10 bg-white min-h-screen">
                        <div className="flex justify-center">
                          <div className="flex flex-col justify-center items-center">
                            <img
                              src={noordersyetimage}
                              className="w-64 md:w-72 lg:w-96"
                            />
                            <div className="text-gray-800 text-lg font-semibold py-4">
                              No Orders!
                            </div>
                            <div className="text-gray-600 text-xs lg:text-sm pb-4">
                              Looks like you have not made any orders yet.
                            </div>
                            <div className="my-4">
                              <Link to="/">
                                <button className="bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                                  Start Shopping
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <ReturnItemForm
              selectedOrderDetailsId={selectedOrderDetailsId}
              setSelectedOrderDetailsId={setSelectedOrderDetailsId}
              setShowReturnPage={setShowReturnPage}
            />
          )}
        </>
      ) : (
        <div>
          <UserLoginForm />
        </div>
      )}
    </>
  );
};

export default MyOrders;
