/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { FacebookShareButton, WhatsappShareButton } from "react-share";

import "./Home.css";

import { MdCancel } from "react-icons/md";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";

import { setCartQuantity } from "../../../features/cartQuantity/cartQuantitySlice";
import { resetRandomProducts } from "../../../features/randomProducts/randomProductsSlice";
import { setRandomProducts } from "../../../features/randomProducts/randomProductsSlice";

import {
  PRODUCT_LIST_CONFIG_URL,
  CART_DETAILS_CONFIG_URL,
  WISHLIST_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import SwipeableImageSlider from "../../../reusable-components/swipeable-image-slider/swipeableImageSlider.component";
import VerticalScrollImageGallery from "../../../reusable-components/vertical-scroll-image-gallery/verticalScrollImageGallery.component";
import ProductDetailsAccordion from "../../../reusable-components/accordion/ProductDetailsAccordion";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import ProductCard from "../../../reusable-components/product-card/productCard.component";

const ViewProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();

  const auth = useSelector((state) => state.authProvider.auth);
  const user_id = useSelector((state) => state.authProvider.auth?.user_id);

  const randomProducts = useSelector(
    (state) => state.randomProducts.randomProducts
  );

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  // console.log("user_id", auth);

  const [isLoading, setIsLoading] = useState(true);

  const [selectedProduct, setSelectedProduct] = useState();
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [showSizeDropdown, setShowSizeDropdown] = useState(false);
  const [showSizeDropdownMobile, setShowSizeDropdownMobile] = useState(false);

  const [selectedSize, setSelectedSize] = useState(null);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [cartAddSuccess, setCartAddSuccess] = useState(false);
  const [showGoToCartButton, setShowGoToCartButton] = useState(false);

  const [showSizeInfoMessage, setShowSizeInfoMessage] = useState(false);
  const [sizeInfoMessage, setSizeInfoMessage] = useState(null);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const getProductCompleteDetails = async (product_id) => {
    try {
      const response = await axios.get(
        `${PRODUCT_LIST_CONFIG_URL}/byProductId`,
        {
          params: {
            product_id,
          },
        }
      );
      // console.log("Product with complete details", { response });
      if (response.status === 200) {
        setSelectedProduct(response?.data?.productCompleteDetails);
        setIsLoading(false);
      }
      if (response.status === 202) {
        console.log("No product details found in the system.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("getProductCompleteDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
      setIsLoading(false);
    }
  };

  const handleSizeChange = (event) => {
    const sizeId = event.target.value;
    setSelectedSize(sizeId);
    const sizeName = event.target.options[event.target.selectedIndex].text;
    onSizeClickHandler(sizeId, sizeName);
  };

  const onSizeClickHandler = (sizeObj) => {
    setSelectedSize(sizeObj);
    setShowSizeDropdown(false);
    displaySizeInfo(sizeObj?.size_name);
    setShowSizeInfoMessage(true);
  };

  // console.log("selectedSize", selectedSize);

  const displaySizeInfo = (size) => {
    if (size === "S") {
      setSizeInfoMessage("'S': 4.5 Feet - 5.0 Feet");
    }

    if (size === "M") {
      setSizeInfoMessage("'M': 5.2 Feet - 5.4 Feet");
    }

    if (size === "L") {
      setSizeInfoMessage("'L': 5.5 Feet - 5.7 Feet");
    }

    if (size === "XL") {
      setSizeInfoMessage("'XL': 5.8 Feet - 5.10 Feet");
    }
  };

  const onBackClickHandler = () => {
    setSelectedProduct(null);
    setSelectedSize(0);
    // setShowProductDetailsPage(false);
    dispatch(resetRandomProducts());
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  //useEffect to fetch complete details of the product for the selected product id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductCompleteDetails(productId);
    }
    return () => {
      isMounted = false;
    };
  }, [productId]);

  // console.log("selectedProduct", selectedProduct);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowSizeChart(false);
      setSelectedSize(null);
      setProcessingOrder(false);
      setCartAddSuccess(false);
      setShowGoToCartButton(false);
      setShowSizeInfoMessage(false);
      setSizeInfoMessage(null);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const onAddClickHandler = async (selectedProduct) => {
    // console.log("selectedProduct", selectedProduct);
    setProcessingOrder(true);
    let status = 0;
    try {
      let productDetailsObj = {
        product_id: selectedProduct.product_id,
        product_name: selectedProduct.product_name,
        size_variety_id: selectedSize?.size_variety_id,
        product_image: selectedProduct?.product_images[0].file_url,
      };
      let sendDataObj = {
        user_id: user_id,
        product_details: JSON.stringify(productDetailsObj),
        quantity: 1,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(CART_DETAILS_CONFIG_URL, sendDataObj);
      status = response.status;

      if (response.status === 200) {
        // console.log("Product added to cart  successfully!");
        getCartItemsQuantityDetails(user_id);
        dispatch(setCartQuantity(cartQuantity));
        setShowGoToCartButton(true);
        setProcessingOrder(false);
      } else {
        console.log("Add to cart error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      if (status === 200) {
        // console.log("Status 200");
        setCartAddSuccess(true);
      } else {
        console.log("Status Others");
      }
      setTimeout(() => {
        setProcessingOrder(false);
      }, 1000);
      cartAddSuccessHandler();
    }
  };

  const cartAddSuccessHandler = () => {
    setTimeout(() => {
      setCartAddSuccess(false);
    }, 4000);
  };

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  const handleAccordionClick = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };

  const accordions = [
    {
      id: 1,
      title: "Shipping",
      content: (
        <>
          <ul className="list-disc pl-4 text-gray-500">
            <li className="text-[10px] lg:text-xs uppercase pb-2">
              Standard processing time for orders is 2-3 Business Days to be
              processed and shipped.
            </li>
            <li className="text-[10px] lg:text-xs uppercase hover:text-gray-700 cursor-pointer">
              <NavLink to="/shipping-policy">
                Read our full shipping policy here
              </NavLink>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      title: "Return",
      content: (
        <>
          <ul className="list-disc pl-4 text-gray-500">
            <li className="text-[10px] lg:text-xs uppercase pb-2">
              All returns must be submitted within 7 days of receiving your
              items.
            </li>
            <li className="text-[10px] lg:text-xs uppercase hover:text-gray-700 cursor-pointer">
              <Link to="/return-refund-policy">
                Read our full return and refund policy here
              </Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 3,
      title: "Refund",
      content: (
        <>
          <ul className="list-disc pl-4 text-gray-500">
            <li className="text-[10px] lg:text-xs uppercase pb-2">
              Refund takes upto 30 days to reflect on the same payment method
              you used for the purchase.
            </li>
            <li className="text-[10px] lg:text-xs uppercase hover:text-gray-700 cursor-pointer">
              <Link to="/return-refund-policy">
                Read our full return and refund policy here
              </Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      title: "Share",
      content: (
        <>
          <div className="flex gap-x-5 mt-1">
            <div>
              <FacebookShareButton
                url={window.location.href}
                quote={"Check this product from Gratitude!"}
                hashtag="#gratitude#forever#grateful"
              >
                <span className="uppercase text-[10px] lg:text-xs">
                  Facebook
                </span>
              </FacebookShareButton>
            </div>

            <div>
              <WhatsappShareButton
                url={window.location.href}
                quote={"Check this product from Gratitude!"}
                hashtag="#gratitude#forever#grateful"
              >
                <span className="uppercase text-[10px] lg:text-xs">
                  Whatsapp
                </span>
              </WhatsappShareButton>
            </div>
          </div>
        </>
      ),
    },

    // Add more accordion items as needed
  ];

  const SizeChartLi = ({ sizename, lowerLimit, upperLimit }) => {
    return (
      <>
        <li className="text-[11px] text-slate-500 uppercase">
          <div className="flex items-center justify-start gap-3">
            <div className="w-5 flex items-center justify-between">
              <p>{sizename}</p>
              <p>:</p>
            </div>

            <div className="flex items-center justify-normal">
              <div className="w-6 flex items-center justify-end pr-1">
                <p>{lowerLimit}</p>
              </div>
              FEET
              <span className="px-3">-</span>
              <div className="w-6 flex items-center justify-end pr-1">
                <p>{upperLimit}</p>
              </div>
              FEET
            </div>
          </div>
        </li>
      </>
    );
  };

  const sizeAccordion = {
    id: 5,
    title: "Size Guide",
    content: (
      <>
        <ul className="list-disc text-[10px] lg:text-xs pl-4">
          <SizeChartLi sizename="S" lowerLimit="4.5" upperLimit="5.0" />
          <SizeChartLi sizename="M" lowerLimit="5.2" upperLimit="5.4" />
          <SizeChartLi sizename="L" lowerLimit="5.5" upperLimit="5.7" />
          <SizeChartLi sizename="XL" lowerLimit="5.8" upperLimit="5.10" />
        </ul>
      </>
    ),
  };

  const detailsAccordion = {
    id: 6,
    title: "DETAILS",
    content: (
      <>
        <div
          className="leading-6 text-[10px] lg:text-xs text-gray-500 font-light pr-4 lg:pr-10 uppercase"
          dangerouslySetInnerHTML={{
            __html: selectedProduct?.other_details,
          }}
        />
      </>
    ),
  };

  // Array to send to image slider component
  let imageArr = [];

  selectedProduct?.product_images?.map((obj, index) => {
    imageArr.push(obj.file_url);
  });

  //Functions to display randomproducts in you  may also like section
  const getProductListDetails = async () => {
    try {
      const response = await axios.get(PRODUCT_LIST_CONFIG_URL);
      // console.log({ response });
      if (response.status === 200) {
        dispatch(
          setRandomProducts(
            getRandomProducts(
              response?.data?.productCompleteDetails
                ?.get_products_complete_details_v2,
              4
            )
          )
        );

        setIsLoading(false);
      }
      if (response.status === 202) {
        console.log("Product List details not found in the system.", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const getRandomProducts = (productList, count) => {
    const shuffled = [...productList].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductListDetails();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : (
        <section className="bg-white min-h-screen md:pt-24 lg:pt-36 font-tenorsans noSelect">
          {/* -------------------- Mobile page -------------------- */}
          <div className="block md:hidden">
            <div className="relative">
              {/* Image Gallery for Mobiles */}
              <div>
                <div
                  style={{
                    maxWidth: "1200px",
                    width: "100%",
                    // height: "500px",
                    aspectRatio: "12 / 8",
                    // margin: "0 auto",
                  }}
                >
                  <SwipeableImageSlider imagesArr={imageArr} />
                </div>
              </div>

              <div className="mx-4">
                <div className="mt-5">
                  <div>
                    <div className="flex items-center gap-x-1 uppercase font-medium text-[11px] text-gray-700 -mb-1">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedProduct?.fit_details,
                        }}
                      />

                      <div>-</div>

                      <div>{selectedProduct?.gender_name}</div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="text-sm lg:text-2xl text-black pb-1">
                        {selectedProduct?.product_name}
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                    <>
                      {selectedProduct?.discount_percentage === 0 ? (
                        <>
                          <div className="font-light text-xs lg:text-sm text-gray-700">
                            RS. {selectedProduct?.product_price}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex gap-2 items-center pb-2">
                            <div className="font-light text-xs lg:text-sm text-black line-through">
                              RS. {selectedProduct?.product_price}
                            </div>
                            <div className="font-light text-xs lg:text-sm text-black pl-1">
                              -{selectedProduct?.discount_percentage}%{" "}
                              <span className="font-light pl-2 pr-1">
                                RS.{" "}
                                {selectedProduct?.product_price -
                                  (selectedProduct?.discount_percentage / 100) *
                                    selectedProduct?.product_price}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>

                <div className="mt-3 py-2">
                  <div className="leading-6 text-sm text-black font-light text-justify w-full">
                    {selectedProduct?.product_description}
                  </div>
                </div>

                {/* Accordion section  start */}
                <div className="mt-5">
                  <>
                    <ProductDetailsAccordion
                      key={detailsAccordion.id}
                      title={detailsAccordion.title}
                      content={detailsAccordion.content}
                      isOpen={detailsAccordion.id === activeAccordion}
                      onAccordionClick={() =>
                        handleAccordionClick(detailsAccordion.id)
                      }
                    />
                  </>
                  <>
                    <ProductDetailsAccordion
                      key={sizeAccordion.id}
                      title={sizeAccordion.title}
                      content={sizeAccordion.content}
                      isOpen={sizeAccordion.id === activeAccordion}
                      onAccordionClick={() =>
                        handleAccordionClick(sizeAccordion.id)
                      }
                    />
                  </>
                  <>
                    {accordions?.map((accordion, index) => (
                      <div key={index} className="">
                        <ProductDetailsAccordion
                          key={accordion.id}
                          title={accordion.title}
                          content={accordion.content}
                          isOpen={accordion.id === activeAccordion}
                          onAccordionClick={() =>
                            handleAccordionClick(accordion.id)
                          }
                        />
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
            <div className="sticky bottom-0">
              {/* Color and size selector */}
              <div className="relative grid grid-cols-2 mt-5 bg-[#EDEDED]">
                <div className="w-full px-2 py-3 border-t border-gray-300 uppercase text-gray-600 text-xs flex flex-col justify-center text-center">
                  {
                    selectedProduct?.product_data[0]?.color_size_quantity[0]
                      ?.color_name
                  }
                </div>

                <div
                  onClick={() =>
                    setShowSizeDropdownMobile(!showSizeDropdownMobile)
                  }
                  className="w-full px-2 py-3 text-xs border-t border-l border-gray-300 text-gray-600 flex justify-between items-center cursor-pointer"
                >
                  <div className="">
                    {selectedSize === null ? (
                      <div className="text-gray-500 text-xs font-medium">
                        Select Size
                      </div>
                    ) : (
                      <>{selectedSize?.size_name}</>
                    )}
                  </div>

                  <div className="">
                    {showSizeDropdownMobile ? (
                      <>
                        <RxCaretUp size={20} />
                      </>
                    ) : (
                      <>
                        <RxCaretDown size={20} />
                      </>
                    )}
                  </div>
                  {/* Mobiles */}
                  {showSizeDropdownMobile && (
                    <div className="absolute bottom-14 left-0 bg-white w-[100%] border-t transition-all ease-in-out duration-600">
                      <p className="uppercase pl-4 py-3">Select Size</p>
                      {selectedProduct?.product_data?.map(
                        (colorsizequantityObj) =>
                          colorsizequantityObj?.color_size_quantity?.map(
                            (sizequantityObj) =>
                              sizequantityObj?.size_quantity?.map(
                                (sizeObj, index) => (
                                  <div
                                    key={sizeObj.size_variety_id}
                                    value={sizeObj.size_variety_id}
                                    className={`text-center ${
                                      sizeObj.stock_quantity <= 0
                                        ? "text-gray-300 cursor-not-allowed"
                                        : "text-black cursor-pointer"
                                    }  `}
                                  >
                                    <div
                                      // onClick={() =>
                                      //   onSizeClickHandler(sizeObj)
                                      // }

                                      onClick={
                                        sizeObj.stock_quantity > 0
                                          ? () => onSizeClickHandler(sizeObj)
                                          : null
                                      }
                                      className={`text-start pl-4 py-3`}
                                    >
                                      {sizeObj.size_name}
                                    </div>
                                  </div>
                                )
                              )
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Add to cart button */}
              <div>
                {user_id ? (
                  <>
                    <div>
                      {processingOrder ? (
                        <>
                          <div className="border border-black px-4 py-2 flex justify-center text-gray-700 text-lg font-semibold cursor-pointer">
                            ...
                          </div>
                        </>
                      ) : (
                        <>
                          {selectedSize === null ? (
                            <>
                              {selectedProduct?.total_stock_quantity > 0 ? (
                                <div className="bg-white border border-gray-700 px-2 py-3 flex justify-center text-gray-400 text-center text-xs lg:text-sm uppercase font-light cursor-not-allowed">
                                  Select a size to continue
                                </div>
                              ) : (
                                <div className="bg-white border border-gray-200 px-2 py-3 flex justify-center text-gray-400 text-center text-xs lg:text-sm uppercase">
                                  Sold Out
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {selectedProduct?.total_stock_quantity > 0 ? (
                                /* Add to Cart Button */
                                <div>
                                  {!cartAddSuccess ? (
                                    <>
                                      <div
                                        onClick={() =>
                                          onAddClickHandler(selectedProduct)
                                        }
                                        className="border border-black bg-black px-2 py-3 flex justify-center text-white text-xs lg:text-sm uppercase font-semibold cursor-pointer transition-all ease-in-out duration-300"
                                      >
                                        Add to cart
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="bg-white bg-whiteborder border-black px-2 py-3 flex justify-center text-green-700 text-sm lg:text-lg font-semibold cursor-pointer">
                                        Added to Cart!
                                        <span></span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div className="bg-white border border-gray-700 px-2 py-3 flex justify-center text-gray-700 text-xs lg:text-sm uppercase">
                                  Sold Out
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/user-signing">
                      <div className="bg-white border border-black px-2 py-3 flex justify-center text-black text-xs lg:text-sm uppercase cursor-pointer">
                        Login to continue
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* -------------------- Desktop and Tablet page -------------------- */}
          <div className="hidden md:grid md:grid-cols-3">
            {/* Size chart */}
            <div className="cols-span-1 px-16">
              {showSizeChart && (
                <motion.div
                  initial={{ x: "150%" }}
                  animate={{ x: showSizeChart ? 0 : "100%" }}
                  transition={{
                    type: "spring",
                    stiffness: 120,
                    damping: 20,
                    duration: 0.5,
                  }}
                  className="relative bg-white min-h-screen"
                >
                  <div>
                    <div
                      onClick={() => setShowSizeChart(false)}
                      // className="my-1 flex justify-end cursor-pointer"
                      className="absolute top-0 right-0 cursor-pointer text-sm lg:text-xl"
                    >
                      <MdCancel color="black" />
                    </div>

                    <div className="text-xs lg:text-base pb-2 lg:pb-4 uppercase border-b text-gray-500">
                      Size Guides
                    </div>

                    <div className="text-gray-500 uppercase text-[10px] lg:text-sm mt-4 mb-2">
                      To help you determine the best size for you, check the
                      following.
                    </div>

                    <div>
                      <ul className="mt-5">
                        <SizeChartLi
                          sizename="S"
                          lowerLimit="4.5"
                          upperLimit="5.0"
                        />
                        <SizeChartLi
                          sizename="M"
                          lowerLimit="5.2"
                          upperLimit="5.4"
                        />
                        <SizeChartLi
                          sizename="L"
                          lowerLimit="5.5"
                          upperLimit="5.7"
                        />
                        <SizeChartLi
                          sizename="XL"
                          lowerLimit="5.8"
                          upperLimit="5.10"
                        />
                      </ul>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>

            {/* Product Image Gallery */}
            <div className="cols-span-1 lg:pl-0">
              {/* Image Gallery for Tablets */}
              <div className="hidden md:block lg:hidden">
                <div
                  className="lg:ml-20 lg:pr-20"
                  style={{
                    maxWidth: "1200px",
                    width: "100%",
                    // height: "500px",
                    aspectRatio: "12 / 8",
                    // margin: "0 auto",
                  }}
                >
                  {/* <SimpleImageSlider imageArr={imageArr} /> */}
                  <SwipeableImageSlider imagesArr={imageArr} />
                </div>
              </div>

              {/* Image Gallery for Desktops */}
              <div className="hidden lg:block">
                <VerticalScrollImageGallery imagesArr={imageArr} />
              </div>
            </div>

            {/* Description/ Add to Cart column */}
            <div className="cols-span-1 bg-[#FFFFFF] py-5 flex flex-col">
              <div className="w-[90%] md:w-[80%] mx-auto">
                <div>
                  <div className="flex items-center gap-x-1 uppercase font-medium text-[11px] text-gray-700 -mb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct?.fit_details,
                      }}
                    />

                    <div>-</div>

                    <div>{selectedProduct?.gender_name}</div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-sm lg:text-2xl text-black pb-1">
                      {selectedProduct?.product_name}
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <>
                    {selectedProduct?.discount_percentage === 0 ? (
                      <>
                        <div className="font-light text-xs lg:text-sm text-gray-700">
                          RS. {selectedProduct?.product_price}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex gap-2 items-center pb-2">
                          <div className="font-light text-xs lg:text-sm text-black line-through">
                            RS. {selectedProduct?.product_price}
                          </div>
                          <div className="font-light text-xs lg:text-sm text-black pl-1">
                            -{selectedProduct?.discount_percentage}%{" "}
                            <span className="font-light pl-2 pr-1">
                              RS.{" "}
                              {selectedProduct?.product_price -
                                (selectedProduct?.discount_percentage / 100) *
                                  selectedProduct?.product_price}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>

                <div>
                  {/* Available sizes listing */}

                  <div className="grid grid-cols-2 gap-x-2 lg:gap-x-4">
                    <div className="w-full px-2 lg:px-4 py-1 lg:py-2 border border-gray-300 uppercase text-gray-600 text-xs lg:text-sm flex flex-col justify-center text-center lg:text-start">
                      {
                        selectedProduct?.product_data[0]?.color_size_quantity[0]
                          ?.color_name
                      }
                    </div>

                    <div
                      onClick={() => setShowSizeDropdown(!showSizeDropdown)}
                      className="relative w-full px-2 lg:px-4 py-1 lg:py-2 border border-gray-300 text-gray-600 flex justify-between items-center cursor-pointer"
                    >
                      <div className="">
                        {selectedSize === null ? (
                          <div className="text-gray-500 text-[11px] lg:text-sm font-semibold">
                            Select Size
                          </div>
                        ) : (
                          <div className="text-[11px] lg:text-sm">
                            {selectedSize?.size_name}
                          </div>
                        )}
                      </div>

                      <div className="">
                        {showSizeDropdown ? (
                          <>
                            <RxCaretUp size={20} />
                          </>
                        ) : (
                          <>
                            <RxCaretDown size={20} />
                          </>
                        )}
                      </div>

                      {/* Desktops and tablets */}
                      {showSizeDropdown && (
                        <div className="absolute top-14 lg:top-16 left-0 bg-white w-[100.5%] grid grid-cols-2 items-center border-b border-r border-gray-200 ">
                          {selectedProduct?.product_data?.map(
                            (colorsizequantityObj) =>
                              colorsizequantityObj?.color_size_quantity?.map(
                                (sizequantityObj) =>
                                  sizequantityObj?.size_quantity?.map(
                                    (sizeObj, index) => (
                                      <div
                                        key={sizeObj.size_variety_id}
                                        value={sizeObj.size_variety_id}
                                        className={`text-center ${
                                          sizeObj.stock_quantity <= 0
                                            ? "text-gray-300 cursor-not-allowed"
                                            : "text-black cursor-pointer"
                                        }  `}
                                      >
                                        <div
                                          // onClick={() =>
                                          //   onSizeClickHandler(sizeObj)
                                          // }

                                          onClick={
                                            sizeObj.stock_quantity > 0
                                              ? () =>
                                                  onSizeClickHandler(sizeObj)
                                              : null
                                          }
                                          className={`hover:bg-gray-50 ${
                                            index % 2 === 0 ? "border-x" : null
                                          } py-3 border-t border-gray-200 md:text-xs`}
                                        >
                                          {sizeObj.size_name}
                                        </div>
                                      </div>
                                    )
                                  )
                              )
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-1 mr-1 flex justify-end uppercase text-gray-500 text-xs">
                    <span
                      onClick={() => setShowSizeChart(!showSizeChart)}
                      className="cursor-pointer"
                    >
                      Size Chart
                    </span>
                  </div>

                  {user_id ? (
                    <>
                      {/* Add to cart button */}
                      <div>
                        {processingOrder ? (
                          <>
                            <div className="mt-8 border border-black px-4 py-2 flex justify-center text-gray-700 text-lg font-semibold cursor-pointer">
                              ...
                            </div>
                          </>
                        ) : (
                          <>
                            {selectedSize === null ? (
                              <>
                                {selectedProduct?.total_stock_quantity > 0 ? (
                                  <div className="mt-8 border border-gray-700 px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-gray-700 text-center text-xs lg:text-sm uppercase font-light cursor-not-allowed">
                                    Select a size to continue
                                  </div>
                                ) : (
                                  <div className="mt-8 border border-gray-200 px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-gray-400 text-center text-xs lg:text-sm uppercase">
                                    Sold Out
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {selectedProduct?.total_stock_quantity > 0 ? (
                                  /* Add to Cart Button */
                                  <div>
                                    {!cartAddSuccess ? (
                                      <>
                                        <div
                                          onClick={() =>
                                            onAddClickHandler(selectedProduct)
                                          }
                                          className="mt-8 border border-black bg-black hover:bg-white px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-white hover:text-black text-xs lg:text-sm uppercase font-semibold cursor-pointer transition-all ease-in-out duration-300"
                                        >
                                          Add to cart
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="mt-8 border border-green-700 px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-green-700 text-sm lg:text-lg font-semibold cursor-pointer">
                                          Added to Cart!
                                          <span></span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="mt-8 border border-gray-700 px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-gray-700 text-xs lg:text-sm uppercase">
                                    Sold Out
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Link to="/user-signing">
                        <div className="mt-8 border border-black px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-black text-xs lg:text-sm uppercase cursor-pointer">
                          Login to continue
                        </div>
                      </Link>
                    </>
                  )}

                  {/* Go to cart button */}
                  {/* {showGoToCartButton && (
                    <Link to="/view-cart">
                      <div className="mt-2 border border-black bg-black px-2 lg:px-4 py-1 md:py-2 lg:py-4 flex justify-center text-white text-sm uppercase cursor-pointer">
                        View Cart
                      </div>
                    </Link>
                  )} */}
                </div>

                <div className="mt-10 py-2">
                  {/* <p className="text-xs uppercase font-semibold">Description</p> */}
                  <div className="leading-6 text-sm text-black font-light text-justify w-full">
                    {selectedProduct?.product_description}
                  </div>
                </div>

                {/* <div className="py-2 border-b border-black"> */}
                <div className="">
                  <ProductDetailsAccordion
                    key={detailsAccordion.id}
                    title={detailsAccordion.title}
                    content={detailsAccordion.content}
                    isOpen={detailsAccordion.id === activeAccordion}
                    onAccordionClick={() =>
                      handleAccordionClick(detailsAccordion.id)
                    }
                  />
                </div>

                {/* Accordion section  start */}
                <>
                  {accordions?.map((accordion, index) => (
                    <div key={index} className="">
                      <ProductDetailsAccordion
                        key={accordion.id}
                        title={accordion.title}
                        content={accordion.content}
                        isOpen={accordion.id === activeAccordion}
                        onAccordionClick={() =>
                          handleAccordionClick(accordion.id)
                        }
                      />
                    </div>
                  ))}
                </>

                {/* Accordion section  end */}
              </div>

              <div className="mt-16 mb-5 md:mt-10">
                <div
                  className="flex justify-center items-center text-black text-[11px] cursor-pointer"
                  onClick={() => onBackClickHandler()}
                >
                  Back to Shop
                </div>
              </div>
            </div>
          </div>

          {/* You may also like section */}
          <section className="mx-4 md:mx-8 lg:mx-16 mt-10 md:mt-20 lg:mt-40 pb-20 lg:pb-20">
            <div className="pb-5 uppercase text-xs text-gray-800">
              You may also like
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-4  gap-x-2 md:gap-x-4 lg:gap-x-8">
              {randomProducts.map((productObj) => (
                <div key={productObj.product_id}>
                  <Link to={`/view-product-details/${productObj.product_id}`}>
                    <ProductCard
                      productObj={productObj}
                      productid={productObj.product_id}
                      productthumbnail={productObj.product_images[0]?.file_url}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </section>
      )}
    </>
  );
};

export default ViewProductDetails;

const SizeChartLi = ({ sizename, lowerLimit, upperLimit }) => {
  return (
    <>
      <li className="text-xs lg:text-sm text-slate-500 uppercase">
        <div className="flex items-center justify-start gap-3">
          <div className="w-5 flex items-center justify-between">
            <p>{sizename}</p>
            <p>:</p>
          </div>

          <div className="flex items-center justify-normal">
            <div className="w-6 flex items-center justify-end pr-1">
              <p>{lowerLimit}</p>
            </div>
            FEET
            <span className="px-3">-</span>
            <div className="w-6 flex items-center justify-end pr-1">
              <p>{upperLimit}</p>
            </div>
            FEET
          </div>
        </div>
      </li>
    </>
  );
};
