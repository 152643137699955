import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  randomProducts: [],
};

export const randomProductsSlice = createSlice({
  name: "randomProducts",
  initialState,
  reducers: {
    setRandomProducts: (state, action) => {
      state.randomProducts = action.payload;
    },

    resetRandomProducts: (state) => {
      state.randomProducts = [];
    },
  },
});

export const { setRandomProducts, resetRandomProducts } =
  randomProductsSlice.actions;

export default randomProductsSlice.reducer;
