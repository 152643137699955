/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import { Blurhash } from "react-blurhash";

import axios from "../../api/axios";
import { PRODUCT_LIST_CONFIG_URL } from "../../api/api_routing_urls";

import { formatINRCurrency } from "../../utilities/currency/currency";

import { RxBookmark } from "react-icons/rx";
import { RxBookmarkFilled } from "react-icons/rx";

import "./productCard.styles.css";

//₹

const ProductCard = ({ productObj, productid, productthumbnail }) => {
  const [selectedProductStock, setSelectedProductStock] = useState();

  let productBlurHashString = "LNQcn{WB_3t7%Mj[j[fQ~qofD%WB";

  const getProductStockDetails = async (product_id) => {
    try {
      const response = await axios.get(
        `${PRODUCT_LIST_CONFIG_URL}/stockByProductId`,
        {
          params: {
            product_id,
          },
        }
      );
      // console.log("Product with stock details", { response });
      response.status === 200 &&
        setSelectedProductStock(response?.data?.productStockDetails);
      response.status === 202 &&
        console.log("No product stock details found in the system.");
    } catch (error) {
      console.error("getProductStockDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  //useEffect to fetch stock details of the product for the selected product id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductStockDetails(productid);
    }
    return () => {
      isMounted = false;
    };
  }, [productid]);

  return (
    <section className="font-tenorsans group">
      <div className="bg-white">
        <div className="relative hover-region">
          {/* <img
              src={productthumbnail}
              alt="product"
              className="object-cover border border-white hover:border-gray-500 hover:transition-all hover:ease-in-out hover:duration-500 cursor-pointer"
            /> */}

          <div>
            <ImageSection
              productBlurHashString={productBlurHashString}
              productthumbnail={productthumbnail}
            />
          </div>

          {selectedProductStock === "0" && (
            <div className="absolute z-20 top-3 left-4 text-gray-700 uppercase text-xs">
              Sold Out
            </div>
          )}
        </div>
      </div>

      <div className="text-black group-hover:text-[#BBBBBB] group-focus:text-[#BBBBBB] pt-1 md:pt-10 lg:pt-24 2xl:pt-32 3xl:pt-32 pb-2">
        <div className="flex items-center justify-start gap-x-1 uppercase font-medium text-[9px] md:text-[11px] -mb-2">
          <div
            dangerouslySetInnerHTML={{
              __html: productObj?.fit_details,
            }}
          />

          <div>-</div>

          <div>{productObj?.gender_name}</div>
        </div>

        <div className="flex justify-between items-center pb-1">
          <div className="flex flex-col">
            <div className="mt-1 lg:mt-0.5 font-light text-xs mb-2 md:mb-3">
              {productObj?.product_name}
            </div>
          </div>
        </div>

        <div className="text-[#BBBBBB]">
          <>
            {productObj?.discount_percentage === 0 ? (
              <div className="pb-1">
                <div className="text-xs">RS. {productObj?.product_price}</div>
              </div>
            ) : (
              <>
                <div className="flex flex-row md:gap-x-2 items-start pb-1">
                  <div className="text-xs line-through">
                    RS. {productObj?.product_price}
                  </div>

                  <div className="text-xs px-1">
                    -{productObj?.discount_percentage}%{" "}
                    <span>
                      RS.{" "}
                      {productObj?.product_price -
                        (productObj?.discount_percentage / 100) *
                          productObj?.product_price}
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </section>
  );
};

export default ProductCard;

const ImageSection = ({ productBlurHashString, productthumbnail }) => {
  return (
    <>
      <div className="h-[35vh] md:h-[50vh] bg-[#F6F6F6] relative flex justify-center">
        <div className="absolute top-0 h-[35vh] md:h-[50vh]">
          <Blurhash
            hash={productBlurHashString}
            width={`100%`}
            height={`100%`}
          />
        </div>
        <div className="absolute top-0 h-[35vh] md:h-[50vh] z-10">
          <img
            src={productthumbnail}
            // className="max-sm:h-full object-contain md:object-cover lg:border lg:border-[#F6F6F6] lg:hover:border-gray-500 lg:hover:transition-all lg:hover:ease-in-out lg:hover:duration-500 cursor-pointer"
            className="max-sm:h-full object-contain md:object-cover cursor-pointer"
            alt="product"
          />
        </div>
      </div>
    </>
  );
};
