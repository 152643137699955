import React from "react";

import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import FloatingInput from "../../../reusable-components/inputs/InputTextBox/FloatingInput";
import FloatingPasswordInput from "../../../reusable-components/inputs/InputTextBox/FloatingPasswordInput";

const BillingDetailsForm = ({
  register,
  errors,
  setError,
  clearErrors,
  setValue,
  defaultValues,
  handleSubmit,
  onSubmit,
  setShowAddAddressForm,
  setEditBillingAddressDetails,
  reset,
}) => {
  const onClickCancel = () => {
    setEditBillingAddressDetails({});
    setShowAddAddressForm(false);
    reset();
  };
  return (
    <section className="bg-white min-h-screen font-tenorsans">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid grid-cols-2 md:gap-x-5">
          <FloatingInput
            placeholderText="Enter your first name"
            defaultName="first_name"
            register={register}
            name="FIRST NAME"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.first_name}
            setValue={setValue}
          />

          <FloatingInput
            placeholderText="Enter your last name"
            defaultName="last_name"
            register={register}
            name="LAST NAME"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            onChangeInput={null}
            defaultValue={defaultValues.last_name}
            setValue={setValue}
          />
        </div>
        <div>
          <FloatingInput
            placeholderText="Enter address line 1"
            defaultName="address_line1"
            register={register}
            name="ADDRESS LINE 1"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            onChangeInput={null}
            defaultValue={defaultValues.address_line1}
            setValue={setValue}
          />

          <FloatingInput
            placeholderText="Enter address line 2"
            defaultName="address_line2"
            register={register}
            name="ADDRESS LINE 2"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            onChangeInput={null}
            defaultValue={defaultValues.address_line2}
            setValue={setValue}
          />
        </div>

        <div>
          <FloatingInput
            placeholderText="Enter landmark"
            defaultName="landmark"
            register={register}
            name="LANDMARK (OPTIONAL)"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            onChangeInput={null}
            defaultValue={defaultValues.landmark}
            setValue={setValue}
          />
        </div>

        <div className="md:grid grid-cols-3 md:gap-x-5">
          <div>
            <FloatingInput
              placeholderText="Enter the name of the city"
              defaultName="city_name"
              register={register}
              name="CITY"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              onChangeInput={null}
              defaultValue={defaultValues.city_name}
              setValue={setValue}
            />
          </div>

          <div>
            <FloatingInput
              placeholderText="Enter the name of the state"
              defaultName="state_name"
              register={register}
              name="STATE"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              onChangeInput={null}
              defaultValue={defaultValues.state_name}
              setValue={setValue}
            />
          </div>

          <div>
            <FloatingInput
              placeholderText="Enter the area pincode"
              defaultName="pincode"
              register={register}
              name="PINCODE"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              onChangeInput={null}
              defaultValue={defaultValues.pincode}
              setValue={setValue}
            />
          </div>
        </div>

        <div className="md:grid grid-cols-2 md:gap-x-5">
          <FloatingInput
            placeholderText="Enter your e-mail id"
            defaultName="user_email"
            register={register}
            name="EMAIL-ID"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            onChangeInput={null}
            defaultValue={defaultValues.user_email}
            setValue={setValue}
          />

          <FloatingInput
            placeholderText="Enter your phone number"
            defaultName="phone_number"
            register={register}
            name="PHONE NUMBER"
            required={false}
            pattern={null}
            errors={errors}
            placeholder=""
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            onChangeInput={null}
            defaultValue={defaultValues.phone_number}
            setValue={setValue}
          />
        </div>

        <div className="md:grid md:grid-cols-2 lg:grid-cols-3">
          <div className="md:col-start-1 lg:col-start-2">
            <div className="md:flex md:justify-center">
              <button
                type="submit"
                className="w-full mt-10 bg-white text-gray-600 text-xs text-center border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
              >
                Save Billing Details
              </button>
            </div>
          </div>

          <div className="">
            <div className="md:flex md:justify-end">
              <div
                onClick={() => onClickCancel()}
                className="mt-4 md:mt-10 px-3 py-2 lg:px-5 lg:py-3 border-[0.1px] border-black bg-white text-xs text-gray-600 uppercase cursor-pointer text-center"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Old Form */}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:flex md:gap-5">
          <Input2
            defaultName="first_name"
            register={register}
            name="First Name"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your first name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.first_name}
            setValue={setValue}
          />
          <Input2
            defaultName="last_name"
            register={register}
            name="Last Name"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your last name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.last_name}
            setValue={setValue}
          />
        </div>
        <div>
          <Input2
            defaultName="address_line1"
            register={register}
            name="Address Line 1"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your address"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.address_line1}
            setValue={setValue}
          />
        </div>
        <div>
          <Input2
            defaultName="address_line2"
            register={register}
            name="Address Line 2"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your address"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.address_line2}
            setValue={setValue}
          />
        </div>
        <div>
          <Input2
            defaultName="landmark"
            register={register}
            name="Landmark (Optional)"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter a landmark"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.landmark}
            setValue={setValue}
          />
        </div>
        <div className="md:flex md:gap-5">
          <div>
            <Input2
              defaultName="city_name"
              register={register}
              name="Town/City"
              required={false}
              pattern={null}
              errors={errors}
              // placeholder="Enter town/city name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
              onChangeInput={null}
              defaultValue={defaultValues.city_name}
              setValue={setValue}
            />
          </div>
          <div>
            <Input2
              defaultName="state_name"
              register={register}
              name="State"
              required={false}
              pattern={null}
              errors={errors}
              // placeholder="Enter state name"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="text"
              classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
              onChangeInput={null}
              defaultValue={defaultValues.state_name}
              setValue={setValue}
            />
          </div>
          <div>
            <Input2
              defaultName="pincode"
              register={register}
              name="Pincode"
              required={false}
              pattern={null}
              errors={errors}
              // placeholder="Enter the pincode"
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="number"
              classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
              onChangeInput={null}
              defaultValue={defaultValues.pincode}
              setValue={setValue}
            />
          </div>
        </div>
        <div className="md:flex md:gap-5">
          <Input2
            defaultName="user_email"
            register={register}
            name="Email ID"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your email id"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.user_email}
            setValue={setValue}
          />
          <Input2
            defaultName="phone_number"
            register={register}
            name="Phone Number"
            required={false}
            pattern={null}
            errors={errors}
            // placeholder="Enter your phone number"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`px-2 py-1 md:px-3 md:py-2 text-xs md:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.phone_number}
            setValue={setValue}
          />
        </div>
        <div className="md:grid md:grid-cols-2 lg:grid-cols-3">
          <div className="md:col-start-1 lg:col-start-2">
            <div className="md:flex md:justify-center">
              <button
                type="submit"
                className="w-full mt-10 bg-white text-black text-xs text-center border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
              >
                Save Billing Details
              </button>
            </div>
          </div>

          <div className="">
            <div className="md:flex md:justify-end">
              <div
                onClick={() => onClickCancel()}
                className="mt-4 md:mt-10 px-3 py-1 lg:px-5 lg:py-2 border border-gray-600 bg-white-700 text-xs text-black uppercase cursor-pointer text-center"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </form> */}
    </section>
  );
};

export default BillingDetailsForm;
