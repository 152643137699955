/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { resetAllLoginVariables } from "../../../features/login/loginSlice";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

import useLogout from "../../../authentication/hooks/useLogout";

import samplepicture from "../../../assets/personalinfo.jpg";

import axios from "../../../api/axios";
import {
  USER_PROFILE_CONFIG_URL,
  BILLING_DETAILS_CONFIG_URL,
  LOGOUT_URL,
} from "../../../api/api_routing_urls";

import { BiUserCircle } from "react-icons/bi";
import {
  MdOutlineLocationOn,
  MdOutlineRemoveShoppingCart,
} from "react-icons/md";
import { HiOutlineHeart } from "react-icons/hi";
import { BsBagCheck } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import BillingAddressCard from "../../../reusable-components/billing-address-card/billingAddressCard.component";
import BillingAddressCardMobile from "../../../reusable-components/billing-address-card-mobile/billingAddressCardMobile.component";
import BillingDetailsForm from "./billing_details_form.component";
import noaddressfoundimage from "../../../assets/noaddressfound.jpg";

const ProfileDetails = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const dispatch = useDispatch();

  // const userDetails = useSelector((state) => state.login.userDetails);

  const auth = useSelector((state) => state.authProvider.auth);
  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const [showOptions, setShowOptions] = useState(false);

  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [billingDetailsList, setBillingDetailsList] = useState();
  const [selectedBillingId, setSelectedBillingId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [showAddressBookMobile, setShowAddressBookMobile] = useState(false);

  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [editBillingAddressDetails, setEditBillingAddressDetails] = useState(
    {}
  );

  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [billingDetailsDeleteId, setBillingDetailsDeleteId] = useState(null);

  const activeTab = "text-white bg-black";

  const isEdit = Object.keys(editBillingAddressDetails)?.length > 0;

  const defaultValues = {
    first_name: userProfileDetails?.first_name,
    last_name: userProfileDetails?.last_name,
    gender: userProfileDetails?.gender,
    phone_number: userProfileDetails?.phone_number,
  };

  const defaultValues2 = {
    first_name: !isEdit ? "" : editBillingAddressDetails?.first_name,
    last_name: !isEdit ? "" : editBillingAddressDetails?.last_name,
    address_line1: !isEdit ? "" : editBillingAddressDetails?.address_line1,
    address_line2: !isEdit ? "" : editBillingAddressDetails?.address_line2,
    landmark: !isEdit ? "" : editBillingAddressDetails?.landmark,
    city_name: !isEdit ? "" : editBillingAddressDetails?.city_name,
    state_name: !isEdit ? "" : editBillingAddressDetails?.state_name,
    pincode: !isEdit ? "" : editBillingAddressDetails?.pincode,
    user_email: !isEdit ? "" : editBillingAddressDetails?.user_email,
    phone_number: !isEdit ? "" : editBillingAddressDetails?.phone_number,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const getUserProfileDetails = async (userId) => {
    try {
      const response = await axios.get(USER_PROFILE_CONFIG_URL, {
        params: {
          user_id: userId,
        },
      });
      // console.log({ response });
      response.status === 200 &&
        setUserProfileDetails(response?.data?.userProfileDetails);
      response.status === 202 &&
        console.log("User Profile Details not found in the system.", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBillingDetailsList = async (userId) => {
    try {
      const response = await axios.get(BILLING_DETAILS_CONFIG_URL, {
        params: {
          user_id: userId,
        },
      });
      // console.log({ response });
      response.status === 200 &&
        setBillingDetailsList(response?.data?.billingDetailsList);
      response.status === 202 &&
        console.log(
          "Billing Details List details not found in the system.",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      // setIsLoading(false);
    }
  };

  const onPersonalDetailsSubmit = async (data) => {
    try {
      let response = "";
      data.user_id = userId;
      response = await axios.post(USER_PROFILE_CONFIG_URL, data);

      // console.log({ response });

      if (response.status === 200) {
        getUserProfileDetails(userId);
        console.log(
          "User Personal Details has been updated successfully.",
          "success"
        );
      } else {
        console.log("User Personal Details updation error.", "error");
      }
      setShowUpdateForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", billingDetailsDeleteId);
    try {
      let response = "";
      if (billingDetailsDeleteId) {
        response = await axios.post(`${BILLING_DETAILS_CONFIG_URL}/delete`, {
          user_id: userId,
          billing_id: billingDetailsDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log(
          "User Billing details has been deleted successfully.",
          "success"
        );
        getBillingDetailsList(userId);
      } else {
        console.log("User Billing details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setBillingDetailsDeleteId(null);
    }
  };

  const onClickEdit = (billingObj) => {
    // console.log("billingObj received inside Edit: ", billingObj);
    setEditBillingAddressDetails(billingObj);
    setShowAddAddressForm(true);
  };

  const onClickAdd = () => {
    setEditBillingAddressDetails({});
    setShowAddAddressForm(true);
  };

  const addressBookClickHandler = () => {
    setSelectedTab(2);
    setShowUpdateForm(false);
  };

  const onBillingClickHandler = (selectedId) => {
    setSelectedBillingId(selectedId);
  };

  const onSubmitAddressForm = async (data) => {
    try {
      // console.log({ data });

      let sendDataObj = {
        user_id: userId,
        first_name: data.first_name,
        last_name: data.last_name,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        landmark: data.landmark,
        city_name: data.city_name,
        state_name: data.state_name,
        pincode: data.pincode,
        user_email: data.user_email,
        phone_number: data.phone_number,
      };

      //   console.log({ sendDataObj });

      let response = "";
      if (!isEdit) {
        // console.log("inside ADD function");
        response = await axios.post(BILLING_DETAILS_CONFIG_URL, sendDataObj);
        // console.log({ response });
      } else {
        // console.log("inside UPDATE function");
        sendDataObj.billing_id = editBillingAddressDetails?.billing_id;

        // console.log({ sendDataObj });

        response = await axios.post(
          `${BILLING_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
        // console.log({ response });
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditBillingAddressDetails({});
          console.log(
            "User Billing details has been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "User Billing details has been added successfully.",
            "success"
          );
        }
        getBillingDetailsList(userId);
      } else {
        if (isEdit) {
          // console.log("Save User Billing Details Error:", response.data);
          console.log("Save User Billing Details Error:");
        } else {
          if (
            response.data.saveBillingDetails.includes("unique_billing_details")
          ) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddAddressForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  //useEffect to fetch profile details, billing details for the particular user id
  useEffect(() => {
    let isMounted = true;
    if (isMounted && userId) {
      getUserProfileDetails(userId);
      getBillingDetailsList(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [userId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setShowAddressBookMobile(false);
      setEditBillingAddressDetails({});
      setBillingDetailsDeleteId(null);
      // setSelectedBillingId(0);
      setShowUpdateForm(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="bg-white min-h-screen font-tenorsans">
      <div className="flex justify-between items-center pb-5">
        <div className="px-2 py-2 md:px-9 md:py-0 text-sm lg:text-2xl uppercase">
          Account Details
        </div>
        {/* <div
            onClick={() => handleLogout()}
            className="hidden md:block bg-white text-black text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm cursor-pointer px-2 py-1 lg:px-8 lg:py-2 uppercase"
          >
            Logout
          </div> */}
      </div>

      {/* Profile Details Section for Desktop, Tablet */}

      <div className="hidden md:grid md:grid-cols-4 lg:grid-cols-6 px-9">
        <div className="col-span-1 mr-5 pt-5 uppercase">
          <div
            onClick={() => setSelectedTab(1)}
            className={`flex gap-2 cursor-pointer items-center py-2 pl-2 ${
              selectedTab === 1 && activeTab
            }`}
          >
            <div>
              <BiUserCircle size={18} />
            </div>
            <div className="md:text-xs lg:text-sm">My details</div>
          </div>

          <div
            onClick={() => addressBookClickHandler()}
            className={`flex gap-2 cursor-pointer items-center py-2 pl-2 ${
              selectedTab === 2 && activeTab
            }`}
          >
            <div>
              <MdOutlineLocationOn size={18} />
            </div>
            <div className="md:text-xs lg:text-sm">My address book</div>
          </div>

          {/* <Link to="/view-wishlist">
              <div
                className={`flex gap-2 cursor-pointer items-center py-2 pl-2`}
              >
                <div>
                  <HiOutlineHeart size={18} />
                </div>
                <div className="md:text-xs lg:text-sm">Wishlist</div>
              </div>
            </Link> */}

          <Link to="/my-orders">
            <div className={`flex gap-2 cursor-pointer items-center py-2 pl-2`}>
              <div>
                <BsBagCheck size={16} />
              </div>
              <div className="md:text-xs lg:text-sm">Orders</div>
            </div>
          </Link>

          <Link to="/returns-centre">
            <div className={`flex gap-2 cursor-pointer items-center py-2 pl-2`}>
              <div>
                <MdOutlineRemoveShoppingCart size={16} />
              </div>
              <div className="md:text-xs lg:text-sm">Returns</div>
            </div>
          </Link>

          <Link to="/change-password">
            <div className="pt-20 pb-2 pl-2 md:text-xs lg:text-sm cursor-pointer">
              Change password
            </div>
          </Link>

          <div
            onClick={() => handleLogout()}
            className="pt-2 pl-2 md:text-xs lg:text-sm cursor-pointer"
          >
            Logout
          </div>
        </div>

        <div className="md:col-span-3 lg:col-span-5 w-full bg-white shadow-2xl rounded-md lg:mr-20">
          {selectedTab === 1 && (
            <section className="pb-16">
              <div className="px-10 py-5">
                <div className="text-sm lg:text-base uppercase pb-1 border-b border-gray-300 mb-4">
                  My Profile
                </div>

                {isLoading ? (
                  <>
                    <div className="bg-white h-screen flex items-center justify-center">
                      <AnimatedLoader />
                    </div>
                  </>
                ) : (
                  <>
                    {!showUpdateForm ? (
                      <div>
                        <div className="hidden md:grid grid-cols-3">
                          {/* <div className="col-span-1">
                              <img src={samplepicture} />
                            </div> */}
                          <div className="col-span-2 bg-white uppercase">
                            <div className="h-full flex flex-col justify-between">
                              <div>
                                <div className="flex gap-x-10 py-2">
                                  <div className="text-sm lg:text-base">
                                    <span className="">First Name:</span>{" "}
                                    {userProfileDetails?.first_name}
                                  </div>

                                  <div className="text-sm lg:text-base">
                                    <span className="">Last Name:</span>{" "}
                                    {userProfileDetails?.last_name}
                                  </div>
                                </div>

                                {/* <div className="py-2">
                              <span className="font-bold">Gender:</span>{" "}
                              {userProfileDetails?.gender}
                            </div> */}

                                <div className="py-2 text-sm lg:text-base">
                                  <span className="">Phone Number:</span>{" "}
                                  {userProfileDetails?.phone_number}
                                </div>

                                {/* <div className="py-2 text-sm lg:text-base">
                                    <span className="">Email Address:</span>{" "}
                                    {userProfileDetails?.username}
                                  </div> */}
                              </div>

                              <div>
                                <div
                                  onClick={() => setShowUpdateForm(true)}
                                  className="mt-5 mb-20 py-2 text-xs cursor-pointer text-black"
                                >
                                  Edit personal details
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <UserProfileDetailsForm
                          userProfileDetails={userProfileDetails}
                          onPersonalDetailsSubmit={onPersonalDetailsSubmit}
                          setShowUpdateForm={setShowUpdateForm}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          )}

          {selectedTab === 2 && (
            <section className="pb-16">
              <div className="px-10 py-5">
                <div className="text-sm lg:text-base uppercase pb-1 border-b border-gray-300 mb-4">
                  My Addresses
                </div>
                <div>
                  {isLoading ? (
                    <>
                      <div className="bg-white h-screen flex items-center justify-center">
                        <AnimatedLoader />
                      </div>
                    </>
                  ) : (
                    <>
                      {billingDetailsList?.length > 0 ? (
                        showAddAddressForm === false ? (
                          <>
                            <div>
                              {billingDetailsList?.map((mapObj) => (
                                <div className="my-5">
                                  <BillingAddressCard
                                    billing_id={mapObj.billing_id}
                                    first_name={mapObj.first_name}
                                    last_name={mapObj.last_name}
                                    address_line1={mapObj.address_line1}
                                    address_line2={mapObj.address_line2}
                                    landmark={mapObj.landmark}
                                    city_name={mapObj.city_name}
                                    state_name={mapObj.state_name}
                                    pincode={mapObj.pincode}
                                    country_name="India"
                                    phone_number={mapObj.phone_number}
                                    onClickEdit={onClickEdit}
                                    onBillingClickHandler={
                                      onBillingClickHandler
                                    }
                                    setBillingDetailsDeleteId={
                                      setBillingDetailsDeleteId
                                    }
                                    setShowDelete={setShowDelete}
                                    mapObj={mapObj}
                                  />
                                </div>
                              ))}
                              <div
                                onClick={() => onClickAdd()}
                                className="pt-6 pb-4 text-xs text-black uppercase cursor-pointer"
                              >
                                + Add New Billing Address
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Add New Billing Details Form start*/}

                            <div className="text-sm uppercase py-3">
                              {!isEdit ? (
                                <span>Add a new billing address:</span>
                              ) : (
                                <span>Edit billing address:</span>
                              )}
                            </div>
                            {/* Add New Billing Details Form start*/}
                            <BillingDetailsForm
                              register={register}
                              errors={errors}
                              setError={setError}
                              clearErrors={clearErrors}
                              setValue={setValue}
                              defaultValues={defaultValues2}
                              handleSubmit={handleSubmit}
                              onSubmit={onSubmitAddressForm}
                              setShowAddAddressForm={setShowAddAddressForm}
                              setEditBillingAddressDetails={
                                setEditBillingAddressDetails
                              }
                              reset={reset}
                            />
                            {/* Add New Billing Details Form end*/}
                          </>
                        )
                      ) : (
                        <div className="pb-10 bg-white">
                          <div className="flex justify-center">
                            <div className="flex flex-col justify-center items-center">
                              <img src={noaddressfoundimage} className="w-96" />
                              <div className="text-gray-800 text-lg uppercase py-4">
                                No Addresses!
                              </div>
                              <div className="text-gray-600 text-sm pb-4">
                                Looks like you have not saved any addresses yet.
                              </div>
                              <div
                                onClick={() => onClickAdd()}
                                className="py-4 text-xs text-red-800 cursor-pointer uppercase"
                              >
                                +Add a new billing address
                              </div>
                            </div>
                          </div>
                          <>
                            {showAddAddressForm && (
                              <BillingDetailsForm
                                register={register}
                                errors={errors}
                                setError={setError}
                                clearErrors={clearErrors}
                                setValue={setValue}
                                defaultValues={defaultValues2}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmitAddressForm}
                                setShowAddAddressForm={setShowAddAddressForm}
                                setEditBillingAddressDetails={
                                  setEditBillingAddressDetails
                                }
                                reset={reset}
                              />
                            )}
                          </>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
          )}

          <>
            <DeleteModal
              open={showDelete}
              setOpen={setShowDelete}
              message={"This billing address will be deleted. Are you sure?"}
              onDelete={onClickDelete}
            />
          </>
        </div>
      </div>

      {/* Profile Details Section for Mobile Phones */}

      <div className="block md:hidden">
        {!showAddressBookMobile ? (
          <>
            {!showUpdateForm ? (
              <div className="text-xs bg-white px-2">
                <div className="uppercase pb-1 mb-4">My Profile</div>
                {isLoading ? (
                  <>
                    {/* <div className="bg-white flex items-center justify-center">
                        <AnimatedLoader />
                      </div> */}
                    <p className="text-xs uppercase">Loading...</p>
                  </>
                ) : (
                  <div className="uppercase">
                    {/* <div className="">
                        <span className="">First Name:</span>{" "}
                        {userProfileDetails?.first_name}
                      </div>
                      <div className="">
                        <span className="">Last Name:</span>{" "}
                        {userProfileDetails?.last_name}
                      </div> */}

                    <div className="pb-2 border-b border-black text-gray-700">
                      {userProfileDetails?.first_name}
                      <span className="ml-1">
                        {userProfileDetails?.last_name}
                      </span>
                    </div>

                    <div className="grid grid-cols-6 border-b border-gray-700">
                      <div className="col-span-5 py-2">
                        <div className="">Phone</div> +91{" "}
                        {userProfileDetails?.phone_number}
                      </div>

                      <div className="col-span-1 mr-4">
                        {!showOptions ? (
                          <div
                            onClick={() => setShowOptions(true)}
                            className="font-bold text-xl h-[70%] flex flex-col justify-center items-end  tracking-widest"
                          >
                            ...
                          </div>
                        ) : (
                          <div className="py-5 flex flex-col gap-1 h-[70%] justify-center">
                            <div
                              onClick={() => setShowOptions(false)}
                              className="font-bold text-xs  tracking-widest flex justify-end"
                            >
                              <RxCross2 size={12} />
                            </div>

                            <div className="flex justify-end">
                              <div
                                onClick={() => setShowUpdateForm(true)}
                                className="text-xs cursor-pointer text-black uppercase"
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      onClick={() => setShowAddressBookMobile(true)}
                      className="grid grid-cols-6 border-b border-gray-700"
                    >
                      <div className="col-span-5 py-5 text-gray-700">
                        <div className="">My Address</div>
                      </div>

                      <div className="col-span-1 mr-4">
                        <div className="font-bold text-xl h-[70%] flex flex-col justify-center items-end  tracking-widest">
                          ...
                        </div>
                      </div>
                    </div>
                    <Link to="/change-password">
                      <div className="grid grid-cols-6 border-b border-gray-700">
                        <div className="col-span-5 py-5 text-gray-700">
                          <div className="">Change password</div>
                        </div>

                        <div className="col-span-1 mr-4">
                          <div className="font-bold text-xl h-[70%] flex flex-col justify-center items-end  tracking-widest">
                            ...
                          </div>
                        </div>
                      </div>
                    </Link>

                    <div
                      onClick={() => handleLogout()}
                      className="my-5 text-xs underline underline-offset-2 capitalize"
                    >
                      Sign out
                    </div>

                    {/* <div>
                        <div
                          onClick={() => setShowUpdateForm(true)}
                          className="text-xs cursor-pointer text-black uppercase"
                        >
                          Edit personal details
                        </div>
                      </div> */}

                    {/* <Link to="/change-password">
                        <div className="mt-4 mb-10 md:text-xs lg:text-sm cursor-pointer">
                          Change password
                        </div>
                      </Link> */}

                    {/* <div>
                        <div
                          onClick={() => setShowAddressBookMobile(true)}
                          className="mt-10 bg-white text-black text-xs text-center cursor-pointer border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
                        >
                          View Addresses
                        </div>

                        <div
                          onClick={() => handleLogout()}
                          className="mt-4 mb-20 bg-white text-black text-xs text-center border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
                        >
                          Logout
                        </div>
                      </div> */}
                  </div>
                )}
              </div>
            ) : (
              <div className="m-4">
                <UserProfileDetailsForm
                  userProfileDetails={userProfileDetails}
                  onPersonalDetailsSubmit={onPersonalDetailsSubmit}
                  setShowUpdateForm={setShowUpdateForm}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <section className="text-xs bg-white px-2">
              <div>
                <div className="uppercase border-b border-black pb-3 px-3 -mx-2">
                  My Address
                </div>
                <div>
                  {isLoading ? (
                    <>
                      <div className="bg-white h-screen flex items-center justify-center">
                        <AnimatedLoader />
                      </div>
                    </>
                  ) : (
                    <>
                      {billingDetailsList?.length > 0 ? (
                        showAddAddressForm === false ? (
                          <>
                            <div>
                              {billingDetailsList?.map((mapObj, index) => (
                                <div key={index} className="my-5">
                                  <BillingAddressCardMobile
                                    billing_id={mapObj.billing_id}
                                    first_name={mapObj.first_name}
                                    last_name={mapObj.last_name}
                                    address_line1={mapObj.address_line1}
                                    address_line2={mapObj.address_line2}
                                    landmark={mapObj.landmark}
                                    city_name={mapObj.city_name}
                                    state_name={mapObj.state_name}
                                    pincode={mapObj.pincode}
                                    country_name="India"
                                    phone_number={mapObj.phone_number}
                                    onClickEdit={onClickEdit}
                                    onBillingClickHandler={
                                      onBillingClickHandler
                                    }
                                    setBillingDetailsDeleteId={
                                      setBillingDetailsDeleteId
                                    }
                                    setShowDelete={setShowDelete}
                                    mapObj={mapObj}
                                  />
                                </div>
                              ))}
                              <div
                                onClick={() => onClickAdd()}
                                className="mt-20 -mx-2 py-2 text-xs text-black cursor-pointer uppercase border-y border-black flex justify-center"
                              >
                                Add Address
                              </div>

                              <div>
                                <div
                                  onClick={() =>
                                    setShowAddressBookMobile(false)
                                  }
                                  className="mt-10 bg-white text-black text-xs text-center cursor-pointer px-3 py-1 lg:px-5 lg:py-2 uppercase"
                                >
                                  Return To Personal Details
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Add New Billing Details Form start*/}

                            <div className="text-xs font-semibold py-3">
                              {!isEdit ? (
                                <span>Add a new billing address:</span>
                              ) : (
                                <span>Edit billing address:</span>
                              )}
                            </div>
                            {/* Add New Billing Details Form start*/}
                            <BillingDetailsForm
                              register={register}
                              errors={errors}
                              setError={setError}
                              clearErrors={clearErrors}
                              setValue={setValue}
                              defaultValues={defaultValues2}
                              handleSubmit={handleSubmit}
                              onSubmit={onSubmitAddressForm}
                              setShowAddAddressForm={setShowAddAddressForm}
                              setEditBillingAddressDetails={
                                setEditBillingAddressDetails
                              }
                              reset={reset}
                            />
                            {/* Add New Billing Details Form end*/}
                          </>
                        )
                      ) : (
                        <div className="pb-10 bg-white">
                          <div className="flex justify-center">
                            <div className="flex flex-col justify-center items-center">
                              <img src={noaddressfoundimage} className="w-96" />
                              <div className="text-gray-800 text-lg font-semibold py-4">
                                No Addresses!
                              </div>
                              <div className="text-gray-600 text-sm pb-4">
                                Looks like you have not saved any addresses yet.
                              </div>
                              <div
                                onClick={() => onClickAdd()}
                                className="py-4 text-xs text-red-800 cursor-pointer"
                              >
                                +Add a new billing address
                              </div>
                            </div>
                          </div>
                          <>
                            {showAddAddressForm && (
                              <BillingDetailsForm
                                register={register}
                                errors={errors}
                                setError={setError}
                                clearErrors={clearErrors}
                                setValue={setValue}
                                defaultValues={defaultValues2}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmitAddressForm}
                                setShowAddAddressForm={setShowAddAddressForm}
                                setEditBillingAddressDetails={
                                  setEditBillingAddressDetails
                                }
                                reset={reset}
                              />
                            )}
                          </>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>

            <>
              <DeleteModal
                open={showDelete}
                setOpen={setShowDelete}
                message={"This billing address will be deleted. Are you sure?"}
                onDelete={onClickDelete}
              />
            </>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfileDetails;

const UserProfileDetailsForm = ({
  userProfileDetails,
  onPersonalDetailsSubmit,
  setShowUpdateForm,
}) => {
  const defaultValues = {
    first_name: userProfileDetails?.first_name,
    last_name: userProfileDetails?.last_name,
    gender: userProfileDetails?.gender,
    phone_number: userProfileDetails?.phone_number,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  return (
    <section className="font-tenorsans">
      <form onSubmit={handleSubmit(onPersonalDetailsSubmit)}>
        <div className="flex flex-col">
          <Input2
            defaultName="first_name"
            register={register}
            name="First Name"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter your first name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 lg:px-3 lg:py-2 text-xs lg:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.first_name}
            setValue={setValue}
          />
          <Input2
            defaultName="last_name"
            register={register}
            name="Last Name"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter your last name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 lg:px-3 lg:py-2 text-xs lg:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.last_name}
            setValue={setValue}
          />
        </div>

        <div className="flex flex-row gap-x-4">
          {/* <Input2
            defaultName="gender"
            register={register}
            name="Gender"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter your gender"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={px-2 py-1 lg:px-3 lg:py-2 text-xs lg:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.gender}
            setValue={setValue}
          /> */}

          <Input2
            defaultName="phone_number"
            register={register}
            name="Phone Number"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter your phone number"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`px-2 py-1 lg:px-3 lg:py-2 text-xs lg:text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
            onChangeInput={null}
            defaultValue={defaultValues.phone_number}
            setValue={setValue}
          />
        </div>

        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => handleSubmit}
            className="bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-4 py-2 lg:px-8 lg:py-3 uppercase"
          >
            Save
          </button>

          <div
            onClick={() => setShowUpdateForm(false)}
            className="cursor-pointer px-4 py-1 lg:px-8 lg:py-2 text-xs lg:text-sm uppercase"
          >
            Cancel
          </div>
        </div>
      </form>
    </section>
  );
};
