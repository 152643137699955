import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import InventoryProductCard from "./inventoryProductCard.component";
import ManageProductInventory from "./manageProductInventory.component";

import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { PRODUCT_VARIANT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import axios from "../../../api/axios";

const Inventory = () => {
  const [productWithVariantDetailsList, setProductWithVariantDetailsList] =
    useState([]);

  const [showManageStockTable, setShowManageStockTable] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedProductName, setSelectedProductName] = useState("");

  const getProductWithVariantDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_VARIANT_DETAILS_CONFIG_URL);
      // console.log("Product with variant details List", { response });
      response.status === 200 &&
        setProductWithVariantDetailsList(
          response.data.productsWithVariantDetailsList
        );
      response.status === 202 &&
        showToast("No product variants found in the system.", "error");
    } catch (error) {
      console.error("getProductWithVariantDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithVariantDetailsList();
      // console.log("Sent product_id from manage button: ", selectedProductId);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard>
        <ToastContainer />
        <div className="font-bold text-lg pb-5">Inventory</div>
        {!showManageStockTable ? (
          <>
            <div className="grid grid-cols-2 gap-6">
              {productWithVariantDetailsList?.map((productObj) => {
                return (
                  <div className="">
                    <InventoryProductCard
                      productObj={productObj}
                      setShowManageStockTable={setShowManageStockTable}
                      setSelectedProductId={setSelectedProductId}
                      setSelectedProductName={setSelectedProductName}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <ManageProductInventory
              setShowManageStockTable={setShowManageStockTable}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              selectedProductName={selectedProductName}
              setSelectedProductName={setSelectedProductName}
              getProductWithVariantDetailsList={
                getProductWithVariantDetailsList
              }
            />
          </>
        )}
      </Dashboard>
    </>
  );
};

export default Inventory;
