import React from "react";

const BillingAddressCard = ({
  billing_id,
  first_name,
  last_name,
  address_line1,
  address_line2,
  landmark,
  city_name,
  state_name,
  pincode,
  country_name,
  phone_number,
  onBillingClickHandler,
  selectedBillingId,
  onClickEdit,
  setBillingDetailsDeleteId,
  setShowDelete,
  mapObj,
}) => {
  return (
    <>
      <div
        onClick={() => onBillingClickHandler(billing_id)}
        className={`px-3 py-1 border  rounded-sm cursor-pointer font-tenorsans ${
          selectedBillingId === billing_id ? "border-black" : "border-gray-300"
        }`}
      >
        <div className="font-bold text-sm">
          {first_name} {last_name}
        </div>
        <div className="text-xs">{address_line1}</div>
        <div className="text-xs">{address_line2}</div>
        {landmark !== null ? <div className="text-xs">{landmark}</div> : null}
        <div className="uppercase text-xs">
          {city_name}, {state_name} - {pincode}
        </div>
        <div className="text-xs">{country_name}</div>
        <div className="text-xs">Phone number: {phone_number}</div>
        <div className="py-5 flex gap-3">
          <div
            onClick={() => onClickEdit(mapObj)}
            className="text-xs cursor-pointer text-black uppercase"
          >
            Edit
          </div>
          <div
            onClick={() => {
              setBillingDetailsDeleteId(billing_id);
              setShowDelete(true);
            }}
            className="text-xs cursor-pointer text-black uppercase"
          >
            Remove
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingAddressCard;
