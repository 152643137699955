import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PRODUCT_GENERIC_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../api/axios";
import AddButton_v2 from "../../../reusable-components/buttons/AddButton_v2";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import RichTextArea from "../../../reusable-components/richtexteditor/RichTextArea";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";
import { NonEmptyString } from "../../../utilities/constants/regularexpressions";

const AddProductForm = ({
  getProductWithGenericDetailsList,
  editProductGenericDetails,
  setEditProductGenericDetails,
  setShowAddForm,
  setIsAdd,
  productTypeDD,
  genderDD,
  seasonDD,
  wearTypeDD,
  clothTypeDD,
  occasionTypeDD,
  tagDD,
}) => {
  // const axiosPrivate = useAxiosPrivate();
  // const { auth } = useAuth();

  const [selectedProductType, setSelectedProductType] = useState({});
  const [selectedGender, setSelectedGender] = useState({});
  const [selectedSeason, setSelectedSeason] = useState({});
  const [selectedWearType, setSelectedWearType] = useState({});
  const [selectedClothType, setSelectedClothType] = useState({});
  const [selectedOccasionType, setSelectedOccasionType] = useState({});

  const [clothTypeListWearTypeWise, setClothTypeListWearTypeWise] = useState(
    []
  );

  const [selectedTag, setSelectedTag] = useState(null);

  const isEdit = Object.keys(editProductGenericDetails)?.length > 0;

  const defaultValues = {
    product_type_id: !isEdit ? "" : selectedProductType,
    gender_id: !isEdit ? "" : selectedGender,
    season_id: !isEdit ? "" : selectedSeason,
    wear_type_id: !isEdit ? "" : selectedWearType,
    cloth_type_id: !isEdit ? "" : selectedClothType,
    occasion_type_id: !isEdit ? "" : selectedOccasionType,
    product_name: !isEdit ? "" : editProductGenericDetails?.product_name,
    product_description: !isEdit
      ? ""
      : editProductGenericDetails?.product_description,
    fit_details: !isEdit ? "" : editProductGenericDetails?.fit_details,
    other_details: !isEdit ? "" : editProductGenericDetails?.other_details,
    material_details: !isEdit
      ? ""
      : editProductGenericDetails?.material_details,
    product_price: !isEdit ? "" : editProductGenericDetails?.product_price,
    discount_percentage: !isEdit
      ? ""
      : editProductGenericDetails?.discount_percentage,
    tag_id: !isEdit ? "" : selectedTag,
  };

  // const {

  //   control,
  // } = useForm({
  //   mode: "onChange",
  //   defaultValues: defaultValues,
  //   criteriaMode: "all",
  //   shouldFocusError: true,
  //   shouldUnregister: true,
  // });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    try {
      let tagArr = [];
      data.tag_id.map((mapObj) => {
        tagArr.push(+mapObj.value);
      });

      let sendDataObj = {
        product_type_id: data.product_type_id.value,
        gender_id: data.gender_id.value,
        season_id: data.season_id.value,
        wear_type_id: data.wear_type_id.value,
        cloth_type_id: data.cloth_type_id.value,
        occasion_type_id: data.occasion_type_id.value,
        product_name: data.product_name,
        product_description: data.product_description,
        fit_details: data.fit_details,
        other_details: data.other_details,
        material_details: data.material_details,
        product_price: +data.product_price,
        discount_percentage: +data.discount_percentage,
        tag_id: JSON.stringify(data.tag_id),
      };

      // console.log({ editProductGenericDetails });
      // console.log({ data });
      // console.log({ sendDataObj });

      let response = "";
      if (!isEdit) {
        // console.log("inside ADD function");
        // data.createdby = auth.userid;
        sendDataObj.createdby = "admin";
        response = await axios.post(
          PRODUCT_GENERIC_DETAILS_CONFIG_URL,
          sendDataObj
        );
        // console.log({ response });
      } else {
        // console.log("inside UPDATE function");
        // data.updatedby = auth.userid;
        sendDataObj.updatedby = "admin";
        sendDataObj.product_id = editProductGenericDetails?.product_id;
        sendDataObj.price_id = editProductGenericDetails?.price_id;
        sendDataObj.tagArr = JSON.stringify(tagArr);

        // console.log({ sendDataObj });

        response = await axios.post(
          `${PRODUCT_GENERIC_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
        // console.log({ response });
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditProductGenericDetails({});
          showToast(
            "Product Generic details has been updated successfully.",
            "success"
          );
        } else {
          showToast(
            "Product Generic details has been added successfully.",
            "success"
          );
        }
        getProductWithGenericDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Product Generic Details Error:", response.data);
        } else {
          if (
            response.data.saveProductGenericDetails.includes("unique_product")
          ) {
            showToast(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const clothTypeChangeHandler = (e) => {
    // console.log({ e });

    // console.log({ clothTypeDD });

    let filteredClothTypeList = clothTypeDD?.filter(
      (clothTypeObj) => +clothTypeObj?.wear_type_id === +e
    );

    // console.log({ filteredClothTypeList });

    setClothTypeListWearTypeWise(filteredClothTypeList);
    setSelectedClothType(null);
  };

  //UseEffect for pre-populating Drop-down Values
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let productType = productTypeDD.filter(
        (productType) =>
          +productType.value === +editProductGenericDetails?.product_type_id
      );
      setSelectedProductType(productType[0]);
      // console.log("productType", productType[0]);

      let gender = genderDD.filter(
        (gender) => gender.label === editProductGenericDetails?.gender_name
      );
      setSelectedGender(gender[0]);
      // console.log({ gender });

      let season = seasonDD.filter(
        (season) => season.label === editProductGenericDetails?.season_name
      );
      setSelectedSeason(season[0]);

      let wearType = wearTypeDD.filter(
        (wearType) =>
          wearType.label === editProductGenericDetails?.wear_type_name
      );
      setSelectedWearType(wearType[0]);

      let clothType = clothTypeDD.filter(
        (clothType) =>
          clothType.label === editProductGenericDetails?.cloth_type_name
      );
      setSelectedClothType(clothType[0]);

      let occasionType = occasionTypeDD.filter(
        (occasionType) =>
          occasionType.label === editProductGenericDetails?.occasion_type_name
      );
      setSelectedOccasionType(occasionType[0]);
    }

    return () => {
      isMounted = false;
    };
  }, [editProductGenericDetails?.gender_id]);

  // UseEffect for pre-populating Tags Drop-down Values
  useEffect(() => {
    let isMounted = true;
    if (isMounted && editProductGenericDetails?.tag_details?.length > 0) {
      let tag = [];
      editProductGenericDetails?.tag_details?.map((mapObj) =>
        tag.push({
          label: mapObj?.tag_name,
          value: `${mapObj?.tag_id}`,
        })
      );
      setSelectedTag(tag);
      //console.log("tag", tag);
    }

    return () => {
      isMounted = false;
    };
  }, [editProductGenericDetails?.tag_details]);

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-10">
          <Dropdown
            defaultName="product_type_id"
            register={register}
            labelname="Product Type"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-60`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={productTypeDD}
            defaultValue={defaultValues.product_type_id}
            setValue={setValue}
            setSelected={setSelectedProductType}
            selected={selectedProductType}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="gender_id"
            register={register}
            labelname="Gender"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-50`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={genderDD}
            defaultValue={defaultValues.gender_id}
            setValue={setValue}
            setSelected={setSelectedGender}
            selected={selectedGender}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="season_id"
            register={register}
            labelname="Season"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-40`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={seasonDD}
            defaultValue={defaultValues.season_id}
            setValue={setValue}
            setSelected={setSelectedSeason}
            selected={selectedSeason}
            maxMenuHeight={120}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-10">
          <Dropdown
            defaultName="wear_type_id"
            register={register}
            labelname="Wear Type"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-30`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={(e) => clothTypeChangeHandler(e)}
            control={control}
            data={wearTypeDD}
            defaultValue={defaultValues.wear_type_id}
            setValue={setValue}
            setSelected={setSelectedWearType}
            selected={selectedWearType}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="cloth_type_id"
            register={register}
            labelname="Cloth Type"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-20`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={clothTypeListWearTypeWise}
            defaultValue={defaultValues.cloth_type_id}
            setValue={setValue}
            setSelected={setSelectedClothType}
            selected={selectedClothType}
            maxMenuHeight={120}
          />

          <Dropdown
            defaultName="occasion_type_id"
            register={register}
            labelname="Occasion Type"
            required={true}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-10`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={occasionTypeDD}
            defaultValue={defaultValues.occasion_type_id}
            setValue={setValue}
            setSelected={setSelectedOccasionType}
            selected={selectedOccasionType}
            maxMenuHeight={120}
          />
        </div>

        <Input
          defaultName="product_name"
          register={register}
          name="Product Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter product name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.product_name}
          setValue={setValue}
        />

        <TextArea
          defaultName="product_description"
          register={register}
          name="Product description"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Description of the product"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full resize-none h-32`}
          onChangeInput={null}
          defaultValue={defaultValues.product_description}
          setValue={setValue}
        />

        <div className="mt-10 mb-2 font-semibold text-lg uppercase">
          Detailed Description of the product
        </div>
        <div className="mb-10">
          <RichTextArea
            defaultName={"fit_details"}
            register={register}
            name={
              "Category Details (Type in 'Blank/ Exclusive/ 1970/ Signature Edition/ Gratitude' in the box below)"
            }
            // name={"Fit Details Description (Type in 'Demo' in the box below)"}
            classes={"w-full"}
            clearError={clearErrors}
            setError={setError}
            setValue={setValue}
            data={defaultValues.fit_details}
            errors={errors}
            pattern={null}
            required={true}
            height={300}
          />

          <RichTextArea
            defaultName={"other_details"}
            register={register}
            name={"Other Details Description"}
            classes={"w-full"}
            clearError={clearErrors}
            setError={setError}
            setValue={setValue}
            data={defaultValues.other_details}
            errors={errors}
            pattern={null}
            required={true}
            height={300}
          />

          <RichTextArea
            defaultName={"material_details"}
            register={register}
            name={
              "Material Details Description (Type in 'Demo' in the box below)"
            }
            classes={"w-full"}
            clearError={clearErrors}
            setError={setError}
            setValue={setValue}
            data={defaultValues.material_details}
            errors={errors}
            pattern={null}
            required={true}
            height={300}
          />
        </div>
        <div>
          <Input
            defaultName="product_price"
            register={register}
            name="Product Price"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter product price"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.product_price}
            setValue={setValue}
          />

          <Input
            defaultName="discount_percentage"
            register={register}
            name="Discount Percentage"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter discount percentage"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="number"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.discount_percentage}
            setValue={setValue}
          />
        </div>
        <div className="mt-10 mb-20">
          <Dropdown
            defaultName="tag_id"
            register={register}
            labelname="Tags"
            required={false}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-50`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={tagDD}
            defaultValue={defaultValues.tag_id}
            setValue={setValue}
            setSelected={setSelectedTag}
            selected={selectedTag}
            maxMenuHeight={120}
            isMulti={true}
            closeMenuOnSelect={false}
          />
        </div>

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditProductGenericDetails({});
              setValue("", "");
              setShowAddForm(false);
              setIsAdd(true);
            }}
            aria-label="Cancel Product Generic Details Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddProductForm;
