/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";

import { getFormattedDateDDMMMYYYYDate } from "../../utilities/dateFunctions/formatdate";

const OrderedItemCard = ({
  order_id,
  order_no,
  order_details_id,
  order_status,
  is_return_initiated,
  awb_tracking_number,
  order_date,
  billing_address,
  company_name,
  product_image,
  product_id,
  product_name,
  product_size,
  product_rate,
  discount_percentage,
  quantity,
  amount_paid,
  setSelectedProductId,
  onProductLinkClickHandler,
  setSelectedOrderDetailsId,
  setShowReturnPage,
}) => {
  const onLinkClick = (product_id) => {
    setSelectedProductId(product_id);
    onProductLinkClickHandler();
  };

  const onReturnItemClickHandler = (order_details_id) => {
    setSelectedOrderDetailsId(order_details_id);
    setShowReturnPage(true);
  };
  return (
    <>
      <div className="w-[95%] mx-auto border border-black font-tenorsans">
        <div className="flex justify-between bg-black px-3 py-4">
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="flex-col">
              <div className="uppercase text-xs text-white">Order Date</div>
              <div className="text-xs text-white">
                {getFormattedDateDDMMMYYYYDate(order_date)}
              </div>
            </div>

            <div className="flex-col">
              <div className="uppercase text-xs text-white">Item Total</div>
              {/* <div className="text-xs text-white">₹ {amount_paid}</div> */}
              <div className="text-xs text-white">
                <>
                  {discount_percentage === 0 ? (
                    <span>₹ {quantity * product_rate}</span>
                  ) : (
                    <span>
                      ₹{" "}
                      {quantity *
                        (product_rate -
                          (discount_percentage / 100) * product_rate)}
                    </span>
                  )}
                </>
              </div>
            </div>

            <div className="flex-col">
              <div className="uppercase text-xs text-white">Ship To</div>
              <div className="text-xs text-white">
                {billing_address?.first_name} {billing_address?.last_name}
              </div>
            </div>
          </div>
          <div className="uppercase text-xs text-white">
            Order# <span className="text-white">{order_no}</span>
          </div>
        </div>

        <div className="bg-white px-3 py-5">
          <div className="flex justify-between items-center">
            <div className="text-xs lg:text-base">
              Order Status: {order_status}
            </div>
            {order_status === "Delivered" && is_return_initiated !== true && (
              <div
                onClick={() => onReturnItemClickHandler(order_details_id)}
                className="text-[11px] lg:text-xs px-1 py-0.5 lg:px-2 lg:py-1 text-black border border-black hover:text-white hover:bg-black cursor-pointer transition-all ease-in-out duration-300"
              >
                Return Item
              </div>
            )}
            {order_status === "Delivered" && is_return_initiated === true && (
              <div className="text-[11px] lg:text-xs px-1 py-0.5 lg:px-2 lg:py-1 text-red-700 cursor-not-allowed">
                Return Request Placed
              </div>
            )}
          </div>

          <>
            {order_status === "Shipped" && awb_tracking_number !== "0" && (
              <div className="py-1 lg:py-3">
                <div className="text-xs lg:text-sm text-gray-500">
                  You can track your shipment here to see the delivery status.
                </div>
                <div className="pt-1 lg:pt-0 text-xs lg:text-sm">
                  DELHIVERY AWB tracking number:{" "}
                  <a
                    href={`https://www.delhivery.com/track/package/${awb_tracking_number}`}
                    target="_blank"
                    className="cursor-pointer text-blue-500"
                  >
                    {awb_tracking_number}
                  </a>
                </div>
              </div>
            )}
          </>
          <div className="pt-8 grid grid-cols-5 lg:pt-5">
            <div className="col-span-2 md:col-span-1">
              <img src={product_image} className="w-20 md:w-28 lg:w-40" />
            </div>
            <div className="col-span-3 md:col-span-4">
              <div className="flex-col">
                <Link to={`/view-product-details/${product_id}`}>
                  <span
                    // onClick={() => onLinkClick(product_id)}
                    className="cursor-pointer text-xs lg:text-base text-black hover:underline"
                  >
                    {product_name}
                  </span>
                </Link>
                <div className="text-xs lg:text-sm">
                  Size: <span className="">{product_size}</span>
                </div>
                <div className="text-xs lg:text-sm">
                  Quantity: <span className="">{quantity}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderedItemCard;
