// Admin CMS

import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/dashboard-components/dashboard.component";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import { IconContext } from "react-icons";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RETURN_REFUND_POLICY_CONFIG_URL } from "../../../../api/api_routing_urls";
import { ToastContainer } from "react-toastify";
import showToast from "../../../../utilities/notification/NotificationModal";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import AddReturnRefundPolicy from "./addReturnRefundPolicy.component";
// import useAuth from "../../../../authentication/hooks/useAuth";

const ReturnRefundPolicyAdmin = () => {
  // const { auth } = useAuth();

  const [showAddForm, setShowAddForm] = useState(false);
  const [returnRefundPolicy, setReturnRefundPolicy] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // const axiosPrivate = useAxiosPrivate();

  const isEdit =
    returnRefundPolicy !== undefined
      ? Object.keys(returnRefundPolicy).length > 0
      : false;

  const getReturnRefundPolicyDetails = async () => {
    try {
      const response = await axios.get(RETURN_REFUND_POLICY_CONFIG_URL);

      response.status === 200 &&
        setReturnRefundPolicy(response?.data?.returnRefundPolicyDetails);
      response.status === 202 &&
        showToast(
          "Return Refund Policy details not found in the system.",
          "error"
        );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getReturnRefundPolicyDetails();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dashboard>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-black" }}>
            <BsFillInfoCircleFill />
          </IconContext.Provider>
          &nbsp; Return - Refund Policy
        </H2withIcon>
        {!showAddForm && (
          <AddButton
            label={`${
              isEdit
                ? "Update Return - Refund Policy"
                : "Add Return - Refund Policy"
            }`}
            onclick={() => setShowAddForm(true)}
          />
        )}
      </div>
      {showAddForm ? (
        <AddReturnRefundPolicy
          getReturnRefundPolicyDetails={getReturnRefundPolicyDetails}
          returnRefundPolicy={returnRefundPolicy}
          setReturnRefundPolicy={setReturnRefundPolicy}
          setShowAddForm={setShowAddForm}
        />
      ) : (
        <ContainerShadow>
          {!isEdit ? (
            <p>Return - Refund Policy content yet to be added!</p>
          ) : (
            <div>
              <br />
              <label>
                Return - Refund Policy:
                <br />
              </label>
              <div
                dangerouslySetInnerHTML={{
                  __html: returnRefundPolicy.return_refund_policy_content,
                }}
              />
            </div>
          )}
        </ContainerShadow>
      )}
    </Dashboard>
  );
};

export default ReturnRefundPolicyAdmin;
