/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ORDER_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { AiFillPrinter } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import UpdateAdminRemarksForm from "./updateAdminRemarksForm.component";

import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import { PrintReport } from "../../../utilities/printPage/PrintReport";
import GenericModal from "../../../reusable-components/modals/GenericModal";

const ViewOrderDetailsAdmin = ({
  selectedOrder,
  setSelectedOrder,
  setViewOrderDetailsPage,
  getOrderDetailsAdminList,
  cartTotal,
  setCartTotal,
  orderTotal,
  setOrderTotal,
  deliverycharge,
}) => {
  const navigate = useNavigate();

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editOrderDetails, setEditOrderDetails] = useState({});
  const [adminRemarks, setAdminRemarks] = useState(
    selectedOrder?.admin_remarks
  );

  const [paymentStatusData, setPaymentStatusData] = useState({});
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [orderPaymentStatus, setOrderPaymentStatus] = useState("");

  const getOrderAdminRemarks = async () => {
    try {
      const response = await axios.get(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}/adminremarksupdate`,
        {
          params: {
            order_id: selectedOrder?.order_id,
          },
        }
      );
      // console.log("Order Admin Remarks", { response });
      response.status === 200 &&
        setAdminRemarks(response.data.orderAdminRemarks);
      response.status === 202 &&
        console.log("No order admin remarks found in the system.", "error");
    } catch (error) {
      console.error("getOrderAdminRemarks", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onBackClickHandler = () => {
    getOrderDetailsAdminList();
    setSelectedOrder(null);
    setViewOrderDetailsPage(false);
  };

  const orderShippingStatusUpdateHandler = async (userid, orderid) => {
    try {
      let sendDataObj = {
        user_id: userid,
        order_id: orderid,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}/shippingstatusupdate`,
        sendDataObj
      );

      if (response.status === 200) {
        console.log("Order Shipping Status updated successfully!");

        let obj = { ...selectedOrder };
        obj.order_status = "Shipped";

        setSelectedOrder(obj);
      } else {
        console.log("Order Shipping Status updation error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const orderDeliveredStatusUpdateHandler = async (userid, orderid) => {
    try {
      let sendDataObj = {
        user_id: userid,
        order_id: orderid,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}/deliveredstatusupdate`,
        sendDataObj
      );

      if (response.status === 200) {
        console.log("Order Delivered Status updated successfully!");
        getOrderDetailsAdminList();
        setViewOrderDetailsPage(false);

        // let obj = { ...selectedOrder };
        // obj.order_status = "Delivered";

        // setSelectedOrder(obj);
      } else {
        console.log("Order Delivery Status updation error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onUpdateClickHandler = (orderObj) => {
    setEditOrderDetails(orderObj);
    setShowUpdateForm(true);
  };

  const calculateTotal = () => {
    let ctotal = 0;
    let ototal = 0;
    selectedOrder?.ordered_items?.map((mapObj) => {
      ctotal =
        ctotal +
        mapObj?.product_details?.quantity *
          (mapObj?.product_details?.product_rate -
            (mapObj?.product_details?.discount_percentage / 100) *
              mapObj?.product_details?.product_rate);
    });
    ototal = ototal + ctotal + deliverycharge;
    setCartTotal(ctotal);
    setOrderTotal(ototal);
  };

  // console.log("selectedOrder", selectedOrder);

  const checkOrderPaymentStatus = async () => {
    try {
      setShowPaymentStatusModal(true);
      const checkOrderObj = {
        reference_no: selectedOrder?.payment_tracking_id,
        order_no: selectedOrder?.order_no,
      };

      console.log({ checkOrderObj });

      const response = await axios.post("/order-status-tracker", checkOrderObj);
      console.log("Response:", response.data);

      if (response.status === 200) {
        setPaymentStatusData(response.data.decResponse);
      }
    } catch (error) {
      console.error("checkOrderStatus", error);
    }
  };

  const orderPaymentStatusUpdateHandler = async (orderno, paymentstatus) => {
    try {
      let sendDataObj = {
        order_no: orderno,
        payment_status: paymentstatus,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}/orderpaymentstatusupdate`,
        sendDataObj
      );

      if (response.status === 200) {
        console.log("Order Payment Status updated successfully!");

        let obj = { ...selectedOrder };
        obj.payment_status = orderPaymentStatus;

        setSelectedOrder(obj);
        setShowPaymentStatusModal(false);
        setOrderPaymentStatus("");
      } else {
        console.log("Order Payment Status updation error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      calculateTotal();
      getOrderAdminRemarks();
      setOrderPaymentStatus("");
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div>
        <div className=" mt-2 flex justify-between pb-2 border-b border-gray-400">
          <div>
            View Order Details for{" "}
            <span className="font-bold">Order# {selectedOrder?.order_no}</span>
          </div>
          <div
            onClick={() => onBackClickHandler()}
            className="flex justify-end px-4 py-1 text-xs text-black hover:underline cursor-pointer"
          >
            Go Back
          </div>
        </div>

        <div className="mt-10  bg-white">
          <div className="px-4 py-5 flex justify-between items-center border-b border-gray-200">
            <div>
              Ordered on:{" "}
              <span className="font-semibold">
                {getFormattedDateDDMMMYYYYDate(selectedOrder?.order_date)}
              </span>
            </div>
            <div className="flex gap-3 items-center">
              {selectedOrder?.order_status === "Delivered" ? (
                <div className="px-3 py-2 border bg-white rounded-sm text-xs text-green-700">
                  Order Delivered
                </div>
              ) : (
                <>
                  {selectedOrder?.order_status !== "Shipped" ? (
                    <>
                      <div
                        onClick={() =>
                          orderShippingStatusUpdateHandler(
                            selectedOrder?.user_id,
                            selectedOrder?.order_id
                          )
                        }
                        className="px-3 py-2 border bg-black rounded-sm text-xs text-white cursor-pointer"
                      >
                        Mark as Shipped
                      </div>
                    </>
                  ) : (
                    <div className="flex gap-2">
                      <div className="px-3 py-2 border bg-white rounded-sm text-xs text-green-700">
                        Order Shipped
                      </div>
                      <div
                        onClick={() =>
                          orderDeliveredStatusUpdateHandler(
                            selectedOrder?.user_id,
                            selectedOrder?.order_id
                          )
                        }
                        className="px-3 py-2 border bg-black rounded-sm text-xs text-white cursor-pointer"
                      >
                        Mark as Delivered
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* <div className="py-2 px-3 bg-gray-400 rounded-sm cursor-pointer">
                <AiFillPrinter color="white" size={16} />
              </div> */}
            </div>
          </div>

          <div className="mt-10 px-4 grid grid-cols-3  border-b border-gray-200">
            <div className="flex gap-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <FaUserAlt size={20} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Customer</div>
                <div>
                  <p>
                    {selectedOrder?.ordered_by?.fname}{" "}
                    {selectedOrder?.ordered_by?.lname}
                  </p>
                  <p>{selectedOrder?.ordered_by?.usremail}</p>
                  <p>{selectedOrder?.ordered_by?.usrphoneno}</p>
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <FaTruck size={20} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Shipping Partner</div>
                <div>
                  <p>Delhivery Couriers</p>
                  <p>privacy@delhivery.com</p>
                  <p>Sikkim, India</p>
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <div>
                <div className="px-3 py-3 rounded-full bg-blue-100">
                  <MdLocationPin size={22} color="black" />
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="font-bold">Deliver to</div>
                <div>
                  <p>
                    {selectedOrder?.billing_address?.first_name}{" "}
                    {selectedOrder?.billing_address?.last_name}
                  </p>
                  <p>{selectedOrder?.billing_address?.address_line1}</p>
                  <p>{selectedOrder?.billing_address?.address_line2}</p>
                  <p>{selectedOrder?.billing_address?.landmark}</p>
                  <p>{selectedOrder?.billing_address?.city_name}</p>
                  <p>
                    {selectedOrder?.billing_address?.state_name} -{" "}
                    {selectedOrder?.billing_address?.pincode}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <section className="my-10">
            <div className="grid grid-cols-3 gap-8">
              <div className="col-span-2 border border-slate-200">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-slate-200">
                      <th className="py-5 pl-2 font-light text-left text-gray-600">
                        Product
                      </th>
                      <th className="px-2 font-light text-left text-gray-600">
                        Size
                      </th>
                      <th className="px-2 font-light text-left text-gray-600">
                        Quantity
                      </th>
                      <th className="px-3 font-light text-left text-gray-600">
                        Unit Price
                      </th>
                      <th className="px-2 font-light text-left text-gray-600">
                        Discount (in %)
                      </th>
                      <th className="px-2 font-light text-left text-gray-600">
                        Total Amount Paid
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedOrder?.ordered_items?.map((mapObj) => (
                      <tr className="border-b border-slate-200">
                        <td className="py-5 pl-2">
                          {mapObj?.product_details?.product_name}
                        </td>
                        <td className="px-2">
                          {mapObj?.product_details?.size_name}
                        </td>
                        <td className="px-2">
                          {mapObj?.product_details?.quantity}
                        </td>
                        <td className="px-2">
                          ₹ {mapObj?.product_details?.product_rate}
                        </td>
                        <td className="px-2">
                          {mapObj?.product_details?.discount_percentage}%
                        </td>
                        <td className="px-2">
                          {/* ₹ {mapObj?.product_details?.amount_paid} */}

                          <>
                            {mapObj?.product_details?.discount_percentage ===
                            0 ? (
                              <span>
                                ₹{" "}
                                {mapObj?.product_details?.quantity *
                                  mapObj?.product_details?.product_rate}
                              </span>
                            ) : (
                              <span>
                                ₹{" "}
                                {mapObj?.product_details?.quantity *
                                  (mapObj?.product_details?.product_rate -
                                    (mapObj?.product_details
                                      ?.discount_percentage /
                                      100) *
                                      mapObj?.product_details?.product_rate)}
                              </span>
                            )}
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-between mt-10 mb-5 px-5">
                  <div className="">Order Subtotal: ₹ {cartTotal}</div>
                  <div className="">Shipping Charge: ₹ {deliverycharge}</div>
                  <div className="flex justify-end font-semibold">
                    Grand Total: ₹ {orderTotal}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex flex-col gap-y-5">
                  <div className="border border-slate-200 rounded-md px-3 py-4 ">
                    <div className="flex justify-between items-center">
                      <div className="text-lg text-gray-600 font-semibold">
                        Payment Info
                      </div>

                      <div
                        onClick={() => checkOrderPaymentStatus()}
                        className="cursor-pointer text-xs uppercase py-1 px-2 bg-black text-white rounded-sm"
                      >
                        Check Payment Status
                      </div>
                    </div>

                    <div className="flex gap-1 pt-3">
                      <div className="font-semibold">Payment Mode:</div>
                      <div>{selectedOrder?.payment_mode}</div>
                    </div>
                    <div className="flex gap-1 pt-3">
                      <div className="font-semibold">Payment Status: </div>
                      <div>{selectedOrder?.payment_status}</div>
                    </div>

                    {/* <div className="text-xs mt-3">
                      Payment Tracking ID:{" "}
                      {selectedOrder?.payment_tracking_id ? (
                        <span>{selectedOrder?.payment_tracking_id}</span>
                      ) : (
                        <span>No Tracking ID found!</span>
                      )}
                    </div> */}
                  </div>

                  <div className="border border-slate-200 rounded-md px-3 py-4 ">
                    <div className="font-semibold">Remarks: </div>
                    <div className="pt-1 flex justify-between items-center p-1 border border-gray-300">
                      {/* <div>{selectedOrder?.admin_remarks}</div> */}
                      <div>{adminRemarks}</div>
                      <div
                        onClick={() => onUpdateClickHandler(selectedOrder)}
                        className="cursor-pointer"
                      >
                        <AiOutlineEdit color="black" size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {showPaymentStatusModal && (
        <>
          <GenericModal
            open={showPaymentStatusModal}
            setOpen={setShowPaymentStatusModal}
            title={"Order Payment Status"}
            // isAdd={true}
            clearData={setShowPaymentStatusModal}
          >
            <div className="mt-4 bg-white py-6 px-4">
              {paymentStatusData?.order_no ? (
                <>
                  {
                    <section>
                      <div className="border-b pb-1 mb-3 flex justify-between items-center">
                        <div className="font-semibold text-lg text-center uppercase">
                          Payment Details
                        </div>

                        <div
                          onClick={() => {
                            setShowPaymentStatusModal(false);
                            setOrderPaymentStatus("");
                          }}
                          className="cursor-pointer"
                        >
                          <RxCross2 />
                        </div>
                      </div>

                      <div className="mt-4 text-sm">
                        <p className="mt-3">
                          <strong>Reference No:</strong>{" "}
                          {paymentStatusData.reference_no}
                        </p>

                        <p className="mt-3">
                          <strong>Order No:</strong>{" "}
                          {paymentStatusData.order_no}
                        </p>

                        <p className="mt-3">
                          <strong>Order Currency:</strong>{" "}
                          {paymentStatusData.order_currncy}
                        </p>

                        <p className="mt-3">
                          <strong>Order Amount:</strong>{" "}
                          {paymentStatusData.order_amt}
                        </p>

                        <p className="mt-3">
                          <strong>Order Date and Time:</strong>{" "}
                          {paymentStatusData.order_date_time}
                        </p>

                        <p className="mt-3">
                          <strong>Order Status:</strong>{" "}
                          {paymentStatusData.order_status}
                        </p>

                        <p className="mt-3">
                          <strong>Bank Response:</strong>{" "}
                          {paymentStatusData.order_bank_response}
                        </p>
                      </div>

                      <div>
                        <p className="mt-4 text-left">
                          <span className="font-medium text-gray-600">
                            You may also verify the same by logging in to CC
                            Avenue Merchant Dashboard at:
                          </span>{" "}
                        </p>
                        <a
                          href="https://dashboard.ccavenue.com/jsp/merchant/hdfcLogin.jsp"
                          target="_blank"
                          className="mt-3 hover:underline text-sm"
                        >
                          https://dashboard.ccavenue.com/jsp/merchant/hdfcLogin.jsp
                        </a>

                        <p className="mt-5">
                          <strong>Search the transaction for:</strong>
                        </p>

                        <p>
                          <strong>Order No:</strong> {selectedOrder?.order_no}
                        </p>

                        {selectedOrder?.payment_tracking_id && (
                          <p>
                            <strong>CC Avenue Ref No:</strong>{" "}
                            {selectedOrder?.payment_tracking_id}
                          </p>
                        )}

                        <p className="mt-5">
                          After verifying the details from the merchant
                          dashboard, kindly update the status below.
                        </p>

                        <div className="mt-6 flex justify-between items-center">
                          <div
                            onClick={() => setOrderPaymentStatus("Success")}
                            className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-green-600 text-white text-center"
                          >
                            Success
                          </div>

                          <div
                            onClick={() => setOrderPaymentStatus("Failed")}
                            className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-red-600 text-white text-center"
                          >
                            Failed
                          </div>

                          <div
                            onClick={() => setOrderPaymentStatus("Refunded")}
                            className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-yellow-600 text-white text-center"
                          >
                            Refunded
                          </div>
                        </div>

                        {orderPaymentStatus !== "" && (
                          <div
                            onClick={() =>
                              orderPaymentStatusUpdateHandler(
                                selectedOrder?.order_no,
                                orderPaymentStatus
                              )
                            }
                            className="mt-8 flex justify-center border cursor-pointer p-2 bg-black text-white"
                          >
                            Update transaction as
                            <strong className="pl-1">
                              {orderPaymentStatus}
                            </strong>
                          </div>
                        )}
                      </div>
                    </section>
                  }
                </>
              ) : (
                <section>
                  <div className="border-b pb-1 mb-3 flex justify-between items-center">
                    <div className="font-semibold text-lg text-center uppercase">
                      Payment Details
                    </div>

                    <div
                      onClick={() => {
                        setShowPaymentStatusModal(false);
                        setOrderPaymentStatus("");
                      }}
                      className="cursor-pointer"
                    >
                      <RxCross2 />
                    </div>
                  </div>

                  <p className="mt-4 text-left">
                    <span className="font-medium text-red-800">
                      No records found for the given transaction. Please login
                      to CC Avenue Merchant Dashboard at:
                    </span>{" "}
                  </p>
                  <a
                    href="https://dashboard.ccavenue.com/jsp/merchant/hdfcLogin.jsp"
                    target="_blank"
                    className="mt-3 hover:underline text-sm"
                  >
                    https://dashboard.ccavenue.com/jsp/merchant/hdfcLogin.jsp
                  </a>

                  <div className="mt-5 text-sm">
                    <p>
                      <strong>Search the transaction for:</strong>
                    </p>

                    <p>
                      <strong>Order No:</strong> {selectedOrder?.order_no}
                    </p>

                    {selectedOrder?.payment_tracking_id && (
                      <p>
                        <strong>CC Avenue Ref No#</strong>{" "}
                        {selectedOrder?.payment_tracking_id}
                      </p>
                    )}

                    <p className="mt-5">
                      After verifying the details from the merchant dashboard,
                      kindly update the status below.
                    </p>

                    <div className="mt-6 flex justify-between items-center">
                      <div
                        onClick={() => setOrderPaymentStatus("Success")}
                        className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-green-600 text-white text-center"
                      >
                        Success
                      </div>

                      <div
                        onClick={() => setOrderPaymentStatus("Failed")}
                        className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-red-600 text-white text-center"
                      >
                        Failed
                      </div>

                      <div
                        onClick={() => setOrderPaymentStatus("Refunded")}
                        className="w-20 p-1 cursor-pointer text-xs rounded-full uppercase bg-yellow-600 text-white text-center"
                      >
                        Refunded
                      </div>
                    </div>

                    {orderPaymentStatus !== "" && (
                      <div
                        onClick={() =>
                          orderPaymentStatusUpdateHandler(
                            selectedOrder?.order_no,
                            orderPaymentStatus
                          )
                        }
                        className="mt-8 flex justify-center border cursor-pointer p-2 bg-black text-white"
                      >
                        Update transaction as
                        <strong className="pl-1">{orderPaymentStatus}</strong>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </div>
          </GenericModal>
        </>
      )}

      {/* To print this above section, copy this and wrap the entire thing inside of
      <PrintReport /> again */}
      <section className="flex justify-center">
        <PrintReport reportTitle={"Order Details"} buttonName={"Order"}>
          <div>
            <div className=" mt-2 flex justify-between">
              <div>
                View Order Details for{" "}
                <span className="font-bold">
                  Order# {selectedOrder?.order_no}
                </span>
              </div>
              <div
                onClick={() => onBackClickHandler()}
                className="flex justify-end px-4 py-1 text-xs text-blue-600 hover:underline cursor-pointer"
              >
                Go Back
              </div>
            </div>

            <div className="mt-10  bg-white">
              <div className="px-4 py-5 flex justify-between items-center border-b border-gray-200">
                <div>
                  Ordered on:{" "}
                  <span className="font-semibold">
                    {getFormattedDateDDMMMYYYYDate(selectedOrder?.order_date)}
                  </span>
                </div>
                <div className="flex gap-3 items-center">
                  {selectedOrder?.order_status !== "Shipped" ? (
                    <>
                      <div
                        onClick={() =>
                          orderShippingStatusUpdateHandler(
                            selectedOrder?.order_id
                          )
                        }
                        className="px-3 py-2 border bg-blue-700 rounded-sm text-xs text-white cursor-pointer"
                      >
                        Mark as Shipped
                      </div>
                    </>
                  ) : (
                    <div className="px-3 py-2 border bg-white rounded-sm text-xs text-green-700">
                      Order Shipped
                    </div>
                  )}

                  {/* <div className="py-2 px-3 bg-gray-400 rounded-sm cursor-pointer">
                    <AiFillPrinter color="white" size={16} />
                  </div> */}
                </div>
              </div>

              <div className="mt-10 px-4 grid grid-cols-3  border-b border-gray-200">
                <div className="flex gap-4">
                  <div>
                    <div className="px-3 py-3 rounded-full bg-blue-100">
                      <FaUserAlt size={20} color="blue" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="font-bold">Customer</div>
                    <div>
                      <p>
                        {selectedOrder?.ordered_by?.fname}{" "}
                        {selectedOrder?.ordered_by?.lname}
                      </p>
                      <p>{selectedOrder?.ordered_by?.usremail}</p>
                      <p>{selectedOrder?.ordered_by?.usrphoneno}</p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div>
                    <div className="px-3 py-3 rounded-full bg-blue-100">
                      <FaTruck size={20} color="blue" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="font-bold">Shipping Partner</div>
                    <div>
                      <p>Delhivery Couriers</p>
                      <p>privacy@delhivery.com</p>
                      <p>Sikkim, India</p>
                    </div>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div>
                    <div className="px-3 py-3 rounded-full bg-blue-100">
                      <MdLocationPin size={22} color="blue" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="font-bold">Deliver to</div>
                    <div>
                      <p>
                        {selectedOrder?.billing_address?.first_name}{" "}
                        {selectedOrder?.billing_address?.last_name}
                      </p>
                      <p>{selectedOrder?.billing_address?.address_line1}</p>
                      <p>{selectedOrder?.billing_address?.address_line2}</p>
                      <p>{selectedOrder?.billing_address?.landmark}</p>
                      <p>{selectedOrder?.billing_address?.city_name}</p>
                      <p>
                        {selectedOrder?.billing_address?.state_name} -{" "}
                        {selectedOrder?.billing_address?.pincode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <section className="my-10">
                <div className="grid grid-cols-3 gap-8">
                  <div className="col-span-2 border border-slate-200">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b border-slate-200">
                          <th className="py-5 pl-2 font-light text-left text-gray-600">
                            Product
                          </th>
                          <th className="px-2 font-light text-left text-gray-600">
                            Size
                          </th>
                          <th className="px-2 font-light text-left text-gray-600">
                            Quantity
                          </th>
                          <th className="px-3 font-light text-left text-gray-600">
                            Unit Price
                          </th>
                          <th className="px-2 font-light text-left text-gray-600">
                            Discount (in %)
                          </th>
                          <th className="px-2 font-light text-left text-gray-600">
                            Total Amount Paid
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrder?.ordered_items?.map((mapObj) => (
                          <tr className="border-b border-slate-200">
                            <td className="py-5 pl-2">
                              {mapObj?.product_details?.product_name}
                            </td>
                            <td className="px-2">
                              {mapObj?.product_details?.size_name}
                            </td>
                            <td className="px-2">
                              {mapObj?.product_details?.quantity}
                            </td>
                            <td className="px-2">
                              ₹ {mapObj?.product_details?.product_rate}
                            </td>
                            <td className="px-2">
                              {mapObj?.product_details?.discount_percentage}%
                            </td>
                            <td className="px-2">
                              {/* ₹ {mapObj?.product_details?.amount_paid} */}

                              <>
                                {mapObj?.product_details
                                  ?.discount_percentage === 0 ? (
                                  <span>
                                    ₹{" "}
                                    {mapObj?.product_details?.quantity *
                                      mapObj?.product_details?.product_rate}
                                  </span>
                                ) : (
                                  <span>
                                    ₹{" "}
                                    {mapObj?.product_details?.quantity *
                                      (mapObj?.product_details?.product_rate -
                                        (mapObj?.product_details
                                          ?.discount_percentage /
                                          100) *
                                          mapObj?.product_details
                                            ?.product_rate)}
                                  </span>
                                )}
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex justify-between mt-10 mb-5 px-5">
                      <div className="">Order Subtotal: ₹ {cartTotal}</div>
                      <div className="">
                        Shipping Charge: ₹ {deliverycharge}
                      </div>
                      <div className="flex justify-end font-semibold">
                        Grand Total: ₹ {orderTotal}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="flex flex-col gap-y-5">
                      <div className="border border-slate-200 rounded-md px-3 py-4 ">
                        <div className="text-lg text-gray-600 font-semibold">
                          Payment Info
                        </div>
                        <div className="flex gap-1 pt-3">
                          <div className="font-semibold">Payment Mode:</div>
                          <div>{selectedOrder?.payment_mode}</div>
                        </div>
                        <div className="flex gap-1 pt-3">
                          <div className="font-semibold">Payment Status: </div>
                          <div>{selectedOrder?.payment_status}</div>
                        </div>
                      </div>
                      <div className="border border-slate-200 rounded-md px-3 py-4 ">
                        <div className="font-semibold">Remarks: </div>
                        <div className="pt-1 flex justify-between items-center p-1 border border-gray-300">
                          <div>{selectedOrder?.admin_remarks}</div>
                          <div className="cursor-pointer">
                            <AiOutlineEdit color="orange" size={20} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </PrintReport>
      </section>

      {showUpdateForm && (
        <GenericModal
          open={showUpdateForm}
          setOpen={setShowUpdateForm}
          title={"Update Order AWB Number"}
          isAdd={false}
          clearData={setEditOrderDetails}
        >
          <UpdateAdminRemarksForm
            editOrderDetails={editOrderDetails}
            setEditOrderDetails={setEditOrderDetails}
            setShowUpdateForm={setShowUpdateForm}
            getOrderAdminRemarks={getOrderAdminRemarks}
          />
        </GenericModal>
      )}
    </>
  );
};

export default ViewOrderDetailsAdmin;
