import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import { BsArrowRight } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: null,
  });

  return (
    <section>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          // className="pb-3 md:mb-6"
        >
          <form>
            <div className="flex items-center justify-start pl-2 md:pl-0 md:justify-end mb-1">
              {/* <Input2
                defaultName="search_term"
                register={register}
                // name="Search Term"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="Enter Keyword"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={` text-slate-800 placeholder-black md:placeholder-transparent 
                text-xs md:text-sm uppercase w-full focus:outline-none focus:ring-0 
                focus:border-b focus:border-gray-800 border-0 border-b border-gray-800 bg-slate-300`}
                onChangeInput={(e) => {
                  // console.log(e);
                  setSearchTerm(e);
                }}
                setValue={setValue}
                defaultValue={searchTerm}
              /> */}

              <div className="flex justify-center items-center border-b border-slate-800 w-full md:min-w-60">
                <input
                  placeholder="Search Products"
                  onChange={(e) => {
                    // console.log(e);
                    setSearchTerm(e.target.value);
                  }}
                  // className={`placeholder-black lg:placeholder-transparent`}
                  className={`text-slate-800 placeholder-black font-tenorsans 
                text-xs md:text-sm uppercase w-full focus:outline-none focus:ring-0 
                focus:border-0 border-0 mb-1 md:mr-3 mt-5 md:mt-0`}
                />

                <Link to={`/searched-products/${searchTerm}`}>
                  <button
                    className="cursor-pointer pt-5 md:pt-0"
                    onClick={() => handleSubmit}
                  >
                    <CiSearch color="black" />
                  </button>
                </Link>
              </div>
            </div>
          </form>
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default SearchBar;
