/* eslint-disable jsx-a11y/alt-text */
// Public View

import React, { useState, useEffect } from "react";

import { ABOUT_US_GRATITUDE_CONFIG_URL } from "../../../api/api_routing_urls";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";

import bblogo from "../../../assets/Bytesberry_Logo_Black.png";

import { BsArrowLeft } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import showToast from "../../../utilities/notification/NotificationModal";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

import logo from "../../../assets/gratitude_black_logo-nobg.png";
import aboutimage from "../../../assets/aboutus.jpg";
import "./about_us.styles.css";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const d = new Date();

  const getAboutUsDetails = async () => {
    try {
      const response = await axios.get(ABOUT_US_GRATITUDE_CONFIG_URL);
      response.status === 200 && setAboutUs(response?.data?.aboutUsDetails);
      response.status === 202 &&
        showToast("About Us details not found in the system.", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getAboutUsDetails();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <section>
        {isLoading ? (
          <div className="bg-white h-screen flex items-center justify-center">
            <AnimatedLoader />
          </div>
        ) : (
          <section className="font-tenorsans">
            <div className="bg-white min-h-screen pt-24 lg:pt-36 pb-20 md:px-[5%] grid grid-cols-1 lg:grid-cols-3 lg:gap-x-8">
              <div className="flex justify-center lg:col-span-1">
                <img
                  src={aboutimage}
                  className="object-cover max-sm:w-[90%] h-full md:h-4/5 lg:h-3/4"
                />
              </div>

              <div className="lg:col-span-2 w-[90%] mx-auto lg:w-full">
                <div className="">
                  {/* <div className="flex justify-center">
                <img src={logo} className="h-16 mb-2" />
              </div> */}

                  <div className="pt-6 md:pt-0 uppercase text-[10px] md:text-xs lg:text-sm font-light tracking-wider text-black">
                    <p className="text-center">
                      Gratitude is an independent fashion label,
                    </p>

                    <p className="text-center">
                      providing minimalistic, contemporary and high quality
                      apparel
                    </p>

                    <p className="text-center">
                      founded in 2023 in Gangtok, Sikkim
                    </p>

                    <p className="text-center">
                      It designs, manufacture and market unisex wear.
                    </p>
                  </div>

                  <div className="font-bold text-xl uppercase text-center pt-14 md:pt-8 pb-3">
                    The Purpose
                  </div>

                  <div
                    className="font-light leading-6 text-center text-sm lg:text-base"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.about_us_content,
                    }}
                  />

                  <div className="flex justify-center lg:justify-end">
                    <Link to="/">
                      <div className="mt-10 bg-white cursor-pointer text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                        Continue Shopping
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-5 border-t text-center p-1.5 text-xs text-black flex flex-col justify-center items-center">
              <span className="">©{d.getFullYear()} Copyright: Gratitude </span>
              <span className="py-4">
                Powered by:{" "}
                <a href="https://bytesberry.com" target="_blank">
                  <img src={bblogo} className="pt-1 h-7" />
                </a>
              </span>
            </div>
          </section>
        )}
      </section>
    </>
  );
};

export default AboutUs;
