/* eslint-disable no-undef */
const generateQueryString = (params) => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${params[key]}`
      // (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
  return queryString;
};

module.exports = {
  generateQueryString,
};
