import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { TAG_CONFIG_URL } from "../../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import Dashboard from "../../../../common/dashboard-components/dashboard.component";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";

import AddTagForm from "./addTagForm.component";
import TagList from "./tagList.component";

const TagConfig = () => {
  // const { auth } = useAuth();
  const [tagList, setTagList] = useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editTagDetails, setEditTagDetails] = useState({});
  const isEdit = Object.keys(editTagDetails).length > 0 ? true : false;

  const getTagList = async () => {
    try {
      const response = await axios.get(TAG_CONFIG_URL);
      // console.log("Tag List", { response });
      response.status === 200 && setTagList(response.data.tagList);
      response.status === 202 &&
        showToast("No tag entries found in the system.", "error");
    } catch (error) {
      console.error("getTagList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getTagList();
      setEditTagDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Tags"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Tag Details" : "Add Tag Details"}`}
        isAdd={true}
        clearData={setEditTagDetails}
      >
        <AddTagForm
          getTagList={getTagList}
          editTagDetails={editTagDetails}
          setEditTagDetails={setEditTagDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <TagList
          tagList={tagList}
          getTagList={getTagList}
          setEditTagDetails={setEditTagDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default TagConfig;
