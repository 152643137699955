import React, { useState, useEffect } from "react";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import showToast from "../../../utilities/notification/NotificationModal";
// import Toggle from "../../../reusable-components/toggleswitch/toggle.component";
import GenericModal from "../../../reusable-components/modals/GenericModal";

import { PRODUCT_STOCK_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { FaRegEdit } from "react-icons/fa";
import UpdateVariantStockForm from "./updateVariantStockForm.component";

const ManageProductInventory = ({
  setShowManageStockTable,
  selectedProductId,
  setSelectedProductId,
  selectedProductName,
  setSelectedProductName,
  getProductWithVariantDetailsList,
}) => {
  const [productWithStockDetailsList, setProductWithStockDetailsList] =
    useState([]);

  const getProductWithStockDetailsList = async (product_id) => {
    try {
      const response = await axios.get(PRODUCT_STOCK_DETAILS_CONFIG_URL, {
        params: {
          product_id,
        },
      });
      // console.log("Product with stock details List", { response });
      response.status === 200 &&
        setProductWithStockDetailsList(
          response.data.productsWithStockDetailsList
        );
      response.status === 202 &&
        showToast("No product stock found in the system.", "error");
    } catch (error) {
      console.error("getProductWithStockDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const [editStockDetails, setEditStockDetails] = useState();
  const [showAddForm, setShowAddForm] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);

  const toggleChangeHandler = (event) => {
    setToggleSwitch(event.target.checked);
    // console.log("Toggle status: ", toggleSwitch);
  };

  const onBackClickHandler = () => {
    setShowManageStockTable((prev) => !prev);
    setSelectedProductId(null);
    setSelectedProductName("");
    getProductWithVariantDetailsList();
  };

  const onUpdateClickHandler = (product) => {
    setEditStockDetails(product);
    setShowAddForm(true);
  };

  //useEffect to fetch variants combinations and stock details for the particular selected product id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithStockDetailsList(selectedProductId);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProductId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setEditStockDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div className="flex justify-between my-5 border-b border-gray-400 pb-3">
        <div className="font-bold uppercase px-2 py-1">
          {selectedProductName}
        </div>
        <div>
          {/* <Toggle
            classForLabel="text-xs text-slate-600"
            labeldescription="Apply same quantity to all variants?"
          /> */}

          {/* <label class="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={toggleSwitch}
              class="sr-only peer"
              onChange={toggleChangeHandler}
            />
            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span class="ml-3 text-xs font-medium text-gray-900 dark:text-gray-300">
              Apply same quantity to all variants?
            </span>
          </label> */}
        </div>
        <div
          className="flex justify-end px-4 py-1 text-xs text-black hover:underline cursor-pointer"
          onClick={() => onBackClickHandler()}
        >
          Back to list
        </div>
      </div>

      <div className="pt-4">
        <TableWrapper>
          <thead className="bg-white text-sm">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 tracking-wider"
              >
                Sl. No.
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 tracking-wider"
              >
                Fabric Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 tracking-wider"
              >
                Size
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 tracking-wider"
              >
                Color
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left font-medium text-gray-900 tracking-wider"
              >
                Qty. in stock
              </th>
              <th
                scope="col"
                className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 tracking-wider"
              >
                Update Stock
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
            {productWithStockDetailsList.length > 0 &&
              productWithStockDetailsList.map((product, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.fabric_type_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.size_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.color_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {product.stock_quantity}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div
                      className="flex justify-center cursor-pointer text-blue-900"
                      onClick={() => onUpdateClickHandler(product)}
                    >
                      <FaRegEdit color="black" />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableWrapper>
      </div>

      {showAddForm && (
        <GenericModal
          open={showAddForm}
          setOpen={setShowAddForm}
          title={"Update Stock Quantity"}
          isAdd={true}
          clearData={setEditStockDetails}
        >
          <UpdateVariantStockForm
            editStockDetails={editStockDetails}
            setEditStockDetails={setEditStockDetails}
            setShowAddForm={setShowAddForm}
            getProductWithStockDetailsList={getProductWithStockDetailsList}
            selectedProductId={selectedProductId}
          />
        </GenericModal>
      )}
    </>
  );
};

export default ManageProductInventory;
