import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import {
  PRODUCT_GENERIC_DETAILS_CONFIG_URL,
  PRODUCT_VARIANT_DETAILS_CONFIG_URL,
  FABRIC_TYPE_CONFIG_URL,
  SIZE_CONFIG_URL,
  COLOR_CONFIG_URL,
} from "../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../api/axios";
import Dashboard from "../../../common/dashboard-components/dashboard.component";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import showToast from "../../../utilities/notification/NotificationModal";

import AddProductVariantForm from "./addProductVariantForm.component";
import ProductVariantList from "./productVariantList.component";

const ProductVariantConfig = () => {
  // const { auth } = useAuth();
  const [productWithVariantDetailsList, setProductWithVariantDetailsList] =
    useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editProductVariantDetails, setEditProductVariantDetails] = useState(
    {}
  );
  const [addProductVariantDetails, setAddProductVariantDetails] = useState({});

  const [fabricTypeDD, setFabricTypeDD] = useState([]);
  const [sizeDD, setSizeDD] = useState([]);
  const [productColorDD, setProductColorDD] = useState([]);

  const getFabricTypeList = async () => {
    try {
      const response = await axios.get(FABRIC_TYPE_CONFIG_URL);
      // console.log("Fabric Type List", { response });
      if (response?.status === 200) {
        if (response?.data?.fabricTypeList.length > 0) {
          let fabricTypeDD = [];
          response?.data?.fabricTypeList?.map((fabricObj) => {
            fabricTypeDD.push({
              label: fabricObj.fabric_type_name,
              value: fabricObj.fabric_type_id,
            });
          });
          setFabricTypeDD(fabricTypeDD);
          // console.log("fabricTypeDD", fabricTypeDD);
        } else {
          setFabricTypeDD([]);
        }
      }

      response.status === 202 &&
        showToast("No fabric type config found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getFabricTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getSizeList = async () => {
    try {
      const response = await axios.get(SIZE_CONFIG_URL);
      // console.log("Size List", { response });
      let sizeDD = [];
      if (response.status === 200) {
        response?.data?.sizeList?.map((sizeObj) => {
          sizeDD.push({
            label: sizeObj.size_name,
            value: sizeObj.size_id,
          });
        });
        setSizeDD(sizeDD);
        // console.log("sizeDD", sizeDD);
      }

      response.status === 202 &&
        showToast("No size config found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getSizeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getProductColorList = async () => {
    try {
      const response = await axios.get(COLOR_CONFIG_URL);
      // console.log("Product Color List", { response });
      let productColorDD = [];
      if (response.status === 200) {
        response?.data?.colorList?.map((colorObj) => {
          productColorDD.push({
            label: colorObj.color_name,
            value: colorObj.color_id,
          });
        });
        setProductColorDD(productColorDD);
        // console.log("productColorDD", productColorDD);
      }

      response.status === 202 &&
        showToast("No product color config found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getProductColorList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const isEdit =
    Object.keys(editProductVariantDetails).length > 0 ? true : false;

  const getProductWithVariantDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_VARIANT_DETAILS_CONFIG_URL);
      // console.log("Product with variant details List", { response });
      response.status === 200 &&
        setProductWithVariantDetailsList(
          response.data.productsWithVariantDetailsList
        );
      response.status === 202 &&
        showToast("No product variants found in the system.", "error");
    } catch (error) {
      console.error("getProductWithVariantDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithVariantDetailsList();
      getFabricTypeList();
      getSizeList();
      getProductColorList();
      setEditProductVariantDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      {showAddForm ? (
        <div className="flex flex-col mb-10">
          <div className="flex gap-2 items-center pb-5">
            <div className="">
              {isAdd ? (
                <MdAddCircleOutline size={18} color="black" />
              ) : (
                <RxUpdate size={17} color="black" />
              )}
            </div>
            <div className="text-slate-700 font-semibold">
              {isAdd ? (
                <>Add Product Variant Details</>
              ) : (
                <>Update Product Variant Details</>
              )}
            </div>
          </div>

          <AddProductVariantForm
            getProductWithVariantDetailsList={getProductWithVariantDetailsList}
            editProductVariantDetails={editProductVariantDetails}
            setEditProductVariantDetails={setEditProductVariantDetails}
            addProductVariantDetails={addProductVariantDetails}
            setAddProductVariantDetails={setAddProductVariantDetails}
            setShowAddForm={setShowAddForm}
            setIsAdd={setIsAdd}
            fabricTypeDD={fabricTypeDD}
            sizeDD={sizeDD}
            productColorDD={productColorDD}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1">
          <ProductVariantList
            productWithVariantDetailsList={productWithVariantDetailsList}
            getProductWithVariantDetailsList={getProductWithVariantDetailsList}
            setEditProductVariantDetails={setEditProductVariantDetails}
            setAddProductVariantDetails={setAddProductVariantDetails}
            setShowAddForm={setShowAddForm}
            setIsAdd={setIsAdd}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default ProductVariantConfig;
