import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { COLOR_CONFIG_URL } from "../../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import Dashboard from "../../../../common/dashboard-components/dashboard.component";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";

import AddColorForm from "./addColorForm.component";
import ColorList from "./colorList.component";

const ColorConfig = () => {
  // const { auth } = useAuth();
  const [colorList, setColorList] = useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editColorDetails, setEditColorDetails] = useState({});
  const isEdit = Object.keys(editColorDetails).length > 0 ? true : false;

  const getColorList = async () => {
    try {
      const response = await axios.get(COLOR_CONFIG_URL);
      // console.log("Color List", { response });
      response.status === 200 && setColorList(response.data.colorList);
      response.status === 202 &&
        showToast("No color entries found in the system.", "error");
    } catch (error) {
      console.error("getColorList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getColorList();
      setEditColorDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Color"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Color Details" : "Add Color Details"}`}
        isAdd={true}
        clearData={setEditColorDetails}
      >
        <AddColorForm
          getColorList={getColorList}
          editColorDetails={editColorDetails}
          setEditColorDetails={setEditColorDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <ColorList
          colorList={colorList}
          getColorList={getColorList}
          setEditColorDetails={setEditColorDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default ColorConfig;
