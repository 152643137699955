import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { NavLink, Link, useNavigate } from "react-router-dom";

import rsa from "../../../../utilities/rsaFunction/rsaEncryption";

import { setAuth } from "../../../../features/authProvider/authProviderSlice";

import axios from "../../../../api/axios";
import {
  PASSWORD_CHANGE_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../../api/api_routing_urls";

import ProfileDetails from "../profile_details.component";
import Input2 from "../../../../reusable-components/inputs/InputTextBox/Input2";
import AnimatedLoader from "../../../../reusable-components/loader/animated_loader.component";
import {
  reEmail,
  reContact,
} from "../../../../utilities/constants/regularexpressions";

const { rsaEncrypt } = rsa;

const LoginRegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [verifiedEmailAddress, setVerifiedEmailAddress] = useState("");
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState("");

  const { auth } = useSelector((state) => state.authProvider);

  const [showVerifyOTPForm, setShowVerifyOTPForm] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const [userNameType, setUserNameType] = useState("");
  const [verifiedUserName, setVerifiedUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const [rsaPublicKey, setRsaPublicKey] = useState();
  const [sessionUuid, setSessionUuid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState(false);

  const getPublicKeyRsa = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(RSA_KEY_URL);
      if (response?.data?.status === 201) {
        setRsaPublicKey(response?.data?.publicKey);
        setSessionUuid(response?.data?.session_uuid);
        // console.log(response?.data);
      } else {
        setRsaPublicKey("");
        setSessionUuid("");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // console.log("auth", auth);

      if (auth?.username) {
        if (reEmail.test(auth?.username)) {
          setVerifiedEmailAddress(auth?.username);
        }

        if (reContact.test(auth?.phone_number)) {
          setVerifiedPhoneNumber(auth?.phone_number);
        }
      }

      // getPublicKeyRsa();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: null,
  });

  const onContinueChangePassword = async (usernametype) => {
    try {
      let responseOTP = undefined;
      let verified_user_name = undefined;
      let user_name_type = undefined;

      if (usernametype === "email") {
        user_name_type = "email";
      } else if (usernametype === "phone") {
        user_name_type = "phone";
      } else {
        setUserNameType("NA");
        return;
      }
      setUserNameType(user_name_type);

      if (user_name_type === "email") {
        verified_user_name = verifiedEmailAddress;
      }

      if (user_name_type === "phone") {
        verified_user_name = verifiedPhoneNumber;
      }
      setVerifiedUserName(verified_user_name);
      getPublicKeyRsa();

      // request for otp
      responseOTP = await axios.post(
        `${PASSWORD_CHANGE_CONFIG_URL}/request-otp`,
        { user_name: verified_user_name, username_type: user_name_type },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (responseOTP.status === 200) {
        setShowVerifyOTPForm(true);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const onContinueVerifyOTP = async (data) => {
    try {
      setIsLoading(true);
      let response = undefined;

      const encOTP = rsaEncrypt(data?.onetimepassword, rsaPublicKey);

      // console.log(encOTP);

      let sendDataObj = {
        user_name: verifiedUserName,
        session_uuid: sessionUuid,
        session_otp: encOTP,
        username_type: userNameType,
      };

      response = await axios.post(
        `${PASSWORD_CHANGE_CONFIG_URL}/verify-otp`,
        sendDataObj,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.userOtpVerification) {
        // console.log("user verified");
        setShowChangePasswordForm(true);
      } else {
        setErrorMessage({
          status: true,
          message: "Invalid OTP entered or OTP expired, please try again.",
        });
        // console.log("Invalid OTP entered or OTP expired, please try again.");
        setShowChangePasswordForm(false);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitChangePassword = async (data) => {
    try {
      setIsLoading(true);
      let response = undefined;

      if (data.new_password === data.confirm_password) {
        // console.log("change password");

        const newPassword = rsaEncrypt(data?.new_password, rsaPublicKey);

        let sendDataObj = {
          user_name: verifiedUserName,
          user_password: newPassword,
          session_uuid: sessionUuid,
          username_type: userNameType,
        };

        response = await axios.post(
          `${PASSWORD_CHANGE_CONFIG_URL}/change-password`,
          sendDataObj,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.data.changePassword) {
          setPasswordChangeStatus(true);
        } else {
          setPasswordChangeStatus(false);
        }
      } else {
        setErrorMessage({
          status: true,
          message:
            "Passwords entered in the fields does not match with each other.",
        });
      }
    } catch (error) {
      console.error("onSubmitChangePassword", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : (
        <section className="bg-white min-h-screen pt-14 lg:pt-24 font-tenorsans">
          {
            <>
              <div className="bg-white w-[80%] md:w-[60%] lg:w-[40%] mx-auto py-20">
                <div className="flex justify-center uppercase text-gray-500 text-sm lg:text-lg pb-5">
                  Change Password
                </div>

                {errorMessage.status === true && (
                  <div className="flex justify-center text-red-500 text-sm pb-3">
                    {errorMessage?.message}
                  </div>
                )}

                {passwordChangeStatus === true ? (
                  <>
                    <div className="text-slate-600 text-center mt-9 uppercase text-sm">
                      Your password has been changed successfully.
                    </div>

                    <div className="flex justify-center mt-12 lg:mt-20">
                      <Link to="/">
                        <div className="mt-10 bg-white cursor-pointer text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                          Continue Shopping
                        </div>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    {showChangePasswordForm === true ? (
                      <div className="mb-2">
                        <>
                          {userNameType !== "NA" ? (
                            <div className="border-[0.1px] p-2 text-center text-slate-600 bg-slate-50 text-sm my-3">
                              Remember to keep your password confidential and
                              avoid using easily guessable information like
                              birthdays or names.
                            </div>
                          ) : null}

                          <form onSubmit={handleSubmit(onSubmitChangePassword)}>
                            <div className="flex flex-col gap-y-2">
                              <Input2
                                defaultName="new_password"
                                register={register}
                                name="New password"
                                required={true}
                                pattern={null}
                                errors={errors}
                                placeholder="Enter the new password"
                                setError={setError}
                                clearError={clearErrors}
                                autoComplete="off"
                                type="password"
                                classes={`px-2 py-1 lg:px-3 lg:py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
                                onChangeInput={null}
                                defaultValue={null}
                                setValue={setValue}
                              />
                              <Input2
                                defaultName="confirm_password"
                                register={register}
                                name="Confirm password"
                                required={true}
                                pattern={null}
                                errors={errors}
                                placeholder="Re-enter the password"
                                setError={setError}
                                clearError={clearErrors}
                                autoComplete="off"
                                type="password"
                                classes={`px-2 py-1 lg:px-3 lg:py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
                                onChangeInput={() => setErrorMessage({})}
                                defaultValue={null}
                                setValue={setValue}
                              />
                            </div>
                            <div className="mt-4 flex flex-col flex-grow">
                              <button
                                onClick={() => handleSubmit}
                                className="bg-slate-900 text-xs border-[0.1px] border-black hover:bg-black text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </>
                      </div>
                    ) : (
                      <div className="mb-2">
                        {showVerifyOTPForm ? (
                          <>
                            {userNameType !== "NA" ? (
                              <div className="border-[0.1px] p-2 text-center text-slate-600 bg-slate-50 text-sm my-3">
                                We've sent an OTP to the {userNameType}
                                {userNameType === "email"
                                  ? " address "
                                  : " number "}
                                {verifiedUserName}. Please enter it below to
                                complete verification.
                              </div>
                            ) : null}
                            <form onSubmit={handleSubmit(onContinueVerifyOTP)}>
                              <div className="flex flex-col gap-y-2">
                                <Input2
                                  defaultName="onetimepassword"
                                  register={register}
                                  name="Enter OTP"
                                  required={false}
                                  pattern={null}
                                  errors={errors}
                                  placeholder="Enter the OTP"
                                  setError={setError}
                                  clearError={clearErrors}
                                  autoComplete="off"
                                  type="text"
                                  classes={`px-2 py-1 lg:px-3 lg:py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border focus:border-black`}
                                  onChangeInput={() => setErrorMessage({})}
                                  defaultValue={null}
                                  setValue={setValue}
                                />
                              </div>

                              <div className="mt-4 flex flex-col flex-grow">
                                <button
                                  onClick={() => handleSubmit}
                                  className="bg-slate-900 text-xs border-[0.1px] border-black hover:bg-black text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                                >
                                  Continue
                                </button>
                              </div>
                            </form>
                          </>
                        ) : (
                          <>
                            {verifiedEmailAddress && verifiedPhoneNumber && (
                              <div>
                                <div className="flex justify-center text-slate-500 text-center text-sm pb-3">
                                  We will send an OTP to proceed with change
                                  password process. Kindly choose where you
                                  would like to receive the OTP.
                                </div>

                                <div className="mt-4 flex flex-col">
                                  <button
                                    onClick={() =>
                                      onContinueChangePassword("phone")
                                    }
                                    className="bg-slate-900 text-xs border-[0.1px] border-black hover:bg-black text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                                  >
                                    Send OTP to {verifiedPhoneNumber}
                                  </button>

                                  <div className="flex justify-center text-slate-500 text-center text-sm py-3 uppercase">
                                    Or
                                  </div>

                                  <button
                                    onClick={() =>
                                      onContinueChangePassword("email")
                                    }
                                    className="bg-slate-900 text-xs border-[0.1px] border-black hover:bg-black text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                                  >
                                    Send OTP to{" "}
                                    <span className="lowercase">
                                      {verifiedEmailAddress}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}

                            {verifiedEmailAddress &&
                              verifiedPhoneNumber === "" && (
                                <div>
                                  <div className="flex justify-center text-slate-500 text-center text-sm pb-3">
                                    We will send an OTP to proceed with change
                                    password process. Kindly click on the button
                                    to generate OTP.
                                  </div>

                                  <div className="mt-4 flex flex-col">
                                    <button
                                      onClick={() =>
                                        onContinueChangePassword("email")
                                      }
                                      className="bg-slate-900 text-xs border border-slate-900 hover:bg-black text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
                                    >
                                      Send OTP to{" "}
                                      <span className="lowercase">
                                        {verifiedEmailAddress}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              )}

                            {verifiedPhoneNumber &&
                              verifiedEmailAddress === "" && (
                                <div>
                                  <div className="flex justify-center text-slate-500 text-center text-sm pb-3">
                                    We will send an OTP to proceed with change
                                    password process. Kindly click on the button
                                    to generate OTP.
                                  </div>

                                  <div className="mt-4 flex flex-col">
                                    <button
                                      onClick={() =>
                                        onContinueChangePassword("phone")
                                      }
                                      className="bg-slate-900 text-xs border border-slate-900 hover:bg-black text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
                                    >
                                      Send OTP to {verifiedPhoneNumber}
                                    </button>
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          }
        </section>
      )}
    </>
  );
};

export default LoginRegisterForm;
