import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";

import { BsCalendarCheck } from "react-icons/bs";
import { RxReader } from "react-icons/rx";
import { BsCalendar3 } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { GiPriceTag } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import { LuLoader } from "react-icons/lu";
import { FaSignal } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { LuMoveRight } from "react-icons/lu";

import axios from "../../../api/axios";
import ViewOrderDetailsAdmin from "./viewOrderDetailsAdmin.component";

import { ORDER_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";

import FloatingInput from "../../../reusable-components/inputs/InputTextBox/FloatingInput";

import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import { PrintReport } from "../../../utilities/printPage/PrintReport";

const ManageOrdersConfig = ({ isDelivered }) => {
  const [orderDetailsAdminList, setOrderDetailsAdminList] = useState([]);

  const [searchOrderText, setSearchOrderText] = useState("");
  const [filteredOrderDetailsAdminList, setFilteredOrderDetailsAdminList] =
    useState([]);

  const [viewOrderDetailsPage, setViewOrderDetailsPage] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();

  const deliverycharge = 100;
  const [cartTotal, setCartTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  let curDate = new Date().toLocaleDateString("en-GB");

  // console.log("isDelivered value coming from App.js", isDelivered);

  const getOrderDetailsAdminList = async () => {
    try {
      const response = await axios.get(ORDER_DETAILS_ADMIN_CONFIG_URL, {
        params: {
          isdelivered: isDelivered,
        },
      });
      // console.log("Order List for Admin", { response });
      if (response.status === 200) {
        setOrderDetailsAdminList(response?.data?.orderDetailsAdminList); //Complete List
        setFilteredOrderDetailsAdminList(response?.data?.orderDetailsAdminList); //This is mapped in the table
      }

      response.status === 202 &&
        showToast("No orders found in the system.", "error");
    } catch (error) {
      console.error("getOrderDetailsAdminList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const hasSeenUpdateHandler = async (orderid) => {
    try {
      let sendDataObj = {
        order_id: orderid,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}`,
        sendDataObj
      );

      if (response.status === 200) {
        // console.log("Order Seen Status updated successfully!");
        getOrderDetailsAdminList();
      } else {
        console.log("Order Seen Status updation error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onViewClickHandler = (orderDetailsObj) => {
    setViewOrderDetailsPage(true);
    setSelectedOrder(orderDetailsObj);
    hasSeenUpdateHandler(orderDetailsObj?.order_id);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    // defaultValues: defaultValues,
  });

  const handleOrderNoInputChange = (value) => {
    // console.log("value", value);
    if (value === "" || value === undefined || value === null) {
      setFilteredOrderDetailsAdminList(orderDetailsAdminList);
      return;
    }
    setSearchOrderText(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        searchOrderText === "" ||
        searchOrderText === undefined ||
        searchOrderText === null
      ) {
        setFilteredOrderDetailsAdminList(orderDetailsAdminList);
        return;
      }

      onSubmitSearch();
    }
  };

  const onSubmitSearch = () => {
    // console.log("searchOrderText", searchOrderText);
    let filteredList = filteredOrderDetailsAdminList?.filter(
      (filterObj) => filterObj?.order_no === searchOrderText
    );

    setFilteredOrderDetailsAdminList(filteredList);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOrderDetailsAdminList();
    }
    return () => {
      isMounted = false;
    };
  }, [isDelivered]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setViewOrderDetailsPage(false);
      setSelectedOrder();
      setCartTotal(0);
      setOrderTotal(0);
      setSearchOrderText("");
    }
    return () => {
      isMounted = false;
    };
  }, [isDelivered]);
  return (
    <>
      <Dashboard>
        <ToastContainer />
        <div className="flex justify-between  pb-5">
          <div className="font-bold text-lg">Orders</div>
          <div className="flex gap-2 items-center justify-center">
            <BsCalendarCheck color="black" size={16} />
            <div className="font-semibold">{curDate}</div>
          </div>
        </div>

        {!viewOrderDetailsPage ? (
          <>
            {/* <div className="pt-5 grid grid-cols-3 gap-x-28">
              <div className="px-8 py-4 flex flex-col rounded-sm bg-[#ECF6FF]">
                <div className="text-sm text-gray-600 font-extrabold">
                  New Orders
                </div>
                <div className="flex items-center pt-2">
                  <div className="text-[#013C98] text-lg font-bold border-r-2 border-[#013C98] pr-4">
                    {orderDetailsAdminList?.length}
                  </div>
                  <div className="pl-4 text-xs font-bold text-gray-600">
                    Gratitude - Forever Grateful
                  </div>
                </div>
              </div>

              <div className="px-8 py-4 flex flex-col rounded-sm bg-[#F6F4FF]">
                <div className="text-sm text-gray-600 font-extrabold">
                  Shipped Orders
                </div>
                <div className="flex items-center pt-2">
                  <div className="text-[#5541BE] text-lg font-bold border-r-2 border-[#5541BE] pr-4">
                    0
                  </div>
                  <div className="pl-4 text-xs font-bold text-gray-600">
                    Gratitude - Forever Grateful
                  </div>
                </div>
              </div>

              <div className="px-8 py-4 flex flex-col rounded-sm bg-[#FFECE6]">
                <div className="text-sm text-gray-600 font-extrabold">
                  Delivered Orders
                </div>
                <div className="flex items-center pt-2">
                  <div className="text-[#F77E49] text-lg font-bold border-r-2 border-[#F77E49] pr-4">
                    0
                  </div>
                  <div className="pl-4 text-xs font-bold text-gray-600">
                    Gratitude - Forever Grateful
                  </div>
                </div>
              </div>
            </div> */}

            <div className="pt-5 grid grid-cols-3 gap-x-28">
              <div className="px-8 py-4 flex flex-col rounded-sm bg-red-50">
                <div className="text-sm text-gray-600 font-extrabold">
                  New Orders
                </div>
              </div>

              <div className="px-8 py-4 flex flex-col rounded-sm bg-yellow-50">
                <div className="text-sm text-gray-600 font-extrabold">
                  Shipped Orders
                </div>
              </div>

              <div className="px-8 py-4 flex flex-col rounded-sm bg-green-50">
                <div className="text-sm text-gray-600 font-extrabold">
                  Delivered Orders
                </div>
              </div>
            </div>

            <div class="mt-10 mb-5 relative flex items-center">
              {/* <div class="flex-grow border-t border-gray-400"></div> */}
              <span class="flex-shrink pr-3 text-sm lg:text-base text-gray-600 font-semibold uppercase">
                Search Order
              </span>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <div className="flex items-center mb-5">
              <div className="w-[30vw]">
                <FloatingInput
                  placeholderText="Enter the order number"
                  defaultName="order_no"
                  register={register}
                  name="ORDER NUMBER"
                  required={false}
                  pattern={null}
                  errors={errors}
                  placeholder=""
                  setError={setError}
                  clearError={clearErrors}
                  autoComplete="off"
                  type="text"
                  classes={`w-full`}
                  onChangeInput={handleOrderNoInputChange}
                  // defaultValue={defaultValues.order_no}
                  setValue={setValue}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="ml-5">
                <button
                  onClick={() => onSubmitSearch()}
                  className="text-gray-400 hover:text-gray-900 rounded-lg text-xl lg:text-2xl w-8 h-8"
                >
                  <LuMoveRight />
                </button>
              </div>
            </div>

            <div className="pt-10">
              <table className="w-full">
                <thead>
                  <tr className="bg-black text-sm">
                    <th className="py-5 px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <RxReader color="white" />
                        </div>
                        <div className="font-light text-white">Order No</div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <BsCalendar3 color="white" />
                        </div>
                        <div className="font-light text-white">Order Date</div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <AiOutlineUser color="white" />
                        </div>
                        <div className="font-light text-white">
                          Customer Name
                        </div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <GiPriceTag color="white" />
                        </div>
                        <div className="font-light text-white">
                          Order Amount
                        </div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <MdOutlinePayment color="white" />
                        </div>
                        <div className="font-light text-white">
                          Payment Mode
                        </div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <LuLoader color="white" />
                        </div>
                        <div className="font-light text-white">
                          Payment Status
                        </div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <FaSignal color="white" />
                        </div>
                        <div className="font-light text-white">
                          Order Status
                        </div>
                      </div>
                    </th>

                    <th className="px-5">
                      <div className="flex gap-1 items-center">
                        <div>
                          <AiOutlineEye color="white" />
                        </div>
                        <div className="font-light text-white">
                          View Order Details
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredOrderDetailsAdminList?.length > 0 ? (
                    <>
                      {filteredOrderDetailsAdminList?.map((orderObj, index) => (
                        <tr
                          key={index}
                          // onClick={() => hasSeenUpdateHandler(orderObj?.order_id)}
                          className={`border-b ${
                            orderObj?.order_status === "Initiated"
                              ? "bg-red-50"
                              : orderObj?.order_status === "Shipped"
                              ? "bg-yellow-50"
                              : orderObj?.order_status === "Delivered"
                              ? "bg-green-50"
                              : "bg-white"
                          }`}
                        >
                          <td className="py-5 px-5 text-sm font-bold">
                            {orderObj?.order_no}
                          </td>

                          <td className="px-5 text-sm">
                            {getFormattedDateDDMMMYYYYDate(
                              orderObj?.order_date
                            )}
                          </td>

                          <td className="px-5 text-sm">
                            {orderObj?.ordered_by.fname}{" "}
                            {orderObj?.ordered_by.lname}
                          </td>

                          <td className="px-5 text-sm text-left">
                            ₹ {orderObj?.order_total}
                          </td>

                          <td className="px-5 text-sm">
                            {orderObj?.payment_mode}
                          </td>

                          <td className="px-5 text-sm">
                            {orderObj?.payment_status}
                          </td>

                          <td className="px-5 text-sm">
                            {orderObj?.order_status}
                          </td>

                          <td
                            onClick={() => onViewClickHandler(orderObj)}
                            className="px-5 cursor-pointer text-sm text-black hover:underline text-center"
                          >
                            View
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr className="bg-slate-100">
                      <td colSpan={8} className="text-center py-2 text-sm">
                        No order details found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div>
              <ViewOrderDetailsAdmin
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                setViewOrderDetailsPage={setViewOrderDetailsPage}
                getOrderDetailsAdminList={getOrderDetailsAdminList}
                cartTotal={cartTotal}
                setCartTotal={setCartTotal}
                orderTotal={orderTotal}
                setOrderTotal={setOrderTotal}
                deliverycharge={deliverycharge}
              />
            </div>
          </>
        )}
      </Dashboard>
    </>
  );
};

export default ManageOrdersConfig;
