import React, { useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import gratitude_logo from "../../../assets/gratitude_black_logo-nobg.png";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

const NUM_CONFETTI = 350;
const COLORS = [
  [85, 71, 106],
  [174, 61, 99],
  [219, 56, 83],
  [244, 92, 68],
  [248, 182, 70],
];
const PI_2 = 2 * Math.PI;

const range = (a, b) => (b - a) * Math.random() + a;

const drawCircle = (x, y, r, style, context) => {
  context.beginPath();
  context.arc(x, y, r, 0, PI_2, false);
  context.fillStyle = style;
  context.fill();
};

const ConfettiComponent = () => {
  const navigate = useNavigate();

  const canvasRef = useRef(null);
  let w = 0;
  let h = 0;
  let xpos = 0.5;

  const Confetti = () => {
    const style = COLORS[~~range(0, 5)];
    const rgb = `rgba(${style[0]}, ${style[1]}, ${style[2]}`;
    const r = ~~range(2, 6);
    const r2 = 2 * r;

    let opacity = 0;
    let dop = 0.03 * range(1, 4);
    let x = range(-r2, w - r2);
    let y = range(-20, h - r2);
    let xmax = w - r;
    let ymax = h - r;
    let vx = range(0, 2) + 8 * xpos - 5;
    let vy = 0.7 * r + range(-1, 1);

    const replace = () => {
      opacity = 0;
      dop = 0.03 * range(1, 4);
      x = range(-r2, w - r2);
      y = range(-20, h - r2);
      xmax = w - r;
      ymax = h - r;
      vx = range(0, 2) + 8 * xpos - 5;
      vy = 0.7 * r + range(-1, 1);
    };

    const draw = (context) => {
      x += vx;
      y += vy;
      opacity += dop;
      if (opacity > 1) {
        opacity = 1;
        dop *= -1;
      }
      if (opacity < 0 || y > ymax) {
        replace();
      }
      if (!(0 < x < xmax)) {
        x = (x + xmax) % xmax;
      }
      drawCircle(~~x, ~~y, r, `${rgb},${opacity})`, context);
    };

    return { draw };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const resizeWindow = () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", resizeWindow, false);
    window.onload = () => setTimeout(resizeWindow, 0);

    document.onmousemove = (e) => {
      xpos = e.pageX / w;
    };

    const confettiArray = Array.from({ length: NUM_CONFETTI }, () =>
      Confetti()
    );

    const step = () => {
      window.requestAnimationFrame(step);
      context.clearRect(0, 0, w, h);
      confettiArray.forEach((c) => c.draw(context));
    };

    step();

    return () => {
      window.removeEventListener("resize", resizeWindow, false);
    };
  }, []);

  useEffect(() => {
    setInterval(() => {
      window.location.replace("https://gratitudefg.com/");
    }, 3000);
  }, []);

  return (
    <section className="font-tenorsans">
      <div className="relative flex flex-col items-center justify-center pt-20">
        <img src={gratitude_logo} />

        <div className="font-bold uppercase text-3xl tracking-widest animate-pulse">
          Welcome aboard!!!
        </div>

        <div className="pt-24">
          <p className="font-semibold text-xs">Redirecting to website.....</p>
          <div>
            <AnimatedLoader />
          </div>
        </div>
      </div>
      <canvas ref={canvasRef} className="absolute top-0" />
    </section>
  );
};

export default ConfettiComponent;
