import React, { useState } from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

import { WEAR_TYPE_CONFIG_URL } from "../../../../api/api_routing_urls";
import axios from "../../../../api/axios";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../../theme/lightTheme";

export default function WearTypeList({
  wearTypeList,
  getWearTypeList,
  setEditWearTypeDetails,
  setShowAddForm,
}) {
  const onClickEdit = (wearType) => {
    setEditWearTypeDetails(wearType);
    setShowAddForm(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [wearTypeDeleteId, setWearTypeDeleteId] = useState(null);

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", wearTypeDeleteId);
    try {
      let response = "";
      if (wearTypeDeleteId) {
        response = await axios.post(`${WEAR_TYPE_CONFIG_URL}/delete`, {
          wear_type_id: wearTypeDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        showToast(
          "Wear Type details has been deleted successfully.",
          "success"
        );
        getWearTypeList();
      } else {
        showToast("Wear Type details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setWearTypeDeleteId(null);
    }
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            Wear Type Name
          </th>
          <th
            scope="col"
            className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
        {wearTypeList?.length > 0 &&
          wearTypeList?.map((wearType, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {wearType.wear_type_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(wearType)}
                    aria-label="Edit Wear Type Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      setWearTypeDeleteId(wearType?.wear_type_id || null);
                      setShowDelete(true);
                    }}
                    aria-label="Delete Wear Type Details"
                    className={linkTextDanger}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This entry will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </TableWrapper>
  );
}
