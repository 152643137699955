/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./verticalScrollImageGallery.styles.css";

const VerticalScrollImageGallery = ({ imagesArr }) => {
  const [slideCount, setSlideCount] = useState(0);
  const [slideHeight, setSlideHeight] = useState(0);

  useEffect(() => {
    setSlideCount(imagesArr.length);
    const slide = document.querySelector(".slides div");
    if (slide) {
      const slideRect = slide.getBoundingClientRect();
      setSlideHeight(slideRect.height);
    }
  }, [imagesArr]);

  const scrollThumb = () => {
    const slideGallery = document.querySelector(".slides");
    const scrollbarThumb = document.querySelector(".thumb");
    if (slideGallery && scrollbarThumb) {
      const index = Math.floor(slideGallery.scrollTop / slideHeight);
      scrollbarThumb.style.height = `${
        ((index + 1) / slideCount) * slideHeight
      }px`;
    }
  };

  const scrollToElement = (index) => {
    const slideGallery = document.querySelector(".slides");
    if (slideGallery) {
      slideGallery.scrollTo(0, index * slideHeight);
    }
  };

  return (
    <div className="gallery-container">
      {/* <div className="thumbnails">
        {imagesArr.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            onClick={() => scrollToElement(index)}
          />
        ))}
      </div> */}

      {/* <div className="scrollbar">
        <div className="thumb"></div>
      </div> */}

      <div className="slides" onScroll={scrollThumb}>
        {imagesArr.map((imageObj, index) => (
          <div key={index}>
            <img src={imageObj} alt={`Image ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalScrollImageGallery;
