import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsCartDrawerOpen } from "./cartPage/cartPageSlice";

export const useCloseCartOnRouteChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Close the cart drawer on route change
    dispatch(setIsCartDrawerOpen(false));
  }, [location, dispatch]);
};
