import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { SIZE_CONFIG_URL } from "../../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import Dashboard from "../../../../common/dashboard-components/dashboard.component";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";

import AddSizeForm from "./addSizeForm.component";
import SizeList from "./sizeList.component";

const SizeConfig = () => {
  // const { auth } = useAuth();
  const [sizeList, setSizeList] = useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editSizeDetails, setEditSizeDetails] = useState({});
  const isEdit = Object.keys(editSizeDetails).length > 0 ? true : false;

  const getSizeList = async () => {
    try {
      const response = await axios.get(SIZE_CONFIG_URL);
      // console.log("Size List", { response });
      response.status === 200 && setSizeList(response.data.sizeList);
      response.status === 202 &&
        showToast("No size entries found in the system.", "error");
    } catch (error) {
      console.error("getSizeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getSizeList();
      setEditSizeDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Size"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Size Details" : "Add Size Details"}`}
        isAdd={true}
        clearData={setEditSizeDetails}
      >
        <AddSizeForm
          getSizeList={getSizeList}
          editSizeDetails={editSizeDetails}
          setEditSizeDetails={setEditSizeDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <SizeList
          sizeList={sizeList}
          getSizeList={getSizeList}
          setEditSizeDetails={setEditSizeDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default SizeConfig;
