import React from "react";
import { LEGAL_NOTICE_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import useAuth from "../../../../authentication/hooks/useAuth";
import { useForm } from "react-hook-form";
import { button, cancel_button } from "../../../../theme/lightTheme";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import RichTextArea from "../../../../reusable-components/richtexteditor/RichTextArea";

const AddLegalNotice = ({
  getLegalNoticeDetails,
  legalNotice,
  setLegalNotice,
  setShowAddForm,
}) => {
  // const axiosPrivate = useAxiosPrivate();

  // const { auth } = useAuth();

  const isEdit =
    legalNotice !== undefined ? Object.keys(legalNotice).length > 0 : false;

  const defaultValues = {
    legal_notice_content: isEdit ? legalNotice.legal_notice_content : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  const emptyObj = {};
  const setDefaultValueEmpty = () => {
    setShowAddForm(false);
  };

  const onSubmit = async (data) => {
    // var safeHTMLString = html`${data.legal_notice_content}`;

    try {
      let response = {};
      if (isEdit) {
        data.legal_notice_id = legalNotice.legal_notice_id;
        // data.updatedby = auth.userid;
        data.updatedby = "admin";
        // data.aboutuscontent = safeHTMLString.__html;
        response = await axios.post(`${LEGAL_NOTICE_CONFIG_URL}/update`, data);
      } else {
        // data.createdby = auth.userid;
        data.createdby = "admin";
        // data.aboutuscontent = safeHTMLString.__html;
        response = await axios.post(LEGAL_NOTICE_CONFIG_URL, data);
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast("Legal Notice Details Successfully Updated", "success");
        } else {
          showToast("Legal Notice Details Successfully Added", "success");
        }
        getLegalNoticeDetails();
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setDefaultValueEmpty();
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <ContainerShadow>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RichTextArea
          defaultName={"legal_notice_content"}
          register={register}
          name={"Legal Notice Description"}
          classes={"w-full"}
          clearError={clearErrors}
          setError={setError}
          setValue={setValue}
          data={defaultValues.legal_notice_content}
          errors={errors}
          pattern={null}
          required={true}
        />
        <div className="flex items-center justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={`${isEdit ? "Update" : "Submit"}`}
            className={button}
          />
          <Button className={cancel_button} onClick={setDefaultValueEmpty}>
            Cancel
          </Button>
        </div>
      </form>
    </ContainerShadow>
  );
};

export default AddLegalNotice;
