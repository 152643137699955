/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { CiSearch } from "react-icons/ci";
import {
  PiBagSimpleThin,
  PiHandbagSimpleLight,
  PiHandbagSimpleFill,
} from "react-icons/pi";

import { setIsSearchDrawerOpen } from "../../../features/searchBar/searchBarSlice";
import { setIsCartDrawerOpen } from "../../../features/cartPage/cartPageSlice";

import "./publicNavbar.styles.css";

// import nav_header_logo from "../../../assets/latest_logo/GratitudeLogoMontserrat_nobg2.png";
import nav_header_logo from "../../../assets/gfglogo-nobg.png";

import DrawerModal from "../../../reusable-components/modals/drawerModal.component";
import DrawerModalCart from "../../../reusable-components/modals/drawerModalCart.component";
import SearchBar from "../../../area/public/Pages/searchbar.component";
import Cart from "../../../area/public/Pages/cart_v2.component";
import AnimatedHamburger from "./AnimatedHamburger/animatedHamburger.component";

import MobileNavAccordion from "../../../reusable-components/accordion/MobileNavAccordion";

const Navbar = () => {
  const dispatch = useDispatch();

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  const isSearchDrawerOpen = useSelector(
    (state) => state.searchBar.isSearchDrawerOpen
  );
  const [searchTerm, setSearchTerm] = useState("");

  const isCartDrawerOpen = useSelector(
    (state) => state.cartPage.isCartDrawerOpen
  );

  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSearchDrawer = () => {
    dispatch(setIsSearchDrawerOpen(!isSearchDrawerOpen));
  };

  const toggleCartDrawer = () => {
    dispatch(setIsCartDrawerOpen(!isCartDrawerOpen));
  };

  const [navLinkData, setNavLinkData] = useState([
    {
      navlink: "Account",
      hasSubMenu: false,
      subMenu: [],
      url: "/user-signing",
    },

    // {
    //   navlink: "Cart",
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/view-cart",
    // },
  ]);

  const [navLinkGeneralData, setNavLinkGeneralData] = useState([
    {
      navlink: "About",
      hasSubMenu: false,
      subMenu: [],
      url: "/about-gratitude",
    },

    {
      navlink: "Legal Notice",
      hasSubMenu: false,
      subMenu: [],
      url: "/legal-notice",
    },

    {
      navlink: "Policies",
      hasSubMenu: true,
      subMenu: [
        { title: "Return And Refund Policy", url: "/return-refund-policy" },
        { title: "Shipping Policy", url: "/shipping-policy" },
      ],
      url: "#",
    },
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position >= 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="font-tenorsans">
      {/* ---------- Desktop Navbar start ---------- */}
      <div className="hidden lg:block">
        <nav
          className={`w-full border-b-[0.1px] border-gray-400 px-16 fixed z-30 nav-menu ${
            isScrolled
              ? "h-[35px] custom-navbar bg-white bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 transition-all ease-in-out duration-500"
              : "h-[54px]"
          } bg-transparent hover:bg-clip-padding hover:backdrop-filter hover:backdrop-blur-sm hover:bg-opacity-30 hover:transition-all hover:ease-in-out hover:duration-500`}
        >
          <div className="grid grid-cols-3 items-center">
            <div className="container-fluid flex justify-start">
              <ul
                className={`flex flex-col lg:flex-row items-center list-none`}
              >
                {navLinkGeneralData.map((item, index) => (
                  <NavLinks
                    item={item}
                    index={index}
                    key={index}
                    isScrolled={isScrolled}
                  />
                ))}
              </ul>
            </div>

            <div className="container-fluid flex justify-center">
              <Link to="/">
                <img
                  src={nav_header_logo}
                  alt="GRATITUDE STORE"
                  className={`object-cover h-8 ${
                    isScrolled
                      ? "pb-1 scale-110 transition-all ease-in-out duration-500"
                      : "pb-0 scale-125 transition-all ease-in-out duration-500"
                  }`}
                />
              </Link>
            </div>

            <div className="container-fluid flex justify-end">
              <div
                className={`px-2 ${
                  !isScrolled ? "pt-[17px]" : "pt-[9px]"
                }  text-xs uppercase font-light leading-snug cursor-pointer transition-all ease-in-out duration-500`}
                onClick={() => toggleSearchDrawer()}
              >
                Search
              </div>

              <ul className="flex flex-col lg:flex-row items-center list-none">
                {navLinkData.map((item, index) => (
                  <NavLinks
                    item={item}
                    index={index}
                    key={index}
                    isScrolled={isScrolled}
                  />
                ))}
              </ul>

              <div
                className={`px-2 group flex justify-center items-center cursor-pointer transition-all ease-in-out duration-500 ${
                  !isScrolled ? "pt-[5px]" : "pt-[0px]"
                }`}
                onClick={() => toggleCartDrawer()}
              >
                <PiHandbagSimpleLight
                  size={16}
                  className="group-hover:hidden"
                />
                <PiHandbagSimpleFill
                  size={16}
                  className="hidden group-hover:block"
                />

                {cartQuantity > 0 ? (
                  <span className="pl-1">{cartQuantity}</span>
                ) : null}
              </div>
            </div>
          </div>
        </nav>
      </div>

      {isSearchDrawerOpen && (
        <div>
          <DrawerModal
            isOpen={isSearchDrawerOpen}
            toggleDrawer={toggleSearchDrawer}
          >
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </DrawerModal>
        </div>
      )}

      {isCartDrawerOpen && (
        <div>
          <DrawerModalCart
            isOpen={isCartDrawerOpen}
            toggleDrawer={toggleCartDrawer}
          >
            <Cart />
          </DrawerModalCart>
        </div>
      )}
      {/* ---------- Desktop Navbar end ---------- */}

      {/* ---------- Mobile Navbar start ---------- */}

      <div className="relative block lg:hidden">
        <MobileNavbar />
      </div>
      {/* ---------- Mobile Navbar end ---------- */}
    </section>
  );
};

export default Navbar;

const NavLinks = ({ item, index, isScrolled }) => {
  const location = useLocation();

  const [showUnderline, setShowUnderline] = useState(false);

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  const checkActive = () => {
    if (location.pathname === item.url) {
      return true;
    } else return false;
  };

  return (
    <section className="font-tenorsans">
      <li
        className={`${
          isScrolled ? "pt-0" : "pt-2"
        } transition-all ease-in-out duration-500 nav-item ${
          item.hasSubMenu ? "group inline-block relative" : "relative"
        }`}
        key={index}
        onClick={() => window.scrollTo(0, 0)}
      >
        {item.url.includes("http") ? (
          <a
            className="px-1 py-2 flex items-center text-xs md:text-xxs lg:text-xs uppercase font-light leading-snug text-black hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none focus:bg-ternary active:bg-ternary"
            href={item.url}
            target="_blank"
          >
            <span className="text-xs md:text-xxs lg:text-xs">
              {item.navlink}
            </span>
          </a>
        ) : (
          <Link to={`${item.url}`}>
            <div
              className={`py-2 flex items-center text-xs md:text-xxs lg:text-xs uppercase font-light leading-snug bg-transparent text-decoration-none ${checkActive()}`}
            >
              <div className="text-xs mx-2 md:text-xxs lg:text-xs  flex items-center justify-normal">
                <div
                  onMouseEnter={() => setShowUnderline(true)}
                  onMouseLeave={() => setShowUnderline(false)}
                >
                  {item.navlink !== "Cart" ? (
                    <>{item.navlink}</>
                  ) : (
                    <div className="group flex">
                      <PiHandbagSimpleLight
                        size={16}
                        className="group-hover:hidden"
                      />
                      <PiHandbagSimpleFill
                        size={16}
                        className="hidden group-hover:block"
                      />

                      {item.navlink === "Cart" && cartQuantity > 0 ? (
                        <div className="pl-1">
                          (<span className="">{cartQuantity}</span>)
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              {checkActive() === false ? (
                <span
                  style={{
                    transform: showUnderline ? "scaleX(1)" : "scaleX(0)",
                  }}
                  className="mx-2 absolute bottom-2 left-0 right-0 h-[1px] origin-left scale-x-0 rounded-full bg-gray-800 transition-transform duration-300 ease-out"
                />
              ) : (
                <span
                  style={{
                    transform: showUnderline ? "scaleX(1)" : "scaleX(1)",
                  }}
                  className="mx-2 absolute bottom-2 left-0 right-0 h-[1px] origin-left scale-x-0 rounded-full bg-gray-800 transition-transform duration-300 ease-out"
                />
              )}
            </div>
          </Link>
        )}

        {item.hasSubMenu && (
          <SubMenu key={index} items={item.subMenu} isScrolled={isScrolled} />
        )}
      </li>
    </section>
  );
};

const SubMenu = ({ items, isScrolled }) => {
  const location = useLocation();

  return (
    <ul
      className={` ${
        isScrolled ? "pt-[0px]" : "pt-[6px]"
      } font-tenorsans transition-all duration-200 ease-in transform origin-center -translate-y-3 group-hover:translate-y-0 group-hover:translate-x-0 absolute invisible opacity-0 group-hover:opacity-100 group-hover:visible max-w-max w-screen text-left shadow-xl rounded-md`}
    >
      {items &&
        items.map((item, index) => (
          <li className="" key={index}>
            {item.url.includes("http") ? (
              <a
                className="bg-white hover:bg-black py-2 px-4 block whitespace-no-wrap text-sm text-black hover:text-white"
                href={item.url}
                target="_blank"
              >
                {item.title}
              </a>
            ) : (
              <Link to={`${item.url}`}>
                <div
                  className={`bg-white hover:bg-black py-2 px-4 block whitespace-no-wrap text-xs uppercase  ${
                    location.pathname === item.url
                      ? "text-ternary hover:text-white"
                      : "text-black hover:text-white"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {item.title}
                </div>
              </Link>
            )}
          </li>
        ))}
    </ul>
  );
};

// Mobile Navbar

const MobileNavbar = () => {
  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  const [showMenu, setShowMenu] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isScreenScrolled, setIsScreenScrolled] = useState(false);

  const navAccordions = [
    {
      id: 1,
      title: "Company",
      content: (
        <>
          <ul className="text-xs pl-4 text-black">
            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="/about-gratitude">About</NavLink>
            </li>

            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="#">Career</NavLink>
            </li>

            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="#">Contact</NavLink>
            </li>
          </ul>
        </>
      ),
    },

    {
      id: 2,
      title: "Support",
      content: (
        <>
          <ul className="text-xs pl-4 text-black">
            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="/shipping-policy">Shipping</NavLink>
            </li>

            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="/return-refund-policy">Return & Refund</NavLink>
            </li>

            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="#">FAQs</NavLink>
            </li>
          </ul>
        </>
      ),
    },

    {
      id: 3,
      title: "Legal",
      content: (
        <>
          <ul className="text-xs pl-4 text-black">
            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <NavLink to="/legal-notice">Privacy Policy</NavLink>
            </li>
          </ul>
        </>
      ),
    },

    {
      id: 4,
      title: "Social",
      content: (
        <>
          <ul className="text-xs pl-4 text-black">
            <li className="my-1 text-[11px] uppercase cursor-pointer">
              <a
                href="https://www.instagram.com/gratitude.fg?igsh=MTN3cTk4eHE3amZxNw=="
                target="_blank"
              >
                Instagram
              </a>
            </li>
          </ul>
        </>
      ),
    },

    // Add more accordion items as needed
  ];

  const handleAccordionClick = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };

  const itemVariants = {
    closed: {
      opacity: 0,
      transition: { duration: 0 },
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
  };

  const onMenuClickHandler = () => {
    setShowMenu(!showMenu);
  };

  const dispatch = useDispatch();

  const isSearchDrawerOpen = useSelector(
    (state) => state.searchBar.isSearchDrawerOpen
  );

  const isCartDrawerOpen = useSelector(
    (state) => state.cartPage.isCartDrawerOpen
  );

  const toggleSearchDrawer = () => {
    dispatch(setIsSearchDrawerOpen(!isSearchDrawerOpen));
  };

  const toggleCartDrawer = () => {
    dispatch(setIsCartDrawerOpen(!isCartDrawerOpen));
  };

  //Scroll checker
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position >= 15) {
        setIsScreenScrolled(true);
      } else {
        setIsScreenScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle body overflow
  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showMenu]);

  return (
    <section className={`fixed z-50 w-[100%]`}>
      <div
        className={`py-3 -mb-1 px-3 grid grid-cols-3 items-center justify-center ${
          showMenu &&
          "bg-transparent bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10"
        } ${isScreenScrolled && "bg-white"}`}
      >
        <div className="col-span-1 flex justify-start">
          <div className="" onClick={() => onMenuClickHandler()}>
            <AnimatedHamburger />
          </div>
        </div>

        <div className="col-span-1 flex justify-center items-center">
          <Link to="/">
            <img
              src={nav_header_logo}
              alt="GRATITUDE STORE"
              className="h-6 md:h-8"
            />
          </Link>
        </div>

        <div className="col-span-1 flex justify-end items-center gap-x-3">
          <div onClick={() => toggleSearchDrawer()}>
            <CiSearch />
          </div>

          {/* <Link to="/view-cart"> */}
          <div onClick={() => toggleCartDrawer()} className="flex items-center">
            <PiBagSimpleThin />

            {cartQuantity > 0 ? <span className="">{cartQuantity}</span> : null}
          </div>
          {/* </Link> */}
        </div>
      </div>

      <AnimatePresence>
        {showMenu && (
          <motion.div
            className={`mt-1 shadow-xl fixed left-0 z-20 ${
              showMenu &&
              "bg-transparent bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10"
              // "bg-white bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30"
            }`}
            initial={{ width: 0, height: "100vh" }}
            animate={{
              width: "100%",
              height: "100%",
              transition: { duration: 0.5 },
            }}
            exit={{
              width: 0,
              height: "screen",
              transition: { duration: 0.5 },
            }}
          >
            <motion.div
              className="grid grid-cols-1 text-sm"
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              <NavLink to="/user-signing">
                <motion.p
                  variants={itemVariants}
                  className="font-medium uppercase text-black mt-5 mb-2 ml-5"
                  onClick={() => {
                    setShowMenu(false);
                  }}
                >
                  Account
                </motion.p>
              </NavLink>

              {/* Accordion section  start */}
              <>
                {navAccordions?.map((accordion, index) => (
                  <motion.div
                    variants={itemVariants}
                    key={index}
                    className="noSelect ml-5"
                  >
                    <MobileNavAccordion
                      key={accordion.id}
                      title={accordion.title}
                      content={accordion.content}
                      isOpen={accordion.id === activeAccordion}
                      onAccordionClick={() =>
                        handleAccordionClick(accordion.id)
                      }
                    />
                  </motion.div>
                ))}
              </>
              {/* Accordion section  end */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

const DemoDrawerTest = ({ isOpen, toggleDrawer, children }) => {
  const itemVariants = {
    closed: {
      opacity: 0,
      transition: { duration: 0 },
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      width: 0,
      transition: { duration: 0.5 },
    },
    open: {
      width: "50%",
      transition: { duration: 0.5 },
    },
  };

  return (
    <section>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed top-0 right-0 z-40 bg-white min-h-screen"
            initial="closed"
            animate="open"
            exit="closed"
            variants={sideVariants}
          >
            <motion.div
              className="p-5" // Adjust padding as needed
              initial="closed"
              animate="open"
              exit="closed"
              variants={{
                closed: { opacity: 0 },
                open: { opacity: 1 },
              }}
            >
              <motion.div
                className="grid grid-cols-1 text-sm"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <p className="mt-40" onClick={() => toggleDrawer()}>
                  Close
                </p>
              </motion.div>

              <motion.div
                className="grid grid-cols-1 text-sm"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <p className="mt-5">Test Drawer</p>
              </motion.div>

              <motion.div
                className="grid grid-cols-1 text-sm"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <p className="mt-5">Test Drawer</p>
              </motion.div>

              <motion.div
                className="grid grid-cols-1 text-sm"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <p className="mt-5">Test Drawer</p>
              </motion.div>

              <motion.div
                className="grid grid-cols-1 text-sm"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <p className="mt-5">Test Drawer</p>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};
