import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
// import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";

import { store } from "./app/store";
import { Provider } from "react-redux";

const GlobalStyle = createGlobalStyle`
    html, body {
        background-color: white;
        overscroll-behavior: contain;
    }

    #root {
        background-color: white;
        overscroll-behavior: contain;
    }

    :root {
        background-color: white;
        overscroll-behavior: contain;
    }
`;

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <Provider store={store}>
          <GlobalStyle />
          <App />
        </Provider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
