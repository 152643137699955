import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  PRODUCT_GENERIC_DETAILS_CONFIG_URL,
  PRODUCT_VARIANT_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../api/axios";
import AddButton_v2 from "../../../reusable-components/buttons/AddButton_v2";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";
import { NonEmptyString } from "../../../utilities/constants/regularexpressions";

const AddProductVariantForm = ({
  getProductWithVariantDetailsList,
  editProductVariantDetails,
  setEditProductVariantDetails,
  addProductVariantDetails,
  setAddProductVariantDetails,
  setShowAddForm,
  setIsAdd,
  fabricTypeDD,
  sizeDD,
  productColorDD,
}) => {
  // const axiosPrivate = useAxiosPrivate();
  // const { auth } = useAuth();

  const [selectedFabricType, setSelectedFabricType] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedProductColor, setSelectedProductColor] = useState(null);

  let isEdit =
    editProductVariantDetails === undefined ||
    editProductVariantDetails === null
      ? false
      : Object?.keys(editProductVariantDetails)?.length > 0
      ? true
      : false;

  // console.log("editProductVariantDetails", editProductVariantDetails);
  // console.log("addProductVariantDetails", addProductVariantDetails);

  const defaultValues = {
    product_name: !isEdit ? "" : editProductVariantDetails?.product_name,
    product_description: !isEdit
      ? ""
      : editProductVariantDetails?.product_description,
    fabric_type_id: !isEdit ? "" : selectedFabricType,
    size_id: !isEdit ? "" : selectedSize,
    color_id: !isEdit ? "" : selectedProductColor,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    // console.log("Data sent after button click: ", data);
    try {
      // console.log("Inside try block: ");
      let fabricArr = [];
      data.fabric_type_id.map((mapObj) => {
        fabricArr.push(+mapObj.value);
      });

      let sizeArr = [];
      data.size_id.map((mapObj) => {
        sizeArr.push(+mapObj.value);
      });

      let colorArr = [];
      data.color_id.map((mapObj) => {
        colorArr.push(+mapObj.value);
      });

      let sendDataObj = {
        fabric_type_id: JSON.stringify(data.fabric_type_id),
        size_id: JSON.stringify(data.size_id),
        color_id: JSON.stringify(data.color_id),
      };

      let response = "";
      if (!isEdit) {
        // console.log("Inside ADD function");
        // data.createdby = auth.userid;
        sendDataObj.product_id = addProductVariantDetails.product_id;
        sendDataObj.createdby = "admin";

        // console.log({ sendDataObj });

        response = await axios.post(
          PRODUCT_VARIANT_DETAILS_CONFIG_URL,
          sendDataObj
        );
      } else {
        // console.log("Inside UPDATE function");
        // data.updatedby = auth.userid;
        sendDataObj.product_id = editProductVariantDetails.product_id;
        sendDataObj.updatedby = "admin";
        sendDataObj.fabricArr = JSON.stringify(fabricArr);
        sendDataObj.sizeArr = JSON.stringify(sizeArr);
        sendDataObj.colorArr = JSON.stringify(colorArr);

        // console.log({ sendDataObj });

        response = await axios.post(
          `${PRODUCT_VARIANT_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditProductVariantDetails({});
          showToast(
            "Product Variant details has been updated successfully.",
            "success"
          );
        } else {
          setAddProductVariantDetails({});
          showToast(
            "Product Variant details has been added successfully.",
            "success"
          );
        }
        getProductWithVariantDetailsList();
      } else {
        if (isEdit) {
          console.log("Save Product Variant Details Error:", response.data);
        } else {
          if (
            response.data.saveProductVariantDetails.includes("unique_product")
          ) {
            showToast(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  //UseEffect for pre-populating Drop-down Values
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let fabricType = [];
      editProductVariantDetails?.fabric_details?.map((mapObj) =>
        fabricType.push({
          label: mapObj?.fabric_type_name,
          value: `${mapObj?.fabric_type_id}`,
        })
      );
      // console.log({ fabricType });
      setSelectedFabricType(fabricType);
      // console.log("fabricType", fabricType);

      let size = [];
      editProductVariantDetails?.size_details?.map((mapObj) =>
        size.push({
          label: mapObj?.size_name,
          value: `${mapObj?.size_id}`,
        })
      );
      setSelectedSize(size);
      //console.log("size", size);

      let color = [];
      editProductVariantDetails?.color_details?.map((mapObj) =>
        color.push({
          label: mapObj?.color_name,
          value: `${mapObj?.color_id}`,
        })
      );
      setSelectedProductColor(color);
      //console.log("color", color);
    }

    return () => {
      isMounted = false;
    };
  }, [editProductVariantDetails?.product_id]);

  return (
    <section>
      <div className="bg-white border border-gray-400 rounded-sm p-4">
        <p className="pb-2 font-bold text-sm">Current Product:</p>
        <p className="text-sm">
          Product Name:{" "}
          <span className="font-semibold">
            {editProductVariantDetails?.product_name ||
              addProductVariantDetails.product_name}
          </span>
        </p>
        <p className="text-sm">
          Product Description:{" "}
          <span className="font-semibold">
            {editProductVariantDetails?.product_description ||
              addProductVariantDetails.product_description}
          </span>
        </p>
      </div>
      <div className="mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Dropdown
            defaultName="fabric_type_id"
            register={register}
            labelname="Fabric Type"
            required={false}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-60`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={fabricTypeDD}
            defaultValue={defaultValues.fabric_type_id}
            setValue={setValue}
            setSelected={setSelectedFabricType}
            selected={selectedFabricType}
            maxMenuHeight={256}
            isMulti={true}
            closeMenuOnSelect={false}
          />

          <Dropdown
            defaultName="size_id"
            register={register}
            labelname="Size"
            required={false}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-50`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={sizeDD}
            defaultValue={defaultValues.size_id}
            setValue={setValue}
            setSelected={setSelectedSize}
            selected={selectedSize}
            maxMenuHeight={120}
            isMulti={true}
            closeMenuOnSelect={false}
          />

          <Dropdown
            defaultName="color_id"
            register={register}
            labelname="Product Color"
            required={false}
            pattern={NonEmptyString}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-40`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={productColorDD}
            defaultValue={defaultValues.color_id}
            setValue={setValue}
            setSelected={setSelectedProductColor}
            selected={selectedProductColor}
            maxMenuHeight={120}
            isMulti={true}
            closeMenuOnSelect={false}
          />

          <div className="flex justify-between pt-4">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                setEditProductVariantDetails({});
                setValue("", "");
                setShowAddForm(false);
                setIsAdd(true);
              }}
              aria-label="Cancel Product Variant Details Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AddProductVariantForm;
