import React from "react";
import { useForm } from "react-hook-form";
import { PRODUCT_TYPE_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import AddButton_v2 from "../../../../reusable-components/buttons/AddButton_v2";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";

const AddProductTypeForm = ({
  getProductTypeList,
  editProductTypeDetails,
  setEditProductTypeDetails,
  setShowAddForm,
}) => {
  // const axiosPrivate = useAxiosPrivate();
  // const { auth } = useAuth();

  const isEdit = Object.keys(editProductTypeDetails)?.length > 0;

  const defaultValues = {
    product_type_name: !isEdit ? "" : editProductTypeDetails?.product_type_name,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (!isEdit) {
        // data.createdby = auth.userid;
        data.createdby = "admin";
        response = await axios.post(PRODUCT_TYPE_CONFIG_URL, data);
      } else {
        // data.updatedby = auth.userid;
        data.updatedby = "admin";
        data.product_type_id = editProductTypeDetails?.product_type_id;
        response = await axios.post(`${PRODUCT_TYPE_CONFIG_URL}/update`, data);
      }

      // console.log({ response });

      if (response.status === 200) {
        if (isEdit) {
          setEditProductTypeDetails({});
          showToast(
            "Product Type details has been updated successfully.",
            "success"
          );
        } else {
          showToast(
            "Product Type details has been added successfully.",
            "success"
          );
        }
        getProductTypeList();
      } else {
        if (isEdit) {
          console.log("Save Product Type Details Error:", response.data);
        } else {
          if (
            response.data.saveProductTypeDetails.includes("unique_product_type")
          ) {
            showToast(
              "Product Type already exists. Cannot insert duplicate product type.",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="product_type_name"
          register={register}
          name="Product Type Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter product type name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full focus:outline-none focus:ring-0 focus:border-2 focus:border-black`}
          onChangeInput={null}
          defaultValue={defaultValues.product_type_name}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditProductTypeDetails({});
              setValue("product_type_name", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Product Type Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddProductTypeForm;
