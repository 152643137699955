import React from "react";

import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const ReturnRequestSuccessPage = () => {
  return (
    <section className="bg-white min-h-screen pt-24 lg:pt-36 pb-20 font-tenorsans">
      <div className="flex justify-center pb-10">
        <AiFillCheckCircle size={60} color="black" />
      </div>

      <div className="flex-col justify-center items-center">
        <div className="text-center uppercase font-bold pb-4">
          Your return request has been forwarded successfully!
        </div>
        <div className="text-center text-sm lg:text-base pb-2">
          Kindly wait till we get back to you.
        </div>
        <div className="w-[80%] lg:w-[20%] mx-auto mt-5">
          <Link to="/">
            <div className="flex justify-center text-xs px-2 py-1 text-black border border-black hover:text-white hover:bg-black cursor-pointer transition-all ease-in-out duration-300">
              OK
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ReturnRequestSuccessPage;
