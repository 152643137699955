import React from "react";

import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const OrderSuccessPage = () => {
  return (
    <section className="font-tenorsans">
      <div className="bg-white min-h-screen pt-24 lg:pt-36 pb-20 px-3">
        <div className="flex justify-center pb-10">
          <AiFillCheckCircle size={60} color="black" />
        </div>

        <div className="flex-col justify-center items-center">
          <div className="flex justify-center uppercase font-bold pb-4">
            Your order has been placed!
          </div>
          <div className="pb-2 text-center text-sm md:text-base">
            Thank you for your payment, your order is processing.
          </div>
          <div className="text-center text-sm md:text-base pb-3">
            {/* Your order # is: <span className="font-semibold">100000023</span> */}
          </div>
          <div className="text-center text-xs md:text-sm pb-10">
            You will receive an order confirmation email with details of your
            order and a link to track your order.
          </div>
          <div className="w-[80%] lg:w-[20%] mx-auto mt-5 mb-5">
            <Link to="/">
              <div className="flex justify-center cursor-pointer bg-white text-black text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-3 lg:py-2 uppercase">
                Continue Shopping
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccessPage;
