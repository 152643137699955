import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setShowSearchBar } from "../../../features/searchBar/searchBarSlice";

import axios from "../../../api/axios";
import { FILTERED_PRODUCT_LIST_CONFIG_URL } from "../../../api/api_routing_urls";

import ProductCard from "../../../reusable-components/product-card/productCard.component";

const FilteredProducts = () => {
  const dispatch = useDispatch();

  const { filterTerm } = useParams();

  const [filteredProductList, setFilteredProductList] = useState([]);

  const onFilterDetailsSubmit = async () => {
    // console.log("filterTerm", filterTerm);

    try {
      const response = await axios.get(`${FILTERED_PRODUCT_LIST_CONFIG_URL}`, {
        params: {
          filter_term: filterTerm,
        },
      });

      //   console.log({ response });

      if (response.status === 200) {
        setFilteredProductList(response?.data?.filteredProductCompleteDetails);
      } else {
        console.log("Search could not be processed.");
      }

      // reset();
    } catch (error) {
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  //useEffect to fetch complete list of products based on search term
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      onFilterDetailsSubmit(filterTerm);
    }
    return () => {
      isMounted = false;
    };
  }, [filterTerm]);

  //   useEffect(() => {
  //     let isMounted = true;
  //     if (isMounted) {
  //       dispatch(setShowSearchBar(false));
  //     }
  //     return () => {
  //       isMounted = false;
  //     };
  //   }, []);

  return (
    <section className="bg-white font-tenorsans min-h-screen">
      <div className="pt-40 pb-10 text-xs uppercase text-center text-gray-600">
        {filterTerm}
      </div>

      {filteredProductList?.length > 0 ? (
        <div className="px-1   pb-10 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
          <>
            {filteredProductList?.map((productObj) => (
              <div key={productObj?.product_id} className="">
                <Link to={`/view-product-details/${productObj?.product_id}`}>
                  <ProductCard
                    productObj={productObj}
                    productid={productObj?.product_id}
                    productthumbnail={productObj?.product_images[0]?.file_url}
                  />
                </Link>
              </div>
            ))}
          </>
        </div>
      ) : (
        <div className="text-sm uppercase text-gray-600 min-h-screen pl-3">
          There are no products available under this category right now.
        </div>
      )}
    </section>
  );
};

export default FilteredProducts;
