/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { getFormattedDateDDMMMYYYYDate } from "../../utilities/dateFunctions/formatdate";

const ReturnedItemCard = ({
  order_id,
  order_no,
  order_details_id,
  return_initiated_date,
  return_remarks,
  return_status,
  refund_status,
  order_date,
  billing_address,
  company_name,
  product_image,
  product_id,
  product_name,
  product_size,
  quantity,
  amount_paid,
}) => {
  return (
    <>
      <div className="w-[95%] mx-auto border border-black font-tenorsans">
        <div className="flex justify-between bg-black px-3 py-4">
          <div className="flex flex-col md:flex-row gap-5 md:gap-20">
            <div className="flex-col">
              <div className="uppercase text-xs text-white">
                Return Initiated Date
              </div>
              <div className="text-xs text-white">
                {getFormattedDateDDMMMYYYYDate(return_initiated_date)}
              </div>
            </div>

            {/* <div className="flex-col">
              <div className="uppercase text-xs text-white">Item Total</div>
              <div className="text-xs text-white">₹ {amount_paid}</div>
              <>
                {discount_percentage === 0 ? (
                  <span>₹ {quantity * product_rate}</span>
                ) : (
                  <span>
                    ₹{" "}
                    {quantity *
                      (product_rate -
                        (discount_percentage / 100) * product_rate)}
                  </span>
                )}
              </>
            </div> */}

            <div className="flex-col">
              <div className="uppercase text-xs text-white">Delivered to</div>
              <div className="text-xs text-white">
                {billing_address?.first_name} {billing_address?.last_name}
              </div>
            </div>
          </div>
          <div className="uppercase text-xs text-white">
            Order# <span className="text-white">{order_no}</span>
          </div>
        </div>
        <div className="bg-white px-3 py-5">
          <div className="flex justify-between items-center">
            <div className="text-xs lg:text-base">
              Return Status: {return_status}
            </div>

            <div className="text-xs lg:text-base">
              Refund Status: {refund_status}
            </div>
          </div>

          <div className="grid grid-cols-5 pt-8 lg:pt-5">
            <div className="col-span-2 md:col-span-1">
              <img src={product_image} className="w-20 md:w-28 lg:w-40" />
            </div>
            <div className="col-span-3 md:col-span-4">
              <div className="flex-col">
                <span className="text-xs lg:text-base text-black">
                  {product_name}
                </span>
                <div className="text-xs lg:text-sm">
                  Size: <span className="">{product_size}</span>
                </div>
                <div className="text-xs lg:text-sm">
                  Quantity: <span className="">{quantity}</span>
                </div>
                <div className="text-xs lg:text-sm pt-5">
                  Reason of Return: <span className="">{return_remarks}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnedItemCard;
