import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSearchBar: false,
  isSearchDrawerOpen: false,
};

export const searchBarSlice = createSlice({
  name: "searchBar",
  initialState,
  reducers: {
    setShowSearchBar: (state, action) => {
      state.showSearchBar = action.payload;
    },
    setIsSearchDrawerOpen: (state, action) => {
      state.isSearchDrawerOpen = action.payload;
    },
  },
});

export const { setShowSearchBar, setIsSearchDrawerOpen } =
  searchBarSlice.actions;

export default searchBarSlice.reducer;
