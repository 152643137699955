import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Ripple, initTE } from "tw-elements";

const InventoryProductCard = ({
  productObj,
  setShowManageStockTable,
  setSelectedProductId,
  setSelectedProductName,
}) => {
  initTE({ Ripple });

  const onManageClickHandler = (product_id, product_name) => {
    setSelectedProductId(product_id);
    // console.log("product_id", product_id);
    setSelectedProductName(product_name);
    setShowManageStockTable((prev) => !prev);
  };

  return (
    <>
      <div class="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <div className="flex justify-between">
          <div>
            <h5 class="text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              {productObj.product_name}
            </h5>
            {/* <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              {productObj.product_description}
            </p> */}
          </div>

          <div>
            <p class="text-xs text-neutral-600 dark:text-neutral-200">
              <span className="font-bold text-black">
                {productObj.total_stock_quantity}
              </span>{" "}
              in stock for{" "}
              <span className="font-bold text-black">
                {productObj.total_variants}
              </span>{" "}
              variants
            </p>
          </div>
        </div>

        <div className="mb-4 flex items-center gap-x-2 font-semibold text-sm uppercase">
          (
          <div
            dangerouslySetInnerHTML={{
              __html: productObj?.fit_details,
            }}
          />
          <div>-</div>
          <div>{productObj?.gender_name}</div>)
        </div>

        <div className="mb-2 flex items-center">
          <div className="text-sm font-semibold">Available fabrics:</div>
          <div>
            {productObj.fabric_details?.map((fabricobj) => {
              return (
                <span className="text-sm px-2 rounded-sm">
                  {fabricobj?.fabric_type_name}
                </span>
              );
            })}
          </div>
        </div>

        <div className="mb-2 flex items-center">
          <div className="text-sm font-semibold">Available sizes:</div>
          <div>
            {productObj.size_details?.map((sizeobj) => {
              return (
                <span className="text-sm px-2 rounded-sm">
                  {sizeobj?.size_name}
                </span>
              );
            })}
          </div>
        </div>

        <div className="mb-2 flex items-center">
          <div className="text-sm font-semibold">Available colors:</div>
          <div>
            {productObj.color_details?.map((colorobj) => {
              return (
                <span className="text-sm px-2 rounded-sm">
                  {colorobj?.color_name}
                </span>
              );
            })}
          </div>
        </div>

        <button
          type="button"
          class="inline-block mt-4 text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-2 py-1 uppercase"
          onClick={() =>
            onManageClickHandler(productObj.product_id, productObj.product_name)
          }
        >
          Manage Stock
        </button>
      </div>
    </>
  );
};

export default InventoryProductCard;
