import React, { useEffect, useState } from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import { VscError } from "react-icons/vsc";
import { MdOutlinePendingActions } from "react-icons/md";

const PaymentResponseHandler = () => {
  const navigate = useNavigate();

  const [decryptedResponse, setDecryptedResponse] = useState();
  let paymentStatusCSS = "";

  useEffect(() => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    let dataParam = urlParams.get("data");

    console.log(dataParam);

    if (dataParam) {
      try {
        //cleaning dataparam
        dataParam = dataParam.replace('"', "");
        // Parse the query string into an object
        const decodedDataParam = decodeURIComponent(dataParam);

        const urlSearchParams = new URLSearchParams(decodedDataParam);
        const responseData = Object.fromEntries(urlSearchParams.entries());

        console.log(responseData);

        // You can now access the properties directly
        const jsonData = {
          order_id: responseData.order_id,
          tracking_id: responseData.tracking_id,
          order_status: responseData.order_status,
          status_message: responseData.status_message,
          amount: responseData.amount,
          trans_date: responseData.trans_date,
          // Add more properties as needed
        };

        paymentStatusCSS =
          responseData.order_status === "Success" ? "text-green-800" : null;

        setDecryptedResponse(jsonData);
      } catch (error) {
        // navigate("/");

        console.error("Error parsing query string:", error);
      }
    } else {
      // navigate("/");
    }
  }, []);

  // console.log(decryptedResponse);

  return (
    <section className="w-[80%] md:w-[70%] lg:w-[50%] h-screen mx-auto font-tenorsans text-slate-800 pt-20 lg:pt-36 rounded-sm">
      <div className="py-9 lg:py-16 border bg-white">
        {decryptedResponse?.order_status === "Success" ? (
          <div
            className={`text-green-800 text-center flex flex-col items-center justify-center mx-auto w-fit font-bold`}
          >
            <div className="text-2xl lg:text-3xl">
              <IoCheckmarkCircleSharp />
            </div>

            <p className="uppercase text-sm lg:text-lg">
              Payment {decryptedResponse?.order_status || "NA"}
            </p>

            <p className="px-3 text-xs lg:text-base text-center font-light text-gray-700">
              Order placed. Find the details of the order below.
            </p>
          </div>
        ) : decryptedResponse?.order_status === "Failure" ? (
          <div
            className={`text-red-800 text-center flex flex-col items-center justify-center mx-auto w-fit font-bold`}
          >
            <div className="text-2xl lg:text-3xl">
              <VscError />
            </div>

            <p className="uppercase text-sm lg:text-lg">
              Payment {decryptedResponse?.order_status || "NA"}
            </p>

            <p className="px-3 text-xs lg:text-base text-center font-light text-gray-700">
              Order could not be placed as the transaction could not be
              completed.
            </p>
          </div>
        ) : decryptedResponse?.order_status === "Aborted" ? (
          <div
            className={`text-red-800 text-center flex flex-col items-center justify-center mx-auto w-fit font-bold`}
          >
            <div className="text-2xl lg:text-3xl">
              <VscError />
            </div>

            <p className="uppercase text-sm lg:text-lg">
              Payment {decryptedResponse?.order_status || "NA"}
            </p>

            <p className="px-3 text-xs lg:text-base text-center font-light text-gray-700">
              Order could not be placed as the transaction was aborted.
            </p>
          </div>
        ) : (
          <div
            className={`text-yellow-600 text-center flex flex-col items-center justify-center mx-auto w-fit font-bold`}
          >
            <div className="text-2xl lg:text-3xl">
              <MdOutlinePendingActions />
            </div>

            <p className="uppercase text-sm lg:text-lg">Payment Pending</p>

            <p className="px-3 text-xs lg:text-base text-center font-light text-gray-700">
              There seems to be some issue. Please try after some time.
            </p>
          </div>
        )}

        <div className="py-3">
          <center>
            <p className="text-sm lg:text-lg py-3 font-semibold text-center">
              {decryptedResponse?.status_message}
            </p>

            <table cellPadding={3} className="text-sm lg:text-base">
              <tr>
                <td className="uppercase">Order ID:</td>
                <td className="font-bold pl-3">
                  {decryptedResponse?.order_id || "NA"}
                </td>
              </tr>

              <tr>
                <td className="uppercase">Amount:</td>
                <td className="font-bold pl-3">
                  {formatINRCurrency(decryptedResponse?.amount) || "NA"}
                </td>
              </tr>

              <tr>
                <td className="uppercase">Date:</td>
                <td className="font-bold pl-3">
                  {decryptedResponse?.trans_date || "NA"}
                </td>
              </tr>
            </table>
          </center>
        </div>
      </div>

      <div className="py-3 lg:py-10">
        <Link to="/">
          <div
            className="mt-6 bg-white text-slate-800 text-xs text-center border
           border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm 
           px-3 py-1 lg:px-5 lg:py-2 uppercase"
          >
            Continue Shopping
          </div>
        </Link>
      </div>
    </section>
  );
};

export default PaymentResponseHandler;
