import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { LuMoveRight } from "react-icons/lu";

const DrawerModalCart = ({ isOpen, toggleDrawer, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling when the drawer is open
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling when the drawer is closed
    }

    return () => {
      document.body.style.overflow = "auto"; // Make sure to enable scrolling when the component unmounts
    };
  }, [isOpen]);

  const handleClose = () => {
    setTimeout(() => {
      toggleDrawer();
    }, 0); // Adjust the delay as needed, 300ms in this case
  };

  return (
    <>
      <motion.div
        id="drawer-right-example"
        className={`fixed top-0 right-0 z-60 h-screen p-4 overflow-y-auto bg-white`}
        tabIndex="-1"
        aria-labelledby="drawer-right-label"
        initial={{ x: "100%" }}
        animate={{ x: isOpen ? 0 : "100%" }}
        exit={{ x: "100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        {/* <div className="px-5 mt-10 flex justify-between">
          <div className="">Shopping Bag</div>
          <div>
            <button
              type="button"
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-900 rounded-lg text-2xl w-8 h-8"
            >
              <LuMoveRight />
              <span className="sr-only">Close menu</span>
            </button>
          </div>
        </div> */}

        {/* Drawer content */}
        <div className="px-5 mt-10">{children}</div>
      </motion.div>
    </>
  );
};

export default DrawerModalCart;
