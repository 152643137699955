// Public View

import React, { useState, useEffect } from "react";
import { LEGAL_NOTICE_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import { BsArrowLeft } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import showToast from "../../../utilities/notification/NotificationModal";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

import logo from "../../../assets/gratitude_black_logo-nobg.png";
import { Link } from "react-router-dom";

const LegalNotice = () => {
  const [legalNotice, setLegalNotice] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getLegalNoticeDetails = async () => {
    try {
      const response = await axios.get(LEGAL_NOTICE_CONFIG_URL);
      response.status === 200 &&
        setLegalNotice(response?.data?.legalNoticeDetails);
      response.status === 202 &&
        showToast("Legal Notice details not found in the system.", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getLegalNoticeDetails();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <section className="font-tenorsans">
      <div className="bg-white min-h-screen pt-24 lg:pt-36">
        <div className="w-[80%] mx-auto flex justify-end pb-5">
          <Link to="/">
            <div className="bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
              Go to home
            </div>
          </Link>
        </div>
        {isLoading ? (
          <div className="bg-white h-screen flex items-center justify-center">
            <AnimatedLoader />
          </div>
        ) : (
          <div className="bg-white py-5 pb-16">
            {/* <div className="flex justify-center">
              <img src={logo} className="h-16 mb-2" />
            </div> */}
            <div
              className="leading-6 w-[80%] mx-auto pb-5 text-sm lg:text-base"
              dangerouslySetInnerHTML={{
                __html: legalNotice.legal_notice_content,
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default LegalNotice;
