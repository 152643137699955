/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import "./Home.css";

import { motion, AnimateSharedLayout, LayoutGroup } from "framer-motion";
import SimpleImageSlider from "../../../reusable-components/simple-product-image-slider/simpleImageSlider.component";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import SwipeableImageSlider from "../../../reusable-components/swipeable-image-slider/swipeableImageSlider.component";
import VerticalScrollImageGallery from "../../../reusable-components/vertical-scroll-image-gallery/verticalScrollImageGallery.component";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { PiBookmarkSimpleLight } from "react-icons/pi";
import { PiBookmarkSimpleFill } from "react-icons/pi";

import { useSelector, useDispatch } from "react-redux";
import { setCartQuantity } from "../../../features/cartQuantity/cartQuantitySlice";

import {
  PRODUCT_LIST_CONFIG_URL,
  CART_DETAILS_CONFIG_URL,
  WISHLIST_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import ProductDetailsAccordion from "../../../reusable-components/accordion/ProductDetailsAccordion";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

const ViewProductDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();

  const auth = useSelector((state) => state.authProvider.auth);
  const user_id = useSelector((state) => state.authProvider.auth?.user_id);

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  // console.log("user_id", auth);

  const [isLoading, setIsLoading] = useState(true);

  const isUnavailable = "text-gray-400 cursor-none pointer-events-none";
  const isAvailable = "text-black cursor-pointer";

  const [selectedProduct, setSelectedProduct] = useState();
  const [productExistsInWishlist, setProductExistsInWishlist] = useState(false);

  const [selectedSize, setSelectedSize] = useState(0);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [cartAddSuccess, setCartAddSuccess] = useState(false);
  const [showGoToCartButton, setShowGoToCartButton] = useState(false);
  const [showLowStockMessage, setShowLowStockMessage] = useState(true);

  const [showSizeInfoMessage, setShowSizeInfoMessage] = useState(false);
  const [sizeInfoMessage, setSizeInfoMessage] = useState(null);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const getProductCompleteDetails = async (product_id) => {
    try {
      const response = await axios.get(
        `${PRODUCT_LIST_CONFIG_URL}/byProductId`,
        {
          params: {
            product_id,
          },
        }
      );
      // console.log("Product with complete details", { response });
      if (response.status === 200) {
        setSelectedProduct(response?.data?.productCompleteDetails);
        setIsLoading(false);
      }
      if (response.status === 202) {
        console.log("No product details found in the system.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("getProductCompleteDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
      setIsLoading(false);
    }
  };

  const checkProductExistsInWishlist = async (user_id, product_id) => {
    try {
      let sendDataObj = { user_id, product_id };

      const response = await axios.post(
        `${WISHLIST_DETAILS_CONFIG_URL}/checkProductAlreadyExists`,
        sendDataObj
      );
      // console.log("Check product exists in wishlist", { response });
      response.status === 200 &&
        setProductExistsInWishlist(
          response?.data?.checkIfProductExistsInWishlistDetails
        );
      // response.status === 202 && console.log("No details found in the system.");
    } catch (error) {
      console.error("checkProductExistsInWishlist", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  const onSizeClickHandler = (size, sizename) => {
    setSelectedSize(size);
    displaySizeInfo(sizename);
    setShowSizeInfoMessage(true);
  };

  const displaySizeInfo = (size) => {
    if (size === "S") {
      setSizeInfoMessage("'S': 4.5 Feet - 5.0 Feet");
    }

    if (size === "M") {
      setSizeInfoMessage("'M': 5.2 Feet - 5.4 Feet");
    }

    if (size === "L") {
      setSizeInfoMessage("'L': 5.5 Feet - 5.7 Feet");
    }

    if (size === "XL") {
      setSizeInfoMessage("'XL': 5.8 Feet - 5.10 Feet");
    }
  };

  const onBackClickHandler = () => {
    setSelectedProduct(null);
    setSelectedSize(0);
    // setShowProductDetailsPage(false);
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  //useEffect to fetch complete details of the product for the selected product id
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductCompleteDetails(productId);
    }
    return () => {
      isMounted = false;
    };
  }, [productId]);

  // console.log("selectedProduct", selectedProduct);

  //useEffect to check if the selected product already exists in the user's wishlist
  useEffect(() => {
    let isMounted = true;
    if (isMounted && user_id) {
      checkProductExistsInWishlist(user_id, productId);
    }
    return () => {
      isMounted = false;
    };
  }, [user_id, productId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setProductExistsInWishlist(false);
      setSelectedSize(0);
      setProcessingOrder(false);
      setCartAddSuccess(false);
      setShowGoToCartButton(false);
      setShowSizeInfoMessage(false);
      setSizeInfoMessage(null);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const onAddToWishlistClickHandler = async (selectedProduct) => {
    // console.log("selectedProduct", selectedProduct);

    try {
      let productDetailsObj = {
        product_id: selectedProduct.product_id,
        product_name: selectedProduct.product_name,
        product_image: selectedProduct?.product_images[0].file_url,
      };
      let sendDataObj = {
        user_id: user_id,
        product_details: JSON.stringify(productDetailsObj),
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(WISHLIST_DETAILS_CONFIG_URL, sendDataObj);

      if (response.status === 200) {
        console.log("Product added to wishlist successfully!");
        checkProductExistsInWishlist(user_id, selectedProduct.product_id);
      } else {
        console.log("Add to wishlist error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onAddClickHandler = async (selectedProduct) => {
    // console.log("selectedProduct", selectedProduct);
    setProcessingOrder(true);
    let status = 0;
    try {
      let productDetailsObj = {
        product_id: selectedProduct.product_id,
        product_name: selectedProduct.product_name,
        size_variety_id: selectedSize,
        product_image: selectedProduct?.product_images[0].file_url,
      };
      let sendDataObj = {
        user_id: user_id,
        product_details: JSON.stringify(productDetailsObj),
        quantity: 1,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(CART_DETAILS_CONFIG_URL, sendDataObj);
      status = response.status;

      if (response.status === 200) {
        // console.log("Product added to cart  successfully!");
        getCartItemsQuantityDetails(user_id);
        dispatch(setCartQuantity(cartQuantity));
        setShowGoToCartButton(true);
        setProcessingOrder(false);
      } else {
        console.log("Add to cart error!");
        return;
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      if (status === 200) {
        // console.log("Status 200");
        setCartAddSuccess(true);
      } else {
        console.log("Status Others");
      }
      setTimeout(() => {
        setProcessingOrder(false);
      }, 1000);
      cartAddSuccessHandler();
    }
  };

  const cartAddSuccessHandler = () => {
    setTimeout(() => {
      setCartAddSuccess(false);
    }, 4000);
  };

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  const handleAccordionClick = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };

  const accordions = [
    {
      id: 1,
      title: "Shipping",
      content: (
        <>
          <ul className="list-disc text-xs pl-4 text-gray-500">
            <li className="text-[11px] uppercase">
              Standard processing time for orders is 2-3 Business Days to be
              processed and shipped.
            </li>
            <li className="text-[11px] uppercase hover:text-gray-700 cursor-pointer">
              <NavLink to="/shipping-policy">
                Read our full shipping policy here
              </NavLink>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      title: "Return",
      content: (
        <>
          <ul className="list-disc text-xs pl-4 text-gray-500">
            <li className="text-[11px] uppercase pb-2">
              All returns must be submitted within 7 days of receiving your
              items.
            </li>
            <li className="text-[11px] uppercase hover:text-gray-700 cursor-pointer">
              <Link to="/return-refund-policy">
                Read our full return and refund policy here
              </Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 3,
      title: "Refund",
      content: (
        <>
          <ul className="list-disc text-xs pl-4 text-gray-500">
            <li className="text-[11px] uppercase pb-2">
              Refund takes upto 30 days to reflect on the same payment method
              you used for the purchase.
            </li>
            <li className="text-[11px] uppercase hover:text-gray-700 cursor-pointer">
              <Link to="/return-refund-policy">
                Read our full return and refund policy here
              </Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      title: "Share",
      content: (
        <>
          <div className="flex gap-x-5 mt-1">
            <div>
              <FacebookShareButton
                url={window.location.href}
                quote={"Check this product from Gratitude!"}
                hashtag="#gratitude#forever#grateful"
              >
                <span className="uppercase">Facebook</span>
              </FacebookShareButton>
            </div>

            <div>
              <WhatsappShareButton
                url={window.location.href}
                quote={"Check this product from Gratitude!"}
                hashtag="#gratitude#forever#grateful"
              >
                <span className="uppercase">Whatsapp</span>
              </WhatsappShareButton>
            </div>
          </div>
        </>
      ),
    },

    // Add more accordion items as needed
  ];

  const sizeAccordion = {
    id: 5,
    title: "Size Guide",
    content: (
      <>
        <ul className="list-disc text-xs pl-4">
          <SizeChartLi sizename="S" lowerLimit="4.5" upperLimit="5.0" />
          <SizeChartLi sizename="M" lowerLimit="5.2" upperLimit="5.4" />
          <SizeChartLi sizename="L" lowerLimit="5.5" upperLimit="5.7" />
          <SizeChartLi sizename="XL" lowerLimit="5.8" upperLimit="5.10" />
        </ul>
      </>
    ),
  };

  const detailsAccordion = {
    id: 6,
    title: "DETAILS",
    content: (
      <>
        <div
          className="leading-6 text-xs text-gray-500 font-light pr-10 uppercase"
          dangerouslySetInnerHTML={{
            __html: selectedProduct?.other_details,
          }}
        />
      </>
    ),
  };

  // Array to send to image slider component
  let imageArr = [];

  selectedProduct?.product_images?.map((obj, index) => {
    imageArr.push(obj.file_url);
  });

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : (
        <section className="bg-white min-h-screen pt-24 lg:pt-36 font-tenorsans noSelect">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="md:pl-8 lg:pl-0">
              {/* Image Gallery for Mobiles and Tablets */}
              <div className="block lg:hidden">
                <div
                  className="lg:ml-20 lg:pr-20"
                  style={{
                    maxWidth: "1200px",
                    width: "100%",
                    // height: "500px",
                    aspectRatio: "12 / 8",
                    // margin: "0 auto",
                  }}
                >
                  {/* <SimpleImageSlider imageArr={imageArr} /> */}
                  <SwipeableImageSlider imagesArr={imageArr} />
                </div>
              </div>

              {/* Image Gallery for Desktops */}
              <div className="hidden lg:block">
                <VerticalScrollImageGallery imagesArr={imageArr} />
              </div>
            </div>

            {/* Description column */}
            <div className="bg-[#FFFFFF] py-5 flex flex-col">
              <div className="w-[90%] md:w-[80%] mx-auto">
                <div>
                  <div className="flex items-center gap-x-1 uppercase font-medium text-[11px] text-gray-700 -mb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct?.fit_details,
                      }}
                    />

                    <div>-</div>

                    <div>{selectedProduct?.gender_name}</div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-sm lg:text-lg text-black pb-1">
                      {selectedProduct?.product_name}
                    </div>

                    {/* {user_id && (
                      <>
                        {productExistsInWishlist === "true" ? (
                          <div className="flex flex-col justify-between items-center">
                            <div className="cursor-pointer">
                              <PiBookmarkSimpleFill />
                            </div>

                            <Link to="/view-wishlist">
                              <div className="cursor-pointer mt-1  text-[10px] lg:text-xs uppercase font-light">
                                View
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <>
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                onAddToWishlistClickHandler(selectedProduct)
                              }
                            >
                              <PiBookmarkSimpleLight />
                            </div>
                          </>
                        )}
                      </>
                    )} */}
                  </div>
                </div>

                <div>
                  <>
                    {selectedProduct?.discount_percentage === 0 ? (
                      <>
                        <div className="font-light text-xs lg:text-sm text-gray-700">
                          {formatINRCurrency(selectedProduct?.product_price)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex gap-2 items-center pb-2">
                          <div className="font-light text-xs lg:text-sm text-black line-through">
                            {formatINRCurrency(selectedProduct?.product_price)}
                          </div>
                          <div className="font-light text-xs lg:text-sm text-white bg-black pl-1">
                            -{selectedProduct?.discount_percentage}%{" "}
                            <span className="font-light pl-5 pr-1">
                              {formatINRCurrency(
                                selectedProduct?.product_price -
                                  (selectedProduct?.discount_percentage / 100) *
                                    selectedProduct?.product_price
                              )}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>

                <div className="text-[10px] lg:text-sm text-gray-600">
                  MRP incl. of all taxes
                </div>

                {/* Add to cart post size selection section */}
                <div>
                  <div className="mt-6">
                    <div>
                      <ProductDetailsAccordion
                        key={sizeAccordion.id}
                        title={sizeAccordion.title}
                        content={sizeAccordion.content}
                        isOpen={sizeAccordion.id === activeAccordion}
                        onAccordionClick={() =>
                          handleAccordionClick(sizeAccordion.id)
                        }
                      />
                    </div>
                  </div>

                  {/* Available sizes listing */}
                  <div className="flex justify-center gap-10 mt-0 lg:mt-2 py-2">
                    {selectedProduct?.product_data?.map(
                      (colorsizequantityObj) =>
                        colorsizequantityObj?.color_size_quantity?.map(
                          (sizequantityObj) =>
                            sizequantityObj?.size_quantity?.map((sizeObj) => (
                              <div>
                                <div
                                  onClick={() =>
                                    onSizeClickHandler(
                                      sizeObj.size_variety_id,
                                      sizeObj.size_name
                                    )
                                  }
                                  className={`${
                                    selectedSize === sizeObj?.size_variety_id &&
                                    sizeObj?.stock_quantity > 0
                                      ? "flex justify-center items-center border border-black rounded-full cursor-pointer"
                                      : "flex justify-center items-center border border-white rounded-full cursor-pointer"
                                  }`}
                                >
                                  <div
                                    className={`relative h-5 w-5 m-1 p-1 ${
                                      sizeObj?.stock_quantity > 0
                                        ? isAvailable
                                        : isUnavailable
                                    }`}
                                  >
                                    <div className="text-[10px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                      {sizeObj.size_name}
                                    </div>
                                  </div>
                                </div>

                                {showLowStockMessage ? (
                                  <div className="text-[9px] pt-2 text-center cursor-none pointer-events-none">
                                    {+sizeObj?.stock_quantity <= 3 &&
                                    +sizeObj?.stock_quantity > 0 ? (
                                      <span className="text-black">
                                        Few left
                                      </span>
                                    ) : (
                                      +sizeObj?.stock_quantity === 0 && (
                                        <span className="text-red-700">
                                          Sold Out
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            ))
                        )
                    )}
                  </div>

                  {showSizeInfoMessage && (
                    <div className="py-2 text-center text-[10px] md:text-xs">
                      Selected size: {sizeInfoMessage}
                    </div>
                  )}

                  {user_id ? (
                    <>
                      {/* Add to cart button */}
                      <div>
                        {processingOrder ? (
                          <>
                            <div className="mt-8 border border-black px-4 py-2 flex justify-center text-gray-700 text-lg font-semibold cursor-pointer">
                              ...
                            </div>
                          </>
                        ) : (
                          <>
                            {selectedSize === 0 ? (
                              <>
                                {selectedProduct?.total_stock_quantity > 0 ? (
                                  <div className="mt-8 border border-gray-700 px-4 py-2 flex justify-center text-gray-700 text-sm uppercase font-light cursor-not-allowed">
                                    Select a size to continue
                                  </div>
                                ) : (
                                  <div className="mt-8 border border-gray-700 px-4 py-2 flex justify-center text-gray-700 text-sm uppercase">
                                    Sold Out
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {selectedProduct?.total_stock_quantity > 0 ? (
                                  /* Add to Cart Button */
                                  <div>
                                    {!cartAddSuccess ? (
                                      <>
                                        <div
                                          onClick={() =>
                                            onAddClickHandler(selectedProduct)
                                          }
                                          className="mt-8 border border-black px-4 py-2 flex justify-center text-gray-700 text-lg font-semibold cursor-pointer"
                                        >
                                          <>
                                            {selectedProduct?.discount_percentage ===
                                            0 ? (
                                              <span>
                                                Add to Cart - ₹{" "}
                                                {selectedProduct?.product_price}
                                                /-
                                              </span>
                                            ) : (
                                              <span>
                                                Add to Cart - ₹{" "}
                                                {selectedProduct?.product_price -
                                                  (selectedProduct?.discount_percentage /
                                                    100) *
                                                    selectedProduct?.product_price}
                                                /-
                                              </span>
                                            )}
                                          </>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="mt-8 border border-green-700 px-4 py-2 flex justify-center text-green-700 text-lg font-semibold cursor-pointer">
                                          Added to Cart!
                                          <span></span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="mt-8 border border-gray-700 px-4 py-2 flex justify-center text-gray-700 text-sm uppercase">
                                    Sold Out
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Link to="/user-signing">
                        <div className="mt-8 border border-black px-4 py-2 flex justify-center text-black text-sm uppercase cursor-pointer">
                          Login to continue
                        </div>
                      </Link>
                    </>
                  )}

                  {/* Go to cart button */}
                  {showGoToCartButton && (
                    <Link to="/view-cart">
                      <div className="mt-2 border border-black bg-black px-4 py-2 flex justify-center text-white text-sm uppercase cursor-pointer">
                        View Cart
                      </div>
                    </Link>
                  )}
                </div>

                <div className="mt-10 py-2">
                  {/* <p className="text-xs uppercase font-semibold">Description</p> */}
                  <div className="leading-6 text-sm text-black font-light text-justify w-full">
                    {selectedProduct?.product_description}
                  </div>
                </div>

                {/* <div className="py-2 border-b border-black"> */}
                <div className="">
                  <ProductDetailsAccordion
                    key={detailsAccordion.id}
                    title={detailsAccordion.title}
                    content={detailsAccordion.content}
                    isOpen={detailsAccordion.id === activeAccordion}
                    onAccordionClick={() =>
                      handleAccordionClick(detailsAccordion.id)
                    }
                  />
                </div>

                {/* Accordion section  start */}
                <>
                  {accordions?.map((accordion, index) => (
                    <div key={index} className="">
                      <ProductDetailsAccordion
                        key={accordion.id}
                        title={accordion.title}
                        content={accordion.content}
                        isOpen={accordion.id === activeAccordion}
                        onAccordionClick={() =>
                          handleAccordionClick(accordion.id)
                        }
                      />
                    </div>
                  ))}
                </>

                {/* Accordion section  end */}
              </div>

              <div className="mt-16 mb-5 md:mt-10">
                <div
                  className="flex justify-center items-center text-black text-[11px] cursor-pointer"
                  onClick={() => onBackClickHandler()}
                >
                  Back to Shop
                </div>
              </div>
            </div>
          </div>

          {/* <div className="hidden lg:block py-72 text-center text-3xl font-bold border-t uppercase text-gray-400">
            You may also like section
          </div> */}
        </section>
      )}
    </>
  );
};

export default ViewProductDetails;

const SizeChartLi = ({ sizename, lowerLimit, upperLimit }) => {
  return (
    <>
      <li className="text-[11px] text-slate-500 uppercase">
        <div className="flex items-center justify-start gap-3">
          <div className="w-5 flex items-center justify-between">
            <p>{sizename}</p>
            <p>:</p>
          </div>

          <div className="flex items-center justify-normal">
            <div className="w-6 flex items-center justify-end pr-1">
              <p>{lowerLimit}</p>
            </div>
            FEET
            <span className="px-3">-</span>
            <div className="w-6 flex items-center justify-end pr-1">
              <p>{upperLimit}</p>
            </div>
            FEET
          </div>
        </div>
      </li>
    </>
  );
};
