import axios from "../../api/axios";
// import useAuth from "./useAuth";

import { useDispatch } from "react-redux";

import {
  setAuth,
  setPersist,
} from "../../features/authProvider/authProviderSlice";

import { resetCartQuantity } from "../../features/cartQuantity/cartQuantitySlice";

const useLogout = () => {
  // const { setAuth } = useAuth();

  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(setAuth({}));
    dispatch(resetCartQuantity(0));
    // localStorage.removeItem("roleid");
    // clearCookie("jwt", {
    //   httpOnly: true,
    //   sameSite: "None",
    //   secure: true,
    // });

    // try {
    //   Cookies.remove("jwt", {
    //     path: "/",
    //     domain: "localhost",
    //     httpOnly: true,
    //   });

    //   // removeCookie("jwt", { path: "/", domain: "localhost", httpOnly: true });
    //   // localStorage.clear();
    //   console.log("testing clearing cookie");
    // } catch (error) {
    //   console.log(error);
    // }

    try {
      await axios("/logout", {
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
