import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { WEAR_TYPE_CONFIG_URL } from "../../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../../api/axios";
import Dashboard from "../../../../common/dashboard-components/dashboard.component";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";

import AddWearTypeForm from "./addWearTypeForm.component";
import WearTypeList from "./wearTypeList.component";

const WearTypeConfig = () => {
  // const { auth } = useAuth();
  const [wearTypeList, setWearTypeList] = useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editWearTypeDetails, setEditWearTypeDetails] = useState({});
  const isEdit = Object.keys(editWearTypeDetails).length > 0 ? true : false;

  const getWearTypeList = async () => {
    try {
      const response = await axios.get(WEAR_TYPE_CONFIG_URL);
      // console.log("Wear Type List", { response });
      response.status === 200 && setWearTypeList(response.data.wearTypeList);
      response.status === 202 &&
        showToast("No wear type found in the system.", "error");
    } catch (error) {
      console.error("getWearTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getWearTypeList();
      setEditWearTypeDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Wear Type"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${
          isEdit ? "Update Wear Type Details" : "Add Wear Type Details"
        }`}
        isAdd={true}
        clearData={setEditWearTypeDetails}
      >
        <AddWearTypeForm
          getWearTypeList={getWearTypeList}
          editWearTypeDetails={editWearTypeDetails}
          setEditWearTypeDetails={setEditWearTypeDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <WearTypeList
          wearTypeList={wearTypeList}
          getWearTypeList={getWearTypeList}
          setEditWearTypeDetails={setEditWearTypeDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default WearTypeConfig;
