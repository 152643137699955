import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import axios from "../../../api/axios";
import { PRODUCT_IMAGE_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import ImageDropzone from "../../../reusable-components/inputs/ImageDropzone/ImageDropzone";

import uploadFileController from "../../../utilities/uploadFiles/uploadFileController";
import showToast from "../../../utilities/notification/NotificationModal";

const AddProductImagesForm = ({
  imageDetails,
  setImageDetails,
  setShowAddForm,
  getProductWithImageDetailsList,
  selectedProduct,
}) => {
  const [imageArr, setImageArr] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    // setImageArr([]);
  }, []);

  const addVariantImages = async () => {
    let file_url = "";
    let file_list = [];

    if (imageArr) {
      return await Promise.all(
        imageArr?.map(async (file) => {
          file_url = await uploadFile(file);
          await file_list.push(file_url);
        })
      ).then(async () => {
        return await file_list;
      });
    }
  };

  const uploadFile = async (file) => {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });
      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const submitImages = async () => {
    let sendDataObj = {};

    try {
      let file_List = await addVariantImages();

      // console.log("selectedProduct", selectedProduct);

      sendDataObj.product_id = selectedProduct.product_id;
      sendDataObj.color_variety_id = imageDetails.color_variety_id;
      sendDataObj.fileUrlArr = JSON.stringify(file_List);

      let response = await axios.post(
        PRODUCT_IMAGE_DETAILS_CONFIG_URL,
        sendDataObj
      );

      if (response?.status === 200) {
        showToast("The images have been added successfully.", "success");
        setShowAddForm(false);
      }
      response?.status === 202 &&
        showToast("No images found in the system", "error");
    } catch (error) {
      setShowAddForm(false);
      console.error(error.message);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } finally {
      getProductWithImageDetailsList(selectedProduct.product_id);
    }
  };

  return (
    <>
      <div className="p-3 bg-white text-black rounded-md border border-gray-400">
        Add images for
        <span className="font-semibold uppercase px-1">
          {selectedProduct.product_name}
        </span>
        for
        <span className="font-semibold uppercase px-1">
          {imageDetails.color_name}
        </span>
        variant
      </div>

      <form onSubmit={handleSubmit(submitImages)}>
        <ImageDropzone
          fieldTitle="Upload images for the above variant:"
          onChange={setImageArr}
        />

        <div className=" mt-20 flex justify-between">
          <button
            type="submit"
            className="px-3 py-1 bg-black text-white cursor-pointer"
          >
            Submit
          </button>
          <div
            className="px-3 py-1 bg-slate-200 cursor-pointer"
            onClick={() => setShowAddForm(false)}
          >
            Cancel
          </div>
        </div>
      </form>
    </>
  );
};

export default AddProductImagesForm;
