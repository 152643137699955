import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

const UserLoginForm = () => {
  const navigate = useNavigate();

  const { auth } = useSelector((state) => state.authProvider);
  //   console.log("auth", auth);

  const userId = useSelector((state) => state.authProvider.auth?.user_id);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    if (userId) {
      navigate("/login-registration-page");
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : (
        // Form section
        <section className="bg-white min-h-screen pt-24 lg:pt-36 font-tenorsans">
          <div className="bg-white w-[80%] md:w-[60%] lg:w-[40%] mx-auto pt-20 pb-10">
            <div className="flex justify-center uppercase text-gray-600 text-sm lg:text-lg pb-3">
              Sign In/ Sign Up
            </div>

            <Link to="/login-using-otp">
              <div className="mt-4 flex flex-col flex-grow">
                <button className="bg-white text-gray-600 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                  Use OTP
                </button>
              </div>
            </Link>

            <div className="mb-2">
              <p className="mt-4 text-gray-600 text-xs text-center uppercase">
                Or
              </p>

              <Link to="/login-registration-page">
                <div className="mt-4 flex flex-col flex-grow">
                  <button className="bg-white text-gray-600 text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase">
                    Use E-mail
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UserLoginForm;
