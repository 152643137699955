import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import ROLES from "./api/roles_list";
import Unauthorized from "./common/Unathorized";
import PersistLogin from "./authentication/components/persistLogins.component";
import RequireAuth from "./authentication/components/RequireAuth";
import Missing from "./common/Missing";
import { PublicLayout } from "./area/public/PublicLayout";

import PasswordConfig from "./area/system-admin/ChangePassword/PasswordConfig";

import DevLogin from "./authentication/components/devLogin.component";
import Dashboard from "./common/dashboard-components/dashboard.component";

import LegalNoticeAdmin from "./area/gratitude-admin/CMS/LegalNotice/legalNotice.component";
import AboutUsAdmin from "./area/gratitude-admin/CMS/AboutUs/aboutUs.component";
import ReturnRefundPolicyAdmin from "./area/gratitude-admin/CMS/ReturnRefundPolicy/returnRefundPolicy.component";
import ShippingPolicyAdmin from "./area/gratitude-admin/CMS/ShippingPolicy/shippingPolicy.component";

import ProductTypeConfig from "./area/gratitude-admin/MasterConfiguration/productTypeConfig/productTypeConfig.component";
import GenderConfig from "./area/gratitude-admin/MasterConfiguration/genderConfig/genderConfig.component";
import SeasonConfig from "./area/gratitude-admin/MasterConfiguration/seasonConfig/seasonConfig.component";
import SizeConfig from "./area/gratitude-admin/MasterConfiguration/sizeConfig/sizeConfig.component";
import ColorConfig from "./area/gratitude-admin/MasterConfiguration/colorConfig/colorConfig.component";
import OccasionTypeConfig from "./area/gratitude-admin/MasterConfiguration/occasionTypeConfig/occasionTypeConfig.component";
import FabricTypeConfig from "./area/gratitude-admin/MasterConfiguration/fabricTypeConfig/fabricTypeConfig.component";
import WearTypeConfig from "./area/gratitude-admin/MasterConfiguration/wearTypeConfig/wearTypeConfig.component";
import ClothTypeConfig from "./area/gratitude-admin/MasterConfiguration/clothTypeConfig/clothTypeConfig.component";
import TagConfig from "./area/gratitude-admin/MasterConfiguration/tagConfig/tagConfig.component";

import AdminLogin from "./area/gratitude-admin/AdminLogin/admin_login.component";
import ProductConfig from "./area/gratitude-admin/Product/productConfig.component";
import ProductVariantConfig from "./area/gratitude-admin/ProductVariants/productVariantConfig.component";
import ProductImage from "./area/gratitude-admin/ProductImages/productImagesConfig.component";
import Inventory from "./area/gratitude-admin/Inventory/inventory.component";
import Pricing from "./area/gratitude-admin/Pricing/pricing.component";
import ManageOrders from "./area/gratitude-admin/OrderManagement/manageOrdersConfig.component";
import ViewOrderDetailsAdmin from "./area/gratitude-admin/OrderManagement/viewOrderDetailsAdmin.component";
import TrackOrdersConfig from "./area/gratitude-admin/OrderTracking/trackOrdersConfig.component";

import UserRegistrationAdminConfig from "./area/gratitude-admin/userRegistrationManagement/userRegistrationAdminConfig.component";
// import UserRegistrationAdminConfig from "./area/gratitude-admin/UserRegistrationManagement/userRegistrationAdminConfig.component";

import ManageOrderReturns from "./area/gratitude-admin/ReturnManagement/manageOrderReturnsConfig.component";
import NewsletterSubscriptionAdminConfig from "./area/gratitude-admin/NewsletterSubscriptionManagement/newsletterSubscriptionAdminConfig.component";

import PaymentUATEnv from "./area/public/PGTestEnvironment/paymentUATEnv.component";

import Home from "./area/public/Pages/Home";
import SearchedProducts from "./area/public/Pages/searched_products.component";
import FilteredProducts from "./area/public/Pages/filtered_products.component";
import AboutUsGratitude from "./area/public/Pages/about_us.component";
import LegalNotice from "./area/public/Pages/legal_notice.component";
import ReturnRefundPolicy from "./area/public/Pages/return_refund_policy.component";
import ShippingPolicy from "./area/public/Pages/shipping_policy.component";
import UserLoginForm from "./area/public/Pages/user_login_form.component";
import LoginRegisterForm from "./area/public/Pages/login_register_form.component";
import OtpLogin from "./area/public/Pages/otp_login.component";
import ForgetPasswordForm from "./area/public/Pages/forgotPassword/forgot_password_form.component";
import ChangePasswordForm from "./area/public/Pages/changePassword/change_password_form.component";

import ProfileDetails from "./area/public/Pages/profile_details.component";
// import ViewProductDetails from "./area/public/Pages/view_product_details.component";
import ViewProductDetails from "./area/public/Pages/view_product_details_v2.component";
import Wishlist from "./area/public/Pages/wishlist.component";
// import Cart from "./area/public/Pages/cart.component";
import Cart from "./area/public/Pages/cart_v2.component";
import Checkout from "./area/public/Pages/checkout.component";
import MyOrders from "./area/public/Pages/my_orders.component";
import OrderSuccessPage from "./area/public/Pages/order_success_page.component";
import ReturnRequestSuccessPage from "./area/public/Pages/return_request_success.component";
import MyReturns from "./area/public/Pages/my_returns.component";
import EmailVerificationPage from "./area/public/Pages/email_verification_page.component";

import ConfettiComponent from "./area/public/Pages/gratitude_confetti.component";
import LaunchPage from "./area/public/Pages/launchPage.component";

import { useCloseCartOnRouteChange } from "./features/closeCartHook";
import { useCloseSearchBarOnRouteChange } from "./features//closeSearchBarHook";

import PaymentResponseHandler from "./area/public/PGTestEnvironment/paymentResponseHandler.component";

import DemoDrawer from "./area/public/Pages/demoDrawer";

function App() {
  const location = useLocation();

  useCloseCartOnRouteChange();
  useCloseSearchBarOnRouteChange();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      {/* Public Routes */}
      {/* <Route exact path="/gratitude-launch/go" element={<LaunchPage />} />
      <Route
        exact
        path="/gratitude-launch/goto"
        element={<ConfettiComponent />}
      /> */}

      <Route element={<PublicLayout />}>
        <Route exact path="/payment/uat-env" element={<PaymentUATEnv />} />
        <Route
          exact
          // path="/payment-response-handler"
          path="/transaction-response"
          element={<PaymentResponseHandler />}
        />

        <Route exact path="/demodrawer" element={<DemoDrawer />} />

        <Route exact path="/" element={<Home />} />

        <Route
          exact
          path="/userRegistrationVerification/:status"
          element={<EmailVerificationPage />}
        />

        <Route
          exact
          path="/searched-products/:searchTerm"
          element={<SearchedProducts />}
        />

        <Route
          exact
          path="/products/:filterTerm"
          element={<FilteredProducts />}
        />

        <Route exact path="/about-gratitude" element={<AboutUsGratitude />} />
        <Route exact path="/legal-notice" element={<LegalNotice />} />
        <Route
          exact
          path="/return-refund-policy"
          element={<ReturnRefundPolicy />}
        />
        <Route exact path="/shipping-policy" element={<ShippingPolicy />} />

        {/* <Route
          exact
          path="/login-registration-page"
          element={<LoginRegisterForm />}
        />

        <Route
          exact
          path="/view-profile-details"
          element={<ProfileDetails />}
        />
        <Route exact path="/view-cart" element={<Cart />} />
        <Route exact path="/proceed-to-checkout" element={<Checkout />} />
        <Route exact path="/order-successful" element={<OrderSuccessPage />} />
        <Route exact path="/my-orders" element={<MyOrders />} /> */}
      </Route>

      <Route path="unauthorized" element={<Unauthorized />} />

      <Route exact path="/auth/login" element={<AdminLogin />} />

      {/* Routes requiring Authentication*/}

      <Route element={<PersistLogin is_admin={false} />}>
        {/* <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES["Admin"],
                ROLES["Super Admin"],
                ROLES["Customer"],
              ]}
            />
          }
        > */}

        <Route exact path="/forget-password" element={<ForgetPasswordForm />} />
        <Route exact path="/change-password" element={<ChangePasswordForm />} />

        <Route
          exact
          path="/view-product-details/:productId"
          element={<ViewProductDetails />}
        />

        <Route exact path="/user-signing" element={<UserLoginForm />} />

        <Route
          exact
          path="/login-registration-page"
          element={<LoginRegisterForm />}
        />

        <Route exact path="/login-using-otp" element={<OtpLogin />} />

        <Route
          exact
          path="/view-profile-details"
          element={<ProfileDetails />}
        />
        {/* <Route exact path="/view-wishlist" element={<Wishlist />} /> */}
        <Route exact path="/view-cart" element={<Cart />} />
        <Route exact path="/proceed-to-checkout" element={<Checkout />} />
        <Route exact path="/order-successful" element={<OrderSuccessPage />} />
        <Route
          exact
          path="/return-request-successful"
          element={<ReturnRequestSuccessPage />}
        />
        <Route exact path="/my-orders" element={<MyOrders />} />
        <Route exact path="/returns-centre" element={<MyReturns />} />
        {/* </Route> */}
      </Route>

      <Route element={<PersistLogin is_admin={true} />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[ROLES["Admin"], ROLES["Super Admin"]]}
            />
          }
        >
          {/* <Route exact path="/auth/login" element={<AdminLogin />} /> */}

          <Route exact path="/auth/dashboard" element={<Dashboard />} />

          <Route
            exact
            path="/gratitude-admin/legal-notice-details-config"
            element={<LegalNoticeAdmin />}
          />

          <Route
            exact
            path="/gratitude-admin/about-us-details-config"
            element={<AboutUsAdmin />}
          />

          <Route
            exact
            path="/gratitude-admin/return-refund-policy-details-config"
            element={<ReturnRefundPolicyAdmin />}
          />

          <Route
            exact
            path="/gratitude-admin/shipping-policy-details-config"
            element={<ShippingPolicyAdmin />}
          />

          <Route
            exact
            path="/gratitude-admin/master-product-type"
            element={<ProductTypeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-gender"
            element={<GenderConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-season"
            element={<SeasonConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-size"
            element={<SizeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-color"
            element={<ColorConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-occasion-type"
            element={<OccasionTypeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-fabric-type"
            element={<FabricTypeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-wear-type"
            element={<WearTypeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-cloth-type"
            element={<ClothTypeConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/master-tag"
            element={<TagConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/product-generic-details-config"
            element={<ProductConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/product-variant-details-config"
            element={<ProductVariantConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/product-images"
            element={<ProductImage />}
          />

          <Route
            exact
            path="/gratitude-admin/products-inventory"
            element={<Inventory />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-price"
            element={<Pricing />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-orders/undelivered-orders"
            element={<ManageOrders isDelivered={false} />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-orders/delivered-orders"
            element={<ManageOrders isDelivered={true} />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-orders/view-order-details"
            element={<ViewOrderDetailsAdmin />}
          />

          <Route
            exact
            path="/gratitude-admin/shipped-orders/track-orders"
            element={<TrackOrdersConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-users/view-registration-requests"
            element={<UserRegistrationAdminConfig />}
          />

          <Route
            exact
            path="/gratitude-admin/manage-order-returns"
            element={<ManageOrderReturns />}
          />
        </Route>

        {/* <Route
          exact
          path="/gratitude-admin/manage-users/view-newsletter-subscription-list"
          element={<NewsletterSubscriptionAdminConfig />}
        /> */}
      </Route>

      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
