import React from "react";
import { Link } from "react-router-dom";

import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";

const RegistrationSuccessModal = ({ isOtpProcess }) => {
  return (
    <>
      <div className="bg-white w-[80%] md:w-[60%] lg:w-[40%] mx-auto shadow-2xl rounded-md">
        <div className="relative">
          <div className="bg-[#BEC6B5] flex justify-center py-20">
            <AiOutlineCheckCircle size={100} color="white" />
          </div>
          <div className="absolute top-3 right-3 cursor-pointer text-white font-extrabold hover:text-gray-50">
            <Link to="/">
              <AiOutlineClose />
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-center text-center font-tenorsans">
          <div className="text-gray-600 text-xl md:text-2xl pt-4 pb-1">
            Great!
          </div>
          {isOtpProcess ? (
            <div className="text-xs">Thank you for registering with us.</div>
          ) : (
            <>
              <div className="text-xs">
                A link to verify your e-mail address has been sent to your
                e-mail.
              </div>
              <div className="text-xs"> Verify your e-mail.</div>
            </>
          )}

          <div className="flex justify-center pb-10">
            <Link to="/">
              <div className="bg-black hover:bg-opacity-95 cursor-pointer rounded-full mt-5 py-1 lg:py-3 px-3 lg:px-6 flex items-center gap-2 text-white text-xs">
                Start Exploring
                <span>
                  <BsArrowRight />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationSuccessModal;
