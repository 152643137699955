import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PublicFooter from "../../common/publicNavbar/PublicFooter";
import PublicNavbar from "../../common/publicNavbar/LatestNavbar/publicNavbar.component";

import { useSelector, useDispatch } from "react-redux";

import {
  setUserId,
  setIsLogin,
  setUserDetails,
} from "../../features/login/loginSlice";

export function PublicLayout() {
  const location = useLocation();

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.login.userId);
  const isLogin = useSelector((state) => state.login.isLogin);
  const userDetails = useSelector((state) => state.login.userDetails);

  const checkLoggedUser = () => {
    const loggedInUserId = localStorage.getItem("user_id");
    const loggedInUserName = localStorage.getItem("username");
    const loggedInUserFirstName = localStorage.getItem("first_name");
    const loggedInUserLastName = localStorage.getItem("last_name");
    const loggedInUserGender = localStorage.getItem("gender");
    const loggedInUserPhoneNumber = localStorage.getItem("phone_number");

    let userObj = {
      user_id: loggedInUserId || 0,
      username: loggedInUserName,
      first_name: loggedInUserFirstName,
      last_name: loggedInUserLastName,
      gender: loggedInUserGender,
      phone_number: loggedInUserPhoneNumber,
    };

    return userObj;
  };

  useEffect(() => {
    let isMounted = false;
    let checkUser = checkLoggedUser();

    if (checkUser.user_id !== 0) {
      isMounted = true;
    }
    if (isMounted) {
      dispatch(setIsLogin());
      dispatch(setUserId(checkUser.user_id));
      dispatch(setUserDetails(checkUser));
    }

    // if (checkUser.user_id !== 0) {
    //   dispatch(setIsLogin());
    // }
  }, []);

  return (
    <>
      <PublicNavbar />
      <Outlet />
      {/* {location.pathname !== "/" && (
        <>
          <PublicFooter />
        </>
      )} */}
    </>
  );
}
