import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Ripple, initTE } from "tw-elements";

const PricingProductCard = ({
  productObj,
  setShowManagePriceTable,
  setSelectedProductId,
  setSelectedProductName,
}) => {
  initTE({ Ripple });

  const onManageClickHandler = (product_id, product_name) => {
    setSelectedProductId(product_id);
    setSelectedProductName(product_name);
    setShowManagePriceTable((prev) => !prev);
  };

  return (
    <>
      <div class="block rounded-lg bg-[#4F46E5] p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div className="flex justify-between">
          <div>
            <p class="mb-2 text-lg font-medium leading-tight text-white">
              {productObj.product_name}
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="text-xs text-white">Available fabrics:</div>
          <div className="flex">
            {productObj.fabric_details?.map((fabricobj) => {
              return (
                <div className="text-xs text-white ml-1">
                  {fabricobj?.fabric_type_name}
                </div>
              );
            })}
          </div>
        </div>

        <button
          type="button"
          class="inline-block rounded bg-white hover:bg-slate-100 px-3 mt-4 py-1 text-xs font-medium leading-normal text-[#4F46E5] shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={() =>
            onManageClickHandler(productObj.product_id, productObj.product_name)
          }
        >
          Manage Prices
        </button>
      </div>
    </>
  );
};

export default PricingProductCard;
