import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import axios from "../../../api/axios";
import {
  BILLING_DETAILS_CONFIG_URL,
  ORDER_DETAILS_CONFIG_URL,
  CART_DETAILS_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../api/api_routing_urls";

import {
  setCartQuantity,
  resetCartQuantity,
} from "../../../features/cartQuantity/cartQuantitySlice";

import Input2 from "../../../reusable-components/inputs/InputTextBox/Input2";
import InputCheckbox from "../../../reusable-components/inputs/InputCheckBox/InputCheckbox";
import Spinner from "../../../reusable-components/spinner/spinner.component";
import DeleteModal from "../../../reusable-components/modals/DeleteModal";
import BillingAddressCard from "../../../reusable-components/billing-address-card/billingAddressCard.component";
import OrderSuccessPage from "./order_success_page.component";
import BillingDetailsForm from "./billing_details_form.component";
import moment from "moment";

import rsaEncryption from "../../../utilities/rsaFunction/rsaEncryption";

const Checkout = ({ cartList, setShowCheckoutPage }) => {
  // const user_id = 1;

  const dispatch = useDispatch();

  const { rsaEncrypt } = rsaEncryption;

  const auth = useSelector((state) => state.authProvider.auth);

  const user_id = auth?.user_id;

  // console.log({ user_id });

  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  // console.log({ cartQuantity });

  const deliverycharge = 100;
  const availablePincodes = [737101, 737102, 737135];

  const [cartTotal, setCartTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const [billingDetailsList, setBillingDetailsList] = useState();
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBillingId, setSelectedBillingId] = useState(0);

  const [showDelete, setShowDelete] = useState(false);
  const [billingDetailsDeleteId, setBillingDetailsDeleteId] = useState(null);

  const [enteredPincode, setEnteredPincode] = useState(0);
  const [pincodeErrMsg, setPincodeErrMsg] = useState("");
  const [showPlaceOrderButton, setShowPlaceOrderButton] = useState(false);
  const [showOrderSuccessPage, setShowOrderSuccessPage] = useState(false);

  const [processingPaymentMsg, setProcessingPaymentMsg] = useState(false);

  const [editBillingAddressDetails, setEditBillingAddressDetails] = useState(
    {}
  );

  const handlePincodeSubmit = () => {
    // console.log("Received pincode: ", enteredPincode);

    const pinCode =
      availablePincodes.filter(
        (filterObj) => +filterObj === +enteredPincode
      )[0] || null;

    if (pinCode !== null) {
      setShowPlaceOrderButton(true);
    } else {
      setPincodeErrMsg(true);
    }
  };

  const isEdit = Object.keys(editBillingAddressDetails)?.length > 0;

  const defaultValues = {
    username: " ",
    password: " ",
    first_name: !isEdit ? "" : editBillingAddressDetails?.first_name,
    last_name: !isEdit ? "" : editBillingAddressDetails?.last_name,
    address_line1: !isEdit ? "" : editBillingAddressDetails?.address_line1,
    address_line2: !isEdit ? "" : editBillingAddressDetails?.address_line2,
    landmark: !isEdit ? "" : editBillingAddressDetails?.landmark,
    city_name: !isEdit ? "" : editBillingAddressDetails?.city_name,
    state_name: !isEdit ? "" : editBillingAddressDetails?.state_name,
    pincode: !isEdit ? "" : editBillingAddressDetails?.pincode,
    user_email: !isEdit ? "" : editBillingAddressDetails?.user_email,
    phone_number: !isEdit ? "" : editBillingAddressDetails?.phone_number,
  };

  const getBillingDetailsList = async (user_id) => {
    try {
      const response = await axios.get(BILLING_DETAILS_CONFIG_URL, {
        params: {
          user_id,
        },
      });
      // console.log({ response });
      response.status === 200 &&
        setBillingDetailsList(response?.data?.billingDetailsList);
      setSelectedBillingId(response?.data?.billingDetailsList[0]?.billing_id);
      response.status === 202 &&
        console.log(
          "Billing Details List details not found in the system.",
          "error"
        );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  const calculateTotal = () => {
    let ctotal = 0;
    let ototal = 0;
    cartList?.map((mapObj) => {
      ctotal =
        ctotal +
        mapObj.quantity *
          (mapObj?.product_rate -
            (mapObj?.discount_percentage / 100) * mapObj?.product_rate);
    });
    ototal = ototal + ctotal + deliverycharge;
    setCartTotal(ctotal);
    setOrderTotal(ototal);
  };

  const onBillingClickHandler = (selectedId) => {
    setSelectedBillingId(selectedId);
    // console.log(
    //   "Selected Billing Address Details (selectedBillingId)",
    //   selectedBillingId
    // );
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: defaultValues,
  });

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", billingDetailsDeleteId);
    try {
      let response = "";
      if (billingDetailsDeleteId) {
        response = await axios.post(`${BILLING_DETAILS_CONFIG_URL}/delete`, {
          user_id: user_id,
          billing_id: billingDetailsDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        console.log(
          "User Billing details has been deleted successfully.",
          "success"
        );
        getBillingDetailsList(user_id);
      } else {
        console.log("User Billing details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setBillingDetailsDeleteId(null);
    }
  };

  const onClickEdit = (billingObj) => {
    // console.log("billingObj received inside Edit: ", billingObj);
    setEditBillingAddressDetails(billingObj);
    setShowAddAddressForm(true);
  };

  const onClickAdd = () => {
    setEditBillingAddressDetails({});
    setShowAddAddressForm(true);
  };

  const onSubmitForm = async (data) => {
    try {
      // console.log({ data });

      let sendDataObj = {
        user_id: user_id,
        first_name: data.first_name,
        last_name: data.last_name,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        landmark: data.landmark,
        city_name: data.city_name,
        state_name: data.state_name,
        pincode: data.pincode,
        user_email: data.user_email,
        phone_number: data.phone_number,
      };

      // console.log({ sendDataObj });

      let response = "";
      if (!isEdit) {
        // console.log("inside ADD function");
        response = await axios.post(BILLING_DETAILS_CONFIG_URL, sendDataObj);
        // console.log({ response });
      } else {
        // console.log("inside UPDATE function");
        sendDataObj.billing_id = editBillingAddressDetails?.billing_id;

        // console.log({ sendDataObj });

        response = await axios.post(
          `${BILLING_DETAILS_CONFIG_URL}/update`,
          sendDataObj
        );
        // console.log({ response });
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditBillingAddressDetails({});
          console.log(
            "User Billing details has been updated successfully.",
            "success"
          );
        } else {
          console.log(
            "User Billing details has been added successfully.",
            "success"
          );
        }
        getBillingDetailsList(user_id);
      } else {
        if (isEdit) {
          console.log("Save User Billing Details Error:", response.data);
        } else {
          if (
            response.data.saveBillingDetails.includes("unique_billing_details")
          ) {
            console.log(
              "Data already exists. Cannot insert duplicate data.",
              "error"
            );
          } else {
            console.log(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
              "error"
            );
          }
        }
        return;
      }
      setShowAddAddressForm(false);
      reset();
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RSA_KEY_URL);
      if (response?.data?.status === 201) {
        const key_id = {
          Public_Key: response?.data?.publicKey,
          Session_Uuid: response?.data?.session_uuid,
        };
        return { key_id };
        // console.log(response?.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestOrderPaymentAPI = async (ORDERNO, amount) => {
    // console.log("Inside requestOrderAPI");
    try {
      const { key_id } = await getPublicKeyRsa();

      console.log({ key_id });

      // Encryption of sensitive data

      const params = {
        order_id: rsaEncrypt(ORDERNO, key_id.Public_Key),
        amount: rsaEncrypt(amount, key_id.Public_Key),
        sessionUuid: key_id.Session_Uuid,
      };

      const response = await axios.post(
        "/ccavRequestHandler",
        params // Sending the generated query string directly as part of the request
      );
      console.log("Response:", response.data);

      // Render the response HTML into an element on the page
      const responseContainer = document.createElement("div");
      responseContainer.innerHTML = response.data;

      // Append the form to the document body
      document.body.appendChild(responseContainer.firstChild);

      // Submit the form to redirect to the payment gateway
      document.getElementById("nonseamless").submit();
    } catch (error) {
      console.error("ccavRequestHandler", error);
    }
  };

  const onPlaceOrderClickHandler = async () => {
    setProcessingPaymentMsg(true);
    try {
      let payment_response = "";
      const timestamp = moment().format("YYYYMMDDHHmmss");
      const ORDERNO = `${user_id}${timestamp}`;

      // console.log("cartList", cartList);
      let prd_details = [];

      cartList?.map((mapObj) => {
        let prd = {
          cart_id: mapObj.cart_id,
          product_id: mapObj.product_details.product_id,
          product_name: mapObj.product_details.product_name,
          product_rate: mapObj.product_rate,
          discount_percentage: mapObj.discount_percentage,
          size_variety_id: mapObj.product_details.size_variety_id,
          size_name: mapObj.size_name,
          quantity: mapObj.quantity,
          amount_paid: orderTotal,
        };

        prd_details.push(prd);
      });

      if (
        orderTotal <= 0 ||
        !orderTotal ||
        orderTotal === undefined ||
        orderTotal === null
      ) {
        return;
      }

      // console.log("prd_details", prd_details);

      let orderDataObj = {
        user_id: user_id,
        billing_id: selectedBillingId,
        payment_mode: "Online",
        product_details: JSON.stringify(prd_details),
        order_no: ORDERNO,
        order_total: orderTotal,
      };

      // console.log({ orderDataObj });

      let response = "";

      response = await axios.post(ORDER_DETAILS_CONFIG_URL, orderDataObj);

      if (response.status === 200) {
        // console.log("Order has been placed successfully.");
        dispatch(resetCartQuantity(0));
        getCartItemsQuantityDetails(user_id);

        //Payment Integration Here
        //#region Payment

        await requestOrderPaymentAPI(
          ORDERNO,
          parseFloat(orderTotal).toFixed(2)
        );

        //#endregion
        // setShowOrderSuccessPage(true);
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  //useEffect to fetch the total items in cart of a logged-in user
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user_id) {
      getCartItemsQuantityDetails(user_id);
    }

    return () => {
      isMounted = false;
    };
  }, [user_id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  //useEffect to fetch billing details for the particular user id
  useEffect(() => {
    let isMounted = true;
    if (isMounted && user_id) {
      getBillingDetailsList(user_id);
    }
    return () => {
      isMounted = false;
    };
  }, [user_id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      calculateTotal();
      setEditBillingAddressDetails({});
      setBillingDetailsDeleteId(null);
      // setSelectedBillingId(0);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {showOrderSuccessPage ? (
        <div>
          <OrderSuccessPage />
        </div>
      ) : (
        <div className="font-tenorsans">
          <div className="grid grid-cols-2 md:grid-cols-3 items-center">
            <div className="md:col-start-2 flex justify-start md:justify-center text-sm md:text-lg lg:text-xl py-4">
              Checkout
            </div>
            <div
              onClick={() => setShowCheckoutPage(false)}
              className="flex justify-end lg:justify-center text-[10px] md:text-xs text-black uppercase cursor-pointer py-4"
            >
              Back to Cart
            </div>
          </div>

          <div className="">
            <section className="py-10">
              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8">
                <div className="col-span-2">
                  <div class="relative flex items-center mb-2">
                    {/* <div class="flex-grow border-t border-gray-400"></div> */}
                    <span class="flex-shrink mr-4 text-sm lg:text-base uppercase">
                      Billing Details
                    </span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>
                  <>
                    <div>
                      {billingDetailsList?.length > 0 &&
                      showAddAddressForm === false ? (
                        <>
                          <div className="uppercase text-xs lg:text-sm py-2 lg:py-3">
                            Select a billing address from below:
                          </div>
                          <div>
                            {billingDetailsList?.map((mapObj) => (
                              <div className="my-3 lg:my-5">
                                <BillingAddressCard
                                  billing_id={mapObj.billing_id}
                                  first_name={mapObj.first_name}
                                  last_name={mapObj.last_name}
                                  address_line1={mapObj.address_line1}
                                  address_line2={mapObj.address_line2}
                                  landmark={mapObj.landmark}
                                  city_name={mapObj.city_name}
                                  state_name={mapObj.state_name}
                                  pincode={mapObj.pincode}
                                  country_name="India"
                                  phone_number={mapObj.phone_number}
                                  onBillingClickHandler={onBillingClickHandler}
                                  selectedBillingId={selectedBillingId}
                                  onClickEdit={onClickEdit}
                                  setBillingDetailsDeleteId={
                                    setBillingDetailsDeleteId
                                  }
                                  setShowDelete={setShowDelete}
                                  mapObj={mapObj}
                                />
                              </div>
                            ))}
                            <div
                              onClick={() => onClickAdd()}
                              className="pt-3 md:pt-6 pb-4 text-xs text-black cursor-pointer uppercase"
                            >
                              + Add New Billing Address
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Add New Billing Details Form start*/}

                          <div className="text-sm py-3">
                            {!isEdit ? (
                              <span className="uppercase">
                                Add a new billing address:
                              </span>
                            ) : (
                              <span className="uppercase">
                                Edit billing address:
                              </span>
                            )}
                          </div>
                          {/* Add New Billing Details Form start*/}
                          <BillingDetailsForm
                            register={register}
                            errors={errors}
                            setError={setError}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            defaultValues={defaultValues}
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmitForm}
                            setShowAddAddressForm={setShowAddAddressForm}
                            setEditBillingAddressDetails={
                              setEditBillingAddressDetails
                            }
                            reset={reset}
                          />
                          {/* Add New Billing Details Form end*/}
                        </>
                      )}
                    </div>
                    <>
                      <DeleteModal
                        open={showDelete}
                        setOpen={setShowDelete}
                        message={
                          "This billing address will be deleted. Are you sure?"
                        }
                        onDelete={onClickDelete}
                      />
                    </>
                  </>
                </div>

                <div className="col-span-1 border mt-8 md:mt-0 px-3 py-4">
                  <div class="relative flex items-center">
                    {/* <div class="flex-grow border-t border-gray-400"></div> */}
                    <span class="flex-shrink pr-3 text-sm lg:text-base">
                      Your Order
                    </span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>
                  <div className="mt-5 flex justify-between border-b border-gray-400">
                    <p className="text-xs lg:text-sm text-gray-700">Product</p>
                    <p className="text-xs lg:text-sm text-gray-700">Total</p>
                  </div>

                  <div className="border-b border-gray-400 pb-5">
                    {cartList?.map((cartObj) => (
                      <div className="mt-5 flex justify-between gap-x-3 lg:gap-x-0">
                        <p className="text-xs lg:text-sm">
                          {cartObj?.product_details?.product_name} (
                          {cartObj.size_name}){" "}
                          <span className="font-bold">x</span>{" "}
                          {cartObj.quantity}
                        </p>
                        <p className="text-xs lg:text-sm">
                          <>
                            {cartObj.discount_percentage === 0 ? (
                              <span>
                                ₹ {cartObj.quantity * cartObj.product_rate}
                              </span>
                            ) : (
                              <span>
                                ₹{" "}
                                {cartObj.quantity *
                                  (cartObj?.product_rate -
                                    (cartObj?.discount_percentage / 100) *
                                      cartObj?.product_rate)}
                              </span>
                            )}
                          </>
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="pt-2 pb-3 flex justify-between border-b border-gray-400">
                    <p className="text-xs lg:text-sm text-gray-700">
                      Cart Subtotal
                    </p>
                    <p className="text-xs lg:text-sm">₹ {cartTotal}</p>
                  </div>
                  <div className="pt-2 pb-3 flex justify-between border-b border-gray-400">
                    <p className="text-xs lg:text-sm text-gray-700">
                      Delivery Charges
                    </p>
                    <p className="text-xs lg:text-sm">₹ {deliverycharge}</p>
                  </div>
                  <div className="text-xs text-red-700 pt-3 pb-8">
                    Delivery fee is non-refundable.
                  </div>
                  <div className="pt-2 pb-3 flex justify-between">
                    <p className="text-xs lg:text-sm text-gray-700">
                      Order Total
                    </p>
                    <p className="text-xs lg:text-sm">₹ {orderTotal}</p>
                  </div>
                  {/* <div class="relative flex items-center pt-4">
        
                    <span class="flex-shrink pr-3 text-sm">Payment Mode</span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div> */}
                  {/* <div className="pt-2 pb-3 border-b border-gray-400">
                    <InputCheckbox
                      defaultName="pod"
                      register={register}
                      name="Pay on Delivery"
                      required={false}
                      pattern={null}
                      errors={errors}
                      placeholder=""
                      setError={setError}
                      clearError={clearErrors}
                      autoComplete="off"
                      type="checkbox"
                      classes={`rounded shadow-lg text-xs lg:text-sm mr-2 cursor-pointer focus:outline-none focus:ring-0 focus:border-0`}
                      onChangeInput={setTest}
                      defaultValue={test}
                    />
                  </div> */}
                  {/* {showPlaceOrderButton ? (
                    <>
                      <p className="text-xs mt-10 text-gray-700">
                        Hurray! We are delivering to this pincode. Click on the
                        button below to place order.
                      </p>
                      <div
                        onClick={() => onPlaceOrderClickHandler()}
                        className="mt-4 flex justify-center cursor-pointer bg-white text-black text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
                      >
                        Place Order
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-10 text-xs lg:text-sm">
                        Enter your area pincode to check delivery feasibility
                      </div>

                      <form>
                        <label for="zipcode"></label>
                        <input
                          type="number"
                          defaultValue={0}
                          id="zipcode"
                          name="zipcode"
                          onChange={(e) => setEnteredPincode(e.target.value)}
                          className="w-full mt-2 focus:ouline-none focus:ring-0 focus:border focus:border-black text-xs lg:text-sm"
                        ></input>

                        <button
                          type="button"
                          onClick={() => handlePincodeSubmit(enteredPincode)}
                          className="mt-4 w-full bg-black text-white text-xs text-center border border-black py-2 uppercase"
                        >
                          Submit
                        </button>
                      </form>

                      {pincodeErrMsg && (
                        <p className="text-xs mt-5 text-red-700 text-center">
                          Sorry! We are currently not delivering to this
                          pincode.
                        </p>
                      )}
                    </>
                  )} */}

                  <>
                    <p className="text-xs mt-10 text-gray-700">
                      {/* Hurray! We are delivering to this pincode. */}
                      Click on the button below to proceed with payment.
                    </p>

                    {!processingPaymentMsg ? (
                      <div
                        onClick={() => onPlaceOrderClickHandler()}
                        className="mt-4 flex justify-center cursor-pointer bg-white text-black text-xs border-[0.1px] border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 px-3 py-2 lg:px-5 lg:py-3 uppercase"
                      >
                        Make Payment
                      </div>
                    ) : (
                      <div className="mt-4 flex gap-x-1 justify-center items-center bg-black text-white rounded-sm px-3 py-1">
                        <div className="flex justify-center text-xs uppercase">
                          Processing
                        </div>

                        <div className="pl-1">
                          <Spinner />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
