import axios from "../../api/axios";
// import useAuth from "./useAuth";

import { useDispatch } from "react-redux";

import { setAuth } from "../../features/authProvider/authProviderSlice";
// } from "../../../features/authProvider/authProviderSlice";

const useRefreshToken = () => {
  // const { auth, setAuth, persist } = useAuth();
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      const response = await axios.get("/refresh", {
        withCredentials: true,
      });

      // console.log("auth on refresh", response.data);

      dispatch(
        setAuth({
          username: response.data.userdetails.username,
          accessToken: response.data.userdetails.accessToken,
          user_id: response.data.userdetails.user_id,
          first_name: response.data.userdetails.first_name,
          last_name: response.data.userdetails.last_name,
          gender: response.data.userdetails.gender,
          phone_number: response.data.userdetails.phone_number,
          roleid: response.data.userdetails.roleid,
          rolename: response.data.userdetails.rolename,
        })
      );

      return response.data.userdetails.accessToken;
    } catch (error) {
      return "";
    }
  };
  return refresh;
};

export default useRefreshToken;
