import React, { useState } from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

import { CLOTH_TYPE_CONFIG_URL } from "../../../../api/api_routing_urls";
import axios from "../../../../api/axios";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../../theme/lightTheme";

export default function ClothTypeList({
  clothTypeList,
  getClothTypeList,
  setEditClothTypeDetails,
  setShowAddForm,
}) {
  const onClickEdit = (clothType) => {
    setEditClothTypeDetails(clothType);
    setShowAddForm(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [clothTypeDeleteId, setClothTypeDeleteId] = useState(null);

  const onClickDelete = async () => {
    // console.log("Received ID to delete: ", clothTypeDeleteId);
    try {
      let response = "";
      if (clothTypeDeleteId) {
        response = await axios.post(`${CLOTH_TYPE_CONFIG_URL}/delete`, {
          cloth_type_id: clothTypeDeleteId,
        });
        setShowDelete(false);
      }

      if (response.status === 200) {
        showToast(
          "Cloth Type details has been deleted successfully.",
          "success"
        );
        getClothTypeList();
      } else {
        showToast("Cloth Type details deletion failed.", "error");
      }
    } catch (error) {
      console.log("Delete Module Error", error);
    } finally {
      setClothTypeDeleteId(null);
    }
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            Wear Type ID
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            Cloth Type Name
          </th>
          <th
            scope="col"
            className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
        {clothTypeList?.length > 0 &&
          clothTypeList?.map((clothType, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {clothType.wear_type_id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {clothType.cloth_type_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(clothType)}
                    aria-label="Edit Cloth Type Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      setClothTypeDeleteId(clothType?.cloth_type_id || null);
                      setShowDelete(true);
                    }}
                    aria-label="Delete Cloth Type Details"
                    className={linkTextDanger}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>

      <>
        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"This entry will be deleted. Are you sure?"}
          onDelete={onClickDelete}
        />
      </>
    </TableWrapper>
  );
}
