import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { MdAddCircleOutline } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";

import {
  PRODUCT_GENERIC_DETAILS_CONFIG_URL,
  PRODUCT_TYPE_CONFIG_URL,
  GENDER_CONFIG_URL,
  SEASON_CONFIG_URL,
  OCCASION_TYPE_CONFIG_URL,
  WEAR_TYPE_CONFIG_URL,
  CLOTH_TYPE_CONFIG_URL,
  TAG_CONFIG_URL,
} from "../../../api/api_routing_urls";
// import useAuth from "../../../../authentication/hooks/useAuth";
// import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import axios from "../../../api/axios";
import Dashboard from "../../../common/dashboard-components/dashboard.component";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import showToast from "../../../utilities/notification/NotificationModal";

import AddProductForm from "./addProductForm.component";
import ProductList from "./productList.component";

const ProductConfig = () => {
  // const { auth } = useAuth();
  const [productWithGenericDetailsList, setProductWithGenericDetailsList] =
    useState([]);
  // const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [editProductGenericDetails, setEditProductGenericDetails] = useState(
    {}
  );

  const [productTypeDD, setProductTypeDD] = useState([]);
  const [genderDD, setGenderDD] = useState([]);
  const [seasonDD, setSeasonDD] = useState([]);
  const [wearTypeDD, setWearTypeDD] = useState([]);
  const [clothTypeDD, setClothTypeDD] = useState([]);
  const [occasionTypeDD, setOccasionTypeDD] = useState([]);
  const [tagDD, setTagDD] = useState([]);

  const getProductTypeList = async () => {
    try {
      const response = await axios.get(PRODUCT_TYPE_CONFIG_URL);
      // console.log("Product Type List", { response });
      let productTypeDD = [];
      if (response.status === 200) {
        response?.data?.productTypeList?.map((productTypeObj) => {
          productTypeDD.push({
            label: productTypeObj.product_type_name,
            value: productTypeObj.product_type_id,
          });
        });
        setProductTypeDD(productTypeDD);
        // console.log("productTypeDD", productTypeDD);
      }

      response.status === 202 &&
        showToast("No product type found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getProductTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getGenderList = async () => {
    try {
      const response = await axios.get(GENDER_CONFIG_URL);
      // console.log("Gender List", { response });
      let genderDD = [];
      if (response.status === 200) {
        response?.data?.genderList?.map((genderObj) => {
          genderDD.push({
            label: genderObj.gender_name,
            value: genderObj.gender_id,
          });
        });
        setGenderDD(genderDD);
        // console.log("genderDD", genderDD);
      }

      response.status === 202 &&
        showToast("No gender config found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getGenderList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getSeasonList = async () => {
    try {
      const response = await axios.get(SEASON_CONFIG_URL);
      // console.log("Season List", { response });
      let seasonDD = [];
      if (response.status === 200) {
        response?.data?.seasonList?.map((seasonObj) => {
          seasonDD.push({
            label: seasonObj.season_name,
            value: seasonObj.season_id,
          });
        });
        setSeasonDD(seasonDD);
        // console.log("seasonDD", seasonDD);
      }

      response.status === 202 &&
        showToast("No season config found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getSeasonList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getWearTypeList = async () => {
    try {
      const response = await axios.get(WEAR_TYPE_CONFIG_URL);
      // console.log("Wear Type List", { response });
      let wearTypeDD = [];
      if (response.status === 200) {
        response?.data?.wearTypeList?.map((wearTypeObj) => {
          wearTypeDD.push({
            label: wearTypeObj.wear_type_name,
            value: wearTypeObj.wear_type_id,
          });
        });
        setWearTypeDD(wearTypeDD);
        // console.log("wearTypeDD", wearTypeDD);
      }

      response.status === 202 &&
        showToast("No wear type found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getWearTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getClothTypeList = async () => {
    try {
      const response = await axios.get(CLOTH_TYPE_CONFIG_URL);
      // console.log("Cloth Type List", { response });
      let clothTypeDD = [];
      if (response.status === 200) {
        response?.data?.clothTypeList?.map((clothTypeObj) => {
          clothTypeDD.push({
            label: clothTypeObj.cloth_type_name,
            value: clothTypeObj.cloth_type_id,
            wear_type_id: clothTypeObj.wear_type_id,
          });
        });
        setClothTypeDD(clothTypeDD);
        // console.log("clothTypeDD", clothTypeDD);
      }

      response.status === 202 &&
        showToast("No cloth type found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getClothTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getOccasionTypeList = async () => {
    try {
      const response = await axios.get(OCCASION_TYPE_CONFIG_URL);
      // console.log("Occasion Type List", { response });
      let occasionTypeDD = [];
      if (response.status === 200) {
        response?.data?.occasionTypeList?.map((occasionTypeObj) => {
          occasionTypeDD.push({
            label: occasionTypeObj.occasion_type_name,
            value: occasionTypeObj.occasion_type_id,
          });
        });
        setOccasionTypeDD(occasionTypeDD);
        // console.log("occasionTypeDD", occasionTypeDD);
      }

      response.status === 202 &&
        showToast("No occasion type found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getOccasionTypeList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const getTagList = async () => {
    try {
      const response = await axios.get(TAG_CONFIG_URL);
      // console.log(Tag List", { response });
      let tagDD = [];
      if (response.status === 200) {
        response?.data?.tagList?.map((tagObj) => {
          tagDD.push({
            label: tagObj.tag_name,
            value: tagObj.tag_id,
          });
        });
        setTagDD(tagDD);
        // console.log("tagDD", tagDD);
      }

      response.status === 202 &&
        showToast("No tags found in the system.", "error");
      return [];
    } catch (error) {
      console.error("getTagList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
    return [];
  };

  const isEdit =
    Object.keys(editProductGenericDetails).length > 0 ? true : false;

  const getProductWithGenericDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_GENERIC_DETAILS_CONFIG_URL);
      // console.log("Product with generic details List", { response });
      response.status === 200 &&
        setProductWithGenericDetailsList(
          response.data.productsWithGenericDetailsList
        );
      response.status === 202 &&
        showToast("No products found in the system.", "error");
    } catch (error) {
      console.error("getProductWithGenericDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithGenericDetailsList();
      getProductTypeList();
      getGenderList();
      getSeasonList();
      getWearTypeList();
      getClothTypeList();
      getOccasionTypeList();
      getTagList();
      setEditProductGenericDetails({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <Dashboard sidebarType={auth.rolename}>
    <Dashboard>
      <ToastContainer />
      {showAddForm ? (
        <div className="flex flex-col mb-10">
          <div className="flex gap-2 items-center pb-5">
            <div className="">
              {isAdd ? (
                <MdAddCircleOutline size={18} color="green" />
              ) : (
                <RxUpdate size={17} color="blue" />
              )}
            </div>
            <div className="text-slate-700 font-semibold">
              {isAdd ? <>Add Product Details</> : <>Update Product Details</>}
            </div>
          </div>

          <AddProductForm
            getProductWithGenericDetailsList={getProductWithGenericDetailsList}
            editProductGenericDetails={editProductGenericDetails}
            setEditProductGenericDetails={setEditProductGenericDetails}
            setShowAddForm={setShowAddForm}
            setIsAdd={setIsAdd}
            productTypeDD={productTypeDD}
            genderDD={genderDD}
            seasonDD={seasonDD}
            wearTypeDD={wearTypeDD}
            clothTypeDD={clothTypeDD}
            occasionTypeDD={occasionTypeDD}
            tagDD={tagDD}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1">
          <ProductList
            productWithGenericDetailsList={productWithGenericDetailsList}
            getProductWithGenericDetailsList={getProductWithGenericDetailsList}
            setEditProductGenericDetails={setEditProductGenericDetails}
            setShowAddForm={setShowAddForm}
            setIsAdd={setIsAdd}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default ProductConfig;
