//Old Loader

// import React from "react";
// import "./animated_loader.styles.css";

// const AnimatedLoader = () => {
//   return (
//     <div className="flex flex-col items-center justify-center -mt-28 gap-y-1">
//       <div class="loader"></div>
//       <p className="pl-2 text-sm lg:text-base font-yantramanav uppercase">
//         Loading...
//       </p>
//     </div>
//   );
// };

// export default AnimatedLoader;

//New Loader

import React from "react";
import "./animated_loader.styles.css";

const AnimatedLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2">
      <div className="text-xs lg:text-sm font-yantramanav uppercase font-medium tracking-wide">
        Loading
      </div>
      <div className="bar h-[1.5px] w-[0.5rem] bg-gray-400" id="bar1"></div>
    </div>
  );
};

export default AnimatedLoader;
