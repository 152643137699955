import React, { useState } from "react";
import Button from "../../../reusable-components/buttons/Button";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../theme/lightTheme";

import { USER_REGISTRATION_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import showToast from "../../../utilities/notification/NotificationModal";
import { linkTextDanger } from "../../../theme/lightTheme";

export default function UserRegistrationAdminList({
  userRegistrationRequestList,
  getUserRegistrationRequestList,
}) {
  const onActivateClickHandler = async (userObj) => {
    try {
      let response = "";
      let sendDataObj = {
        user_uuid: userObj?.user_uuid,
        user_email_address: userObj?.user_email_address,
        user_password: userObj?.user_password,
      };

      response = await axios.post(
        `${USER_REGISTRATION_CONFIG_URL}/update`,
        sendDataObj
      );

      // console.log({ response });

      if (response.status === 200) {
        showToast("The user has been activated successfully.", "success");
        getUserRegistrationRequestList();
      } else {
        showToast("User Activation updation error.", "error");
      }
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            Sl No.
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            User Email
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-gray-900 uppercase tracking-wider"
          >
            User Unique ID
          </th>
          <th
            scope="col"
            className="pl-6 pr-9 py-3 text-right font-medium text-gray-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 text-gray-600">
        {userRegistrationRequestList?.length > 0 ? (
          userRegistrationRequestList?.map((userObj, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {index + 1}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {userObj?.user_email_address}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {userObj?.user_uuid}
              </td>
              <td className="pl-6 pr-9 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onActivateClickHandler(userObj)}
                    aria-label="Confirm User Registration"
                    className={linkTextPrimary}
                  >
                    Activate
                  </Button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <>
            <tr className="bg-slate-50">
              <td
                colSpan={4}
                className="text-center text-xs font-semibold py-3"
              >
                No new registration requests.
              </td>
            </tr>
          </>
        )}
      </tbody>
    </TableWrapper>
  );
}
