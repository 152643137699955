import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import axios from "../../../api/axios";
import { ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";

import { BsCalendarCheck } from "react-icons/bs";

import { RxReader } from "react-icons/rx";
import { BsCalendar3 } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { GiPriceTag } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import { FaSignal } from "react-icons/fa";
import { GrView } from "react-icons/gr";

import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";

import ViewReturnDetailsAdmin from "./viewReturnDetailsAdmin.component";

const ManageOrderReturns = () => {
  let curDate = new Date().toLocaleDateString("en-GB");

  const [orderReturnDetailsAdminList, setOrderReturnDetailsAdminList] =
    useState([]);
  const [viewReturnDetailsPage, setViewReturnDetailsPage] = useState(false);
  const [selectedOrderReturn, setSelectedOrderReturn] = useState();

  const getOrderReturnDetailsAdminList = async () => {
    try {
      const response = await axios.get(ORDER_RETURN_DETAILS_ADMIN_CONFIG_URL);
      // console.log("Order Return List for Admin", { response });
      response.status === 200 &&
        setOrderReturnDetailsAdminList(
          response.data.orderReturnDetailsAdminList
        );
      response.status === 202 &&
        showToast("No orders found in the system.", "error");
    } catch (error) {
      console.error("getOrderReturnDetailsAdminList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const onViewClickHandler = (returnDetailsObj) => {
    setViewReturnDetailsPage(true);
    setSelectedOrderReturn(returnDetailsObj);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOrderReturnDetailsAdminList();
      setViewReturnDetailsPage(false);
      setSelectedOrderReturn();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard>
        <ToastContainer />
        <div className="flex justify-between  pb-5">
          <div className="font-bold text-lg">Order Returns</div>
          <div className="flex gap-2 items-center justify-center">
            <BsCalendarCheck color="black" size={16} />
            <div className="font-semibold">{curDate}</div>
          </div>
        </div>

        {!viewReturnDetailsPage ? (
          <>
            <div className="pt-10">
              <table className="w-full">
                <thead>
                  <tr className="bg-slate-200">
                    <th className="py-5 px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <RxReader />
                        </div>
                        <div className="font-light">Order No</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <BsCalendar3 />
                        </div>
                        <div className="font-light">Order Date</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <AiOutlineUser />
                        </div>
                        <div className="font-light">Customer Name</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <GiPriceTag />
                        </div>
                        <div className="font-light">Return Initiated Date</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <FaSignal />
                        </div>
                        <div className="font-light">Return Status</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <MdOutlinePayment />
                        </div>
                        <div className="font-light">Refund Status</div>
                      </div>
                    </th>
                    <th className="px-6">
                      <div className="flex gap-1 items-center">
                        <div>
                          <GrView />
                        </div>
                        <div className="font-light">View Details</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderReturnDetailsAdminList?.map((returnObj, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-5 px-6 text-sm font-bold">
                        {returnObj?.order_no}
                      </td>
                      <td className="px-6 text-sm">
                        {getFormattedDateDDMMMYYYYDate(returnObj?.order_date)}
                      </td>
                      <td className="px-6 text-sm">
                        {returnObj?.ordered_by.fname}{" "}
                        {returnObj?.ordered_by.lname}
                      </td>
                      <td className="px-6 text-sm text-left">
                        {getFormattedDateDDMMMYYYYDate(
                          returnObj?.returning_items[0].return_initiated_date
                        )}
                      </td>
                      <td className="px-6 text-sm">
                        {returnObj?.returning_items[0].return_status}
                      </td>
                      <td className="px-6 text-sm">
                        {returnObj?.returning_items[0].refund_status}
                      </td>
                      <td
                        onClick={() => onViewClickHandler(returnObj)}
                        className="px-6 cursor-pointer text-sm text-black hover:underline"
                      >
                        View Return Details
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div>
              <ViewReturnDetailsAdmin
                selectedOrderReturn={selectedOrderReturn}
                setSelectedOrderReturn={setSelectedOrderReturn}
                setViewReturnDetailsPage={setViewReturnDetailsPage}
                getOrderReturnDetailsAdminList={getOrderReturnDetailsAdminList}
              />
            </div>
          </>
        )}
      </Dashboard>
    </>
  );
};

export default ManageOrderReturns;
