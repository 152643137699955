import React from "react";
import "./animatedHamburger.styles.css";

const AnimatedHamburger = () => {
  const toggleActive = () => {
    document.querySelector(".burger").classList.toggle("active");
  };

  return <button className="burger noSelect" onClick={toggleActive}></button>;
};

export default AnimatedHamburger;
