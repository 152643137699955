import React, { useState, useCallback } from "react";

import { Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { DropzoneDiv } from "./imageDropzone.styles";

import { ImFilesEmpty } from "react-icons/im";

const ImageDropzone = ({ fieldTitle, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [imgPreview, setImgPreview] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("File received inside dropzone component: ", acceptedFiles);

    if (acceptedFiles) {
      let fileArr = [];
      onChange(acceptedFiles);
      acceptedFiles.map((mapObj) => {
        fileArr.push(URL.createObjectURL(mapObj));
      });

      setImgPreview(fileArr);
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: "image/*",
  });

  return (
    <>
      <div className="font-semibold text-sm pt-6 pb-2">{fieldTitle}</div>
      <DropzoneDiv {...getRootProps()}>
        <input {...getInputProps()} />
        {imgPreview.length > 0 ? (
          imgPreview.map((mapObj) => {
            return <img src={mapObj} className="px-2" />;
          })
        ) : loading ? (
          <Spinner variant="standard" animation="border" role="status" />
        ) : (
          <div className="flex flex-col">
            <div className="pb-16 flex justify-center">
              <ImFilesEmpty color="black" size={50} />
            </div>
            <p className="cursor-pointer font-bold text-black">
              Drag and drop file(s) to upload
            </p>
            <p className="font-normal text-gray-600 pb-3 flex justify-center">
              or use the "Upload" button.
            </p>
            <div className="pb-3 flex justify-center">
              <div className="px-5 py-2 bg-black text-white rounded-sm cursor-pointer">
                Upload
              </div>
            </div>
          </div>
        )}
      </DropzoneDiv>
    </>
  );
};

export default ImageDropzone;
