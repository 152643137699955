import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useEffect } from "react";

export default function CookieModal({ title, message, setIsCookieModalOpen }) {
  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    localStorage.setItem("CookieModal", "true");
    setIsCookieModalOpen(false);
  }

  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment} onClose={() => false}>
        <Dialog as="div" className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex md:flex-col min-h-full items-end md:items-start justify-end md:justify-end text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-sm max-w-md transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center bg-gray-100">
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-sm lg:text-lg font-medium leading-6 text-black uppercase py-2 px-3"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                    <div className="px-2 cursor-pointer" onClick={closeModal}>
                      <GrFormClose size={20} />
                    </div>
                  </div>

                  <div className="mt-2">
                    <p className="text-xs lg:text-sm text-black py-3">
                      {message}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="focus:outline-none focus:border-0 focus:ring-0
                      w-full border border-transparent bg-black px-4 py-2 text-xs lg:text-sm text-white font-medium  hover:bg-gray-950 uppercase"
                      onClick={closeModal}
                    >
                      Accept All Cookies
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
