import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ORDER_DETAILS_ADMIN_CONFIG_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";

import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";

import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const UpdateAdminRemarksForm = ({
  editOrderDetails,
  setEditOrderDetails,
  setShowUpdateForm,
  getOrderAdminRemarks,
}) => {
  const defaultValues = {
    admin_remarks: editOrderDetails?.admin_remarks,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let sendDataObj = {
        order_id: editOrderDetails?.order_id,
        admin_remarks: data?.admin_remarks,
      };

      // console.log({ sendDataObj });

      let response = "";
      response = await axios.post(
        `${ORDER_DETAILS_ADMIN_CONFIG_URL}/adminremarksupdate`,
        sendDataObj
      );

      if (response.status === 200) {
        showToast("Admin Remarks updated successfully!", "success");
        getOrderAdminRemarks();
        setShowUpdateForm(false);
      } else {
        showToast("Admin Remarks updation error!", "error");
        return;
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <section>
      <div className="flex flex-col bg-yellow-100 p-4 rounded-md">
        <p className="text-xs font-bold">
          You are updating the Admin Remarks for the Order ID:{" "}
        </p>
        <div className="flex gap-3">
          <p className="text-lg font-semibold">{editOrderDetails.order_id}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="admin_remarks"
          register={register}
          name="Admin Remarks"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter your remarks"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.admin_remarks}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditOrderDetails([]);
              setValue("admin_remarks", "");
              setShowUpdateForm(false);
            }}
            aria-label="Cancel Admin Remarks Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UpdateAdminRemarksForm;
