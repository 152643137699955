import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { axiosJose } from "../../../api/axios";
import {
  ORDER_DETAILS_CONFIG_URL,
  RSA_KEY_URL,
} from "../../../api/api_routing_urls";
import { generateQueryString } from "../../../payment/generateQueryString";
import moment from "moment";
import rsaEncryption from "../../../utilities/rsaFunction/rsaEncryption";
import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";

const PaymentUATEnv = () => {
  const [orderNo, setOrderNo] = useState();
  const [refNo, setRefNo] = useState();
  const [amount, setAmount] = useState(parseFloat(1).toFixed(2));
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState({});

  const { rsaEncrypt } = rsaEncryption;

  const navigate = useNavigate();

  const BASE_URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_ENDPOINT_URL
      : process.env.REACT_APP_ENDPOINT_URL_ONLINE;

  const [paymentRequest, setPaymentRequest] = useState(false);

  const onProceedPaymentClickHandler = async () => {
    try {
      setIsLoading(true);

      let prd_details = [];

      let amt_paid = 1;

      let prd = {
        cart_id: 70,
        product_id: 29,
        product_name: "Oatmeal Coachella Crewneck",
        product_rate: 1,
        discount_percentage: 0,
        size_variety_id: 2,
        size_name: "M",
        quantity: 1,
        amount_paid: 1,
      };

      prd_details.push(prd);

      console.log("prd_details", prd_details);

      let orderDataObj = {
        user_id: 1,
        billing_id: 13,
        payment_mode: "Pay on Delivery",
        product_details: JSON.stringify(prd_details),
      };

      setPaymentRequest((prev) => !prev);

      console.log({ orderDataObj });
    } catch (error) {
      console.error("error", error);
      if (!error?.response) {
        console.log("No Server Response", "error");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  const checkOrderStatus = async () => {
    try {
      const checkOrderObj = {
        reference_no: refNo,
        order_no: orderNo,
      };

      console.log({ checkOrderObj });

      const response = await axios.post("/order-status-tracker", checkOrderObj);
      console.log("Response:", response.data);

      if (response.status === 200) {
        setOrderStatusData(response.data.decResponse);
      }
    } catch (error) {
      console.error("checkOrderStatus", error);
    }
  };

  //for testing payment api calls
  useEffect(() => {
    const getPublicKeyRsa = async () => {
      try {
        const response = await axios.get(RSA_KEY_URL);
        if (response?.data?.status === 201) {
          const key_id = {
            Public_Key: response?.data?.publicKey,
            Session_Uuid: response?.data?.session_uuid,
          };
          return { key_id };
          // console.log(response?.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    // Make sure the function inside useEffect is declared and invoked
    const fetchData = async () => {
      setIsLoading(true);
      console.log(" inside fetch data");
      try {
        const { key_id } = await getPublicKeyRsa();

        console.log({ key_id });

        // Encryption of sensitive data

        const timestamp = moment().format("YYYYMMDDHHmmss");
        const ORDERNO = `GRTD${timestamp}`;

        const params = {
          order_id: rsaEncrypt(ORDERNO, key_id.Public_Key),
          amount: rsaEncrypt(amount, key_id.Public_Key),
          sessionUuid: key_id.Session_Uuid,
        };

        const response = await axios.post(
          "/ccavRequestHandler",
          params // Sending the generated query string directly as part of the request
        );
        console.log("Response:", response.data);

        // Render the response HTML into an element on the page
        const responseContainer = document.createElement("div");
        responseContainer.innerHTML = response.data;

        // Append the form to the document body
        document.body.appendChild(responseContainer.firstChild);

        // Submit the form to redirect to the payment gateway
        document.getElementById("nonseamless").submit();
      } catch (error) {
        console.error("ccavRequestHandler", error);
      }
    };

    if (paymentRequest) {
      fetchData(); // Invoke the async function to make the API call
    }
  }, [paymentRequest]); // Empty dependency array to run this effect only once on mount

  return (
    <section className="w-[80%] mx-auto font-tenorsans">
      <div className="flex justify-center text-sm md:text-lg lg:text-xl pt-40 pb-4">
        Checkout
      </div>

      <div class="relative flex items-center mb-2">
        {/* <div class="flex-grow border-t border-gray-400"></div> */}
        <span class="flex-shrink mr-4 text-sm lg:text-base uppercase">
          Billing Details
        </span>
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      <div className="mt-3 mb-3 lg:mt-5 lg:mb-10">
        <BillingAddressCardv2
          billing_id={13}
          first_name="Kripa Charya"
          last_name="Chettri"
          address_line1="Development Area"
          address_line2="Below Little Pixies School"
          landmark=""
          city_name="Gangtok"
          state_name="Sikkim"
          pincode={737101}
          country_name="India"
          phone_number="8597355169"
        />
      </div>

      <div className="border mt-8 md:mt-0 px-3 py-4 mb-20">
        <div class="relative flex items-center">
          {/* <div class="flex-grow border-t border-gray-400"></div> */}
          <span class="flex-shrink pr-3 text-sm lg:text-base">Your Order</span>
          <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div className="mt-5 flex justify-between border-b border-gray-400">
          <p className="text-xs lg:text-sm text-gray-700">Product</p>
          <p className="text-xs lg:text-sm text-gray-700">Total</p>
        </div>

        <div className="border-b border-gray-400 pb-5">
          <div className="mt-5 flex justify-between gap-x-3 lg:gap-x-0">
            <p className="text-xs lg:text-sm">
              {/* {cartObj?.product_details?.product_name} ({cartObj.size_name}){" "}
              <span className="font-bold">x</span> {cartObj.quantity} */}
              Oatmeal Coachella Crewneck (M){" "}
              <span className="font-bold">x</span> {1}
            </p>
            <p className="text-xs lg:text-sm">
              <>
                {/* {cartObj.discount_percentage === 0 ? (
                  <span>₹ {cartObj.quantity * cartObj.product_rate}</span>
                ) : (
                  <span>
                    ₹{" "}
                    {cartObj.quantity *
                      (cartObj?.product_rate -
                        (cartObj?.discount_percentage / 100) *
                          cartObj?.product_rate)}
                  </span>
                )} */}
                ₹ {amount}
              </>
            </p>
          </div>
        </div>

        <div className="pt-2 pb-3 flex justify-between border-b border-gray-400">
          <p className="text-xs lg:text-sm text-gray-700">Cart Subtotal</p>
          {/* <p className="text-xs lg:text-sm">₹ {cartTotal}</p> */}
          <p className="text-xs lg:text-sm">₹ {amount}</p>
        </div>

        <div className="pt-2 pb-3 flex justify-between border-b border-gray-400">
          <p className="text-xs lg:text-sm text-gray-700">Delivery Charges</p>
          {/* <p className="text-xs lg:text-sm">₹ {deliverycharge}</p> */}
          <p className="text-xs lg:text-sm">₹ {0}</p>
        </div>

        <div className="text-xs text-red-700 pt-3 pb-8">
          Delivery fee is non-refundable.
        </div>

        <div className="pt-2 pb-3 flex justify-between">
          <p className="text-xs lg:text-sm text-gray-700">Order Total</p>
          {/* <p className="text-xs lg:text-sm">₹ {orderTotal}</p> */}
          <p className="text-xs lg:text-sm">₹ {amount}</p>
        </div>
        <label> Change Amount</label>
        <input
          type="number"
          min={0}
          className="ml-3"
          defaultValue={amount}
          onChange={(e) => setAmount(parseFloat(e.target.value).toFixed(2))}
        ></input>

        {isLoading ? (
          <>
            <div className="relative mt-32">
              <AnimatedLoader />
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => onProceedPaymentClickHandler()}
              className="mt-10 mb-5 flex justify-center cursor-pointer bg-white text-black text-xs border border-black hover:bg-black hover:text-white transition-all ease-in-out duration-300 rounded-sm px-3 py-1 lg:px-5 lg:py-2 uppercase"
            >
              {paymentRequest ? "Create payment request" : "Proceed to payment"}
            </div>
          </>
        )}
      </div>

      <div className=" py-10 border-t">
        <div className="flex justify-center text-sm md:text-lg lg:text-xl py-3 pb-4">
          Check Order Status
        </div>
        <label className="w-40">Order Id</label>
        <input
          className=" ml-6 p-1"
          onChange={(e) => {
            setOrderNo(e.target.value);
          }}
        ></input>
        <br></br>
        <br></br>

        <label className="w-40">Ref No </label>
        <input
          className=" ml-6 p-1"
          onChange={(e) => {
            setRefNo(e.target.value);
          }}
        ></input>
        <br></br>
        <br></br>
        <button
          onClick={() => checkOrderStatus()}
          className="bg-black text-white px-9 py-3"
        >
          Check Order Status
        </button>
      </div>

      <div className="bg-yellow-100 p-9">
        {orderStatusData?.order_no ? (
          <>
            {
              <div>
                <h2 className="font font-extrabold text-2xl text-center ">
                  Order Status Details
                </h2>
                <p>Reference No: {orderStatusData.reference_no}</p>
                <p>Order No: {orderStatusData.order_no}</p>
                <p>Order Currency: {orderStatusData.order_currncy}</p>
                <p>Order Amount: {orderStatusData.order_amt}</p>
                <p>Order Date and Time: {orderStatusData.order_date_time}</p>
                <p>Order Status: {orderStatusData.order_status}</p>
                <p>Bank Response: {orderStatusData.order_bank_response}</p>
              </div>
            }
          </>
        ) : (
          <>test nulll</>
        )}
      </div>
    </section>
  );
};

export default PaymentUATEnv;

const BillingAddressCardv2 = ({
  billing_id,
  first_name,
  last_name,
  address_line1,
  address_line2,
  landmark,
  city_name,
  state_name,
  pincode,
  country_name,
  phone_number,
}) => {
  return (
    <>
      <div
        className={`px-3 py-1 border  rounded-sm cursor-pointer font-tenorsans border-black`}
      >
        <div className="font-bold text-sm">
          {first_name} {last_name}
        </div>
        <div className="text-xs">{address_line1}</div>
        <div className="text-xs">{address_line2}</div>
        {landmark !== null ? <div className="text-xs">{landmark}</div> : null}
        <div className="uppercase text-xs">
          {city_name}, {state_name} - {pincode}
        </div>
        <div className="text-xs">{country_name}</div>
        <div className="text-xs">Phone number: {phone_number}</div>
      </div>
    </>
  );
};
