import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/latest_logo/GratitudeLogoMontserrat_nobg.png";
import { FiSettings } from "react-icons/fi";
import { BiBookContent } from "react-icons/bi";
import { MdOutlineInventory } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { GiClothes } from "react-icons/gi";
import { MdOutlineSettingsInputComposite } from "react-icons/md";
import { RiImageAddLine } from "react-icons/ri";
import { FiShoppingBag } from "react-icons/fi";
import { IoMdLocate } from "react-icons/io";
import { FiUserPlus } from "react-icons/fi";
import { MdAssignmentReturned } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";

function Sidebar({ sidebarOpen, setSidebarOpen, sidebarType }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const [showSubConfig, setShowSubConfig] = useState(true);
  const [showCMSSubConfig, setShowCMSSubConfig] = useState(true);
  const [showOrdersSubConfig, setShowOrdersSubConfig] = useState(true);

  const configClickHandler = () => {
    setShowSubConfig(!showSubConfig);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto 
        lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-44 
        lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-56 shrink-0 bg-white border border-r p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-44"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-700 hover:text-slate-800"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="#" className="block">
            <div className="flex items-center">
              {/* <img src={Logo} width="40" height="40" /> */}

              {sidebarExpanded && (
                // <p className="text-white text-sm font-medium pl-3">Gratitude</p>
                <img src={Logo} width="200" height="40" />
              )}
            </div>
          </NavLink>
        </div>

        {/* Links */}
        <div
          className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center"
          // onClick={() => configClickHandler()}
        >
          {/* Pages group */}
          <div className="">
            <BiBookContent />
          </div>
          <p className="text-black font-bold">Content Management</p>
        </div>
        {showCMSSubConfig && (
          <div className="ml-10 text-sm transition ease-in-out">
            <Link to="/gratitude-admin/about-us-details-config">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/about-us-details-config"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                About Us
              </p>
            </Link>

            <Link to="/gratitude-admin/legal-notice-details-config">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/legal-notice-details-config"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Legal Notice
              </p>
            </Link>

            <Link to="/gratitude-admin/return-refund-policy-details-config">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/return-refund-policy-details-config"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Return-Refund Policy
              </p>
            </Link>

            <Link to="/gratitude-admin/shipping-policy-details-config">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/shipping-policy-details-config"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Shipping Policy
              </p>
            </Link>
          </div>
        )}

        <div
          className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center"
          // onClick={() => configClickHandler()}
        >
          {/* Pages group */}
          <div className="">
            <FiSettings />
          </div>
          <p className="text-black font-bold">Master Configuration</p>
        </div>
        {showSubConfig && (
          <div className="ml-10 text-sm transition ease-in-out">
            <Link to="/gratitude-admin/master-product-type">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-product-type"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Product Type
              </p>
            </Link>

            <Link to="/gratitude-admin/master-gender">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-gender"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Gender
              </p>
            </Link>

            <Link to="/gratitude-admin/master-season">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-season"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Season
              </p>
            </Link>

            <Link to="/gratitude-admin/master-wear-type">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-wear-type"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Wear Type
              </p>
            </Link>

            <Link to="/gratitude-admin/master-cloth-type">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-cloth-type"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Cloth Type
              </p>
            </Link>

            <Link to="/gratitude-admin/master-fabric-type">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-fabric-type"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Fabric Type
              </p>
            </Link>

            <Link to="/gratitude-admin/master-occasion-type">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-occasion-type"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Occasion Type
              </p>
            </Link>

            <Link to="/gratitude-admin/master-color">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-color"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Color
              </p>
            </Link>

            <Link to="/gratitude-admin/master-size">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-size"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Size
              </p>
            </Link>

            <Link to="/gratitude-admin/master-tag">
              <p
                className={`${
                  location.pathname === "/gratitude-admin/master-tag"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Product Tags
              </p>
            </Link>
          </div>
        )}
        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <GiClothes />
          </div>
          <Link
            to="/gratitude-admin/product-generic-details-config"
            className="text-black font-bold py-1"
          >
            Products
          </Link>
        </div>
        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <MdOutlineSettingsInputComposite />
          </div>
          <Link
            to="/gratitude-admin/product-variant-details-config"
            className="text-black font-bold py-1"
          >
            Product Variants
          </Link>
        </div>
        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <RiImageAddLine />
          </div>
          <Link
            to="/gratitude-admin/product-images"
            className="text-black font-bold py-1"
          >
            Product Images
          </Link>
        </div>
        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <MdOutlineInventory />
          </div>
          <Link
            to="/gratitude-admin/products-inventory"
            className="text-black font-bold py-1"
          >
            Inventory
          </Link>
        </div>

        <div
          className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center"
          // onClick={() => configClickHandler()}
        >
          {/* Pages group */}
          <div className="">
            <FiShoppingBag />
          </div>
          <p className="text-black font-bold">Orders</p>
        </div>
        {showOrdersSubConfig && (
          <div className="ml-10 text-sm transition ease-in-out">
            <Link to="/gratitude-admin/manage-orders/undelivered-orders">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/manage-orders/undelivered-orders"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                New Orders
              </p>
            </Link>

            <Link to="/gratitude-admin/manage-orders/delivered-orders">
              <p
                className={`${
                  location.pathname ===
                  "/gratitude-admin/manage-orders/delivered-orders"
                    ? "text-black font-semibold"
                    : "text-gray-700 font-medium"
                }`}
              >
                Delivered Orders
              </p>
            </Link>
          </div>
        )}

        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <IoMdLocate />
          </div>
          <Link
            to="/gratitude-admin/shipped-orders/track-orders"
            className="text-black font-bold py-1"
          >
            Shipped Orders AWB
          </Link>
        </div>

        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <MdAssignmentReturned />
          </div>
          <Link
            to="/gratitude-admin/manage-order-returns"
            className="text-black font-bold py-1"
          >
            Order Returns
          </Link>
        </div>

        <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <FiUserPlus />
          </div>
          <Link
            to="/gratitude-admin/manage-users/view-registration-requests"
            className="text-black font-bold py-1"
          >
            Registration Requests
          </Link>
        </div>

        {/* <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <TfiAnnouncement />
          </div>
          <Link
            to="/gratitude-admin/manage-users/view-newsletter-subscription-list"
            className="text-black font-bold py-1"
          >
            Newsletter Subscriptions
          </Link>
        </div> */}

        {/* <div className="text-sm cursor-pointer hover:bg-slate-200 flex gap-2 items-center">
          <div>
            <IoPricetagsOutline />
          </div>
          <Link
            to="/gratitude-admin/manage-price"
            className="text-black font-bold py-1"
          >
            Pricing
          </Link>
        </div> */}

        {/* Expand / collapse button */}
        {/* <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 ">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-200"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
              </svg>
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
