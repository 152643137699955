import { Link } from "react-router-dom";
import NotFoundGif from "../assets/404.gif";

const Missing = () => {
  return (
    <article className="h-screen flex flex-row px-20 justify-center items-center bg-white items-center">
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 md:col-span-1">
          <img src={NotFoundGif} className="w-full" />
        </div>
        <div className="col-span-3 md:col-span-1 flex flex-col flex-wrap justify-center items-center md:items-start">
          <p className="px-3 py-2 rounded-full w-max text-center bg-slate-600 text-white shadow mb-3 text-sm">
            Page not found
          </p>
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-center md:text-left">
            Oh No! Error 404
          </h1>
          <p className="text-base md:text-lg w-full md:w-2/3 text-center md:text-left">
            The page that you are looking for might be broken, removed or could
            not be found
          </p>
          <div className="w-max text-center rounded-lg shadow-lg px-2 py-3 bg-blue-900 text-white mt-4">
            <Link to="/" className="mt-3">
              Visit Our Homepage
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Missing;
