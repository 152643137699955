import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import PricingProductCard from "./pricingProductCard.component";
import ManageProductPricing from "./manageProductPricing.component";

import Dashboard from "../../../common/dashboard-components/dashboard.component";
import showToast from "../../../utilities/notification/NotificationModal";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { PRODUCT_VARIANT_DETAILS_CONFIG_URL } from "../../../api/api_routing_urls";

import axios from "../../../api/axios";

const Pricing = () => {
  const [productWithVariantDetailsList, setProductWithVariantDetailsList] =
    useState([]);

  const [showManagePriceTable, setShowManagePriceTable] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedProductName, setSelectedProductName] = useState("");

  const getProductWithVariantDetailsList = async () => {
    try {
      const response = await axios.get(PRODUCT_VARIANT_DETAILS_CONFIG_URL);
      // console.log("Product with variant details List", { response });
      response.status === 200 &&
        setProductWithVariantDetailsList(
          response.data.productsWithVariantDetailsList
        );
      response.status === 202 &&
        showToast("No product variants found in the system.", "error");
    } catch (error) {
      console.error("getProductWithVariantDetailsList", error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided.", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductWithVariantDetailsList();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dashboard>
        <ToastContainer />
        <div className="font-bold text-lg pb-5">Pricing</div>
        {!showManagePriceTable ? (
          <>
            <div className="grid grid-cols-3 gap-6">
              {productWithVariantDetailsList?.map((productObj) => {
                return (
                  <div className="">
                    <PricingProductCard
                      productObj={productObj}
                      setShowManagePriceTable={setShowManagePriceTable}
                      setSelectedProductId={setSelectedProductId}
                      setSelectedProductName={setSelectedProductName}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <ManageProductPricing
              setShowManagePriceTable={setShowManagePriceTable}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              selectedProductName={selectedProductName}
              setSelectedProductName={setSelectedProductName}
              getProductWithVariantDetailsList={
                getProductWithVariantDetailsList
              }
            />
          </>
        )}
      </Dashboard>
    </>
  );
};

export default Pricing;
